import React, { useState, useEffect } from "react";
import Amplify from "aws-amplify";
import config from "../../../aws/config";

import Select from "react-select";
import { Auth } from "aws-amplify";
import { BrowserRouter as Router, useRouteMatch } from "react-router-dom";
import DropZone from "./DropZone";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import Login from "../../login/Login";
Amplify.configure(config);

function ImageUpload(props) {
  const { path, url } = useRouteMatch();
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [opps, setOpps] = useState([]);
  const [selectedOpp, setOpp] = useState(null);
  const [resources, setResources] = useState([]);
  let [showUploadedResources, toggleUploadedResources] = useState(false);
  let [loadingImages, setLoadingImages] = useState(false);
  let [uploading, setUploading] = useState(false);

  async function fetchPrograms() {
    const session = await Auth.currentSession();

    const idToken = session.getIdToken().getJwtToken();
    await fetch(`https:api-hk.myhousekeys.com/signup/program`, {
      headers: { Authorization: idToken },
    })
      .then((rep) => rep.json())
      .then((resp) => {
        setPrograms(resp.data);
      });
  }

  async function fetchOpp() {
    if (selectedProgram == null) return;
    const session = await Auth.currentSession();

    const idToken = session.getIdToken().getJwtToken();
    await fetch(
      `https:api-hk.myhousekeys.com/signup/opportunity?programID=${selectedProgram.value}&rowCnt=50&page=1`,
      {
        headers: { Authorization: idToken },
      }
    )
      .then((rep) => rep.json())
      .then((resp) => {
        setOpps(resp.data);
      });
  }

  async function fetchImages() {
    const session = await Auth.currentSession();

    const idToken = session.getIdToken().getJwtToken();
    setLoadingImages(true);
    await fetch(
      `https:api-hk.myhousekeys.com/signup/images?prefix=${selectedProgram.label}/${selectedOpp.value}`,
      {
        headers: {
          Authorization: idToken,
        },
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        setLoadingImages(false);
        setResources(resp.data.resources);
      })
      .catch((err) => setLoadingImages(false));
  }

  useEffect(() => {
    //fetchImages();
    fetchPrograms();
  }, []);

  useEffect(() => {
    if (selectedProgram == null) return;

    fetchOpp();
  }, [selectedProgram]);

  const programOptions = programs.map((p) => ({
    label: p.Title,
    value: p.ProgramID,
  }));

  const oppOptions = opps.map((o) => ({
    label: o.program,
    value: o.id,
  }));

  function onPhotoSelected(files) {
    const url = `https://api.cloudinary.com/v1_1/housekeys/upload`;

    const formData = new FormData();

    formData.append("upload_preset", "ml_default");
    formData.append("folder", `${selectedProgram.label}/${selectedOpp.value}`);
    files.forEach((file) => {
      formData.append("file", file);

      if (file.cover) formData.append("tags", "cover");
    });
    // formData.append("tags", "cover");
    formData.append("multiple", true);
    setUploading(true);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((resp) => {
        setUploading(false);
        fetchImages();
      })
      .catch((err) => {
        setUploading(false);
      });
  }

  return (
    <div className="min-h-screen">
      <h3 className="mt-4 oblique font-bold text-md">
        Upload your images here
      </h3>
      {/* <div className="flex mt-4 items-center flex-col sm:flex-row">
        <Select
          className="w-64 mt-2 sm:ml-2"
          name="programs"
          placeholder="Select a Program"
          options={programOptions}
          value={selectedProgram}
          onChange={setSelectedProgram}
        />
        {!!selectedProgram && (
          <Select
            className="w-64 mt-4 sm:mt-2 sm:ml-2"
            name="opportunities"
            placeholder="Select a Project"
            options={oppOptions}
            value={selectedOpp}
            onChange={setOpp}
            disabled={selectedProgram == null}
          />
        )}
      </div>
      {!!selectedOpp && (
        <section data-test="img-uploader" className="mt-4 p-8">
          
          <DropZone multiple onPhotoSelected={onPhotoSelected} />
        </section>
      )}
      <section className="mt-4 mb-2 flex flex-col">
        {!!selectedOpp && (
          <button
            onClick={() => toggleUploadedResources(!showUploadedResources)}
            className="mt-4 font-semibold text-md text-gray-800 oblique self-start ml-6"
          >
            Uploaded resources{" "}
            {showUploadedResources ? (
              <MdExpandMore className="inline" />
            ) : (
              <MdExpandLess onClick={fetchImages} className="inline" />
            )}
          </button>
        )}

        {resources.length > 0 && showUploadedResources ? (
          <CloudinaryContext cloudName="housekeys">
            <div className="flex justify-between items-center flex-wrap mt-2 px-4">
              {resources.map((r) => {
                return (
                  <a href={r.secure_url}>
                    <Image
                      key={r.public_id}
                      publicId={r.public_id}
                      className="thumbnail inline ml-2 mt-2 rounded-sm"
                      width="350"
                      height="350"
                      crop="fit"
                      quality="80"
                    >
                      <Transformation quality="auto" fetchFormat="auto" />
                    </Image>
                  </a>
                );
              })}
            </div>
          </CloudinaryContext>
        ) : null}
      </section> */}
    </div>
  );

  function uploadWidget() {
    window.cloudinary.openUploadWidget(
      {
        cloud_name: "housekeys",
        upload_preset: "ml_default",
        folder: `${selectedProgram.label}/${selectedOpp.value}`,
      },
      (err, res) => {
        console.log(res);
      }
    );
  }
}

export default ImageUpload;
