import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PeopleIcon from "@material-ui/icons/People";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import DrawingApplicants from "../DrawingApplicants";
import Loading from "../../../../shared/misc/Loading";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function RankingsManager({
  open,
  onClose,
  onRankingCreation,
  programId,
}) {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);

  const handleProcessing = (processing) => {
    setProcessing(processing);
  };

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        Create Ranking
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <section className="mt-4">
          <div className="flex justify-center flex-col">
            <div className="flex justify-center items-center">
              <PeopleIcon />

              <span className="px-2 pt-2">Select Applicants</span>
              {processing && <Loading />}
            </div>
            <DrawingApplicants
              onProcessing={handleProcessing}
              onRankingCreation={onRankingCreation}
              programId={programId}
            />
          </div>
        </section>
      </DialogContent>
    </Dialog>
  );
}
