import React, { useEffect, useReducer } from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useRouteMatch } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Select from "../shared/Select";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

import MaterialUITable from "../shared/Table/MaterialUITable";

type ReducerState = {
  firstName?: string;
  lastName?: string;
  program?: string;
  drawingId?: string;
  drawings: any[];
  programs: any[];
  applicants: any[];
  showLoading: boolean;
};

function reducer(state: ReducerState, action: { type: string; payload: any }) {
  return { ...state, [action.type]: action.payload };
}

export default function AdminFileCabinet() {
  const [state, dispatch] = useReducer(reducer, {});
  const {
    firstName = "",
    lastName = "",
    drawingId,
    drawings = [],
    applicants = [],
    showLoading = false,
  } = state;

  const history = useHistory();
  const { path } = useRouteMatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: name,
      payload: value,
    });
  };

  useEffect(() => {
    fetchDrawings();
    //fetchPrograms();
  }, []);

  const tableColumns = [
    { title: "First ", field: "firstName" },
    {
      title: "Last",
      field: "lastName",
    },
    { title: "Member ID", field: "memberId" },
    { title: "User ID ", field: "userId" },
  ];
  return (
    <section className="min-h-screen ">
      <h1 className="bg-hk-orange-100 text-hk-orange-400 text-2xl uppercase p-2 h-20 flex items-center justify-center">
        Admin file cabinet
      </h1>
      <form className="flex flex-col sm:flex-row sm:justify-between items-baseline p-6">
        <Select
          label="Drawings"
          name="drawingId"
          onChange={handleChange}
          options={drawings.map((d) => ({
            label: `${d.ProgramTitle} (${d.LotteryScheduleID})`,
            value: d.LotteryScheduleID,
          }))}
          value={drawingId}
        />
        <TextField
          name="firstName"
          onChange={handleChange}
          label="Applicant First Name"
          value={firstName}
        />
        <TextField
          name="lastName"
          onChange={handleChange}
          label="Applicant Last Name"
          value={lastName}
        />

        <Button
          disabled={!drawingId}
          onClick={loadApplicants}
          variant="contained"
          size="small"
          color="primary"
        >
          Search
        </Button>
      </form>

      <section className="mt-6 p-6">
        <MaterialUITable
          isLoading={showLoading}
          columns={tableColumns}
          stickyHeader
          className="p-4 mt-4"
          data={applicants}
          title={`Applicants (${applicants.length})`}
          actions={[
            {
              icon: ChevronRightIcon,
              tooltip: "View Files",

              onClick: (event, rowData) => {
                history.push({
                  pathname: `${path}/files/${rowData.memberId}`,
                });
              },
            },
          ]}
          options={{
            exportButton: true,
            pageSize: 10,
            grouping: true,
            actionsColumnIndex: -1,
          }}
        />
      </section>
    </section>
  );

  async function fetchDrawings() {
    dispatch({
      type: "showLoading",
      payload: true,
    });
    const {
      data: { data: drawings },
    } = await axios.get(`${getBaseUrl()}/drawings?rowCnt=500&page=1`);
    dispatch({
      type: "showLoading",
      payload: false,
    });
    dispatch({
      type: "drawings",
      payload: drawings,
    });
  }

  async function fetchPrograms() {
    dispatch({
      type: "showLoading",
      payload: true,
    });
    const {
      data: { data: programs },
    } = await axios.get(`${getBaseUrl()}/programs?rowCnt=100&page=1`);
    dispatch({
      type: "showLoading",
      payload: false,
    });
    dispatch({
      type: "programs",
      payload: programs,
    });
  }

  async function loadApplicants(e) {
    e.preventDefault();
    dispatch({
      type: "showLoading",
      payload: true,
    });
    const {
      data: { data: applicants },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/applicants?firstName=${firstName}&lastName=${lastName}`
    );
    dispatch({
      type: "showLoading",
      payload: false,
    });
    dispatch({
      type: "applicants",
      payload: applicants,
    });
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type Applicant = {
  userId: string;
  memberId: string;
  firstName: string;
  lastName: string;
};
