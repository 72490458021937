import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HhldPrograms from "./HhldPrograms";
import { HhldMarketplace } from "./HhldMarketplace";
import HhldActivityLog from "./HhldActivityLog";
import HhldFAQs from "./faq/HhldFAQs";
import HhldOpportunities from "./HhldOpportunities";
import HhldOpportunityDrawings from "./HhldOpportunityDrawings";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const tabsPathToIndex = {
  "/hhld/programs": 0,
  "/hhld/opportunities": 1,
  "/hhld/drawings": 2,
  "/hhld/mkt-place": 3,
  "/hhld/faqs": 4,
};

const tabsIndexToPath = {
  0: "/hhld/programs",
  1: "/hhld/opportunities",
  2: "/hhld/drawings",
  3: "/hhld/mkt-place",
  4: "/hhld/faqs",
};

export default function HhldProgramCenter() {
  const { pathname } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState(tabsPathToIndex[pathname]);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    history.push(tabsIndexToPath[newValue]);
    setValue(newValue);
  };

  useEffect(() => {
    setValue(tabsPathToIndex[pathname]);
  }, [pathname]);

  return (
    <section className={classes.root}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Program Center tabs"
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab label="Programs" />
        <Tab label="Opportunities" />
        <Tab label="Drawings" />
        <Tab label="Market Place" />
        <Tab label="FAQ" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <HhldPrograms />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HhldOpportunities />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <HhldOpportunityDrawings />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <HhldMarketplace />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <HhldFAQs />
      </TabPanel>
    </section>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}
