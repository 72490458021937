import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardContent, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    height: 400,
    overflowY: "scroll",
    position: "relative",

    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    cursor: "pointer",
    "&:hover": {
      boxShadow: "10px 10px 10px 10px  #edf2f7",
    },
  },
  header: {
    position: "sticky",
    top: 0,
    left: 0,
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export default function HHldPreAppCard({ applications }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        onClick={() => history.push("/hhld/programs")}
        disableTypography
        subheader={
          <Tooltip title="Click to go to Program Center">
            <div className="bg-hk-orange-100 p-2 flex flex-col">
              <span className="text-lg tracking-wider text-gray-600">{`My Programs (${applications.length})`}</span>
              <span className="text-xs text-gray-400 oblique">
                (Progams I've received a pre-application id for)
              </span>
            </div>
          </Tooltip>
        }
      ></CardHeader>
      <CardContent>
        <div>
          {applications.map((a) => (
            <CardItem key={a.ApplicationFileID} application={a} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

function CardItem({ application }) {
  const {
    ApplicationFileID,
    EntityName,
    ProgramTitle,
    ProgramTypeTitle,
    RequestDate,
    HHLDProgramID,
    DateAssigned,
  } = application;
  return (
    <div className="py-4  px-6 rounded flex flex-col mt-2 text-sm border border-gray-300">
      <span className="">{ProgramTitle}</span>
      <span className="text-gray-600 mt-2">{EntityName}</span>
      <span className="mt-4 text-hk-dark-blue p-2 rounded  font-semibold bg-hk-blue-100">
        {ApplicationFileID}
      </span>
    </div>
  );
}
