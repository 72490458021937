import React, { useState, useRef, useEffect, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import throttle from "lodash/throttle";
import axios from "../../../utils/axios";

const { REACT_APP_SMARTY } = process.env;

export default function Address({ userId, onAddressChange, ...other }) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const zipcode = value?.zipcode ?? "";

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        fetchAdd(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "" || inputValue === other.defaultValue) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    (async () => {
      if (zipcode) {
        const countyName = await getCountyName();

        onAddressChange({ ...value, county: countyName });
      } else {
        onAddressChange(null);
      }
    })();
  }, [zipcode]);

  return (
    <Autocomplete
      id="autcomplete_address"
      style={{ width: "100%", maxWidth: 300 }}
      getOptionLabel={(option) => {
        return typeof option === "string"
          ? option
          : `${option.street_line}, ${option.city}, ${option.state}, ${option.zipcode}`;
      }}
      defaultValue={other.defaultValue}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      popupIcon={null}
      includeInputInList
      filterSelectedOptions
      value={value !== null ? value : other.defaultValue}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Address" variant="outlined" fullWidth />
      )}
      {...other}
    />
  );

  async function fetchAdd({ input: street }, callback) {
    const resp = await axios
      .get(
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?
key=${REACT_APP_SMARTY}&search=${street}`
      )
      .catch((err) => {
        console.log("error getting address suggestions");
      });

    callback(resp?.data?.suggestions ?? []);
  }

  async function getCountyName() {
    const { data: zips } = await axios
      .get(
        `https://us-zipcode.api.smartystreets.com/lookup?key=${REACT_APP_SMARTY}&zipcode=${value?.zipcode}`
      )
      .catch((err) => {});
    //console.log("zips", zips);
    const [r] = zips?.[0]?.zipcodes ?? {};
    return r?.["county_name"] ?? "";
  }
}
