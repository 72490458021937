export const propTypeOptions = [
  { label: "All", value: 0 },
  { label: "Ownership", value: 1 },
  { label: "Rental", value: 2 },
  { label: "Loan or Financing", value: 3 },
];

export const incomeLevelOptions = [
  { label: "All Income Levels", value: 0 },
  { label: "Extremely Low", value: "Extremely Low" },
  { label: "Very Low", value: "Very Low" },
  { label: "Low", value: "Low" },
  { label: "Median", value: "Median" },
  { label: "Moderate", value: "Moderate" },
];

export const numBedsOptions = [
  { label: "All Beds", value: 0 },
  { label: "Studio", value: "studio" },
  { label: "1 Bed", value: 1 },
  { label: "2 Beds", value: 2 },
  { label: "3 Beds", value: 3 },
  { label: "4 Beds", value: 4 },
  { label: "5 Beds", value: 5 },
];

export const homeTypeOptions = [
  { label: "All Home Types", value: "All Home Types" },
  { label: "Single-Room Occupancy", value: "single" },
  { label: "Condominium", value: "condo" },
  { label: "Townhome", value: "townhome" },
  { label: "Duplex", value: "duplex" },
  { label: "Single-Family Detached", value: "single-detached" },
  { label: "Single-Family Attached", value: "single-attached" },
  { label: "Stacked Flat", value: "flat" },
  { label: "Apartment Unit", value: "aunit" },
];

export const drawingTypeOptions = [
  { label: "Lottery Selection", value: "lottery" },
  { label: "First Form First Served", value: "fform" },
  { label: "First File First Served", value: "ffile" },
  { label: "First Application ID First Served", value: "fapp" },
];

export const applicationStatusOptions = [
  { label: "Yes", value: 1 },
  { label: "No, we are NOT accepting Applications", value: 2 },
  { label: "In-Progress", value: 3 },
];

export const opportunityStatusOptions = [
  { label: "All", value: 0 },
  { label: "Open", value: 3 },
  { label: "Closed", value: 5 },
];
