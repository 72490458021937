import React, { useState, useEffect } from "react";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import IncomeTypes from "./IncomeTypes";
import EmployerIncomeForm from "./EmployerIncomeForm";
import SelfEmployedIncomeForm from "./SelfEmployedIncomeForm";
import UnEmploymentIncomeForm from "./UnEmploymentIncomeForm";
import RetirementIncomeForm from "./RetirementIncomeForm";
import SpousalSupportIncomeForm from "./SpousalSupportIncomeForm";
import ChildSupportIncomeForm from "./ChildSupportIncomeForm";
import AffidavitIncomeForm from "./AffidavitIncomeForm";

export default function IncomeForm({
  handleChange,
  fields,
  dispatch,
  showIncomeTypes,
}) {
  const { incomeType } = fields;
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    fetchIndustries();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {showIncomeTypes && (
        <IncomeTypes incomeType={incomeType} handleChange={handleChange} />
      )}

      {incomeType == "" && <div className="filler min-h-1/2"></div>}

      {incomeType === "E" && (
        <EmployerIncomeForm
          fields={fields}
          handleChange={handleChange}
          handleEmployerAddressChange={handleEmployerAddressChange}
          industries={industries}
        />
      )}
      {incomeType === "S" && (
        <SelfEmployedIncomeForm
          fields={fields}
          handleChange={handleChange}
          handleEmployerAddressChange={handleEmployerAddressChange}
        />
      )}
      {incomeType === "U" && (
        <UnEmploymentIncomeForm
          fields={fields}
          handleChange={handleChange}
          handleEmployerAddressChange={handleEmployerAddressChange}
        />
      )}
      {(incomeType === "R" || incomeType === "D") && (
        <RetirementIncomeForm
          fields={fields}
          handleChange={handleChange}
          handleEmployerAddressChange={handleEmployerAddressChange}
        />
      )}
      {incomeType === "SS" && (
        <SpousalSupportIncomeForm fields={fields} handleChange={handleChange} />
      )}
      {incomeType === "CS" && (
        <ChildSupportIncomeForm fields={fields} handleChange={handleChange} />
      )}
      {incomeType === "A" && (
        <AffidavitIncomeForm fields={fields} handleChange={handleChange} />
      )}
    </div>
  );

  async function fetchIndustries() {
    try {
      const {
        data: { data: industries },
      } = await axios.get(`${getBaseUrl()}/programs/industries`);

      setIndustries(industries);
    } catch (err) {
      console.error(`error getting industries ${err}`);
      setIndustries([]);
    }
  }

  function handleEmployerAddressChange(address) {
    dispatch({
      type: "employerAddr",
      payload: address?.street_line ?? "",
    });
    dispatch({
      type: "employerCity",
      payload: address?.city ?? "",
    });
    dispatch({
      type: "employerState",
      payload: address?.state ?? "",
    });
    dispatch({
      type: "employerZip",
      payload: address?.zipcode ?? "",
    });
  }
}
