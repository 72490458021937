import React, { useReducer } from "react";
import { Auth } from "aws-amplify";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  containsLowerCase,
  containsUpperCase,
  containsNumber,
  lengthGreaterThanOrEqual,
  hasSpecialChars,
} from "../../../utils/jsUtils";
import PasswordPolicy from "../PasswordPolicy";
import FormGroup from "../../shared/form/FormGroup";
import PopperMsg from "../../shared/misc/PopperMsg";
import { usePreferredLanguage } from "../../../translations/LanguageContext";
import messages_en from "../../../translations/en.json";
import messages_esp from "../../../translations/espanol.json";
import { Tooltip } from "@material-ui/core";

const messages = {
  es: messages_esp,
  en: messages_en,
};

type SignupProps = {
  setStatus: Function;
};

type ReducerState = {
  userName: string;
  password: string;
  firstName: string;
  lastName: string;
  processing: boolean;
  showMessage: boolean;
  passwordFocus: boolean;
};

type ReducerAction = {
  type: string;
  payload: string | number | boolean;
};

function reducer(state: ReducerState, action: ReducerAction) {
  return { ...state, [action.type]: action.payload };
}

export default function Signup({ setStatus }: SignupProps) {
  const [state, dispatch] = useReducer(reducer, {
    userName: "",
    password: "",
    firstName: "",
    lastName: "",
    processing: false,
    showMessage: false,
    passwordFocus: false,
  });

  const {
    userName,
    password,
    firstName,
    lastName,
    passwordFocus,
    processing,
    showMessage,
  } = state;

  const { preferredLang } = usePreferredLanguage();

  const passwordAtLeast8Chars = lengthGreaterThanOrEqual(8);
  const passwordPolicySatisfied =
    passwordAtLeast8Chars(password) &&
    containsNumber(password) &&
    containsUpperCase(password) &&
    containsLowerCase(password) &&
    hasSpecialChars(password);

  const submitDisabled =
    !passwordPolicySatisfied ||
    !firstName ||
    !lastName ||
    !userName ||
    !password;

  const submitDisabledReasons = getSubmitDisabledReasons(
    passwordPolicySatisfied,
    state
  );

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    dispatch({
      type: name,
      payload: value,
    });
  };

  return (
    <form className="w-full sm:w-96 shadow-md p-8" onSubmit={handleSubmit}>
      <div className="border-b border-gray-200 py-2">
        <AccountCircleIcon fontSize="large" className="text-hk-orange-300" />
      </div>
      <p className=" text-xl text-gray-700 pt-4">
        <FormattedMessage
          id="signup.header"
          defaultMessage="Create a new account"
        />
      </p>

      <div className="mt-4 space-y-2 flex flex-col">
        <FormGroup
          label={messages[preferredLang]["signup.username"]}
          required
          type="text"
          handleChange={handleChange}
          value={userName}
          id="password"
          placeholder={messages[preferredLang]["signup.username.placeholder"]}
          name="userName"
        />
      </div>
      <FormGroup
        onFocus={(e) => dispatch({ type: "passwordFocus", payload: true })}
        onBlur={(e) => dispatch({ type: "passwordFocus", payload: false })}
        label={messages[preferredLang]["signup.password"]}
        required
        type="password"
        handleChange={handleChange}
        value={password}
        id="password"
        placeholder={messages[preferredLang]["signup.password.placeholder"]}
        name="password"
      />
      {passwordFocus && (
        <PasswordPolicy
          meetsLengthReq={passwordAtLeast8Chars(password)}
          meetsLowerCaseReq={containsLowerCase(password)}
          meetsNumberReq={containsNumber(password)}
          meetsSpecialCharsReq={hasSpecialChars(password)}
          meetsUpperCaseReq={containsUpperCase(password)}
        />
      )}

      <FormGroup
        label={messages[preferredLang]["signup.firstname"]}
        required
        type="firstName"
        handleChange={handleChange}
        value={firstName}
        id="firstName"
        name="firstName"
      />

      <FormGroup
        label={messages[preferredLang]["signup.lastname"]}
        required
        type="lastName"
        handleChange={(e: React.FormEvent<HTMLInputElement>) => handleChange(e)}
        value={lastName}
        id="lastName"
        name="lastName"
      />

      <div className="mt-6 flex flex-col sm:flex-row justify-between items-baseline text-xs">
        <div className="flex space-x-2 mt-2">
          <span className="text-gray-400">
            <FormattedMessage
              id="signup.have_account"
              defaultMessage="Have an account?"
            />
          </span>
          <a
            href="#"
            id="sign-up-link"
            onClick={() => setStatus("sign-in")}
            className="text-hk-orange-400 hover:underline"
          >
            <FormattedMessage id="signup.signin" defaultMessage="Sign in" />
          </a>
        </div>
        <Tooltip title={submitDisabledReasons}>
          <button
            disabled={submitDisabled || processing}
            className=" w-full mt-4 tracking-wide sm:w-32 order-first sm:order-last  h-10 uppercase bg-hk-orange-400 text-white font-semibold disabled:opacity-50 disabled:cursor-default"
            type="submit"
          >
            {processing ? (
              <CircularProgress />
            ) : (
              <FormattedMessage
                id="signup.create_account"
                defaultMessage="Create Account"
              />
            )}
          </button>
        </Tooltip>
      </div>
      <PopperMsg
        open={showMessage}
        msgType="success"
        handleClose={() => {
          dispatch({
            type: "showMessage",
            payload: false,
          });
          setStatus("confirm-sign-up");
        }}
      >
        <FormattedMessage
          id="signup.success"
          defaultMessage="Signup successful. Please check your email for the verification code."
        />
      </PopperMsg>
    </form>
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    dispatch({
      type: "processing",
      payload: true,
    });

    Auth.signUp({
      username: userName,
      password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
      },
    })
      .then(
        (resp) => {
          dispatch({
            type: "showMessage",
            payload: true,
          });
          dispatch({
            type: "processing",
            payload: false,
          });

          setTimeout(() => {
            setStatus("confirm-sign-up");
          }, 1000);
        },
        (reject) => {
          dispatch({
            type: "processing",
            payload: false,
          });
        }
      )
      .catch((err) => {
        dispatch({
          type: "processing",
          payload: false,
        });
      });
  }
}

function getSubmitDisabledReasons(passwordPolicySatisfied, fields) {
  const { firstName, lastName, userName, password } = fields;
  let reasons = [];
  if (!passwordPolicySatisfied) {
    reasons.push("password does not meet requirements");
  }
  if (!firstName) {
    reasons.push("First name is required field");
  }
  if (!lastName) {
    reasons.push("Last name is required field");
  }
  if (!userName) {
    reasons.push("User name is required field");
  }
  return reasons.join(",");
}
