import React, { createContext } from "react";

interface UserCtx {
  user: User;
  setUser: () => void;
}

export type User = {
  accountStatus?: string;
  email?: string;
  entityId?: string;
  firstName?: string;
  lastName?: string;
  incId?: string;
  profileDisplay?: string;
  userId?: string;
  memberId?: string;
  profileType?: string;
  hhldProgramId?: string;
  role_admin?: number;
  emulated: boolean;
};

const UserContext = createContext<UserCtx>({
  user: {
    emulated: false
  },
  setUser: () => {},
});

export default UserContext;
