import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getRankingStepsWithDate } from "../../../../utils/drawingUtils";
import axios from "../../../../utils/axios";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import { formatDateToTimeZone } from "../../../../utils/formatters";
import Loading from "../../../shared/misc/Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "red",
  },
  step: {
    fontSize: "0.5rem",
  },
  label: {
    "&& .MuiStepLabel-label": {
      fontSize: "0.8rem",
    },
  },
  actionLabel: {
    "&& .MuiTypography-body1": {
      fontSize: "0.8rem",
    },
  },
  actionDate: {
    caption: {
      marginLeft: "0.2rem",
      fontSize: "0.5rem",
      color: "#cbd5e0",
    },
  },
}));

export default function RankingTimleine({ rankingId }) {
  const classes = useStyles();
  const [audits, setAudits] = useState([]);
  const [loadingAudits, setLoadingAudits] = useState(true);

  const steps = getRankingStepsWithDate(audits);
  const activeStep = getActiveStepFromAudits(steps);

  useEffect(() => {
    (async () => {
      setLoadingAudits(true);
      const {
        data: { data: audits = [] },
      } = await axios.get(`${getBaseUrl()}/rankings/${rankingId}/audits`);
      setAudits(audits);
      setLoadingAudits(false);
    })();
  }, []);

  if (loadingAudits) return <Loading />;

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          return (
            <Step key={label.action}>
              <StepLabel className={classes.label}>
                <Typography
                  className={classes.actionLabel}
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  color="textPrimary"
                  display="inline"
                >
                  {label.action}
                </Typography>
                {label.date !== "N/A" && (
                  <span className="ml-2">
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      display="inline"
                    >{`(${formatDateToTimeZone(
                      label.date,
                      "MM/dd/yyyy h:mm:ss a"
                    )})`}</Typography>
                  </span>
                )}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

function getActiveStepFromAudits(sortedAudits) {
  const indexOfLastActionWithNoDate = sortedAudits.findIndex(
    (a) => a.date === "N/A"
  );
  if (indexOfLastActionWithNoDate === -1) return 5;
  return indexOfLastActionWithNoDate;
}
