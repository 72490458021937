import React, { useState, useReducer, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import SearchBar from "./SearchBar";
import "./OpportunityList.css";
import Map from "../maps/MapGl";

import { GiHamburgerMenu } from "react-icons/gi";
import Select from "../components/shared/Select";
import OpportunityCard from "./OpportunityCard";
import AddOpportunity from "./AddOpportunity";
import useOpportunityFilters from "./useOpportunityFilters";
import UserContext from "../components/user/UserContext";

import axios from "../utils/axios";
import { getBaseUrl } from "../utils/queryHelpers";
import { extractLatAndLong } from "../utils/mapHelpers";
import {
  propTypeOptions,
  incomeLevelOptions,
  numBedsOptions,
  applicationStatusOptions,
  opportunityStatusOptions,
} from "./filterOptions";

function OpportunityList(props) {
  const history = useHistory();

  const [state, dispatch] = useOpportunityFilters();
  const { user } = useContext(UserContext) || {};

  const {
    incomeLevel,
    numBeds,
    propertyType,
    loadingOpportunites,
    filteredOpps,
    oppStatus,
  } = state;

  const [msaOptions, setMSAOptions] = useState([]);
  const [msa, setMSA] = useState(msaOptions[0]);

  const [showFilters, toggleFilters] = useState(false);
  const [showAddOppModal, setShowAddOppModal] = useState(false);
  const { search } = history.location;

  useEffect(() => {
    //fetchMsa();
  }, []);

  useEffect(() => {
    fetchOpportunities();
  }, []);

  return (
    <section className="bg-custom-white min-h-screen">
      <div className="flex justify-between pt-2 px-2 w-full">
        <SearchBar setOpps={() => {}} />
        <GiHamburgerMenu
          className="inline-block h-full sm:hidden ml-8 text-3xl text-gray-600"
          onClick={() => toggleFilters(!showFilters)}
        />
      </div>

      <div className="hidden sm:flex p-2 sm:mt-4 flex-wrap ">
        <Select
          className="w-64 mt-2 ml-2"
          name="msa"
          label="MSA"
          options={msaOptions}
          value={msa}
          onChange={setMSA}
        />
        <Select
          className="w-64 ml-2 mt-2"
          name="propertyType"
          options={propTypeOptions}
          label="Opportunity Type"
          value={propertyType}
          onChange={handleFilterChange}
        />
        <Select
          name="incomeLevel"
          className="w-64  ml-2 mt-2"
          options={incomeLevelOptions}
          value={incomeLevel}
          label="Income  Level"
          onChange={handleFilterChange}
        />
        <Select
          name="numBeds"
          className="w-64  ml-2 mt-2"
          options={numBedsOptions}
          value={numBeds}
          onChange={handleFilterChange}
          label="Num Beds"
        />

        <Select
          name="oppStatus"
          className="w-64  ml-2 mt-2"
          label="Opportunity Status"
          options={opportunityStatusOptions}
          value={oppStatus}
          onChange={handleFilterChange}
        />
      </div>

      <div className={showFilters ? "flex flex-col px-2" : "hidden"}>
        <Select
          className="mt-4"
          name="msa"
          options={msaOptions}
          value={msa}
          onChange={handleFilterChange}
          label="MSA"
        />
        <Select
          name="propertyType"
          className="mt-1"
          options={propTypeOptions}
          value={propertyType}
          onChange={handleFilterChange}
          label="Opportunity Type"
        />
        <Select
          name="incomeLevel"
          className="mt-1"
          options={incomeLevelOptions}
          value={incomeLevel}
          onChange={handleFilterChange}
          label="Income Level"
        />
        <Select
          name="numBeds"
          className="mt-1"
          options={numBedsOptions}
          value={numBeds}
          onChange={handleFilterChange}
          placeholder="Beds"
          label="Beds"
        />

        <Select
          name="oppStatus"
          className="w-64  ml-2 mt-2"
          label="Opportunity Status"
          options={opportunityStatusOptions}
          value={oppStatus}
          onChange={handleFilterChange}
        />
      </div>

      <section className="w-full flex flex-col">
        <div className="flex justify-between px-6 sm:px-10 pt-6 items-baseline">
          <Typography
            variant="h6"
            className="ml-2 text-lg font-semibold  tracking-wide  
       "
          >
            {filteredOpps.length} Opportunities found
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => setShowAddOppModal(true)}
          >
            Add{" "}
          </Button>
        </div>
        <AddOpportunity
          open={showAddOppModal}
          onClose={() => setShowAddOppModal(false)}
        />
        <section className="flex flex-wrap sm:px-4">
          {loadingOpportunites ? (
            <div className="ml-auto mt-6 mr-auto text-center" />
          ) : (
            filteredOpps.map((o) => {
              return (
                <OpportunityCard key={o.OpportunityID} opp={o} user={user} />
              );
            })
          )}
        </section>

        {/*  <section className="hidden sm:block sm:w-3/5 relative sm:h-screen ml-4 mt-4 mr-4 overflow-hidden">
          <Map data={extractLatAndLong(opportunities)} />
        </section> */}
      </section>
    </section>
  );

  function handleFilterChange(e) {
    const { name, value } = e.target;
    dispatch({
      type: name,
      payload: value,
    });
  }

  async function fetchOpportunities() {
    const {
      data: { data: opps },
    } = await axios.get(
      `${getBaseUrl()}/opportunities?${addSearchParams(search)}`
    );

    dispatch({
      type: "opps",
      payload: opps,
    });
  }

  function addSearchParams(params) {
    const [_, routerParams] = params.split("?");
    const baseParams = ["rowCnt=100", "page=1"];
    return routerParams
      ? [routerParams, ...baseParams].join("&")
      : baseParams.join("&");
  }
}

export default OpportunityList;
