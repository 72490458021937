import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { useDrawingDetails } from "../../queries/programs/useDrawingDetails";
import { useDrawingOpportunities } from "../../queries/programs/useDrawingOpportunities";
import { useHhldDrawings } from "../../queries/hhld/useHhldDrawings";
import { formatDateAndTime } from "../../utils/formatters";
import { getDrawingType } from "../../utils/drawingUtils";

import UserContext from "../user/UserContext";
import ownership_placeholder from "../../media/HK-assets/ownership_placeholder.jpg";
import rental_placeholder from "../../media/HK-assets/rental_placeholder.jpg";
import OpportunityCard from "../../components/admin/program/OpportunityCard";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F1B896",
    width: "242px",
    height: "36px",
    color: "#fff",
    marginTop: "20px",
    marginBottom: "20px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#F1B896",
      fontWeight: 600,
    },
  },
  disabled: {
    color: "#1a202c",
    backgroundColor: "#e2e8f0",
  },
}))(Button);

export function HhldDrawingDetail() {
  const { drawingId } = useParams();
  console.log("drawingId", drawingId);
  const [drawingDetail] = useDrawingDetails(drawingId);
  const history = useHistory();
  const { user } = useContext(UserContext) || {};
  const [state, dispatch] = useHhldDrawings(user.userId, {
    drawingId,
  });

  const opps = useDrawingOpportunities(drawingId, drawingDetail.program_id);

  return (
    <section className="min-h-screen flex flex-col justify-center items-center py-4 max-w-6xl ml-auto mr-auto">
      <div className="w-full max-w-6xl p-8">
        <img
          className="w-full h-96"
          src={
            drawingDetail.inventoryTypeId == 1
              ? ownership_placeholder
              : rental_placeholder
          }
        />
      </div>
      <section className="p-8 flex flex-col sm:flex-row justify-between w-full">
        <section className="flex flex-col">
          <section className="flex flex-col items-start py-4">
            <h5 className="flex items-center text-2xl h-20 uppercase">
              Drawing ({drawingId})
            </h5>
            <div>
              <span className="text-xl">{drawingDetail.programName}</span>
              <span className="text-gray-600 px-2">
                ({drawingDetail.preComponentName})
              </span>
            </div>

            <div className="flex items-baseline space-x-2">
              <span className="text-sm text-gray-600 text-left">
                {drawingDetail.incomeLevelsUniq}
              </span>
              <span className="text-xs">AMI ({drawingDetail.amiPct})</span>
            </div>

            <div className="text-left mt-4 min-h-10">
              <span>Household Size</span>
            </div>
            <div className="flex items-baseline text-gray-600">
              <span>Max </span>
              <span className="text-lg ml-1">
                {" "}
                {drawingDetail.maxHHLDSize} /{" "}
              </span>
              <span className="ml-2">Min </span>
              <span className="text-lg ml-1"> {drawingDetail.minHHLDSize}</span>
            </div>
          </section>

          <section className="flex  flex-col py-4">
            <h3 className="text-left text-lg">Description</h3>
            <p
              className="text-sm text-left mt-2 tracking-wide leading-loose"
              dangerouslySetInnerHTML={{
                __html: Array.isArray(drawingDetail.lotteryDescription)
                  ? drawingDetail.lotteryDescription?.[0]
                  : drawingDetail.lotteryDescription,
              }}
            ></p>
          </section>
        </section>{" "}
        {/* end of grid */}
        <section className="w-full    flex flex-col space-y-2 sm:ml-4 mt-4 sm:w-96 justify-self-end place-self-start border border-gray-200 rounded-md">
          <h3 className="bg-gray-100 flex justify-center items-center h-10 text-gray-900">
            Important Info
          </h3>

          <div className="flex space-x-2 text-sm text-gray-900 py-2 px-4">
            <span className="text-left">Drawing Deadline:</span>
            <span>{formatDateAndTime(drawingDetail.entry_deadline)}</span>
          </div>

          <div className="flex space-x-2 text-sm py-2 px-4">
            <span className="text-left">File Submission Deadline:</span>
            <span>
              {formatDateAndTime(drawingDetail.file_submission_deadline)}
            </span>
          </div>

          <div className="flex space-x-2 text-sm py-2 px-4">
            <span className="text-left">Delivery Date:</span>
            <span>
              {formatDateAndTime(drawingDetail.file_submission_deadline)}
            </span>
          </div>

          <div className="flex space-x-2 text-sm py-2 px-4">
            <span className="text-left">Drawing Type:</span>
            <span>
              {getDrawingType(
                drawingDetail.drawingType,
                drawingDetail.finalType
              )}
            </span>
          </div>
          <div className="mt-4">
            <StyledButton
              /* disabled={
                (typeof mergedDrawing.entryNo !== "undefined" ||
                  mergedDrawing.entryNo !== null) &&
                mergedDrawing.applicantsAccepted != 1
              } */
              onClick={enterDrawing}
            >
              Enter Drawing
            </StyledButton>
          </div>
          <div className="flex flex-col space-y-2 py-2">
            <span className="text-lg text-gray-900">
              {drawingDetail.provider}
            </span>
            <span className="text-sm text-gray-700">
              {drawingDetail.program_name}
            </span>
          </div>
        </section>
      </section>

      <Opportunities opps={opps} />
    </section>
  );

  function enterDrawing(e) {
    e.stopPropagation();

    history.push({
      pathname: `/drawings/${drawingDetail.drawing_id}/enter`,
      state: {
        hhldProgramId: drawingDetail.hhldProgramId,
        agency: drawingDetail.provider,
        program: drawingDetail.program_name,
        programId: drawingDetail.program_id,
      },
    });
  }
}

function RentalOrSaleSign({ flag }) {
  return (
    <div className="h-6 p-2 border-3 bg-red-400 border- flex justify-center items-center ml-2">
      <span className="uppercase text-xs text-white">
        For {flag == 1 ? "Sale" : "Rent"}
      </span>
    </div>
  );
}

function Opportunities({ opps = [] }) {
  return (
    <div className="mt-4  flex flex-col shadow-md min-h-32  sm:ml-4 w-full max-w-6xl flex-wrap">
      <section className="lottery-result-opp-card w-full">
        <div className="opp-card-header  py-2 rounded-t-md w-full border-b border-gray-500 flex sticky top-0 z-10">
          <span className="font-semibold sticky text-gray-700 px-2 leading-loose">
            Opportunities ({opps.length})
          </span>
        </div>

        {opps.map((o) => {
          return <OpportunityCard key={o.id} {...o} />;
        })}
      </section>
    </div>
  );
}
