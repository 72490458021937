import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useDrawingQuestions(drawingId, programId) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchDrawingQuestions();
  }, [drawingId, programId]);

  async function fetchDrawingQuestions() {
    const {
      data: { data: questions },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/questions?programId=${programId}`
    );
    setQuestions(questions);
  }

  return [questions, fetchDrawingQuestions];
}
