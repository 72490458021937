import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import IncomeLineItem from "./IncomeEntry";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";

const useStyles = makeStyles({
  button: {
    edit: {
      fontSize: "12px",
      textTransform: "none",
    },
  },
});

export default function HhldIncomes({
  totalIncome,
  incomes,
  handleEditIncome,
  openAddIncomeModal,
  removeIncome,
}) {
  const classes = useStyles();
  const [emulatedUser] = useLocalStorage("emulatedUser");

  return (
    <section className="org flex flex-col mt-8 min-h-48">
      <div className="flex border-b-2 border-hk-blue bg-gray-100">
        <h5 className="uppercase text-left p-2 mr-auto text-sm flex space-x-2 items-center">
          {" "}
          <MonetizationOnIcon className="text-gray-600" />
          <span>Income ({totalIncome})</span>
        </h5>

        <Button
          size="small"
          endIcon={<AddIcon />}
          disabled={emulatedUser}
          onClick={openAddIncomeModal}
          className={classes.button.edit}
          color="default"
        >
          Add Income
        </Button>
      </div>
      <section
        className="w-full  flex flex-col border-gray-200 "
        id="user-profile-top "
      >
        <section className="hidden sm:grid grid-cols-6 mt-4 w-full items-baseline place-items-center justify-between uppercase text-xs text-gray-600 border-b border-gray-300 p-2">
          <span className=" justify-self-start ">Name</span>
          <span className=" justify-self-start ">Employer</span>
          <span className="col-span-2 ">Income</span>
          <span className="col-span-2 justify-self-end">Actions</span>
        </section>
        {Object.entries(incomes).map(([memberId, income]) => (
          <IncomeLineItem
            key={memberId}
            incomes={income}
            removeIncome={removeIncome}
            handleEditIncome={handleEditIncome}
          />
        ))}
      </section>
      {/* <DesktopView incomes={incomes} /> */}
    </section>
  );
}

function DesktopView({ incomes }) {
  const cols = [
    {
      title: "Name",
      field: "MemberName",
    },
    {
      title: "Employer",
      field: "CompanyName",
    },
    {
      title: "Amount",
      field: "Income",
    },
  ];
  return <section className="hidden sm:block"></section>;
}
