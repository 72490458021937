import React, { useState, useEffect } from "react";
import { getBaseUrl } from "../../../utils/queryHelpers";
import axios from "../../../utils/axios";
import HhldPreApplicationCard from "./cards/HhldPreApplicationCard";
import HhldDrawings from "./cards/HhldDrawings";
import HHLDRegSummary from "./HHLDRegSummary";

export default function HHLDDashboard({ user }) {
  const [applications, setApplications] = useState([]);
  const [loadingApplications, setLoadingApplications] = useState(false);

  const [drawings, setDrawings] = useState([]);

  useEffect(() => {
    fetchHHldApplcations();
    fetchHHldDrawings();
  }, []);

  return (
    <section className="min-h-screen flex flex-col items-start rela">
      <h3 className="tracking-wide text-3xl uppercase bg-hk-orange-100 h-32 flex items-center justify-center  p-4 text-hk-orange-400 w-full">
        Dashboard
      </h3>
      <div className="sm:hidden flex justify-center w-full -mt-8">
        <HHLDRegSummary />
      </div>
      <aside className="min-h-screen sm:flex justify-between hidden space-x-10 space-y-10">
        <HHLDRegSummary />
        <div className="flex ml-4 space-x-12 spacy-y-12 mt-4">
          <HhldPreApplicationCard applications={applications} />
          <HhldDrawings drawings={drawings} />
        </div>
      </aside>
      <div className="flex flex-col sm:flex-row justify-center space-y-10 w-full sm:hidden">
        <HhldPreApplicationCard applications={applications} />
        <HhldDrawings drawings={drawings} />
      </div>
    </section>
  );

  async function fetchHHldApplcations() {
    setLoadingApplications(true);
    const {
      data: { data: applications },
    } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/applications?rowCnt=100&page=1`
    );
    setLoadingApplications(false);
    setApplications(applications.filter((a) => a.ApplicationFileID != ""));
  }

  async function fetchHHldDrawings() {
    const {
      data: { data: drawings },
    } = await axios.get(`${getBaseUrl()}/user/${user.userId}/drawings`);

    setDrawings(
      drawings.filter((a) => a.opportunityDrawingApplicantId != null)
    );
  }
}
