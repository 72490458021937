import React, { useEffect, useReducer } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

function reducer(state, action) {
  switch (action.type) {
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    case "opps":
      return {
        ...state,
        opportunities: action.payload,
        filteredOpps: action.payload,
      };
    case "programType":
      return {
        ...state,
        programType: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.OpportunityType === action.payload
              ),
      };

    case "program":
      return {
        ...state,
        program: action.payload,
        filteredOpps:
          action.payload === "All"
            ? state.opportunities
            : state.opportunities.filter((o) => o.ProgramID === action.payload),
      };
    case "drawingId":
      return {
        ...state,
        drawingId: action.payload,
        filteredOpps:
          action.payload === "All"
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.OpportunityDrawingID === action.payload
              ),
      };
    case "incomeCategory":
      return {
        ...state,
        incomeCategory: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.IncomeCategory === action.payload
              ),
      };
    case "numBeds":
      return {
        ...state,
        numBeds: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter((o) => o.Bedrooms == action.payload),
      };
    case "loadingOpps":
      return {
        ...state,
        loadingOpportunites: action.payload,
      };

    case "searchText":
      return { ...state, searchText: action.payload };

    case "oppStatus":
      return {
        ...state,
        oppStatus: action.payload,
        filteredOpps:
          action.payload === 0
            ? state.opportunities
            : state.opportunities.filter(
                (o) => o.OpportunityStatus === action.payload
              ),
      };

    default:
      return state;
  }
}
export function useOpportunities(userId, deps) {
  const [state, dispatch] = useReducer(reducer, {
    opportunities: [],
    filteredOpps: [],
    programType: 0,
    numBeds: 0,
    incomeCategory: 0,
    searchText: "",
    program: "All",
    loading: true,
  });

  const { searchText } = state;

  useEffect(() => {
    fetchOpportunities();
  }, []);

  useEffect(() => {
    const fetchTimer = setTimeout(fetchOpportunities, 200);
    return () => clearTimeout(fetchTimer);
  }, [searchText]);

  return [state, dispatch];

  async function fetchOpportunities() {
    try {
      dispatch({
        type: "loading",
        payload: true,
      });
      const {
        data: { data: opps },
      } = await axios.get(
        `${getBaseUrl()}/user/${userId}/opportunities?rowCnt=100&page=1&searchText=${searchText}`
      );
      dispatch({ type: "opps", payload: opps });
      dispatch({
        type: "loading",
        payload: false,
      });
    } catch (err) {
      console.log(`failed to fetch opps ${err}`);
      dispatch({ type: "opps", payload: [] });
      dispatch({
        type: "loading",
        payload: false,
      });
    }
  }
}
