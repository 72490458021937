import React, { useContext } from "react";

import { Route, Switch } from "react-router-dom";
import NotAuthorized from "../shared/misc/NotAuthorized";
import HhldProgramCenter from "./HhldProgramCenter";
import HhldProfile from "./profile/HhldProfile";
import { HhldDrawingDetail } from "./HhldDrawingDetail";
import { HhldOpportunityDetail } from "./HhldOpportunityDetail";
import HhldActivityLog from "./HhldActivityLog";
import { HhldMarketplace } from "./HhldMarketplace";
import { isHHldApplicant, isInternalAdmin } from "../../utils/authUtils";
import UserContext from "../user/UserContext";

export default function HouseholdRoutes(props) {
  const { user } = useContext(UserContext) || {};

  const showHhldPages =
    isHHldApplicant(user.profileType) || isInternalAdmin(user.profileType);

  return (
    <Switch>
      <Route exact path="/hhld/programs">
        {showHhldPages ? <HhldProgramCenter /> : <NotAuthorized />}
      </Route>

      <Route exact path="/hhld/opportunities">
        {showHhldPages ? <HhldProgramCenter /> : <NotAuthorized />}
      </Route>

      <Route exact path="/hhld/opportunities/:opportunityId">
        {showHhldPages ? <HhldOpportunityDetail /> : <NotAuthorized />}
      </Route>

      <Route exact path="/hhld/drawings/:drawingId">
        {showHhldPages ? <HhldDrawingDetail /> : <NotAuthorized />}
      </Route>

      <Route exact path="/hhld/drawings">
        {showHhldPages ? <HhldProgramCenter /> : <NotAuthorized />}
      </Route>

      <Route exact path="/hhld/profiles">
        {showHhldPages ? <HhldProfile /> : <NotAuthorized />}
      </Route>

      <Route exact path="/hhld/faqs">
        {showHhldPages ? <HhldProgramCenter /> : <NotAuthorized />}
      </Route>

      <Route exact path="/hhld/activities">
        {showHhldPages ? <HhldActivityLog /> : <NotAuthorized />}
      </Route>
      <Route exact path="/hhld/mkt-place">
        {showHhldPages ? <HhldMarketplace /> : <NotAuthorized />}
      </Route>
    </Switch>
  );
}
