import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import { StagedFiles } from "./StagedFiles";
import { AccordionItem } from "./AccordionItem";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import PopperMsg from "../shared/misc/PopperMsg";

import UserContext from "../user/UserContext";
import { hhldFilesTabs } from "./fileUtils";

const Accordion = withStyles({
  root: {
    backgroundColor: "#f3f3f3",
    padding: "2px",
    boxShadow: "none",
    marginTop: 5,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "10px",
    marginBottom: -1,

    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    justifyContent: "center",

    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

const SubmitButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#ed7d31",
    borderRadius: "5px",
    color: "#fff",
    width: "220px",
    height: "44px",
    padding: "8px 0 8.8px",
    flexGrow: 0,
    alignSelf: "center",
    fontSize: "16px",
    fontWeight: "bold",
    marginTop: "64px",
    marginBottom: "120px",
    textTransform: "unset",
  },
  disabled: {
    backgroundColor: "#FFE7D9",
    color: "#fff",
  },
}))(Button);

type UploadedFile = {
  name: string;
  tags: string[];
  file: any;
  preview: string;
};

export function AllUploads() {
  const [files, setFiles] = useState({
    all: [],
    appIds: [],
    incomes: [],
    assets: [],
    qualDocs: [],
    preferences: [],
    others: [],
  });
  const [showUploadConfirmation, setShowUploadConfirmation] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [showSuccessMg, setShowSuccessMsg] = useState(false);
  const [showFailureMg, setShowFailureMsg] = useState(false);

  const [failedToUpload, setFailedToUpload] = useState([]);
  const [successfullyUploaded, setSucessfullyUploaded] = useState([]);
  const location = useLocation();
  const { hash } = location;

  const [expanded, setExpanded] = useState(
    hhldFilesTabs.get(Number(hash?.split("#")?.[1]))?.["id"] || "appIds"
  );

  const { user } = useContext(UserContext);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const history = useHistory();

  const filesStaged =
    Object.values(files).filter((f) => f.length > 0).length > 0;

  return (
    <section className="px-6">
      <h2 className="text-left  pt-8  pb-4 text-gray-900 flex flex-col sm:flex-row items-baseline space-x-2">
        <span className="order-2 sm:order-1 text-xl sm:text-3xl mt-6">
          Upload Your Supporting Documents
        </span>
        <Button
          size="small"
          className="order-1 sm:order-2"
          variant="outlined"
          style={{
            backgroundColor: "#ed7d31",
            color: "#fff",
            marginLeft: "auto",
          }}
          onClick={() => history.push("/file-cabinet")}
        >
          View Uploaded files
        </Button>
      </h2>

      <section className="bg-gray-100">
        {[...hhldFilesTabs.entries()].map(([key, value]) => (
          <Accordion
            key={key}
            square
            expanded={expanded === value.id}
            onChange={handleChange(value.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <AccordionTitleText
                header={`${value.title} (${files[value.id].length})`}
                subtitle={value.subTitle}
              />
            </AccordionSummary>
            <AccordionDetails>
              <AccordionItem onFileDrop={handleFileDrop(value.id)} />
              <StagedFiles
                files={files[value.id]}
                handleRemove={handleRemove(value.id)}
                submitting={submitting}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </section>

      <SubmitButton disabled={!filesStaged} onClick={handleUpload}>
        {!submitting && "Submit All Documents"}
        {submitting && <CircularProgress size={20} />}
      </SubmitButton>

      <Dialog open={showUploadConfirmation}>
        <DialogContent>
          <span>The following files are ready to be uploaded!!</span>
          <FilesList files={files} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUploadConfirmation(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              await handleUpload();
              setShowUploadConfirmation(false);
            }}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <PopperMsg
        open={showSuccessMg}
        handleClose={() => setShowSuccessMsg(false)}
      >
        <div className="flex flex-col space-y-2">
          <span>The following files were uploaded successfully.</span>
          {successfullyUploaded.map((f) => (
            <span>{f}</span>
          ))}
        </div>
      </PopperMsg>
      <PopperMsg
        msgType="error"
        open={showFailureMg}
        handleClose={() => setShowFailureMsg(false)}
      >
        <div className="flex flex-col space-y-2">
          <span>The following files could not be uploaded.</span>
          {failedToUpload.map((f) => (
            <span>{f}</span>
          ))}
        </div>
      </PopperMsg>
    </section>
  );

  function handleFileDrop(key) {
    return function(files) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [key]: [...prevFiles[key], ...files],
      }));
    };
  }

  async function handleUpload() {
    setSubmitting(true);

    const payload = Object.entries(files).reduce((acc, curr) => {
      const [key, files] = curr;

      files.forEach((f) => {
        acc.push({
          path: key === "all" ? "/" : `/${key}`,
          key,
          filename: f.name,
          contentType: f.type,
        });
      });

      return acc;
    }, []);

    try {
      const { data } = await axios.post(
        `${getBaseUrl()}/user/${user.userId}/files`,
        {
          memberId: user.memberId,
          files: payload,
        }
      );

      if (data?.uploads) {
        let successFul = [];
        let failed = [];
        data.uploads.forEach(async (u) => {
          const key = u.path.split("/")[1] || "all";
          const file = files[key].find((f) => f.name === u.filename);
          try {
            const resp = await fetch(
              new Request(u.href, {
                method: "PUT",
                body: file,
              })
            );

            if (resp.status === 200) {
              successFul.push(u.filename);
              handleRemove(key)(u.filename);
            } else {
              failed.push(u.filename);
            }
          } catch (err) {
            failed.push(u.filename);
          }

          if (successFul.length + failed.length === data.uploads.length) {
            //all files have received a response
            setSubmitting(false);
            if (failed.length > 0) {
              setFailedToUpload(failed);
              setShowFailureMsg(true);
            } else {
              setSucessfullyUploaded(successFul);
              setShowSuccessMsg(true);
            }
          }
        });
      }
    } catch (err) {
      console.log("error uploading files", err);
      setSubmitting(false);
    }
  }

  function handleRemove(key: string) {
    return function(fileName: string) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [key]: prevFiles[key].filter((f) => f.name !== fileName),
      }));
    };
  }
}

function AccordionTitleText({ header, subtitle }) {
  return (
    <div className="flex flex-col items-start">
      <span>{header}</span>
      <span className="pl-4 pt-1 text-xs text-gray-500">{subtitle}</span>
    </div>
  );
}

function FilesList({ files }) {
  return Object.entries(files).map(([key, files]) => {
    return files.map((f) => (
      <div
        key={`${key}_${f.name}`}
        className="flex  items-center space-x-2 py-4"
      >
        <CheckCircleIcon className="text-green-500" fontSize="small" />
        <span className="text-sm">{f.name}</span>
        <span className="text-sm text-gray-600">{key}</span>

        <RemoveCircleIcon className="text-red-500 " fontSize="small" />
        {f.loading && <CircularProgress size="200" />}
      </div>
    ));
  });
}
