import React from "react";
import {
  Button,
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Select from "../../../../shared/Select";
import DatePicker from "../../../../shared/form/DatePicker";
import DatetimePicker from "../../../../shared/form/DatetimePicker";
import axios from "../../../../../utils/axios";
import { getBaseUrl } from "../../../../../utils/queryHelpers";
import {
  applyTZOffset,
  formatDateToTimeZone,
  removeHTMLTagsFromString,
} from "../../../../../utils/formatters";
import PopperMsg from "../../../../shared/misc/PopperMsg";

const StyledBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#f7fafc",
  },
})(Backdrop);

const StyledFormControl = withStyles({
  label: {
    fontSize: "14px",
  },
})((props) => <FormControlLabel {...props} />);

const StyledCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export function EditDrawing({ drawingId, handleClose }) {
  const [updates, setUpdates] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [drawing, setDrawing] = React.useState({});
  const [showSuccessMsg, setShowSuccessMsg] = React.useState(false);
  const [showFailureMsg, setShowFailureMsg] = React.useState(false);

  const state = {
    ...drawing,
    startDate: applyTZOffset(drawing.startDate),
    ...updates,
  };

  const {
    amiPct,
    applicantsAcceptedR = "",
    applicationEntryDate,
    applicationEntryTime,
    applicantUploadFlag,
    candidateHouseholdSize,
    candidateIncome,
    candidateIncomeLevel = "",
    dateCreated,
    drawingStatus,
    drawingStatusR = "",
    drawingType = "",
    endDate,
    entityName,
    fileSubmissionDeadline,
    lotteryDate,
    lotteryDescription,
    lotteryRosterId,
    lotterySubject,
    lotteryTime,
    maxHHLDSize,
    minHHLDSize,
    prefTypeOption = "",
    programId,
    programName,
    publishFlag,
    publishPgmCtrFlag,
    recordStatus = "",
    startDate,
    targetApplicantDeliveryDate,
  } = state;

  React.useEffect(() => {
    fetchDrawing();
  }, []);

  const resultsPublishedDate = `${lotteryDate}${
    lotteryTime ? ` ${lotteryTime}` : ""
  }`;

  console.log("resultsPublisehdDate", resultsPublishedDate);

  return (
    <section className="relative">
      <form className="flex flex-col items-baseline space-y-4  w-full ">
        <div className="flex justify-between w-full space-x-2">
          <TextField value={drawingId} disabled label="Drawing Id" />
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={lotteryRosterId}
            disabled
            label="Roster Id"
          />
        </div>
        <div className="flex justify-between w-full space-x-2">
          <TextField
            fullWidth
            value={entityName}
            disabled
            label="Provider"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            value={programName}
            disabled
            label="Program"
            InputLabelProps={{ shrink: true }}
          />
        </div>

        <TextField
          multiline
          disabled
          label="Subject"
          InputLabelProps={{ shrink: true }}
          value={lotterySubject}
          name="lotterySubject"
          fullWidth
        />

        <TextField
          multiline
          disabled
          value={removeHTMLTagsFromString(lotteryDescription)}
          maxRows={4}
          label="Description"
          InputLabelProps={{ shrink: true }}
          name="lotteryDescription"
          fullWidth
        />

        <TextField
          value={amiPct}
          name="amiPct"
          label="AMI Level"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />

        <div className="flex space-x-2 justify-between w-full">
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={candidateIncome}
            label="Candidate Household Income"
            name="candidateIncome"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={candidateHouseholdSize}
            label="Candidate Household Size"
            name="candidateHouseholdSize"
            onChange={handleChange}
          />
        </div>
        <div className="flex space-x-4 justify-between w-full">
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={maxHHLDSize}
            label="Maximum Household Size"
            name="maxHHLDSize"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            value={minHHLDSize}
            InputLabelProps={{ shrink: true }}
            label="Minimum Household Size"
            name="minHHLDSize"
            onChange={handleChange}
          />
        </div>
        <Select
          onChange={handleChange}
          value={candidateIncomeLevel}
          name="candidateIncomeLevel"
          label="Candidate Income Level"
          options={[
            { value: 1, label: "Extremely Low" },
            { value: 2, label: "Very Low" },
            { value: 3, label: "Low" },
            { value: 4, label: "Median" },
            { value: 5, label: "Moderate" },
            { value: 6, label: "Above Moderate" },
          ]}
        />

        <div className="flex flex-col sm:flex-row space-x-2 w-full justify-between">
          <DatePicker
            required
            label="Start Date"
            value={startDate}
            handleChange={(date) =>
              handleChange({ target: { name: "startDate", value: date } })
            }
          />
          <DatePicker
            label="End Date"
            value={endDate}
            handleChange={(date) =>
              handleChange({ target: { name: "endDate", value: date } })
            }
          />
        </div>

        <div className="flex flex-col sm:flex-row w-full justify-between">
          <DatetimePicker
            label="Application Entry Deadline"
            value={applicationEntryDate}
            handleChange={(date) =>
              handleChange({
                target: { name: "applicationEntryDate", value: date },
              })
            }
          />
          <DatetimePicker
            label="File Submission Deadline"
            value={fileSubmissionDeadline}
            handleChange={(date) =>
              handleChange({
                target: { name: "fileSubmissionDeadline", value: date },
              })
            }
          />
        </div>
        <div className="flex flex-col sm:flex-row w-full justify-between">
          <DatePicker
            label="Target Applicant Delivery Date"
            value={targetApplicantDeliveryDate}
            handleChange={(date) =>
              handleChange({
                target: { name: "targetApplicantDeliveryDate", value: date },
              })
            }
          />

          <DatetimePicker
            label="Drawing Results Publish Date"
            value={lotteryDate}
            handleChange={(date) =>
              handleChange({
                target: { name: "lotteryDate", value: date },
              })
            }
          />
        </div>

        <div className="flex w-full justify-between flex-col sm:flex-row">
          <Select
            onChange={handleChange}
            value={drawingType}
            name="drawingType"
            label="Drawing Type"
            options={[
              { value: 1, label: "Lottery Selection" },
              { value: 2, label: "First-Form First-Serve" },
              { value: 3, label: "First-File First-Serve" },
              { value: 4, label: "First-ApplicationID First-Serve" },
              { value: 5, label: "First Pre-Application Submitted" },
            ]}
          />
          <Select
            onChange={handleChange}
            value={applicantsAcceptedR}
            name="applicantsAcceptedR"
            label="Applicant Acceptance"
            options={[
              {
                value: 46,
                label:
                  "Accepting Entries (Lottery Ranking Hasn't Happened Yet)",
              },
              {
                value: 60,
                label:
                  "Applicant(s) Selected. New Entrants will be added to Backup List",
              },
              {
                value: 47,
                label:
                  "Lottery Ranking Completed. Now Accepting Backup Entrants.",
              },
              {
                value: 55,
                label: "We are not accepting new entrants at this time",
              },
            ]}
          />
        </div>

        <div className="flex w-full justify-between flex-col sm:flex-row">
          <Select
            onChange={handleChange}
            value={drawingStatusR}
            name="drawingStatusR"
            label="Drawing Status"
            options={[
              { value: 44, label: "Closed" },
              { value: 43, label: "Open" },
              { value: 45, label: "Pending Release" },
            ]}
          />
          <Select
            onChange={handleChange}
            value={recordStatus}
            name="recordStatus"
            label="Record Status"
            options={[
              { value: "A", label: "Active" },
              { value: "I", label: "InActive" },
            ]}
          />
        </div>
        <Select
          onChange={handleChange}
          value={prefTypeOption}
          name="prefTypeOption"
          label="Preference Type"
          options={[
            { value: 0, label: "N/A" },
            { value: 1, label: "Tiers" },
            { value: 2, label: "Points" },
          ]}
        />

        <StyledFormControl
          label="Publish to Marketplace"
          control={
            <StyledCheckbox
              checked={publishFlag == 1}
              name="publishFlag"
              onChange={handleChange}
              size="small"
            />
          }
          labelPlacement="start"
        />

        <StyledFormControl
          label="Publish to Program Center"
          control={
            <StyledCheckbox
              checked={publishPgmCtrFlag == 1}
              name="publishPgmCtrFlag"
              onChange={handleChange}
              size="small"
            />
          }
          labelPlacement="start"
        />

        <StyledFormControl
          label="Allow File uploads"
          control={
            <StyledCheckbox
              checked={applicantUploadFlag == 1}
              name="applicantUploadFlag"
              onChange={handleChange}
              size="small"
            />
          }
          labelPlacement="start"
        />

        <div className="flex space-x-2 justify-end w-full mt-6">
          <Button size="small" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={editDrawing}
          >
            Submit
          </Button>
        </div>
      </form>
      <StyledBackdrop open={loading}>
        <CircularProgress />
      </StyledBackdrop>
      <PopperMsg
        open={showSuccessMsg}
        handleClose={() => setShowSuccessMsg(false)}
      >
        <p>Edit successful!</p>
      </PopperMsg>
      <PopperMsg
        msgType="alert"
        open={showFailureMsg}
        handleClose={() => setShowFailureMsg(false)}
      >
        <p>Request could not be processed!</p>
      </PopperMsg>
    </section>
  );

  async function fetchDrawing() {
    setLoading(true);
    const {
      data: { data: drawings },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}`);
    setDrawing(drawings?.[0] ?? {});
    setLoading(false);
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    setUpdates((prev) => ({
      ...prev,

      [name]: value ? value : type !== "text" ? (checked ? 1 : 0) : "",
    }));
  }

  async function editDrawing(e) {
    e.preventDefault();
    setLoading(true);

    const payload = {
      ...updates,
      ...formDateDateTimeFields(updates),
    };

    try {
      const { data } = await axios.put(
        `${getBaseUrl()}/drawings/${drawingId}`,
        {
          ...updates,
          ...payload,
        }
      );

      if (data.statusCode == 201) {
        await fetchDrawing();
        setUpdates({});
        setShowSuccessMsg(true);
        //handleClose();
      } else {
        setShowFailureMsg(false);
      }
    } catch (e) {
      console.log("error updating ", e);
      setLoading(false);
    }
  }
}

function formDateDateTimeFields(updates) {
  const formatted = {};
  if (updates.fileSubmissionDeadline) {
    formatted.fileSubmissionDeadline = formatDateToTimeZone(
      updates.fileSubmissionDeadline,
      "MM/dd/yyyy HH:mm:ss"
    );
  }
  if (updates.applicationEntryDate) {
    const [applicationEntryDate, applicationEntryTime] = formatDateToTimeZone(
      updates.applicationEntryDate,
      "MM/dd/yyyy HH:mm:ss"
    ).split(" ");

    formatted.applicationEntryDate = applicationEntryDate;
    formatted.applicationEntryTime = applicationEntryTime;
  }
  if (updates.lotteryDate) {
    const [lotteryDate, lotteryTime] = formatDateToTimeZone(
      updates.lotteryDate,
      "MM/dd/yyyy HH:mm:ss"
    ).split(" ");

    formatted.lotteryDate = lotteryDate;
    formatted.lotteryTime = lotteryTime;
  }
  if (updates.targetApplicantDeliveryDate) {
    formatted.targetApplicantDeliveryDate = formatDateToTimeZone(
      updates.targetApplicantDeliveryDate,
      "MM/dd/yyyy"
    );
  }
  return formatted;
}
