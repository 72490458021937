import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";

import TimerIcon from "@material-ui/icons/Timer";
import CasinoIcon from "@material-ui/icons/Casino";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 20,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  stepLabel: {
    marginBottom: 10,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  witnessDialog: {
    body: {
      padding: theme.spacing(4),
      width: "200px",
    },
    title: {
      fontSize: "12px",
    },
  },
}));

function getSteps() {
  return [
    "Select Ranking Method",
    "Finalize Ranking ?",
    "Adjust Ranking",
    "Witness",
    "Close",
    "Publish",
  ];
}

export default function HorizontalLinearStepper({
  handleAction,
  activeStep,
  setActiveStep,
  nextAllowed,
  setRankingMethod,
  showResetButton,
  turnOnManualAdjust,
}) {
  const classes = useStyles();

  const [skipped, setSkipped] = useState(new Set());

  const steps = getSteps();

  const matches = useMediaQuery("(min-width: 600px)");

  /*  const isStepOptional = (step) => {
    return step === 2;
  }; */

  const isStepOptional = () => true;

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    handleAction(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
    turnOnManualAdjust(false);
  };

  const handleReset = () => {
    handleAction(6);
  };

  function performRanking(rankingMethod) {
    setRankingMethod(rankingMethod);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className="flex justify-center">
            <Button
              id="rank-random-btn"
              startIcon={<CasinoIcon />}
              className="p-2"
              onClick={() => performRanking("random")}
            >
              Random
            </Button>
            <Button
              startIcon={<PersonIcon />}
              className="p-2"
              onClick={() => performRanking("applicantId")}
            >
              Applicant ID
            </Button>
            <Button
              id="rank-manual-btn"
              startIcon={<EditIcon />}
              className="p-2"
              onClick={() => performRanking("manual")}
            >
              Manual
            </Button>
            <Button
              id="rank-timestamp-btn"
              startIcon={<TimerIcon />}
              className="p-2"
              onClick={() => performRanking("timestamp")}
            >
              Timestamp
            </Button>
          </div>
        );
      case 1:
        return "Finalize with your preferences?";
      case 2:
        return (
          <div className="flex justify-center">
            <Button
              startIcon={<CheckCircleIcon />}
              size="small"
              variant="contained"
              color="secondary"
              className="p-2"
              onClick={() => turnOnManualAdjust(true)}
            >
              Yes
            </Button>
          </div>
        );
      case 3:
        return "Witness";
      case 4:
        return "Close Ranking ?";
      case 5:
        return "Publish Ranking ?";
      default:
        return "Unknown step";
    }
  }

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        orientation={matches ? "horizontal" : "vertical"}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Typography
              className={classes.instructions}
              onClick={() => console.log("step 1")}
            >
              {getStepContent(activeStep)}
            </Typography>
            <div>
              <Button
                id="rank-back-btn"
                disabled={!showResetButton}
                onClick={handleReset}
                className={classes.button}
              >
                Reset
              </Button>

              <Button
                id="rank-back-btn"
                disabled={activeStep === 0 || !showResetButton}
                onClick={handleBack}
                className={classes.button}
              >
                Back
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  id="rank-skip-btn"
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              <Button
                id="rank-next-btn"
                variant="contained"
                disabled={!nextAllowed}
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Publish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
