import React, { useState, useEffect, useContext } from "react";
import { FaUserAlt } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ThemeProvider } from "@material-ui/core/styles";
import popOverTheme from "../themes/hk-theme";
import { useHistory } from "react-router-dom";

import UserContext from "./UserContext";
import useLocalStorage from "../shared/hooks/useLocalStorage";

const useStyles = makeStyles({
  root: {
    minWidth: 375,
    padding: "1rem",
  },

  currPage: {
    color: "#2d3748",
    "&:hover": {
      textDecoration: "none",
      color: "#285b6c",
    },
  },

  body: {
    padding: "1rem",
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function User({ logoutHandler }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const history = useHistory();

  const { user, setUser } = useContext(UserContext) || {};
  const [emulatedUser, setEmulatedUser] = useLocalStorage("emulatedUser");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  async function handleLogout() {
    logoutHandler();
    /*  await Auth.signOut(); */
    history.push("/");
  }

  function pushToProfilePage() {
    history.push("/profile");
  }

  const open = Boolean(anchorEl);

  return (
    <ThemeProvider theme={popOverTheme}>
      <div className="user-menu ml-auto flex items-baseline">
        {emulatedUser && (
          <button className="mr-2 " onClick={exitEmulation}>
            <span className="">Exit Emulation</span>
            <span className="text-sm ml-2 text-gray-600">
              ({emulatedUser.userName})
            </span>
          </button>
        )}
        <h5 className="hidden sm:block mr-2 text-gray-700 text-sm">
          Welcome, {user.firstName}
        </h5>
        <button
          className="text-gray-600 hover:text-gray-800 text-xl px-2"
          onClick={handleClick}
        >
          <FaUserAlt />
        </button>
        <Popover
          className={classes.root}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
        >
          <section className="p-4 flex flex-col justify-start">
            <h6 className="text-gray-700">
              {user.firstName} {user.lastName}
            </h6>
            <span className="text-gray-500 pt-2 text-sm">
              {user.profileDisplay}
            </span>
          </section>
          <Divider />
          <List>
            <ListItem button onClick={pushToProfilePage}>
              <ListItemText
                button
                primaryTypographyProps={{
                  style: { fontSize: "0.8rem" },
                }}
                primary="Profile"
              />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemText
                primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                primary="Sign out"
              />
            </ListItem>
          </List>
        </Popover>
      </div>
    </ThemeProvider>
  );

  async function exitEmulation() {
    await setUser();
    setEmulatedUser(null);
    window.location.reload();
    //history.push("/");
  }
}
