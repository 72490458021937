import axios from "axios";
import { Auth } from "aws-amplify";

axios.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();

  const item = window.localStorage?.getItem("emulatedUser");
  const emulatedUser = item ? JSON.parse(item) : null;

  return {
    ...config,
    headers: {
      ...config.headers, //no mutations allowed during emulation
      ...(emulatedUser &&
        config.method === "get" && {
          "HK-Emulated-User": emulatedUser?.userName,
        }),
      Authorization: idToken,
    },
  };
});

export default axios;
