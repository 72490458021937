import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LaunchIcon from "@material-ui/icons/Launch";
import PeopleIcon from "@material-ui/icons/People";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import axios from "../../../../utils/axios";
import { CreateDrawing } from "../drawings/CreateDrawing";
import PrimaryButton from "../../../shared/form/PrimaryButton";
import Select from "../../../shared/Select";
import {
  removeHTMLTagsFromString,
  formatAsUSCurrency,
} from "../../../../utils/formatters";
import { useProgramPreferences } from "../../../../queries/programs/useProgramPreferences";
import { useProgramQuestions } from "../../../../queries/programs/useProgramQuestions";
import { useProgramApplicants } from "../../../../queries/programs/useProgramApplicants";
import { useProgramOpportunities } from "../../../../queries/programs/useProgramOpportunities";
import { usePreferences } from "../../../../queries/preferences/usePreferences";
import { useQuestions } from "../../../../queries/questions/useQuestions";
import { useProgramForms } from "../../../../queries/programs/useProgramForms";
import programApplicationFormImg from "../../../../media/HK-assets/program_application_form.png";
import enterDrawingFormImg from "../../../../media/HK-assets/enter_drawing_form.png";
import cityOfBurlingame from "../../../../media/HK-assets/burlingame_7.png";
import { DrawingsList } from "../drawings/Drawings";
import { Opportunities } from "../drawings/DrawingManager";

function reducer(state, { type, payload }) {
  switch (type) {
    case "clearPreferenceForm":
      return {
        ...state,
        ...(payload === true && {
          preference: "",
          prefDescription: "",
          prefValue: "",
        }),
      };
  }
  return {
    ...state,
    [type]: payload,
  };
}

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Accordion = withStyles({
  root: {
    backgroundColor: "#f3f3f3",
    padding: "2px",
    boxShadow: "none",
    marginTop: 5,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "10px",
    marginBottom: -1,

    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    justifyContent: "center",

    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

export default function ProgramDetails() {
  const { programId } = useParams();
  const [state, dispatch] = useReducer(reducer, {
    drawings: [],
    preferenceGroupDescription: "",
    preferenceGroupName: "",
    questionsGroupName: "",
    questionsGroupDescription: "",
    selectedPrefs: [],
    selectedQuestions: [],
    showAddFormModal: false,
    showCreateDrawingModal: false,
    showAddPrefModal: false,
    showCreatePrefGroupModal: false,
    showCreateQuestionsGroupModal: false,
    showAddNewQuestionForm: false,
    showAddNewPreferenceForm: false,
    refetchData: false,
  });
  const [program, setProgram] = useState({});
  const [applicantCount, setApplicantCount] = useState(0);
  const [expanded, setExpanded] = useState("prefs");
  const [programPrefs, fetchProgramPrefs] = useProgramPreferences(programId);
  const [programQuestions, fetchProgramQuestions] = useProgramQuestions(
    programId
  );
  const programOpps = useProgramOpportunities(programId);
  const [questions, fetchQuestions] = useQuestions();
  const [programForms] = useProgramForms(programId);
  const [preferences, fetchPrefs] = usePreferences();

  const history = useHistory();

  const {
    drawings,
    preferenceGroupDescription,
    preferenceGroupName,
    questionsGroupName,
    questionsGroupDescription,
    selectedPrefs,
    selectedQuestions,
    showAddFormModal,
    showAddNewQuestionForm,
    showAddPrefModal,
    showAddQuestionsModal,
    showCreateDrawingModal,
    showCreatePrefGroupModal,
    showCreateQuestionsGroupModal,
  } = state;

  function handlePrefSelection(preferenceId) {
    if (selectedPrefs.includes(preferenceId)) {
      dispatch({
        type: "selectedPrefs",
        payload: selectedPrefs.filter((s) => s !== preferenceId),
      });
    } else {
      dispatch({
        type: "selectedPrefs",
        payload: [...selectedPrefs, preferenceId],
      });
    }
  }

  function handleQuestionSelection(questionId) {
    if (selectedQuestions.includes(questionId)) {
      dispatch({
        type: "selectedQuestions",
        payload: selectedQuestions.filter((s) => s !== questionId),
      });
    } else {
      dispatch({
        type: "selectedQuestions",
        payload: [...selectedQuestions, questionId],
      });
    }
  }

  const handleTabToggle = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { description = "", provider, programName } = program;

  useEffect(() => {
    fetchProgramDetails();
    fetchProgramApplicants();
    fetchDrawings();
  }, []);

  return (
    <section className="min-h-screen p-4 max-w-6xl sm:flex flex-col sm:ml-auto sm:mr-auto">
      <h3 className="hidden">Program Details</h3>
      <div className="flex flex-col border border-gray-300 rounded-md">
        <div className="bg-gray-200  h-10 flex items-center justify-center space-x-2">
          <span className="text-lg uppercase">Program ID</span>
          <span>({programId})</span>
        </div>
        <div className="flex flex-col sm:flex-row p-4 ">
          <div className="img  flex justify-center items-start">
            <img className="" src={cityOfBurlingame} />
          </div>
          <div className="flex flex-col sm:ml-16 pt-4 sm:pt-0">
            <div className="flex flex-col items-start text-sm sm:text-md">
              <span className="text-gray-400 ">Provider</span>
              <span className="text-sm sm:text-lg">{provider}</span>
            </div>

            <div className="flex flex-col items-start py-2 text-sm sm:text-md">
              <span className="text-gray-400">Program</span>
              <span className="text-sm sm:text-lg">{programName}</span>
            </div>

            <div className="flex flex-col items-start py-2 text-sm sm:text-md">
              <span className="text-gray-400">Description</span>
              <span
                className="text-sm sm:text-lg text-left"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>

            <div className="flex flex-col items-start py-2 text-sm sm:text-md">
              <Tooltip title="view applicants">
                <Button
                  size="small"
                  onClick={viewApplicants}
                  endIcon={
                    <LaunchIcon
                      fontSize="small"
                      className="text-hk-orange-300"
                    />
                  }
                  className="text-gray-400"
                >
                  Applicants
                </Button>
              </Tooltip>
              <div className="flex space-x-2">
                <PeopleIcon fontSize="small" />
                <span className="text-left">{applicantCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="py-4">
        {/* opps */}
        <Accordion
          square
          expanded={expanded === "opps"}
          onChange={handleTabToggle("opps")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="program_opps"
            id="program_opps"
          >
            <AccordionTitleText
              header={`Opportunities (${programOpps.length})`}
              subtitle="Program Opportunities"
            />
          </AccordionSummary>
          <AccordionDetails>
            {/* <Drawings drawings={drawings} handleClick={viewDrawingDetail} /> */}
            <Opportunities opps={programOpps.slice(0, 5)} />

            <div className="flex ">
              <Button
                onClick={() =>
                  dispatch({
                    type: "showCreateOpportunityModal",
                    payload: true,
                  })
                }
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Create New Opportunity
              </Button>
              {/* <Button
                disabled={selectedPrefs.length < 1}
                size="small"
                onClick={() =>
                  dispatch({ type: "showCreateDrawingModal", payload: true })
                }
                startIcon={<GroupWorkIcon className="text-hk-orange-400" />}
              >
                Create Preference Group
              </Button> */}
            </div>
          </AccordionDetails>
        </Accordion>

        {/* drawings */}
        <Accordion
          square
          expanded={expanded === "drawings"}
          onChange={handleTabToggle("drawings")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="program_drawings"
            id="program_drawings"
          >
            <AccordionTitleText
              header={`Drawings (${drawings.length})`}
              subtitle="Program Drawings"
            />
          </AccordionSummary>
          <AccordionDetails>
            <DrawingsList drawings={drawings} />

            <div className="flex ">
              <Button
                onClick={() =>
                  dispatch({ type: "showCreateDrawingModal", payload: true })
                }
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Create New Drawing
              </Button>
              {/* <Button
                disabled={selectedPrefs.length < 1}
                size="small"
                onClick={() =>
                  dispatch({ type: "showCreateDrawingModal", payload: true })
                }
                startIcon={<GroupWorkIcon className="text-hk-orange-400" />}
              >
                Create Preference Group
              </Button> */}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expanded === "prefs"}
          onChange={handleTabToggle("prefs")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <AccordionTitleText
              header={`Preferences (${programPrefs.length})`}
              subtitle="Program Prefs"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Preferences
              prefs={programPrefs}
              handlePrefSelection={handlePrefSelection}
              selectedPrefs={selectedPrefs}
            />
            <div className="flex ">
              <Button
                onClick={() =>
                  dispatch({ type: "showAddPrefModal", payload: true })
                }
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Add Preferences
              </Button>
              <Button
                disabled={selectedPrefs.length < 1}
                size="small"
                onClick={() =>
                  dispatch({ type: "showCreatePrefGroupModal", payload: true })
                }
                startIcon={<GroupWorkIcon className="text-hk-orange-400" />}
              >
                Create Preference Group
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expanded === "questions"}
          onChange={handleTabToggle("questions")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <AccordionTitleText
              header={`Questions (${programQuestions.length})`}
              subtitle="Program Questions"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Questions
              questions={programQuestions}
              selectedQuestions={selectedQuestions}
              handleQuestionSelection={handleQuestionSelection}
            />
            <div className="flex ">
              <Button
                onClick={() =>
                  dispatch({ type: "showAddQuestionsModal", payload: true })
                }
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Add Questions
              </Button>
              <Button
                disabled={selectedQuestions.length < 1}
                size="small"
                onClick={() =>
                  dispatch({
                    type: "showCreateQuestionsGroupModal",
                    payload: true,
                  })
                }
                startIcon={<GroupWorkIcon className="text-hk-orange-400" />}
              >
                Create Questions Group
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expanded === "forms"}
          onChange={handleTabToggle("forms")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="program_forms"
            id="program_forms"
          >
            <AccordionTitleText
              header={`Forms (${programForms.length})`}
              subtitle="Program Forms"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Forms forms={programForms} programId={programId} />

            <div className="flex py-2">
              <Button
                onClick={() =>
                  dispatch({ type: "showAddFormModal", payload: true })
                }
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Add Form
              </Button>
            </div>
            {/* <Drawings drawings={drawings} handleClick={viewDrawingDetail} /> */}
          </AccordionDetails>
        </Accordion>
      </section>

      {/* questions */}
      <Dialog
        open={showAddQuestionsModal}
        onClose={() =>
          dispatch({ type: "showAddQuestionsModal", payload: false })
        }
      >
        <DialogTitle className="bg-hk-orange-400 text-white">
          Add Questions- {program.programName}
        </DialogTitle>
        <DialogContent>
          <Questions
            questions={questions}
            selectedQuestions={selectedQuestions}
            handleQuestionSelection={handleQuestionSelection}
          />
          <div className="py-4">
            <PrimaryButton
              startIcon={<AddCircleIcon />}
              onClick={() => {
                dispatch({
                  type: "showAddNewQuestionForm",
                  payload: true,
                });
              }}
            >
              Add New Question
            </PrimaryButton>
          </div>
          {showAddNewQuestionForm && (
            <AddNewQuestionForm onAdd={addQuestionsToBank} />
          )}
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              onClick={() =>
                dispatch({ type: "showAddQuestionsModal", payload: false })
              }
            >
              Cancel
            </Button>
            <Button onClick={addQuestionsToProgram}>Add</Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* add preference */}
      <Dialog
        open={showAddPrefModal}
        onClose={() => dispatch({ type: "showAddPrefModal", payload: false })}
      >
        <DialogTitle>Add Preferences - {program.programName}</DialogTitle>
        <DialogContent>
          <Preferences
            prefs={getPrefsNotInProgram()}
            selectedPrefs={selectedPrefs}
            handlePrefSelection={handlePrefSelection}
          />
          <div className="py-2">
            <Button
              onClick={() =>
                dispatch({
                  type: "showAddNewPreferenceForm",
                  payload: true,
                })
              }
              size="small"
            >
              Add New Preference
            </Button>
            {state.showAddNewPreferenceForm && (
              <AddNewPreferenceForm
                handleChange={handleChange}
                handleSave={createNewPreference}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              onClick={() =>
                dispatch({ type: "showAddPrefModal", payload: false })
              }
            >
              Cancel
            </Button>
            <Button onClick={addPreference}>Add</Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* create preference group */}
      <Dialog
        open={showCreatePrefGroupModal}
        onClose={() =>
          dispatch({ type: "showCreatePrefGroupModal", payload: false })
        }
      >
        <DialogTitle>Create Preference Group</DialogTitle>
        <DialogContent>
          <form className="flex flex-col">
            <TextField
              label="Name"
              name="preferenceGroupName"
              onChange={handleChange}
            />
            <TextField
              label="Description"
              name="prefGroupDescription"
              onChange={handleChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              onClick={() =>
                dispatch({ type: "showCreatePrefGroupModal", payload: false })
              }
            >
              Cancel
            </Button>
            <Button onClick={addPreferenceToGroup}>Create</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showCreateQuestionsGroupModal}
        onClose={() =>
          dispatch({ type: "showCreateQuestionsGroupModal", payload: false })
        }
      >
        <DialogTitle>Create Questions Group</DialogTitle>
        <DialogContent>
          <form className="flex flex-col">
            <TextField
              label="Name"
              name="questionsGroupName"
              onChange={handleChange}
              value={questionsGroupName}
            />
            <TextField
              label="Description"
              name="questionsGroupDescription"
              onChange={handleChange}
              value={questionsGroupDescription}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              onClick={() =>
                dispatch({
                  type: "showCreateQuestionsGroupModal",
                  payload: false,
                })
              }
            >
              Cancel
            </Button>
            <Button onClick={addQuestionsToGroup}>Create</Button>
          </div>
        </DialogActions>
      </Dialog>

      <CreateDrawing
        open={showCreateDrawingModal}
        onClose={() =>
          dispatch({ type: "showCreateDrawingModal", payload: false })
        }
      />

      <Dialog
        open={showAddFormModal}
        onClose={() => dispatch({ type: "showAddFormModal", payload: false })}
      >
        <DialogTitle>Create Form</DialogTitle>
        <DialogContent>
          <form className="flex flex-col">
            <TextField label="Name" name="formName" onChange={handleChange} />
            <TextField
              label="Description"
              name="formDescription"
              onChange={handleChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              size="small"
              onClick={() =>
                dispatch({ type: "showAddFormModal", payload: false })
              }
            >
              Cancel
            </Button>
            <Button size="small" onClick={addPreferenceToGroup}>
              Create
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </section>
  );

  function viewApplicants() {
    history.push({
      pathname: `/programs/applicants`,
      search: `?programId=${programId}`,
    });
  }

  function viewDrawingDetail(drawingId) {
    history.push({
      pathname: `/drawings/${drawingId}`,
      state: {
        programId,
      },
    });
  }

  async function addQuestionsToBank(question) {
    const { data } = await axios.post(`${getBaseUrl()}/questions`, question);
    fetchQuestions();
    dispatch({
      type: "showAddNewQuestionForm",
      payload: false,
    });
  }

  async function createNewPreference() {
    const { prefDescription, preference } = state;
    try {
      const { data } = await axios.post(
        `${getBaseUrl()}/programs/${programId}/preferences/`,
        {
          prefDescription,
          preference,
        }
      );

      if (data.statusCode == 200) {
        fetchPrefs();
        fetchProgramPrefs();
        dispatch({
          type: "showAddNewPreferenceForm",
          payload: false,
        });
        dispatch({
          type: "clearPreferenceForm",
          payload: true,
        });
      }
    } catch (err) {
      console.log("failed to create preference", err);
    }

    /*  dispatch({
      type: "refetchData",
      payload: true,
    }); */
  }

  function getPrefsNotInProgram() {
    const programPrefIds = programPrefs.map((p) => p.preference_id);
    const prefIds = preferences.map((p) => p.preference_id);
    const diff = prefIds.filter((p) => !programPrefIds.includes(p));
    return preferences.reduce((acc, curr) => {
      if (diff.includes(curr.preference_id)) acc.push(curr);
      return acc;
    }, []);
  }

  async function addPreferenceToGroup() {
    const resp = await axios.post(
      `${getBaseUrl()}/programs/${programId}/preferences_group`,
      {
        preferenceGroupName,
        preferenceGroupDescription,
        prefs: selectedPrefs,
      }
    );
  }

  async function addPreference() {
    const { selectedPrefs } = state;
    const resp = await axios.post(
      `${getBaseUrl()}/programs/${programId}/preferences`,
      {
        selectedPrefs,
      }
    );
    dispatch({ type: "showAddPrefModal", payload: false });
  }

  async function addQuestionsToGroup() {
    const { questionsGroupName, questionsGroupDescription } = state;
    const resp = await axios.post(
      `${getBaseUrl()}/programs/${programId}/questions_groups`,
      {
        questionsGroupName,
        questionsGroupDescription,
        questions: selectedQuestions,
      }
    );
  }

  async function addQuestionsToProgram() {
    const { selectedQuestions } = state;
    const resp = await axios.post(
      `${getBaseUrl()}/programs/${programId}/questions`,
      { questions: selectedQuestions }
    );
    fetchProgramQuestions();
    dispatch({ type: "showAddQuestionsModal", payload: false });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    dispatch({
      type: name,
      payload: value,
    });
  }

  async function fetchProgramDetails() {
    const {
      data: { data: program },
    } = await axios.get(`${getBaseUrl()}/programs/${programId}`);
    setProgram(program);
  }

  async function fetchDrawings() {
    const {
      data: { data: drawings },
    } = await axios.get(
      `${getBaseUrl()}/programs/${programId}/drawings?limit=5&offset=0`
    );
    dispatch({
      type: "drawings",
      payload: drawings,
    });
  }

  async function fetchProgramApplicants() {
    const {
      data: { data },
    } = await axios.get(
      `${getBaseUrl()}/programs/${programId}/applicants?countOnly=1`
    );

    setApplicantCount(data?.applicantCount ?? 0);
  }
}

function AccordionTitleText({ header, subtitle }) {
  return (
    <div className="flex flex-col items-start">
      <span>{header}</span>
      <span className="pl-4 pt-1 text-xs text-gray-500">{subtitle}</span>
    </div>
  );
}

function Preferences({ prefs, handlePrefSelection, selectedPrefs }) {
  if (prefs.length < 1)
    return (
      <div className="flex py-4">
        <span className="text-gray-500 text-sm">
          No preferences assigned currently.
        </span>
      </div>
    );
  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {prefs.map((p) => (
        <div
          key={p.preference_id}
          className={`border rounded-sm shadow-sm border-gray-100 w-full flex h-10 items-center p-2 py-6 text-xs `}
        >
          <GreenCheckbox
            onChange={() => handlePrefSelection(p.preference_id)}
            checked={selectedPrefs.includes(p.preference_id)}
          />
          <span className="text-left text-sm flex-grow">{p.title}</span>
          <TextField
            size="small"
            disabled={!selectedPrefs.includes(p.preference_id)}
            label="Tier/Point"
            name="pref_value"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            style={{
              width: "80px",
              height: "40px",
            }}
          />
        </div>
      ))}
    </div>
  );
}

/* function Drawings({ drawings, handleClick }) {
  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {drawings.map((d) => (
        <div
          key={d.drawing_id}
          onClick={() => handleClick(d.drawing_id)}
          className={`bg-gray-50 w-full flex h-10 items-center p-2 `}
        >
          <span>{d.description}</span>
        </div>
      ))}
    </div>
  );
} */

/* function Opportunities({ opps, handleClick }) {
  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {opps.map((d) => (
        <div
          key={d.opportunityId}
          
          className={`bg-gray-50 w-full flex  items-center p-2 `}
        >
          <span
            className="text-xs"
            dangerouslySetInnerHTML={{ __html: d.oppDescription }}
          />
        </div>
      ))}
    </div>
  );
} */

export function Questions({
  questions,
  handleQuestionSelection,
  selectedQuestions,
}) {
  console.log("questions", questions);
  const [questionConfig, setQuestionConfig] = useState(
    questions.reduce((acc, curr) => {
      const { question_id } = curr;
      acc[question_id] = {
        required: true,
        responseType: "",
        answerOptions: [],
        currentAnswer: "",
      };
      return acc;
    }, {})
  );

  console.log("questionConfig", questionConfig);
  if (questions.length < 1)
    return (
      <div className="flex py-4">
        <span className="text-gray-400 text-sm tracking-wide">
          No questions assigned currently.
        </span>
      </div>
    );

  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {questions.map((q) => {
        const isSelected = false; //selectedQuestions.includes(q.question_id);
        const answerOptions =
          questionConfig?.[q.question_id]?.["answerOptions"] ?? [];
        const showAnswerOptions =
          questionConfig?.[q.question_id]?.["responseType"] === "radio" ??
          false;
        // const isExpectedAnswer =
        return (
          <div
            key={q.question_id}
            className={`border rounded-sm shadow-sm border-gray-100 w-full flex ${
              isSelected ? " flex-col" : "flex-row items-center"
            }   p-2 py-6 text-xs `}
          >
            <div>
              <GreenCheckbox
                checked={selectedQuestions.includes(q.question_id)}
                onChange={() => handleQuestionSelection(q.question_id)}
              />
              <span>{q.question}</span>
            </div>
            {isSelected && (
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <Select
                    onChange={(e) => handleChange(e, q.question_id)}
                    options={[
                      { label: "Radio", value: "radio" },
                      { label: "Checkbox", value: "cb" },
                      { label: "Select", value: "select" },
                      { label: "Text", value: "text" },
                      { label: "Date", value: "date" },
                    ]}
                    label="Response Type"
                    name="responseType"
                    value={questionConfig[q.question_id]["responseType"]}
                  />
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className="mb-4">
                      Required
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="required"
                      name="required"
                      value="yes"
                      value={questionConfig[q.question_id]["required"]}
                      onChange={(e) => handleChange(e, q.question_id)}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio size="small" />}
                        label="yes"
                      />

                      <FormControlLabel
                        value="false"
                        control={<Radio size="small" />}
                        label="no"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {showAnswerOptions && (
                  <section className="py-2 flex flex-col">
                    <h3>Answers</h3>
                    {answerOptions.map((a, idx) => (
                      <form
                        onSubmit={(e) => removeAnswerOption(idx, q.question_id)}
                        className="flex mt-2 space-x-2 items-center"
                      >
                        <GreenCheckbox checked={true} onChange={() => {}} />
                        <span>Answer #{idx + 1}</span>
                        <span className="underline w-10 px-4">{a}</span>
                        <Button
                          type="submit"
                          /* onClick={(e) => removeAnswerOption(idx, q.question_id)} */
                        >
                          <HighlightOffIcon
                            fontSize="small"
                            className="text-red-500"
                          />
                        </Button>
                      </form>
                    ))}
                  </section>
                )}

                <FormControl className="">
                  <InputLabel htmlFor="standard-adornment-password">
                    Answer
                  </InputLabel>
                  <Input
                    value={questionConfig[q.question_id]["currentAnswer"]}
                    onChange={(e) => handleChange(e, q.question_id)}
                    name="currentAnswer"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          fontSize="small"
                          aria-label="add response"
                          onClick={() => addCurrentToOptions(q.question_id)}
                        >
                          <AddCircleIcon className="text-hk-orange-300" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  function handleChange(e, question_id) {
    const { name, value } = e.target;

    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: { ...prev[question_id], [name]: value },
    }));
  }

  function addCurrentToOptions(question_id) {
    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: {
        ...prev[question_id],
        answerOptions: [
          ...prev[question_id]["answerOptions"],
          prev[question_id]["currentAnswer"],
        ],
        currentAnswer: "",
      },
    }));
  }

  function removeAnswerOption(elem, question_id) {
    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: {
        ...prev[question_id],
        answerOptions: prev[question_id]["answerOptions"].filter(
          (q, idx) => idx != elem
        ),
      },
    }));
  }
}

function AddNewQuestionForm({ onAdd }) {
  const [question, setQuestion] = useState({
    title: "",
    helpText: "",
    description: "",
  });

  return (
    <form className="flex flex-col space-y-4">
      <div className="flex justify-between items-baseline">
        <TextField
          size="small"
          variant="outlined"
          label="Question"
          name="title"
          onChange={handleChange}
          value={question.title}
          className="flex-grow"
        />
      </div>

      <TextField
        variant="outlined"
        size="small"
        label="Help"
        name="helpText"
        value={question.helpText}
        onChange={handleChange}
      />
      <TextField
        label="Description"
        size="small"
        variant="outlined"
        name="description"
        value={question.description}
        onChange={handleChange}
      />
      <div className="flex space-x-2">
        <Button size="small" onClick={clear}>
          Cancel
        </Button>
        <Button
          size="small"
          onClick={() => {
            onAdd(question);
            clear();
          }}
        >
          Add
        </Button>
      </div>
    </form>
  );

  function clear() {
    setQuestion({
      title: "",
      helpText: "",
      description: "",
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    console.log(`handleCHange name ${name} value ${value}`);

    setQuestion((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
}

function AddNewPreferenceForm({ handleChange, handleSave }) {
  return (
    <form className="flex flex-col">
      <TextField
        size="small"
        label="Preference"
        name="preference"
        onChange={handleChange}
      />
      <TextField
        size="small"
        label="Description"
        name="prefDescription"
        onChange={handleChange}
      />
      <TextField
        size="small"
        label="Tier/Point"
        name="prefValue"
        onChange={handleChange}
      />
      <Button onClick={handleSave} size="small">
        Save
      </Button>
    </form>
  );
}

function Forms({ forms, programId }) {
  const history = useHistory();
  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {forms.map((f) => (
        <div
          key={f.id}
          className={`border rounded-sm shadow-sm border-gray-100 w-full justify-between flex p-2 py-6 text-xs `}
        >
          <span>{f.title}</span>
          <span>{f.description}</span>
          <div className="flex space-x-2">
            <Tooltip title="Edit Form">
              <IconButton
                size="small"
                onClick={() =>
                  history.push(`/programs/${programId}/forms/${f.id}`)
                }
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Preview Form">
              <IconButton
                size="small"
                onClick={() =>
                  history.push({
                    pathname: `/programs/${programId}/preApp/preview`,
                    search: `?formId=${f.id}`,
                  })
                }
              >
                <DescriptionIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
}
