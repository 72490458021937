const {
  REACT_APP_COGNITO_IDENTITY_POOL_ID: identityPoolId,
  REACT_APP_COGNITO_USER_POOL_ID: userPoolId,
  REACT_APP_COGNITO_REGION: region,
  REACT_APP_COGNITO_CLIENT_ID: userPoolWebClientId
} = process.env;

const Auth = {
  identityPoolId,
  region,
  userPoolId,
  userPoolWebClientId,
  authenticationFlowType: "USER_PASSWORD_AUTH"
};

export default Auth;
