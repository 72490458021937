import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useOpportunityDetail(oppId) {
  const [opp, setOpp] = useState({});

  useEffect(() => {
    (async () => {
      const {
        data: { data: detail },
      } = await axios.get(`${getBaseUrl()}/opportunities/${oppId}`);
      setOpp(detail);
    })();
  }, [oppId]);

  return opp;
}
