import React, { useContext } from "react";
import { Button, Tooltip } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import useLocalStorage from "../shared/hooks/useLocalStorage";
import UserContext from "../user/UserContext";

export default function HhldProgramCard({
  programId,
  provider,
  programName,
  programType,
  applicationFileId,
  preAppId,
}) {
  const history = useHistory();
  const [emulatedUser] = useLocalStorage("emulatedUser");
  const { user } = useContext(UserContext) || {};

  return (
    <div className="w-full sm:w-1/4 flex flex-col h-64 shadow-md p-4  flex-grow">
      <span className="text-gray-700 pt-2">{programName}</span>
      <p className="flex pt-4 justify-center items-baseline oblique">
        <span className="text-gray-900 oblique ">{provider}</span>

        <span className="text-gray-600 text-sm pl-2">( {programType} )</span>
      </p>

      <section className="flex justify-around mt-auto">
        {applicationFileId !== "" && (
          <Button
            variant="contained"
            size="small"
            style={{
              color: "white",
              backgroundColor: "#41C5B0",
              marginLeft: "5px",
              fontWeight: "600",
            }}
            onClick={viewApplication}
          >
            View Application
          </Button>
        )}
        {applicationFileId === "" && !emulatedUser && (
          <Tooltip title="Allows you to view and enter drawings/opportunities in this program">
            <Button
              size="small"
              style={{
                color: "white",
                backgroundColor: "#4986a7",
              }}
              variant="contained"
              onClick={showApplicationForm}
            >
              Request Application ID
            </Button>
          </Tooltip>
        )}
      </section>
    </div>
  );

  function showApplicationForm() {
    history.push(`/programs/${programId}/preapp/application`);
  }

  function viewApplication() {
    history.push(`/programs/${programId}/preApp/${preAppId}`);
  }
}
