import React from "react";
import { useHistory } from "react-router-dom";
import useQueryParams from "../../../shared/hooks/useQuery";
import {
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Select from "../../../shared/Select";
import TextField from "../../../shared/form/TextField";

import MaterialUITable from "../../../shared/Table/MaterialUITable";
import { formatAsUSCurrency } from "../../../../utils/formatters";
import { usePrograms } from "../../../../queries/programs/usePrograms";
import { useProgramApplicants } from "../../../../queries/programs/useProgramApplicants";

export function ProgramApplicants() {
  const qryParams = useQueryParams();
  const programId = qryParams.get("programId");

  const [state, dispatch] = useProgramApplicants(programId);
  const programs = usePrograms();
  const history = useHistory();

  const columns = [
    { title: "HHLD Program Id", field: "hhldProgramId" },
    { title: "Date Issued", field: "applicationFileDate" },
    { title: "Applicant Roster Id", field: "hkFileId" },
    { title: "Household Id", field: "HHLDProgramID" },
    { title: "First", field: "firstName" },
    { title: "Last ", field: "lastName" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "mobilePhone" },
    { title: "Age", field: "Age" },
    { title: "Acquisition Method", field: "acquisitionMethod" },
    { title: "Ethnicity/Race", field: "EthnicityRace" },
    { title: "# Incomes ", field: "noOfIncome" },
    {
      title: "Total Income",
      field: "TotalIncome",
      render: (row) => formatAsUSCurrency(row.totalIncome),
    },
    { title: "Zip", field: "primaryZip" },
    { title: "City", field: "city" },
    { title: "County", field: "county" },
    { title: "Income Level", field: "incomeLevel" },
  ];

  const {
    loading,
    applicants,
    counts = [],
    firstName,
    lastName,
    programIncomeLevel,
    numMembers,
    email,
    city,
    county,
    selectedProgramId,
    showOnlyUsersWithFileUploads,
  } = state;

  const count = counts?.[0] ?? {};
  const { ProgramTitle, ProgramID } = count;

  return (
    <section className="min-h-screen">
      <h1 className="flex justify-center items-center text-2xl bg-hk-orange-100 text-hk-orange-400 h-20 uppercase">
        {ProgramTitle} ({ProgramID}) applicants
      </h1>

      {/* <BreakDownBySize breakdown={applicants?.[0]} /> */}
      <form className="flex flex-col sm:flex-row  space-y-2 flex-wrap space-x-2 py-6 px-4 items-baseline">
        <Select
          label="Programs"
          name="selectedProgramId"
          value={selectedProgramId}
          options={programs.map((p) => ({
            label: p.Title,
            value: p.ProgramID,
          }))}
          onChange={handleChange}
        />

        <TextField
          label="First Name"
          value={firstName}
          onChange={handleChange}
          name="firstName"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={handleChange}
        />
        <TextField
          label="Email"
          value={email}
          onChange={handleChange}
          name="email"
        />
        <TextField
          label="City"
          value={city}
          onChange={handleChange}
          name="city"
        />
        <TextField
          label="County"
          value={county}
          onChange={handleChange}
          name="city"
        />
        <Select
          label="Income Level"
          name="programIncomeLevel"
          value={programIncomeLevel}
          options={[
            { label: "Extremely Low Income", value: "Extremely Low Income" },
            { label: "Very Low Income", value: "Very Low Income" },
            { label: "Low Income", value: "Low Income" },
            { label: "Median Income", value: "Median Income" },
            { label: "Moderate Income", value: "Moderate Income" },
          ]}
          onChange={handleChange}
        />

        <Select
          label="Number of Members"
          name="numMembers"
          value={numMembers}
          options={[
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10+", value: 10 },
          ]}
          onChange={handleChange}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={showOnlyUsersWithFileUploads}
              onChange={handleChange}
              name="showOnlyUsersWithFileUploads"
            />
          }
          label="Show only users with file uploads"
        />

        <div className="flex space-x-1">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={search}
            type="button"
          >
            Search
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={reset}
            type="button"
          >
            Clear
          </Button>
        </div>
      </form>
      <section className="p-4">
        <MaterialUITable
          title={`${applicants.length} Applicants`}
          data={applicants}
          columns={columns}
          isLoading={loading}
          options={{
            exportButton: true,
            pageSize: 10,
            grouping: true,
            actionsColumnIndex: -1,
          }}
        />
      </section>
    </section>
  );

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    if (name === "selectedProgramId") {
      history.push({
        pathname: "/programs/applicants",
        search: `?${new URLSearchParams({ programId: value })}`,
      });
    }
    dispatch({
      type: name,
      payload: value ? value : type !== "text" ? checked : "",
    });
  }

  function search(e) {
    e?.preventDefault();
    dispatch({
      type: "searching",
      payload: true,
    });
  }

  function reset(e) {
    e.preventDefault();
    dispatch({
      type: "reset",
      payload: true,
    });
    search();
  }
}

function BreakDownBySize({ breakdown = {} }) {
  const {
    HHLDSize1Cnt,
    HHLDSize2Cnt,
    HHLDSize3Cnt,
    HHLDSize4Cnt,
    HHLDSize5Cnt,
    HHLDSize6Cnt,
  } = breakdown;

  const totalCount =
    HHLDSize1Cnt +
    HHLDSize2Cnt +
    HHLDSize3Cnt +
    HHLDSize4Cnt +
    HHLDSize5Cnt +
    HHLDSize6Cnt;
  return (
    <section className="breakdown w-80 p-4 h-72 shadow-md">
      <h3>By Size</h3>

      <div className="grid grid-cols-2 text-sm">
        <span className="text-md underline py-2">Household Size</span>
        <span className="text-md underline py-2">Total Count</span>
        <span>1 Person:</span>
        <span>{HHLDSize1Cnt}</span>
        <span>2 Person:</span>
        <span>{HHLDSize2Cnt}</span>
        <span>2 Person:</span>
        <span>{HHLDSize2Cnt}</span>
        <span>3 Person:</span>
        <span>{HHLDSize3Cnt}</span>
        <span>4 Person:</span>
        <span>{HHLDSize4Cnt}</span>
        <span>5 Person:</span>
        <span>{HHLDSize5Cnt}</span>
        <span>6 or more:</span>
        <span>{HHLDSize6Cnt}</span>
        <span className="py-4 border-t border-gray-300"></span>
        <span className="border-t border-gray-300 ">{totalCount}</span>
      </div>
    </section>
  );
}
