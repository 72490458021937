import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { red, green } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import HelpIcon from "@material-ui/icons/Help";
import HotelIcon from "@material-ui/icons/Hotel";

import BathtubIcon from "@material-ui/icons/Bathtub";
import SquareFootIcon from "@material-ui/icons/SquareFoot";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";

import AMI from "../components/admin/program/AMI";
import axios from "../utils/axios";
import { getBaseUrl } from "../utils/queryHelpers";
import { formatAsUSCurrency, formatDateAndTime } from "../utils/formatters";
import { getDrawingDisabledReasons } from "../utils/hhldUtils";
import ownership_placeholder from "../media/HK-assets/ownership_placeholder.jpg";
import rental_placeholder from "../media/HK-assets/rental_placeholder.jpg";
import useLocalStorage from "../components/shared/hooks/useLocalStorage";
import { getDrawingType } from "../utils/drawingUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    width: 345,
    margin: "20px",
    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    cursor: "pointer",
    "&:hover": {
      boxShadow: "10px 10px 10px 10px  #edf2f7",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatarOpen: {
    backgroundColor: green[500],
  },
  avatarClosed: {
    backgroundColor: red[500],
  },
  price: {
    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
  },
  address: {
    "&& .MuiTypography-body2": {
      fontSize: "0.8rem",
    },
  },
}));

const StyledButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#eb7e3c",
  },
  disabled: {
    color: "#1a202c",
    backgroundColor: "#e2e8f0",
  },
}))(Button);

export default function OpportunityCard({ hideActions = false, opp, user }) {
  const {
    AMIPct,

    ApplicationStatusDesc,
    ApplicantsAccepted,
    Address1,
    Address2,
    Bathrooms,
    BathRoomCnt,
    Bedrooms,
    BedRoomCnt,
    City,
    PublishDrawingPgmCtrFlag,

    EntityID,
    EntityName,
    FileSubmissionDeadlineDate,
    FileSubmissionDeadlineTime,
    FinalType,
    ProgramID,
    State,
    ZipCode,
    OpportunityID,
    PurchaseRentalFinanceAmt,
    ProgramTitle = "",

    LikeStatus,
    LotAreaSqft,
    OpportunityDrawingID,
    OppSubject,
    OppDescription,

    OpportunityTypeDesc,
    HHLDProgramID,
    OpportunityType,
    PreComponentName,
    HOAFee,
    IncomeCategory,
    DrawingDate,
    DrawingTime,
    DrawingType,
    DrawingDeadlineDate,
    DrawingDeadlineTime,

    OppDeliveryDate,
    OppDeliveryTime,
    OpportunityStatus,
    OpportunityDrawingApplicantID,

    SquareFt,
  } = opp;
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [showAMI, setShowAMI] = useState(false);
  const [emulatedUser] = useLocalStorage("emulatedUser");

  const drawingDisabledReasons = getDrawingDisabledReasons({
    user,
    OpportunityDrawingApplicantID,
    ApplicantsAccepted,
    OpportunityDrawingID,
    PublishDrawingPgmCtrFlag,
  });

  const enableEnterDrawing = drawingDisabledReasons.length < 1 && !emulatedUser;

  const enterDrawingButtonTooltipText =
    drawingDisabledReasons.length > 0
      ? drawingDisabledReasons.join("\r\n")
      : "";

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const toggleVertMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleVertMenuClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const showVertMenu = Boolean(anchorEl);

  const oppAvailable = OpportunityStatus == 3;
  const avatarToolTipText = oppAvailable
    ? `${OpportunityTypeDesc} Opportunity Available`
    : `${OpportunityTypeDesc} Opportunity Not Available`;

  const avatarLabel =
    OpportunityType === 1 ? "O" : OpportunityType === 2 ? "R" : "U";

  return (
    <Card className={classes.root} onClick={viewOppDetails}>
      <CardHeader
        avatar={
          <Tooltip title={avatarToolTipText}>
            <Avatar
              aria-label="recipe"
              className={
                oppAvailable ? classes.avatarOpen : classes.avatarClosed
              }
            >
              {avatarLabel}
            </Avatar>
          </Tooltip>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={
          <span onClick={viewProgramDetails}>
            {ProgramTitle} ({OpportunityDrawingID})
          </span>
        }
        subheader={
          <div className="flex flex-col mt-2">
            <span>{EntityName}</span>
            <span className="pt-1">({OpportunityTypeDesc})</span>

            <span className="pt-2 uppercase">{PreComponentName}</span>
          </div>
        }
      />
      <CardMedia
        component="image"
        className={classes.media}
        image={
          OpportunityType == 1 ? ownership_placeholder : rental_placeholder
        }
        title={`${OppSubject} image`}
      />
      <CardContent>
        <div className="flex space-x-2 items-baseline">
          <Typography align="left">
            {formatAsUSCurrency(PurchaseRentalFinanceAmt)}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            ({IncomeCategory})
          </Typography>
        </div>
        <div className="flex items-baseline">
          <span className="text-gray-500 line text-sm">
            {`AMI ${AMIPct}`}
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setShowAMI(true);
              }}
              size="small"
            >
              <HelpIcon style={{ width: 14, height: 14 }} />
            </IconButton>
            <span className="text-xs">({IncomeCategory})</span>
          </span>
          {!!HOAFee && (
            <Typography
              color="textSecondary"
              align="left"
              variant="subtitle2"
            >{`, HOA ${formatAsUSCurrency(HOAFee)}`}</Typography>
          )}
        </div>
        <div className="flex space-x-2 items-baseline mt-4">
          <div className="flex space-x-2 items-center">
            <HotelIcon className="text-hk-orange-200" />
            <span className="text-gray-700">{BedRoomCnt || Bedrooms}</span>
          </div>
          <div className="flex gap-2 items-center">
            <BathtubIcon className="text-hk-orange-200" />
            <span className="text-gray-700">{BathRoomCnt || Bathrooms}</span>
          </div>
          <div className="flex gap-2 items-center">
            <SquareFootIcon className="text-hk-orange-200" />
            <span className="text-gray-700">{SquareFt || LotAreaSqft} </span>
            {SquareFt && <span className="text-gray-500 text-sm">Sq.Ft.</span>}
          </div>
        </div>
        <Address
          add1={Address1}
          add2={Address2}
          city={City}
          state={State}
          zip={ZipCode}
        />
        <Divider />
        <DrawingDetails
          drawingDate={formatDateAndTime(DrawingDate, DrawingTime)}
          fileSubmissionDeadline={formatDateAndTime(
            FileSubmissionDeadlineDate,
            FileSubmissionDeadlineTime
          )}
          drawingType={getDrawingType(DrawingType, FinalType)}
          deadline={formatDateAndTime(DrawingDeadlineDate, DrawingDeadlineTime)}
          deliveryDate={formatDateAndTime(OppDeliveryDate, OppDeliveryTime)}
        />
        {!!ApplicationStatusDesc && (
          <div className="text-center mt-4 uppercase text-xs tracking-wide text-hk-blue-400  bg-hk-blue-100 p-2">
            {ApplicationStatusDesc}
          </div>
        )}
      </CardContent>
      {!hideActions && (
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites" onClick={handleFav}>
            <FavoriteIcon
              className={LikeStatus === 1 ? "text-red-700" : "text-gray-600"}
            />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <Tooltip title={enterDrawingButtonTooltipText}>
            <div>
              <StyledButton
                label="Enter Drawing"
                disabled={!enableEnterDrawing}
                onClick={enterDrawing}
              >
                Enter Drawing
              </StyledButton>
            </div>
          </Tooltip>

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            dangerouslySetInnerHTML={{ __html: OppDescription }}
          />
        </CardContent>
      </Collapse>
      <Popover
        open={showVertMenu}
        anchorEl={anchorEl}
        onClose={handleVertMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button onClick={addImages}>
            <ListItemIcon>
              <AddCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Add Images" />
          </ListItem>
          <ListItem button onClick={editCoverImage}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Cover Image" />
          </ListItem>
        </List>
        <Divider />
        <List component="nav" aria-label="drawing related actions">
          {OpportunityDrawingID && (
            <ListItem button onClick={viewDrawing}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="View Drawing" />
            </ListItem>
          )}
          {!OpportunityDrawingID && (
            <ListItem button>
              <ListItemIcon>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary="Add To Drawing" />
            </ListItem>
          )}
        </List>
      </Popover>
      <AMI
        open={showAMI}
        entityId={EntityID}
        onClose={() => setShowAMI(false)}
      />
    </Card>
  );

  function editCoverImage(e) {
    e.stopPropagation();
    //history.push(`/drawings?drawingId=${OpportunityDrawingID}`);
  }

  function viewDrawing(e) {
    e.stopPropagation();
    history.push(`/drawings?drawingId=${OpportunityDrawingID}`);
  }

  function addImages(e) {
    e.stopPropagation();
    history.push(
      `/opportunities/${OpportunityID}/uploads?providerId=${EntityID}&programId=${ProgramID}`
    );
  }

  function viewOppDetails() {
    history.push(
      `/hhld/opportunities/${OpportunityID}?drawingId=${OpportunityDrawingID}`
    );
  }

  function viewProgramDetails(e) {
    e.stopPropagation();
    // history.push(`/programs/${ProgramID}`);
  }

  async function handleFav(e) {
    e.stopPropagation();

    await axios.post(
      `${getBaseUrl()}/opportunity/${OpportunityID}/fav?action=${
        LikeStatus === 1 ? 0 : 1
      }&hhldProgramId=${user.hhldProgramId}`
    );
  }

  function enterDrawing(e) {
    e.stopPropagation();

    history.push({
      pathname: `/drawings/${OpportunityDrawingID}/enter`,
      state: {
        hhldProgramId: HHLDProgramID,
        agency: EntityName,
        program: ProgramTitle,
        programId: ProgramID,
      },
    });
  }
}

function Address({ add1, add2, city, state, zip }) {
  return (
    <div className="flex flex-wrap py-2 text-gray-900 text-sm">
      <AddressItem label={add1} />
      {add2 && <AddressItem label={`, ${add2}`} />}
      {city && <AddressItem label={`, ${city}`} />}
      {state && <AddressItem label={`, ${state}`} />}
      {zip && <AddressItem label={`, ${zip}`} />}
    </div>
  );
}

function AddressItem({ label = "" }) {
  return (
    <Typography color="textPrimary" align="left" variant="subtitle2">
      {label}
    </Typography>
  );
}

function DrawingDetails({
  drawingDate,
  fileSubmissionDeadline,
  deadline,
  deliveryDate,
  drawingType,
}) {
  return (
    <div className="flex flex-col text-sm gap-2 pt-4">
      <DrawingDetailItem label="Drawing Type" value={drawingType} />
      <DrawingDetailItem label="Drawing Date" value={drawingDate} />
      <DrawingDetailItem label="Drawing Deadline" value={deadline} />
      <DrawingDetailItem
        label="File Submission Deadline"
        value={fileSubmissionDeadline}
      />
      <DrawingDetailItem label="Target Delivey" value={deliveryDate} />
    </div>
  );
}

function DrawingDetailItem({ label, value }) {
  if (!value) return null;
  return (
    <div className="flex gap-2">
      <Typography color="textPrimary" align="left" variant="subtitle2">
        {label}:{" "}
      </Typography>
      <Typography color="textSecondary" variant="subtitle2">
        {value}
      </Typography>
    </div>
  );
}
