import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useProgramForms(programId) {
  const [forms, setForms] = useState([]);

  useEffect(() => {
    fetchForms();
  }, []);

  async function fetchForms() {
    const {
      data: { data: forms },
    } = await axios.get(`${getBaseUrl()}/programs/${programId}/forms`);

    setForms(forms);
  }

  return [forms, fetchForms];
}
