import React from "react";
import CreateIcon from "@material-ui/icons/Create";
import HelpIcon from "@material-ui/icons/Help";
import { Button, Tooltip } from "@material-ui/core";

export default function CreateRankingButton({ onClick, disabled }) {
  return (
    <>
      <Button
        id="create-ranking-btn"
        startIcon={<CreateIcon />}
        size="small"
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={onClick}
      >
        Create Ranking
      </Button>
      {disabled && (
        <Tooltip title="You currently have a Ranking in this Drawing that is not closed or published. ">
          <HelpIcon fontSize="small" className="ml-2" />
        </Tooltip>
      )}
    </>
  );
}
