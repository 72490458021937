import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography,
} from "@material-ui/core";

import { red, green } from "@material-ui/core/colors";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/Home";
import {
  removeHTMLTagsFromString,
  formatAsUSCurrency,
} from "../../../utils/formatters";
import Select from "../../shared/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    margin: "20px",
    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    cursor: "pointer",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatarOpen: {
    backgroundColor: green[500],
  },
  avatarClosed: {
    backgroundColor: red[500],
  },
  price: {
    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
  },
  address: {
    "&& .MuiTypography-body2": {
      fontSize: "0.8rem",
    },
  },
}));

export default function OpportunitiesSorter({
  opps,
  choices,
  handleChange,
  fields,
}) {
  return (
    <section className="mt-4 border border-hk-soft-green rounded p-4">
      <h3 className="hidden">Opportunities</h3>

      {opps.map((o) => (
        <Opportunity
          key={o.OpportunityID}
          opp={o}
          choices={choices}
          handleChange={handleChange}
          selections={fields.choices}
        />
      ))}
    </section>
  );
}

function Opportunity({ opp, choices, handleChange, selections }) {
  const classes = useStyles();
  const {
    AMIPct,
    IncomeCategory,
    OppDeliveryDate,
    OppDescription,
    OpportunityCnt,
    OpportunityID,
    OppSubject,
    PurchaseRentalFinanceAmt,
  } = opp;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const oppAvailable = true;

  return (
    <div className="w-full flex space-x-2 justify-center">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={
                oppAvailable ? classes.avatarOpen : classes.avatarClosed
              }
            >
              <HomeIcon />
            </Avatar>
          }
          title={OppSubject}
        />

        <CardContent>
          <div className="flex space-x-2 items-baseline">
            <Typography align="left">
              {formatAsUSCurrency(PurchaseRentalFinanceAmt)}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              ({IncomeCategory})
            </Typography>
          </div>
          <div className="flex">
            <Typography
              color="textSecondary"
              align="left"
              variant="subtitle2"
            >{`AMI ${AMIPct}`}</Typography>
          </div>
          <div className="flex space-x-2 mt-4 items-baseline">
            <span className="text-sm tracking-wide">Delivery Date: </span>
            <span className="text-gray-600">{OppDeliveryDate}</span>
          </div>
          {OpportunityCnt > 1 && (
            <Select
              options={choices}
              value={selections[OpportunityID] || ""}
              label="Choice"
              onChange={(e) => handleChange(e.target.value, OpportunityID)}
            />
          )}
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <span className="text-gray-600 leading-relaxed text-center text-sm">
              {removeHTMLTagsFromString(OppDescription)}
            </span>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
