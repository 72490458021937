import React from "react";
import { Route, Switch } from "react-router-dom";
import ProgramList from "../ProgramList";
import ProgramPreAppSubmitted from "./preapp/ProgramPreAppSubmitted";
import ProgramDetails from "./ProgramDetails";
import ProgramPreAppForm from "../../../hhld/ProgramApplication";
import { Preferences } from "../../preferences/Preferences";
import { Questions } from "../../questions/Questions";
import { ProgramApplicants } from "../programs/ProgramApplicants";
import { ProgramForm } from "../../program/forms/ProgramForm";
import { ProgramAppPreview } from "../../program/forms/ProgramAppPreview";

export default function ProgramRoutes(props) {
  return (
    <Switch>
      <Route exact path="/admin/programs">
        <ProgramList />
      </Route>

      <Route exact path="/admin/preferences">
        <Preferences />
      </Route>

      <Route exact path="/admin/questions">
        <Questions />
      </Route>

      <Route exact path="/programs/applicants">
        <ProgramApplicants />
      </Route>

      <Route exact path="/programs/:programId">
        <ProgramDetails />
      </Route>

      <Route exact path="/programs/:programId/forms/:formId">
        <ProgramForm />
      </Route>

      <Route exact path="/programs/:programId/preApp/application">
        <ProgramPreAppForm />
      </Route>

      <Route exact path="/programs/:programId/preApp/preview">
        <ProgramAppPreview />
      </Route>
      <Route exact path="/programs/:programId/preApp/:preAppId">
        <ProgramPreAppSubmitted />
      </Route>
    </Switch>
  );
}
