import React from "react";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Select from "../../shared/Select";
import Address from "../../shared/form/Address";
import AnnualIncome from "../../shared/form/AnnualIncome";

export default function EmployerIncomeForm({
  handleChange,
  fields,
  industries,
  handleEmployerAddressChange,
}) {
  const {
    lineOfWorkYears,
    employedLessThan12 = "no",
    annualIncome,
    payType = 1,
    employerName = "",
    employerUrl,

    employerPhone,
    companyDesc,
    title,
    jobDesc,
    jobYears,
    employerAddr = "",
    employerCity = "",
    employerState = "",
    employerZip = "",
    industry = 1,
  } = fields;

  return (
    <form className=" mt-4 flex flex-col sm:grid grid-cols-2 gap-10 ">
      <TextField
        name="employerName"
        value={employerName}
        required
        label="Employer Name"
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        name="employerUrl"
        value={employerUrl}
        label="Website Address"
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />

      <Select
        name="industry"
        label="Industry"
        onChange={handleChange}
        required
        options={industries.map((i) => ({
          label: i.industryTitle,
          value: i.industryId,
        }))}
        value={industry}
      />
      <TextField
        value={employerPhone}
        name="employerPhone"
        label="Phone number"
        type="tel"
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        inputProps={{ maxLength: 10 }}
      />
      <TextField
        name="companyDesc"
        label="Company Description"
        onChange={handleChange}
        value={companyDesc}
        InputLabelProps={{ shrink: true }}
      />

      <Address
        onAddressChange={handleEmployerAddressChange}
        address={employerAddr}
        city={employerCity}
        state={employerState}
        zip={employerZip}
        defaultValue={`${employerAddr}, ${employerCity}, ${employerState}, ${employerZip} `}
      />

      <TextField
        name="title"
        label="Position Title"
        onChange={handleChange}
        value={title}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        name="jobDesc"
        label="Job Description"
        onChange={handleChange}
        value={jobDesc}
        InputLabelProps={{ shrink: true }}
      />
      <Select
        name="payType"
        label="Pay Type"
        required
        onChange={handleChange}
        options={[
          { label: "Hourly", value: 1 },
          { label: "Salary", value: 2 },
          { label: "Other", value: 3 },
        ]}
        value={payType}
      />

      <FormControl component="fieldset">
        <FormControl
          required
          className="text-sm font-semibold"
          component="legend"
        >
          Employed less than 12 months?
        </FormControl>
        <RadioGroup
          required
          aria-label="Employed Less than 12  Months"
          name="employedLessThan12"
          value={employedLessThan12}
          onChange={handleChange}
        >
          <div>
            <FormControlLabel
              value="yes"
              control={<Radio />}
              size="small"
              label="Yes"
            />

            <FormControlLabel
              className="text-sm"
              value="no"
              control={<Radio />}
              label="No"
            />
          </div>
        </RadioGroup>
      </FormControl>

      <TextField
        name="jobYears"
        required
        type="number"
        label="Number of years on the job?"
        onChange={handleChange}
        value={jobYears}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        name="lineOfWorkYears"
        required
        type="number"
        label="Number of years in this line of work?"
        onChange={handleChange}
        value={lineOfWorkYears}
        InputLabelProps={{ shrink: true }}
      />
      <AnnualIncome value={annualIncome} handleChange={handleChange} />
    </form>
  );
}
