import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
} from "@material-ui/core";
import { propIn } from "../../../utils/jsUtils";
import CardItem from "./CardItem";
import useCardStyles from "./cardStyles";

export default function OpportunityCard({
  orgName,
  oppStats = {},
  clickHandler,
}) {
  const classes = useCardStyles();

  return (
    <section className="opportunity p-4 cursor-pointer w-full sm:w-1/4 w-full ">
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          title="Opportunities"
          subheader={orgName}
        ></CardHeader>
        <Divider />
        <CardContent>
          <List>
            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumOpportunitiesCount", oppStats)}
                label="Total"
                value={oppStats.NumOpportunitiesCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumAvailableCount", oppStats)}
                label="Available"
                value={oppStats.NumAvailableCount}
              />
            </ListItem>

            <Divider />
            <section className="py-2">
              <h6 className="text-gray-500">By Income Level</h6>

              <div className="grid grid-cols-5 gap-1 text-sm mt-4">
                <span>Ext. Low</span>
                <span>V. Low</span>
                <span> Low</span>
                <span> Moderate</span>
                <span>Median</span>
              </div>

              <div
                className="grid grid-cols-5 gap-1 text-sm mt-4 text-gray-600
              "
              >
                <span>{oppStats.ExtremelyLowCount}</span>
                <span>{oppStats.VeryLowCount}</span>
                <span> {oppStats.LowCount}</span>
                <span> {oppStats.ModerateCount}</span>
                <span>{oppStats.MedianCount}</span>
              </div>
            </section>
          </List>
        </CardContent>
      </Card>
    </section>
  );
}
