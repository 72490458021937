import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useHhldIncomes(userId, memberId) {
  const [incomes, setIncome] = useState([]);

  useEffect(() => {
    if (!memberId) return;
    (async () => {
      const {
        data: { data: incomes },
      } = await axios.get(
        `${getBaseUrl()}/user/${userId}/incomes?memberId=${memberId}`
      );
      setIncome(incomes);
    })();
  }, [userId, memberId]);

  return incomes;
}
