import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import { useFileDropZone } from "./useFileDropZone";
import { StagedFiles } from "./StagedFiles";

const BrowseButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#ed7d31",
    borderRadius: "5px",
    color: "#fff",
    width: "114.8px",
    height: "26px",
    padding: "3/5px 10.5px 6.1px 11.7px",
    flexGrow: 0,
    alignSelf: "center",
    fontSize: "12px",
    marginTop: "20px",
  },
}))(Button);

type UploadedFile = {
  name: string;
  tags: string[];
  file: any;
  preview: string;
};
export function DropZone({ getInputProps, getRootProps }) {
  // const { files, getInputProps, getRootProps } = useFileDropZone();

  return (
    <section
      className=""
      {...getRootProps({
        className:
          " sm:w-1/2 cursor-pointer dropzone border border-gray-800 border-dashed rounded-md bg-white p-2 mt-4 mr-5 ml-5",
      })}
    >
      <input {...getInputProps()} />
      <h3 className="hidden">Upload files here</h3>
      <div className=" mt-6 text-gray-400 flex flex-col ">
        <span className="text-gray-700">Drag and drop your files here</span>
        <span className="text-gray-400 text-sm pt-2">---OR---</span>
        <BrowseButton>Upload</BrowseButton>
      </div>
    </section>
  );
}
