import React, { useContext } from "react";

import { Route, Switch } from "react-router-dom";
import NotAuthorized from "../../../shared/misc/NotAuthorized";

import Drawings from "./Drawings";
import DrawingRankings from "./DrawingRankings";
import DrawingManager from "./DrawingManager";
import DrawingApplicants from "./DrawingApplicants";

import EnterDrawingForm from "../../../hhld/enter_drawing/EnterDrawingForm";
import DrawingSubmitted from "../../../hhld/enter_drawing/DrawingSubmitted";
import DrawingOptOut from "../../../hhld/optout/DrawingOptOut";
import RankingsList from "./RankingsList";
import LotteryDetail from "../LotteryResultDetail";
import Ranking from "../Ranking";
import { RankingReview } from "../../program/ranking/RankingReview";

import { isInternalAdmin, isExternalAdmin } from "../../../../utils/authUtils";
import UserContext from "../../../user/UserContext";

export default function DrawingHome(props) {
  const { user } = useContext(UserContext) || {};

  const showAdminPages =
    isInternalAdmin(user.profileType) || isExternalAdmin(user.profileType);

  return (
    <Switch>
      <Route exact path="/drawings">
        {showAdminPages ? <Drawings /> : <NotAuthorized />}
      </Route>

      <Route exact path="/drawings/:drawingId">
        {showAdminPages ? <DrawingManager /> : <NotAuthorized />}
      </Route>

      <Route exact path="/drawings/:drawingId/rankings">
        {showAdminPages ? <DrawingRankings /> : <NotAuthorized />}
      </Route>

      <Route path="/drawings/:drawingId/enter">
        <EnterDrawingForm />
      </Route>

      <Route exact path="/drawings/:drawingId/submitted/:submissionId">
        <DrawingSubmitted />
      </Route>

      <Route exact path="/drawings/:drawingId/opt-out">
        <DrawingOptOut />
      </Route>

      <Route exact path="/rankings">
        {showAdminPages ? <RankingsList /> : <NotAuthorized />}
      </Route>

      <Route exact path="/rankings/:rankId/review">
        {showAdminPages ? <RankingReview /> : <NotAuthorized />}
      </Route>
      <Route exact path="/rankings/:rankId">
        {showAdminPages ? <Ranking /> : <NotAuthorized />}
      </Route>

      <Route exact path="/drawings/:drawingId/applicants">
        {showAdminPages ? <DrawingApplicants /> : <NotAuthorized />}
      </Route>

      <Route exact path="/drawings/:drawingId/results">
        <LotteryDetail />
      </Route>

      <Route exact path="/drawings/:drawingId/rankings/:rankId">
        <Ranking />
      </Route>
    </Switch>
  );
}
