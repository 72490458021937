import React from "react";
import { DropZone } from "./DropZone";
import { useFileDropZone } from "./useFileDropZone";

export function AccordionItem({ onFileDrop }) {
  const { files, getInputProps, getRootProps } = useFileDropZone(onFileDrop);
  return (
    <section className="flex justify-center items-center">
      <DropZone getInputProps={getInputProps} getRootProps={getRootProps} />
    </section>
  );
}
