import { removeHTMLTagsFromString } from "./formatters";
import {
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Input,
  InputLabel,
  TextField,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import DatePicker from "../components/shared/form/DatePicker";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { hasEmptyFields } from "./jsUtils";
import { isHhldAdmin } from "./authUtils";

export const formLabelsTheme = createMuiTheme({
  overrides: {
    /*    MuiFormGroup: {
      root: {
        display: "flex",
        flexDirection: "row",
      },
    }, */

    MuiFormControl: {
      root: {
        display: "flex",
        maxWidth: "70rem",
      },
    },

    MuiFormControlLabel: {
      root: {
        display: "flex",
        maxWidth: "80rem",
      },
      label: {
        fontSize: "0.8rem",
        textAlign: "left",
        placeItems: "self-start",
        maxWidth: "80rem",
      },
    },

    MuiIconButton: {
      label: {
        alignSelf: "end",
      },
    },
  },
});

export const checkBoxTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        alignSelf: "end",
      },
    },
    MuiFormGroup: {
      root: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.8rem",
        textAlign: "left",
        placeItems: "self-start",
        maxWidth: "80rem",
      },
    },
  },
});
/**
 *
 * @param {*} ansOptions
 */
export function getSelectOptionsFromAnswerOptions(ansOptions = "") {
  return ansOptions.map((a) => {
    const [label, isCorrect, id] = a;
    return {
      label: label,
      value: label,
    };
  });
  if (ansOptions !== null) {
    return ansOptions
      .split("|")
      .filter(Boolean)
      .map((opt) => {
        const [value, option] = opt.split("~");

        return {
          label: removeHTMLTagsFromString(option),
          value: Number(value),
        };
      });
  }
  return [];
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&& .MuiFormLabel-root": {
      fontSize: "0.75rem",
    },
  },
  formGroup: {
    marginTop: "10px",
    display: "flex",

    alignItems: "center",
  },
  checkBoxLabel: {
    "&& .MuiFormControlLabel-label": {
      fontSize: "0.75rem",
    },
  },
  selectMenuLabel: {
    fontSize: "0.65rem",
  },
}));

type Question = {
  answer_options: string;
  answer_type: number;
  AnswerValue: string | null;
  question_id: string;
  EntityID: string;

  ExpectedPassAnswers: string;
  ID: number;
  InitiatedBy: string;
  OpportunityCnt: number;
  OpportunityDrawingID: string;
  OpportunityEligibilityQuestionID: string;
  OpportunityID: string;
  Points: number;
  ProgramID: string;
  Question: string;
  RecordStatus: string;
  SortOrder: number;
  iPageCnt: number;
};

type Other = {
  dispatch: (type: string, payload: object) => void;
  form: string;
  keyId: string;
  fields: any;
};

export function renderFormField(question: Question, other: Other) {
  //console.log("renderFormField", question);

  switch (question.answer_type) {
    case "checkbox":
      return RenderCheckbox(question, other);
    case "radio":
      return RenderRadio(question, other);

    case "dropdown":
      return RenderSelect(question, other);

    case "text":
      return RenderTextField(question, other);
    case "datetime":
      return RenderDateField(question, other);
  }
}

export function RenderDateField(q: Question, other: Other) {
  const { keyId, form, dispatch, fields, disabled } = other;
  return (
    <div
      className="w-full sm:w-1/2
    "
    >
      <DatePicker
        label={
          <span
            className="text-xs"
            dangerouslySetInnerHTML={{ __html: q.Question }}
          ></span>
        }
        required
        disabled={disabled}
        name={`${form}_${q[keyId]}`}
        value={fields[`${form}_${q[keyId]}`]?.value}
        handleChange={(date) => {
          dispatch({
            type: `${form}_${q[keyId]}`,
            payload: {
              value: date,
              ansType: 6,
            },
          });
        }}
      />
    </div>
  );
}

export function RenderCheckbox(q: Question, other: Other) {
  const { keyId, form, dispatch, fields, disabled = false } = other;
  const options = getSelectOptionsFromAnswerOptions(q.AnswerOptions);

  return (
    <ThemeProvider theme={checkBoxTheme}>
      <div className="flex flex-col  justify-between items-baseline mt-4 border-l-2 border-gray-200 p-4">
        <FormLabel>
          <div className="flex w-full">
            <span
              className="text-sm leading-loose w-full asterisk"
              dangerouslySetInnerHTML={{ __html: q.Question }}
            />
          </div>
        </FormLabel>
        <FormGroup>
          {options.map((o) => {
            return (
              <FormControlLabel
                required
                control={
                  <Checkbox
                    disabled={disabled}
                    name={`${form}_${q[keyId]}`}
                    checked={fields[`${form}_${q[keyId]}`]?.value[o.value] == 1}
                    onChange={(e) =>
                      dispatch({
                        type: `${form}_${q[keyId]}`,
                        mode: "multiple",
                        payload: {
                          value: {
                            [o.value]: e.target.checked ? 1 : 0,
                          },
                          ansType: 1,
                        },
                      })
                    }
                  />
                }
                label={o.label}
              />
            );
          })}
        </FormGroup>
      </div>
    </ThemeProvider>
  );
}

/**
 *
 * @param {*} q
 * @param {*} other
 */
export function RenderRadio(q: Question, other: Other) {
  const {
    answer_options = "1~<p>Yes</p>~7~Live or Work in Program Location|2~<p>No</p>~0~|",
    question_id,

    question,
  } = q;
  const { dispatch, fields, form, keyId, disabled = false, type } = other;

  const options = getSelectOptionsFromAnswerOptions(answer_options);

  console.log("fields", fields);

  const value =
    type === "preference"
      ? fields["preferences"]?.[question_id]
      : fields["questions"]?.[question_id];

  return (
    <ThemeProvider theme={formLabelsTheme}>
      <div className="flex flex-col justify-between items-baseline mt-6 border-l-2 border-gray-200 px-4">
        <FormLabel>
          <div className="flex w-full">
            <span
              className="text-sm text-gray-600 leading-loose w-full asterisk"
              dangerouslySetInnerHTML={{ __html: question }}
            />
          </div>
        </FormLabel>
        <RadioGroup
          row
          value={Number(value)}
          size="small"
          onChange={(e) => {
            dispatch({
              type: type,
              payload: {
                id: question_id,
                value: Number(e.target.value),
              },
            });
          }}
          name={`${form}_${q[keyId]}`}
        >
          {options.map(({ label, value }, i) => (
            <FormControlLabel
              key={i}
              value={Number(value)}
              label={label}
              control={
                <Radio
                  disabled={disabled}
                  style={{
                    alignSelf: "center",
                    fontSize: "0.5rem",
                  }}
                />
              }
            />
          ))}
        </RadioGroup>
      </div>
    </ThemeProvider>
  );
}

export function RenderSelect(q, other) {
  const classes = useStyles();
  const { Question, AnswerOptions } = q;
  const options = getSelectOptionsFromAnswerOptions(AnswerOptions);
  const { form, keyId, dispatch, fields, disabled = false } = other;

  return (
    <div className="flex mt-4  border-l-2 border-gray-200 px-4">
      <TextField
        disabled={disabled}
        aria-label="required"
        label="Select"
        name={`${form}_${q[keyId]}`}
        value={fields[`${form}_${q[keyId]}`]?.value ?? ""}
        helperText={
          <span
            className="text-xs asterisk"
            dangerouslySetInnerHTML={{ __html: Question }}
          />
        }
        onChange={(e) =>
          dispatch({
            type: `${form}_${q[keyId]}`,
            payload: {
              value: e.target.value,
              ansType: 3,
            },
          })
        }
        select
      >
        {options.map((option) => (
          <MenuItem
            classes={{ root: classes.selectMenuLabel }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

export function RenderTextField(q, other) {
  const {
    textFieldWidth = 300,
    txtFieldLabelFontSize = "0.8rem",
    dispatch,
    form,
    keyId,
    fields,
    disabled = false,
  } = other;
  const classes = useStyles();

  return (
    <ThemeProvider theme={formLabelsTheme}>
      <div className="flex mt-8 border-l-2 border-gray-200 px-4">
        <FormControl fullWidth>
          <InputLabel shrink htmlFor={`txt-${q.keyId}`} className="asterisk">
            <span
              dangerouslySetInnerHTML={{ __html: `${q.question} ?` }}
            ></span>
          </InputLabel>

          <Input
            disabled={disabled}
            id={`txt-${q.EligibilityQuestionID}`}
            style={{
              width: textFieldWidth,
            }}
            name={`${form}_${q[keyId]}`}
            value={fields[`${form}_${q[keyId]}`]?.value ?? ""}
            onChange={(e) =>
              dispatch({
                type: `${form}_${q[keyId]}`,
                payload: {
                  value: e.target.value,
                  ansType: q.AnswerType,
                },
              })
            }
          />
        </FormControl>
      </div>
    </ThemeProvider>
  );
}

/**
 *
 * @param {*} given an object extract key, values matching the formKey
 * @param {*} formKey
 *
 */
export function extractFormFields(state, formKey) {
  return Object.entries(state).reduce((acc, [key, value]) => {
    if (key.startsWith(formKey)) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

/**
 *
 * @param {*} incomes
 * @param {*} members
 * @returns a merged object where incomes, and members are combined based on MemberID
 */
export function combined(incomes = [], members = []) {
  return members.reduce((acc, member) => {
    const income = incomes.filter(
      (income) => income.MemberID === member.MemberID
    );

    acc.push({ member: member, incomes: [...income] });

    return acc;
  }, []);
}

export function checkIncomeFields(state) {
  switch (state.incomeType) {
    case "E":
      return hasEmptyFields(state, [
        "incomeType",
        "annualIncome",
        "industry",
        "employerName",
        "employedLessThan12",
        "lineOfWorkYears",
      ]);

    case "S":
      return hasEmptyFields(state, [
        "annualIncome",
        "title",
        "employerName",
        "yearFormed",
        "lineOfWorkYears",
      ]);

    case "U":
      return hasEmptyFields(state, [
        "annualIncome",
        "dateIncomeStarted",
        "unemploymentInstName",
      ]);

    case "SS":
      return hasEmptyFields(state, [
        "annualIncome",
        "dateIncomeStarted",
        "anticipatedIncomeEndDate",
        "caseNum",
        "caseDate",
        "payerName",
      ]);

    case "CS":
      return hasEmptyFields(state, [
        "annualIncome",
        "dateIncomeStarted",
        "anticipatedIncomeEndDate",
        "caseNum",
        "caseDate",
        "payerName",
        "childName",
        "childDOB",
      ]);

    case "A":
      return hasEmptyFields(state, [
        "dateLastEmployed",
        "receiveUnEmployment",
        "declaration",
      ]);

    case "R":
    case "D":
      return hasEmptyFields(state, [
        "annualIncome",
        "dateIncomeStarted",
        "retirementInstName",
        "employerState",
        "employerCity",
        "employerAddr",
        "employerZip",
      ]);
  }
}

export function getIncomeTypeTextFromInt(incomeType) {
  if (incomeType == 1) return "E";
  if (incomeType == 2) return "S";
  if (incomeType == 3) return "U";
  if (incomeType == 4) return "R";
  if (incomeType == 5) return "D";
  if (incomeType == 6) return "SS";
  if (incomeType == 7) return "CS";
  if (incomeType == 7) return "IA";
}

export function getDrawingDisabledReasons(props) {
  const {
    user,
    OpportunityDrawingID,
    ApplicantsAccepted,
    OpportunityDrawingApplicantID,
    entryNo,
    PublishDrawingPgmCtrFlag = 1,
  } = props;
  let reasons = [];

  if (!isHhldAdmin(user)) {
    reasons.push("User not admin. Only admins can enter a drawing");
  }

  if (!OpportunityDrawingID) {
    reasons.push("Opportunity has not been assigned a drawing yet.");
  }

  if (ApplicantsAccepted != 1) {
    reasons.push("Not accepting applicants anymore.");
  }
  if (OpportunityDrawingApplicantID) {
    reasons.push(
      "Entering multiple drawings in the same program is not allowed."
    );
  }
  if (PublishDrawingPgmCtrFlag != 1) {
    reasons.push("Opportunity has not been published yet.");
  }

  return reasons;
}
