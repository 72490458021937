import React, { useState, useReducer } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { makeStyles } from "@material-ui/core/styles";
import Select from "../../shared/Select";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { validateEmail } from "../../../utils/jsUtils";
import { formatDateToTimeZone } from "../../../utils/formatters";

function formReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "firstName":
      return { ...state, firstName: payload };
    case "lastName":
      return { ...state, lastName: payload };
    case "userName":
      return { ...state, userName: payload };
    case "userType":
      return { ...state, userType: payload };
  }
}

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formField: {
    width: "200px",
  },
}));

export default function AddUser({ updateUserIncId, open, onClose, orgId }) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(formReducer, {
    userName: "",
    firstName: "",
    lastName: "",
    userType: "Z01",
  });

  const { userName, firstName, lastName, userType } = state;
  const [errors, setErrors] = useState({});
  const [userCreated, setUserCreated] = useState(false);
  const submitDisabled =
    !userName || !firstName || !lastName || Object.keys(errors).length > 0;
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        Add user
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} className="mt-4 p-4">
          <div className="flex flex-col justify-center items-start">
            <div className="flex">
              <TextField
                error={!!errors["userName"]}
                helperText={errors["userName"] ? errors["userName"] : ""}
                required
                className={classes.formField}
                value={userName || ""}
                type="email"
                onChange={validateEmailAndUpdate}
                label="User Name"
                name="userName"
              />

              <Tooltip title="Add this user to Org">
                <IconButton
                  disabled={!!errors["userName"]}
                  aria-label="close"
                  onClick={addExistingUserToOrg}
                  size="small"
                >
                  <PersonAddIcon />
                </IconButton>
              </Tooltip>
            </div>

            <TextField
              required
              className={classes.formField}
              value={firstName || ""}
              onChange={handleChange}
              label="First Name"
              name="firstName"
            />

            <TextField
              required
              className={classes.formField}
              value={lastName || ""}
              onChange={handleChange}
              label="Last Name"
              name="lastName"
            />
            <div className="pt-4 -ml-2">
              <Select
                required
                className={classes.formField}
                label="User Type"
                options={[
                  {
                    label: "Internal Admin (A01)",
                    value: "A01",
                    disabled: true,
                  },
                  { label: "External Admin (D01)", value: "D01" },
                  { label: "Household Applicant (Z01)", value: "Z01" },
                ]}
                name="userType"
                onChange={handleChange}
                value={userType}
              ></Select>
            </div>
          </div>

          <DialogActions>
            <Button size="small" type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={submitDisabled}
              size="small"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  async function handleSubmit(e) {
    e.preventDefault();
    const {
      data: { data: User },
    } = await axios.post(`${getBaseUrl()}/user`, {
      userName: userName,
      firstName: firstName,
      lastName: lastName,
      profileType: userType,
      orgId: orgId,
    });

    onClose(false);
    if (User) {
      setUserCreated(true);
    }
    const subId = getSub(User?.User?.Attributes);
    console.log("subId", subId);
    if (subId) {
      updateUserIncId({ subId, userName });
    }
    //fetchCognitoUserList();
  }
  function getSub(attributes = []) {
    return attributes.find((a) => a.Name === "sub")?.Value;
  }
  /* async function updateUserIncId(subId, userName) {
      a const {
      data: { data: user },
    } = await axios.post(`${getBaseUrl()}/admin/user/${userId}`, {
      userId,
      subId,
    });

  } */
  function handleChange(e) {
    const { value, name } = e.target;
    dispatch({
      type: name,
      payload: value,
    });
  }

  async function validateEmailAndUpdate(e) {
    //remove userName related error msgs
    const err = { ...errors };
    delete err["userName"];
    setErrors(err);
    const { name, value } = e.target;
    dispatch({
      type: name,
      payload: value,
    });

    if (validateEmail(value)) {
      const { data: msg } = await axios.post(`${getBaseUrl()}/user/cog`, {
        userName: value,
      });

      if (msg.errorMessage) {
        setErrors({ ...errors, userName: msg.errorMessage });
      }
    }
  }

  async function addExistingUserToOrg() {}
}
