import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";

type FormGroupProps = {
  label: string;
  name: string;
  id: string;
  error?: boolean;
  errorMessage?: string;
  type: string;
  placeholder?: string;
  required?: boolean;
  value: string | number;
  handleChange: Function;
};

export default function FormGroup({
  label,
  name,
  id,
  error,
  errorMessage,
  type,
  placeholder,
  required,
  value,
  handleChange,
  ...other
}: FormGroupProps) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="mt-4 flex flex-col space-y-2 relative">
      <label htmlFor={id} className="text-left text-gray-600 text-sm">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      {type === "password" && (
        <IconButton
          onClick={togglePasswordVisibility}
          style={{ position: "absolute", right: 0, top: 15 }}
        >
          {showPassword && <VisibilityIcon />}
          {!showPassword && <VisibilityOffIcon />}
        </IconButton>
      )}
      <input
        id={id}
        className="w-full pl-4 h-10 border border-gray-200 placeholder-gray-400 text-sm"
        type={type === "password" && showPassword ? "text" : type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
        {...other}
      />
      {error && (
        <span className="text-red-600 text-xs text-left">{errorMessage}</span>
      )}
    </div>
  );

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }
}
