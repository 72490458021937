import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useDrawingOpportunities(drawingId, programId) {
  const [opps, setOpps] = useState([]);

  useEffect(() => {
    if (!drawingId) return;
    (async () => {
      const {
        data: { data: opps },
      } = await axios.get(
        `${getBaseUrl()}/drawings/${drawingId}/opportunities`
      );
      console.log("opps", opps);
      setOpps(opps);
    })();
  }, [drawingId]);

  return opps;
}
