import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import SkeletonWrapper from "../../shared/SkeletonWrapper";
import UserContext from "../../user/UserContext";
import { propIn } from "../../../utils/jsUtils";
import axios from "../../../utils/axios";
import { formatAsUSCurrency, formatDate } from "../../../utils/formatters";
import { getBaseUrl } from "../../../utils/queryHelpers";
import AddHhldMemberModal from "../AddHhldMemberModal";
import AddIncomeModal from "../income/AddIncomeModal";
import EditMemberModal from "../edit/EditMember";
import EditIncomeModal from "../edit/EditIncome";
import HhldMembers from "./members/HhldMembers";
import HhldIncomes from "./income/HhldIncomes";
import LoadingBackDrop from "../../shared/misc/LoadingBackDrop";
import { useHhldRegSummary } from "../../../queries/hhld/useHhldRegSummary";
import PopperMsg from "../../shared/misc/PopperMsg";

function Item({ skeletenCond, label, value }) {
  return (
    <div className="flex p-3  justify-between">
      <span className="w-1/3 text-left text-sm font-semibold text-gray-800">
        {label}
      </span>
      <SkeletonWrapper w={50} h={18} condition={skeletenCond}>
        <span className="w-2/3 text-gray-700 text-sm">{value}</span>
      </SkeletonWrapper>
      <hr />
    </div>
  );
}

function reducer(state, { type, payload }) {
  if (type === "incomes") {
    return {
      ...state,
      incomes: mergeIncomes(payload),
      totalIncome: payload.reduce((acc, curr) => acc + curr.Income, 0),
    };
  }
  if (type === "selectedMember") {
    return {
      ...state,
      selectedMember: payload,
      showEditMemberModal: true,
    };
  }
  if (type === "selectedIncome") {
    return {
      ...state,
      selectedIncome: payload,
      showEditIncomeModal: true,
    };
  }
  if (type === "showAddIncomeModal") {
    return {
      ...state,
      showAddIncomeModal: payload,
      showLoading: payload,
    };
  }

  if (type === "showEditIncomeModal") {
    return {
      ...state,
      showEditIncomeModal: payload,
      showLoading: payload,
    };
  }
  return { ...state, [type]: payload };
}

export default function HhldProfile() {
  const [
    {
      members,
      showAddMemberModal,
      showAddIncomeModal,
      showEditMemberModal,
      showEditIncomeModal,
      incomes,
      showLoading,
      totalIncome,
      selectedIncome,
      selectedMember,
      errorMessage,
      showAMIHelp,
      showIncomeHelp,
      editSuccess,
      editFailure,
    },
    dispatch,
  ] = useReducer(reducer, {
    members: [],
    incomes: [],
    showAddMemberModal: false,
    showAddMemberModal: false,
    showEditMemberModal: false,
    showEditMemberModal: false,
    showLoading: false,
    totalIncome: 0,
    selectedIncome: "",
    selectedMember: "",
    errorMessage: null,
    showAMIHelp: false,
    showIncomeHelp: false,
    editSuccess: false,
    editFailure: false,
  });

  const { user } = useContext(UserContext) || {};

  const summary = useHhldRegSummary(user.userId, [showLoading]);

  useEffect(() => {
    fetchHhldMembers();
    fetchHhldIncomes();
  }, []);

  const hhldAdminPresent = members.find((member) => member.Role_Admin == 1);

  return (
    <section className="min-h-screen">
      <h3 className="bg-hk-orange-100 text-hk-orange-400 h-20 text-2xl uppercase flex items-center justify-center text-center align-middle">
        Information Profiles
      </h3>
      <section className="mt-4 w-full sm:px-20 p-4 flex flex-col min-h-48">
        <section
          id="hhldProfile_signup"
          className="hhldProfiles_members flex flex-col mt-8 min-h-48"
        >
          <div className="flex border-b-2 border-hk-blue bg-gray-100">
            <h5 className=" flex items-center uppercase text-sm gap-2 text-left p-2 mr-auto">
              Sign up Info
            </h5>
          </div>

          <section
            className="  w-full flex flex-col border-gray-200 "
            id="user-signup "
          >
            <Item
              skeletenCond={!propIn("noOfBuyers", summary)}
              label="Total HouseHold Members"
              value={summary.noOfBuyers}
            />

            <Item
              skeletenCond={!propIn("totalIncome", summary)}
              label="Total Houshold Income"
              value={formatAsUSCurrency(summary.totalIncome)}
            />

            <Item
              skeletenCond={!propIn("totalMonthlyHousingPymt", summary)}
              label="Total Monthly Housing Payment"
              value={formatAsUSCurrency(summary.totalMonthlyHousingPymt)}
            />
          </section>
        </section>
        <div className="flex border-b-2 border-hk-blue bg-gray-100">
          <h5 className="uppercase text-sm text-left p-2 mr-auto">Profile</h5>
          {/* <Button
            size="small"
            className={classes.button.edit}
            endIcon={<EditIcon />}
            disabled
            color="default"
          >
            Edit
          </Button> */}
        </div>
        <section
          className="  w-full flex flex-col border-gray-200 "
          id="user-profile-top "
        >
          <Item
            skeletenCond={!propIn("firstName", user)}
            label="Name"
            value={`${user.firstName} ${user.lastName}`}
          />

          <Item
            skeletenCond={!propIn("email", user)}
            label="Email"
            value={user.email}
          />

          <Item
            skeletenCond={!propIn("phone", user)}
            label="Phone"
            value={user.phone}
          />

          <Item
            skeletenCond={!propIn("profileDisplay", user)}
            label="Role"
            value={user.profileDisplay}
          />
          <Item
            skeletenCond={!propIn("totalHhldMembers", user)}
            label="Total HouseHold Members"
            value={user.totalHhldMembers}
          />
          <Item
            skeletenCond={!propIn("totalHhldIncome", user)}
            label="Total Income"
            value={formatAsUSCurrency(user.totalHhldIncome)}
          />
          <Item
            skeletenCond={!propIn("amiPct", user)}
            label={
              <div className="flex items-center">
                <span>AMI %</span>
                <IconButton onClick={toggleAMIHelp}>
                  <HelpIcon style={{ fontSize: "16px" }} />
                </IconButton>
              </div>
            }
            value={user.amiPct}
          />
          <Item
            skeletenCond={!propIn("amiLevel", user)}
            label={
              <div className="flex items-center">
                <span>Income Category</span>
                <IconButton onClick={toggleIncomeHelp}>
                  <HelpIcon style={{ fontSize: "16px" }} />
                </IconButton>
              </div>
            }
            value={user.amiLevel}
          />
          <Item
            skeletenCond={!propIn("primaryCounty", user)}
            label="County"
            value={user.primaryCounty}
          />
        </section>
        {/* error messages */}
        <PopperMsg
          open={errorMessage !== null}
          msgType="error"
          handleClose={() => dispatch({ type: "errorMessage", payload: null })}
        >
          <span>{errorMessage}</span>
        </PopperMsg>

        <PopperMsg
          open={editSuccess}
          handleClose={() => dispatch({ type: "editSuccess", payload: false })}
        >
          <span>Edit Income Successful</span>
        </PopperMsg>

        {/* members */}
        <HhldMembers
          members={members}
          removeMember={removeMember}
          editMember={editMember}
          openMemberModal={openMemberModal}
        />

        {/* Incomes */}
        <HhldIncomes
          totalIncome={formatAsUSCurrency(totalIncome)}
          incomes={incomes}
          openAddIncomeModal={openAddIncomeModal}
          removeIncome={removeIncome}
          handleEditIncome={handleEditIncome}
        />
      </section>
      <AddHhldMemberModal
        userId={user.userId}
        email={user.email}
        open={showAddMemberModal}
        onClose={handleCloseAddMemberModal}
        hhldAdminPresent={hhldAdminPresent}
      />
      <AddIncomeModal
        userId={user.userId}
        open={showAddIncomeModal}
        members={members}
        handleSubmit={addIncome}
        onClose={handleCloseAddIncomeModal}
      />
      <Dialog
        open={showEditMemberModal}
        onClose={() =>
          dispatch({ type: "showEditMemberModal", payload: false })
        }
      >
        <DialogTitle className="bg-hk-cyan-100 text-hk-cyan-400">
          Edit Member
        </DialogTitle>
        <DialogContent>
          <EditMemberModal
            userId={user.userId}
            open={showEditMemberModal}
            memberId={selectedMember}
            handleSubmit={handleEditMember}
            onClose={handleCloseEditMemberModal}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={showEditIncomeModal}
        onClose={() =>
          dispatch({ type: "showEditIncomeModal", payload: false })
        }
      >
        <DialogTitle className="bg-hk-cyan-100 text-hk-cyan-400">
          <span>Edit Income ({selectedIncome.memberName})</span>
        </DialogTitle>
        <DialogContent>
          <EditIncomeModal
            userId={user.userId}
            open={showEditIncomeModal}
            memberId={selectedMember}
            income={selectedIncome}
            handleSubmit={handleSubmitEditIncome}
            onClose={handleCloseEditIncomeModal}
            processing={showLoading}
          />
        </DialogContent>
      </Dialog>
      <LoadingBackDrop open={showLoading} />
      <AMIHelpDialog
        open={showAMIHelp}
        onClose={(e) =>
          dispatch({
            type: "showAMIHelp",
            payload: false,
          })
        }
      />

      <IncomeHelpDialog
        open={showIncomeHelp}
        onClose={(e) =>
          dispatch({
            type: "showIncomeHelp",
            payload: false,
          })
        }
      />
    </section>
  );

  function toggleAMIHelp() {
    dispatch({
      type: "showAMIHelp",
      payload: true,
    });
  }

  function toggleIncomeHelp() {
    dispatch({
      type: "showIncomeHelp",
      payload: true,
    });
  }

  async function fetchHhldMembers() {
    try {
      const {
        data: { data: members },
      } = await axios.get(`${getBaseUrl()}/user/${user.userId}/hhld_members`);
      dispatch({
        type: "members",
        payload: members,
      });
    } catch (err) {
      console.error(`error getting members list ${err}`);
      dispatch({
        type: "members",
        payload: [],
      });
    }
  }

  async function fetchHhldIncomes() {
    try {
      const {
        data: { data: incomes },
      } = await axios.get(`${getBaseUrl()}/user/${user.userId}/incomes`);
      dispatch({
        type: "incomes",
        payload: incomes,
      });
    } catch (err) {
      console.error(`error getting incomes ${err}`);
      dispatch({
        type: "incomes",
        payload: [],
      });
    }
  }

  async function addIncome(income) {
    dispatch({
      type: "showAddIncomeModal",
      payload: false,
    });
    dispatch({ type: "showLoading", payload: true });

    const resp = await axios.post(
      `${getBaseUrl()}/user/${user.userId}/incomes`,
      {
        ...income,
        caseDate: formatDate(income.caseDate),
        dateLastEmployed: formatDate(income.dateLastEmployed),
        dateIncomeStarted: formatDate(income.dateIncomeStarted),
        yearFormed: formatDate(income.yearFormed),
        anticipatedIncomeEndDate: formatDate(income.anticipatedIncomeEndDate),
      }
    );
    fetchHhldIncomes();
    dispatch({ type: "showLoading", payload: false });
  }

  function handleCloseAddMemberModal() {
    dispatch({
      type: "showAddMemberModal",
      payload: false,
    });

    fetchHhldMembers();
    fetchHhldIncomes();
  }

  function openMemberModal() {
    dispatch({
      type: "showAddMemberModal",
      payload: true,
    });
  }

  function handleCloseAddIncomeModal() {
    dispatch({
      type: "showAddIncomeModal",
      payload: false,
    });
  }

  function handleCloseEditMemberModal() {
    dispatch({
      type: "showEditMemberModal",
      payload: false,
    });
    fetchHhldMembers();
  }

  function handleCloseEditIncomeModal() {
    dispatch({
      type: "showEditIncomeModal",
      payload: false,
    });

    fetchHhldIncomes();
  }

  function openAddIncomeModal() {
    dispatch({
      type: "showAddIncomeModal",
      payload: true,
    });
  }
  async function removeMember(memberId) {
    dispatch({
      type: "showLoading",
      payload: true,
    });

    const { data } = await axios.delete(
      `${getBaseUrl()}/user/${user.userId}/members/${memberId}`
    );

    if (data.statusCode != 200) {
      dispatch({
        type: "errorMessage",
        payload: data.errorMessage,
      });
    }

    dispatch({
      type: "showLoading",
      payload: false,
    });
    fetchHhldMembers();
    fetchHhldIncomes();
  }

  async function editMember(memberId) {
    dispatch({
      type: "selectedMember",
      payload: memberId,
    });
  }

  async function handleEditIncome(income) {
    dispatch({
      type: "selectedIncome",
      payload: income,
    });
  }

  async function handleSubmitEditIncome(income) {
    dispatch({
      type: "showEditIncomeModal",
      payload: true,
    });
    dispatch({
      type: "showLoading",
      payload: true,
    });

    await axios
      .post(`${getBaseUrl()}/user/${user.userId}/incomes?edit=y`, income)
      .catch((err) => {
        console.log("error edit income");
        dispatch({
          type: "showEditIncomeModal",
          payload: false,
        });
        dispatch({
          type: "editFailure",
          payload: true,
        });
      });

    fetchHhldIncomes();
    dispatch({
      type: "showLoading",
      payload: false,
    });
    dispatch({
      type: "editSuccess",
      payload: true,
    });

    dispatch({
      type: "showEditIncomeModal",
      payload: false,
    });
  }

  async function handleEditMember(member) {
    dispatch({
      type: "showEditMemberModal",
      payload: true,
    });
    dispatch({
      type: "showLoading",
      payload: true,
    });

    await axios
      .post(
        `${getBaseUrl()}/user/${user.userId}/members/${selectedMember}`,
        member
      )
      .catch((err) => {
        console.log("error edit member");
        dispatch({
          type: "showLoading",
          payload: true,
        });
      });
    fetchHhldMembers();
    fetchHhldIncomes();
    dispatch({
      type: "showEditMemberModal",
      payload: false,
    });
    dispatch({
      type: "showLoading",
      payload: false,
    });
  }

  async function removeIncome(incomeId) {
    dispatch({
      type: "showLoading",
      payload: true,
    });
    await axios.delete(
      `${getBaseUrl()}/user/${user.userId}/incomes/${incomeId}`
    );
    dispatch({
      type: "showLoading",
      payload: false,
    });
    fetchHhldMembers();
    fetchHhldIncomes();
  }
}

/**
 * merge incomes belonging to the same member under
 * a common structure
 */
function mergeIncomes(incomes) {
  return incomes.reduce((acc, curr) => {
    const { MemberID } = curr;
    if (acc[MemberID]) {
      acc[MemberID].push(curr);
    } else {
      acc[MemberID] = [curr];
    }

    return acc;
  }, {});
}

function AMIHelpDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="bg-gray-100 text-hk-gray-900">
        <span>AMI</span>
      </DialogTitle>
      <DialogContent>
        <p>
          Area Median Income Percentage (AMI) is the percentage of your total
          household income divided by the local county’s median income. This
          number will change as you change your income information using the
          Income Profiles.
        </p>
      </DialogContent>
    </Dialog>
  );
}

function IncomeHelpDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="bg-gray-100 text-hk-gray-900">
        <span>Income Categories</span>
      </DialogTitle>
      <DialogContent>
        <p>
          Income Categories are based on the maximum income limits set by the
          federal and state government. These are maximums so you’ll see
          HouseKeys use a less than (&lt;) sign. Based on your Total Household
          Income, you will be categorized based on the income limits that your
          household fits in. These categories include:
        </p>
        <ol className="mt-2 text-sm flex flex-col space-y-2 text-gray-600">
          <li>Acutley Low Income (&lt;15%)</li>
          <li>Extremely Low Income ( &lt;30%)</li>
          <li>Very Low Income ( &lt;50%)</li>
          <li>Low Income ( &lt;80%)</li>
          <li>ModerateIncome ( &lt;120%)</li>
          <li>Extremely Low Income ( &lt;120%, &lt;160%)</li>
        </ol>
      </DialogContent>
    </Dialog>
  );
}
