import React from "react";
import { Button } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import { formatBytes } from "../../utils/formatters";

export function StagedFiles({ files, handleRemove, submitting }) {
  const thumbs = files.map((file) => {
    return (
      <div className="border-b-2 border-gray-100" key={file.name}>
        <div
          className="flex sm:grid grid-cols-8 justify-between relative items-baseline mb-8 mr-8  p-4 w-full -items-center"
          key={file?.name}
        >
          <span className="text-sm text-gray-700 col-span-4 text-left">
            {file?.name}
          </span>

          <span className="text-sm text-gray-700 col-span-1 text-left">
            {formatBytes(file?.size || 0)}
          </span>

          <div className="flex items-center space-x-1 col-span-1">
            <Button
              disabled={submitting}
              startIcon={<RemoveCircleIcon />}
              size="small"
              color="secondary"
              onClick={() => handleRemove(file.name)}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    );
  });

  return <section>{thumbs}</section>;
}
