export const hhldFilesTabs = new Map([
  [
    0,
    {
      id: "all",
      title: "All",
    },
  ],
  [
    1,
    {
      id: "appIds",
      title: "Application Docs",
      subTitle:
        "Documents associated with program application and any exhibits",
    },
  ],
  [
    2,
    {
      id: "incomes",
      title: "Income",
      subTitle:
        "Supporting documents that show the income that you earn on weekly, monthly, or annual basis (paystubs, tax returns, wage statements e.tc.)",
    },
  ],
  [
    3,
    {
      id: "assets",
      title: "Assets",
      subTitle: "Properties, cash, stock, and other assets.",
    },
  ],
  [
    4,
    {
      id: "qualDocs",
      title: "Qualification Docs",
      subTitle: "Mortgage pre-approval e.t.c",
    },
  ],
  [
    5,
    {
      id: "preferences",
      title: "Preference",
      subTitle: "Proof of employment, eligibility docs",
    },
  ],
  [6, { id: "others", title: "Others", subTitle: "All Others" }],
]);
