import React, { useState, useEffect, useContext } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import UserContext from "../user/UserContext";
import HhldProgramCard from "./HhldProgramCard";
import Loading from "../shared/misc/Loading";

export default function HhldPrograms() {
  const [applications, setApplications] = useState([]);
  const [loadingApplications, setLoadingApplications] = useState([]);
  const [elig, setElig] = useState([]);
  const { user } = useContext(UserContext) || {};
  const { url } = useRouteMatch();
  const history = useHistory();

  async function fetchHHldApplcations() {
    setLoadingApplications(true);
    const {
      data: { data: applications },
    } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/applications?rowCnt=100&page=1`
    );
    setLoadingApplications(false);
    setApplications(applications);
  }

  useEffect(() => {
    fetchHHldApplcations();
  }, []);

  return (
    <section className="min-h-screen">
      <div className="bg-hk-orange-100 h-32 flex flex-col justify-center items-center mt-2 p-4">
        <h2 className="text-hk-orange-400 uppercase text-2xl">
          Programs ({applications.length})
        </h2>
      </div>
      {loadingApplications ? (
        <Loading />
      ) : (
        <div className="mt-4 flex flex-wrap gap-8 p-4 justify-center">
          {applications.map((a) => (
            <HhldProgramCard
              key={a.ID}
              provider={a.EntityName}
              programName={a.ProgramTitle}
              entityId={a.EntityID}
              hhldProgramId={a.HHLDProgramID}
              elig={elig}
              programType={a.ProgramTypeTitle}
              applicationFileId={a.ApplicationFileID}
              programId={a.ProgramID}
              userId={user.userId}
              preAppId={a.PreApplicationInfoID}
              numApplicants={a.NumApplicants}
            />
          ))}
        </div>
      )}
    </section>
  );

  async function viewApplication() {}

  async function requestApplicationId(event, rowData) {
    console.log("rowData", rowData);
    history.push({
      pathname: `${url}/programs/${rowData.ProgramID}/application/${user.userId}/`,
      state: {
        provider: rowData.EntityName,
        programName: rowData.ProgramTitle,
        programType: rowData.ProgramTypeTitle,
      },
    });
  }
}
