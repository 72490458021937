import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { formatAsUSCurrency } from "../../../../utils/formatters";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";

export default function IncomeLineItem({
  incomes,
  removeIncome,
  handleEditIncome,
}) {
  const [income] = incomes;
  const { MemberName } = income;
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState("");
  const [emulatedUser] = useLocalStorage("emulatedUser");
  return (
    <div className="py-4">
      <div className=" flex space-x-2 w-full  px-2 items-baseline text-sm ">
        <div className="">
          <AccountCircleIcon className="text-gray-400" />
        </div>

        <div className="flex flex-col text-left w-full">
          <span className="uppercase tracking-wider">{MemberName}</span>

          {incomes.map((i) => (
            <div
              key={i.IncomeID}
              className=" text-gray-500 flex flex-col w-full"
            >
              <div className="grid grid-cols-6 w-full items-baseline place-items-center justify-between">
                <span className="uppercase text-xs col-span-2 ">
                  {i.CompanyName}
                </span>
                <span className="pt-2 text-gray-700 col-span-2">
                  {formatAsUSCurrency(i.Income)}
                </span>
                <div className="col-start-5 sm:col-start-6 flex justify-end place-self-end ml-auto">
                  <IconButton
                    disabled={emulatedUser}
                    onClick={() => {
                      handleEditIncome({
                        incomeId: i.IncomeID,
                        memberId: i.MemberID,
                        incomeType: i.IncomeType,
                        memberName: i.MemberName,
                      });
                    }}
                  >
                    <EditIcon
                      styles={{
                        fontSize: "16px",
                        marginRight: 5,
                      }}
                      size="small"
                      className="text-blue-600"
                    />
                  </IconButton>
                  <IconButton
                    disabled={emulatedUser}
                    onClick={() => {
                      setShowRemoveAlert(true);
                      setSelectedEntry(i.IncomeID);
                    }}
                  >
                    <DeleteIcon
                      styles={{
                        fontSize: "16px",
                      }}
                      size="small"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Dialog open={showRemoveAlert}>
          <DialogContent>
            Are you sure you want to remove this entry?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowRemoveAlert(false)}>Cancel</Button>
            <Button
              onClick={() => {
                setShowRemoveAlert(false);
                removeIncome(selectedEntry);
              }}
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
