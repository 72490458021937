import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { formatAsUSCurrency } from "../../../utils/formatters";
import HkLoading from "../../shared/misc/HkLoading";
import { amiYear } from "../../../utils/appConsts";

export default function AMI({ entityId, open, onClose }) {
  const [ami, setAMI] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!open) return;
    fetchAMI();
  }, [open, entityId]);
  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <span className="text-sm text-gray-800">AMI</span>
          <div className="flex border-b border-gray-200 pb-2 items-baseline">
            <span className="text-sm text-gray-600">
              {ami?.[0]?.CountyName}
            </span>
            <span className="text-gray-500 ml-2 text-xs">
              ({ami?.[0]?.CalendarYear})
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          {loading && <HkLoading />}
          <div className="flex flex-col  sm:flex-row">
            <div className="p-2">
              {ami.map((a) => (
                <div key={a.AMIID} className="border-b border-gray-200 mt-2">
                  <span className="text-sm text-gray-700">{a.IncomeLevel}</span>
                  <LineItem size="1" income={a.HHSize1} />
                  <LineItem size="2" income={a.HHSize2} />
                  <LineItem size="3" income={a.HHSize3} />
                  <LineItem size="4" income={a.HHSize4} />
                  <LineItem size="5" income={a.HHSize5} />
                  <LineItem size="6" income={a.HHSize6} />
                  <LineItem size="7" income={a.HHSize7} />
                  <LineItem size="8" income={a.HHSize8} />
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  async function fetchAMI() {
    setLoading(true);
    const {
      data: { data: e },
    } = await axios.get(`${getBaseUrl()}/entity/${entityId}`);
    const {
      data: { data: ami },
    } = await axios.get(
      `${getBaseUrl()}/ami?year=${amiYear}&countyId=${e?.[0].CountyID}`
    );
    setAMI(ami);
    setLoading(false);
  }
}

function LineItem({ size, income }) {
  return (
    <div className="flex space-x-2 text-xs ml-2 text-gray-500 py-1">
      <span>{size}</span>
      <span>{formatAsUSCurrency(income)}</span>
    </div>
  );
}
