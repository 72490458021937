import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BallotIcon from "@material-ui/icons/Ballot";
import GetAppIcon from "@material-ui/icons/GetApp";
import HelpIcon from "@material-ui/icons/Help";
import EventIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import KitchenIcon from "@material-ui/icons/Kitchen";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import ListIcon from "@material-ui/icons/List";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DraftsIcon from "@material-ui/icons/Drafts";
import InfoIcon from "@material-ui/icons/Info";
import FolderIcon from "@material-ui/icons/Folder";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import TvIcon from "@material-ui/icons/Tv";
import { useHistory, Link, useLocation } from "react-router-dom";

import {
  Button,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";
import PeopleIcon from "@material-ui/icons/People";
import UserContext from "../user/UserContext";
import {
  isInternalAdmin,
  isExternalAdmin,
  isHHldApplicant,
  isLoanOfficer,
  hasFileCabinetAccess,
} from "../../utils/authUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listItemText: {
    fontSize: "0.5em",
  },
  button: {
    color: "#fff",
  },
  drawer: {
    backgroundColor: "#edf2f7",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function Navbar(props) {
  const [showNav, toggleNav] = React.useState(false);
  const location = useLocation();
  const currPage = location.pathname.split("/").filter(Boolean)[0] || "/";

  const [activeItem, setActiveItem] = React.useState(currPage);
  const history = useHistory();

  const classes = useStyles();

  const [openUserMenu, setOpenUserMenu] = useState(false);

  const { user } = useContext(UserContext) || {};

  const [programOpsOpen, setProgramOpsOpen] = useState(true);
  const [hhldMgmtOpen, setHhldMgmtOpen] = useState(false);
  const [hhldAppsOpen, setHhldAppsOpen] = useState(false);
  const [fileCabinetOpen, setFileCabinetOpen] = useState(false);

  const handleUserMenuClick = () => {
    setOpenUserMenu(!openUserMenu);
  };

  const handleHhldAppsClick = () => {
    setHhldAppsOpen(!hhldAppsOpen);
  };

  const handleProgramOpsClick = () => {
    setProgramOpsOpen(!programOpsOpen);
  };

  const handleFileCabinetClick = () => {
    setFileCabinetOpen(!fileCabinetOpen);
  };

  const handleHhldMgmtClick = () => {
    setHhldMgmtOpen(!hhldMgmtOpen);
  };

  function setActiveTab(tabName) {
    setActiveItem(tabName);
    toggleNav(false);
  }

  return (
    <div className="">
      <div className="flex">
        <Button id="menu-btn" onClick={() => toggleNav(true)}>
          <MenuIcon className="text-hk-dark-blue"></MenuIcon>
          Menu
        </Button>
      </div>
      <Drawer anchor="left" open={showNav} onClose={() => toggleNav(false)}>
        <List>
          <ListItem
            button
            selected={activeItem === "/"}
            onClick={() => {
              setActiveTab("/");
              history.push("/");
            }}
          >
            <ListItemIcon>
              <Link to="/">
                <HomeIcon className="text-hk-orange" />
              </Link>
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
              primary="Home"
            />
          </ListItem>

          {isInternalAdmin(user.profileType) && (
            <>
              <ListItem button onClick={handleUserMenuClick}>
                <ListItemIcon>
                  <PeopleIcon className="text-blue-300" />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  primary="User/Org Mgmt"
                />
                {openUserMenu ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openUserMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "user"}
                    onClick={() => {
                      setActiveTab("user");
                      history.push("/user");
                    }}
                  >
                    <ListItemIcon>
                      <PersonIcon className="text-blue-500" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Users"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "orgs"}
                    onClick={() => {
                      setActiveTab("orgs");

                      history.push("/orgs");
                    }}
                  >
                    <ListItemIcon>
                      <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Org"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          {(isHHldApplicant(user.profileType) ||
            isInternalAdmin(user.profileType)) && (
            <>
              <ListItem
                button
                onClick={() => {
                  handleHhldMgmtClick();
                }}
              >
                <ListItemIcon>
                  <PeopleIcon className="text-hk-soft-blue" />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  primary="Manage Your Household"
                />
                {hhldMgmtOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={hhldMgmtOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "hhld/profiles"}
                    onClick={() => {
                      setActiveTab("hhld/profiles");

                      history.push("/hhld/profiles");
                    }}
                  >
                    <ListItemIcon>
                      <InfoIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Information Profiles"
                    />
                  </ListItem>

                  <ListItem
                    disabled
                    button
                    className={classes.nested}
                    selected={activeItem === "hhld/tools-planning"}
                    onClick={() => {
                      setActiveTab("hhld/tools-planning");

                      history.push("hhld/tools-planning");
                    }}
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Tools and Planning"
                    />
                  </ListItem>

                  <ListItem
                    button
                    disabled
                    className={classes.nested}
                    selected={activeItem === "community-center"}
                    onClick={() => {
                      setActiveTab("community-center");

                      history.push("/community-center");
                    }}
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Community  Center"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          {(isHHldApplicant(user.profileType) ||
            isInternalAdmin(user.profileType)) && (
            <>
              <ListItem
                button
                onClick={() => {
                  handleHhldAppsClick();
                }}
              >
                <ListItemIcon>
                  <LockOpenIcon className="text-hk-orange-300" />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  primary="Program Center"
                />
                {hhldAppsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={hhldAppsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "/hhld/programs"}
                    onClick={() => {
                      setActiveTab("/hhld/programs");
                      history.push("/hhld/programs");
                    }}
                  >
                    <ListItemIcon>
                      <AssignmentIcon className="text-hk-cyan-400" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Programs"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "/hhld/drawings"}
                    onClick={() => {
                      setActiveTab("/hhld/drawings");

                      history.push("/hhld/drawings");
                    }}
                  >
                    <ListItemIcon>
                      <AssignmentTurnedInIcon className="text-hk-blue-400" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Drawings"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "hhld/opportunities"}
                    onClick={() => {
                      setActiveTab("hhld/opportunities");

                      history.push("/hhld/opportunities");
                    }}
                  >
                    <ListItemIcon>
                      <HomeIcon className="text-blue-600" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Opportunities"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "/hhld/faqs"}
                    onClick={() => {
                      setActiveTab("/hhld/faqs");
                      history.push("/hhld/faqs");
                    }}
                  >
                    <ListItemIcon>
                      <AssignmentIcon className="text-hk-cyan-400" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="FAQ"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "/hhld/mkt-place"}
                    onClick={() => {
                      setActiveTab("/hhld/mkt-place");

                      history.push("/hhld/mkt-place");
                    }}
                  >
                    <ListItemIcon>
                      <TvIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Market Place"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          {(isInternalAdmin(user.profileType) ||
            isExternalAdmin(user.profileType)) && (
            <>
              <ListItem
                button
                onClick={() => {
                  handleProgramOpsClick();
                }}
              >
                <ListItemIcon>
                  <DescriptionIcon className="text-hk-blue" />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  primary="Program Operations"
                />
                {programOpsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={programOpsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "admin/programs"}
                    onClick={() => {
                      setActiveTab("admin/programs");

                      history.push("/admin/programs");
                    }}
                  >
                    <ListItemIcon>
                      <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Programs"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "admin/preferences"}
                    onClick={() => {
                      setActiveTab("admin/preferences");

                      history.push("/admin/preferences");
                    }}
                  >
                    <ListItemIcon>
                      <BallotIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Preferences"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "admin/questions"}
                    onClick={() => {
                      setActiveTab("admin/questions");

                      history.push("/admin/questions");
                    }}
                  >
                    <ListItemIcon>
                      <HelpIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Questions"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "/programs/applicants"}
                    onClick={() => {
                      setActiveTab("/programs/applicants");

                      history.push("/programs/applicants");
                    }}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Applicants"
                    />
                  </ListItem>

                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "opportunity"}
                    onClick={() => {
                      setActiveTab("opportunity");
                      history.push("/opportunities");
                    }}
                  >
                    <ListItemIcon>
                      <FolderOpenIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Opportunities"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "drawings"}
                    onClick={() => {
                      setActiveTab("drawings");

                      history.push("/drawings");
                    }}
                  >
                    <ListItemIcon>
                      <FileCopyIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Drawings"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "rankings"}
                    onClick={() => {
                      setActiveTab("rankings");

                      history.push("/rankings");
                    }}
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Rankings"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          {(isInternalAdmin(user.profileType) ||
            isExternalAdmin(user.profileType)) && (
            <ListItem
              button
              selected={activeItem === "lottery-results"}
              onClick={() => {
                setActiveTab("lottery-results");
                history.push("/lottery-results");
              }}
            >
              <ListItemIcon>
                <DraftsIcon className="text-hk-dark-blue" />
              </ListItemIcon>

              <ListItemText
                primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                primary="Drawing Results"
              />
            </ListItem>
          )}
          {hasFileCabinetAccess(user) && (
            <>
              <ListItem
                button
                onClick={() => {
                  handleFileCabinetClick();
                }}
              >
                <ListItemIcon>
                  <KitchenIcon className="text-hk-cyan-300" />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  primary="File Cabinet"
                />
                {fileCabinetOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={fileCabinetOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "file-cabinet"}
                    onClick={() => {
                      setActiveTab("file-cabinet");
                      history.push("/file-cabinet");
                    }}
                  >
                    <ListItemIcon>
                      <FolderOpenIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Files"
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    selected={activeItem === "file-cabinet/downloads"}
                    onClick={() => {
                      setActiveTab("file-cabinet/downloads");
                      history.push("/file-cabinet/downloads");
                    }}
                  >
                    <ListItemIcon>
                      <GetAppIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: "0.8rem" },
                      }}
                      primary="Downloads"
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
          {isInternalAdmin(user.profileType) && (
            <React.Fragment>
              <ListItem
                button
                selected={activeItem === "events"}
                onClick={() => {
                  setActiveTab("events");
                  history.push("/events");
                }}
              >
                <ListItemIcon>
                  <EventIcon className="text-hk-dark-blue" />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  primary="Events"
                />
              </ListItem>
              <ListItem
                button
                selected={activeItem === "templates"}
                onClick={() => {
                  setActiveTab("templates");
                  history.push("/templates");
                }}
              >
                <ListItemIcon>
                  <DeveloperModeIcon className="text-hk-dark-blue" />
                </ListItemIcon>

                <ListItemText
                  primaryTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  primary="Email Templates"
                />
              </ListItem>
            </React.Fragment>
          )}
        </List>
      </Drawer>
      <Divider />
    </div>
  );
}

export default Navbar;
