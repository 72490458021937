import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function usePrograms() {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data: programs },
      } = await axios.get(`${getBaseUrl()}/programs?rowCnt=100&page=1`);

      setPrograms(programs);
    })();
  }, []);

  return programs;
}
