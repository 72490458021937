import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useHhldMembers(userId) {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data: members },
      } = await axios.get(`${getBaseUrl()}/user/${userId}/hhld_members`);
      setMembers(members);
    })();
  }, [userId]);

  return members;
}
