import React, { useContext, useEffect, useReducer, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link as MatLink,
  DialogTitle,
  Tab,
  Tabs,
  Tooltip,
  TextField,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BathtubIcon from "@material-ui/icons/Bathtub";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckIcon from "@material-ui/icons/Check";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LaunchIcon from "@material-ui/icons/Launch";
import PeopleIcon from "@material-ui/icons/People";
import SingleBedIcon from "@material-ui/icons/SingleBed";
import { withStyles } from "@material-ui/core/styles";
import RankingManager from "./mgr/RankingsManager";
import DrawingProfileCard from "./mgr/DrawingProfileCard";

import { getBaseUrl } from "../../../../utils/queryHelpers";
import axios from "../../../../utils/axios";

import CreateRankingButton from "../ranking/CreateRankingButton";
import { EditDrawing } from "./mgr/EditDrawing";
import { DrawingQuestions } from "./mgr/questions/DrawingQuestions";
import { DrawingAuthorizations } from "./mgr/authorizations/DrawingAuthorizations";
import { DrawingOpportunities } from "./mgr/opps/DrawingOpportunities";
import { ApplicantsList } from "./DrawingApplicants";
import { RankingsList } from "./DrawingRankings";

import { Events } from "../../../events/Events";
import { useDrawingRankings } from "../../../../queries/drawings/useDrawingRankings";
import { useDrawingApplicants } from "../../../../queries/drawings/useDrawingApplicants";
import { useDrawingQuestions } from "../../../../queries/drawings/useDrawingQuestions";
import { useDrawingOpportunities } from "../../../../queries/programs/useDrawingOpportunities";
import { useDrawingDetails } from "../../../../queries/programs/useDrawingDetails";
import { useDrawingPreferences } from "../../../../queries/programs/useDrawingPreferences";
import { useProgramQuestions } from "../../../../queries/programs/useProgramQuestions";
import { formatDateToTimeZone } from "../../../../utils/formatters";

import programApplicationFormImg from "../../../../media/HK-assets/program_application_form.png";
import enterDrawingFormImg from "../../../../media/HK-assets/enter_drawing_form.png";
import noImg from "../../../../media/HK-assets/no-image-icon-6.png";
import { formatAsUSCurrency } from "../../../../utils/formatters";
import UserContext from "../../../user/UserContext";
import { useProgramPreferences } from "../../../../queries/programs/useProgramPreferences";

const SelectButton = withStyles({
  root: {
    width: "140px",
    height: "25px",
    textDecoration: "none",
  },
})(Button);

const Accordion = withStyles({
  root: {
    backgroundColor: "#f3f3f3",
    padding: "2px",
    boxShadow: "none",
    marginTop: 5,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "10px",
    marginBottom: -1,

    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    justifyContent: "center",

    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

function reducer(state, { type, payload }) {
  return { ...state, [type]: payload };
}

export default function DrawingManager() {
  const [
    {
      expandedTab,
      prefGroupSelection,
      questionsGroupSelection,
      showAddQuestionsModal,
      showAddPrefModal,
      showCreateRankingModal,
      showEditDrawingDialog,
    },
    dispatch,
  ] = useReducer(reducer, {
    expandedTab: "prefs",
    showAddQuestionsModal: false,
    prefGroupSelection: "",
    questionsGroupSelection: "",
    showCreateRankingModal: false,
    showAddPrefModal: false,
    showEditDrawingDialog: false,
  });
  const { user } = useContext(UserContext);

  const history = useHistory();

  const { drawingId } = useParams();

  const { state = {} } = useLocation();

  const rankings = useDrawingRankings(drawingId);
  const applicants = useDrawingApplicants(drawingId);
  const opps = useDrawingOpportunities(drawingId);
  const [drawingQuestions, fetchDrawingQuestions] = useDrawingQuestions(
    drawingId
  );

  const [drawing, fetchDrawingDetail] = useDrawingDetails(drawingId);
  const [drawingPrefs, fetchDrawingPrefs] = useDrawingPreferences(drawingId);
  const [programPrefs, fetchProgramPrefs] = useProgramPreferences(
    state.program_id
  );
  const [programQuestions] = useProgramQuestions(state.program_id);

  const programPrefGroups = programPrefs.reduce((acc, curr) => {
    const { group_id, ...rest } = curr;

    if (group_id !== null) {
      if (acc[group_id]) {
        acc[group_id].push({ ...rest });
      } else {
        acc[group_id] = [{ ...rest }];
      }
    }
    return acc;
  }, {});

  const programQuestionsGroups = programQuestions.reduce((acc, curr) => {
    const { group_id, ...rest } = curr;

    if (group_id !== null) {
      if (acc[group_id]) {
        acc[group_id].push({ ...rest });
      } else {
        acc[group_id] = [{ ...rest }];
      }
    }
    return acc;
  }, {});

  return (
    <section className="min-h-screen max-w-6xl sm:flex flex-col sm:ml-auto sm:mr-auto">
      <div className="flex flex-col border border-gray-300 rounded-md mt-4">
        <div className="bg-gray-200  h-10 flex items-center justify-center space-x-2">
          <span className="text-lg uppercase">Drawing Id</span>
          <span>({drawingId})</span>
        </div>
        <div className="flex justify-around">
          <div className="flex flex-col sm:flex-row p-4 ">
            <div className="img  flex justify-center items-start">
              {/*  <img className="" src="" /> */}
            </div>
            <div className="flex flex-col sm:ml-16 pt-4 sm:pt-0">
              <div className="flex flex-col items-start text-sm sm:text-md">
                <span className="text-gray-400 ">Provider</span>
                <span className="text-sm sm:text-lg">{drawing.provider}</span>
              </div>

              <div className="flex flex-col items-start py-2 text-sm sm:text-md">
                <span className="text-gray-400">Program</span>
                <span className="text-sm sm:text-lg">
                  {drawing.program_name}
                </span>
              </div>

              <div className="flex flex-col items-start py-2 text-sm sm:text-md">
                <span className="text-gray-400">Description</span>
                <span className="text-sm sm:text-lg text-left">
                  {drawing.description}
                </span>
              </div>

              <div className="flex flex-col items-start py-2 text-sm sm:text-md">
                <Tooltip title="view applicants">
                  <Button
                    size="small"
                    onClick={viewApplicants}
                    endIcon={
                      <LaunchIcon
                        fontSize="small"
                        className="text-hk-orange-300"
                      />
                    }
                    className="text-gray-400"
                  >
                    Applicants
                  </Button>
                </Tooltip>
                <div className="flex space-x-2">
                  <PeopleIcon fontSize="small" />
                  <span className="text-left">{applicants.length}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="border rounded-md border-gray-100 flex flex-col p-2">
              <div className="flex space-x-2 text-sm items-center">
                <DateRangeIcon fontSize="small" />
                <span className="text-gray-800">Entry Deadline</span>

                <EditIcon fontSize="small" />
              </div>

              <TextField
                name="entry_deadline"
                value={formatDateToTimeZone(
                  drawing.entry_deadline,
                  "MM/dd/yyyy h:mm:ss a"
                )}
                disabled
                className="text-gray-800"
                size="small"
                InputProps={{ style: { fontSize: "0.8rem" } }}
              />
            </div>

            <div className="border rounded-md border-gray-100 flex flex-col p-2 mt-4">
              <div className="flex space-x-2 text-sm items-center">
                <DateRangeIcon fontSize="small" />
                <span className="text-gray-800">Drawing Date</span>
              </div>

              <span className="text-gray-500 text-xs pt-2 tracking-wider">
                {formatDateToTimeZone(
                  drawing.entry_deadline,
                  "MM/dd/yyyy h:mm:ss a"
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <section className="py-4">
        <Accordion
          square
          expanded={expandedTab === "opps"}
          onChange={handleTabToggle("opps")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="program_opps"
            id="program_opps"
          >
            <AccordionTitleText
              header={`Opportunities (${opps.length})`}
              subtitle="Drawing Opportunities"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Opportunities opps={opps} drawingId={drawingId} />

            <div className="flex mt-4">
              <Button
                onClick={() => {}}
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Create New Opportunity
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expandedTab === "prefs"}
          onChange={handleTabToggle("prefs")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <AccordionTitleText
              header={`Preferences (${drawingPrefs.length})`}
              subtitle="Drawing Preferences"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Preferences
              prefs={drawingPrefs}
              handlePrefSelection={() => {}}
              selectedPrefs={[]}
              handleEdit={editPreferences}
              handleDelete={handleDelete}
            />
            <div className="flex ">
              <Button
                onClick={() =>
                  dispatch({ type: "showAddPrefModal", payload: true })
                }
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Add New Preference
              </Button>
              {/* <Button
                disabled={false}
                size="small"
                onClick={() => {}}
                startIcon={<GroupWorkIcon className="text-hk-orange-400" />}
              >
                Create Preference Group
              </Button> */}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expandedTab === "questions"}
          onChange={handleTabToggle("questions")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <AccordionTitleText
              header={`Questions (${drawingQuestions.length})`}
              subtitle="Drawing Questions"
            />
          </AccordionSummary>
          <AccordionDetails>
            <Questions
              questions={drawingQuestions}
              selectedQuestions={[]}
              handleQuestionSelection={() => {}}
            />
            <div className="flex ">
              <Button
                onClick={() =>
                  dispatch({ type: "showAddQuestionsModal", payload: true })
                }
                startIcon={<AddCircleIcon className="text-hk-orange-400" />}
                size="small"
              >
                Add New Question
              </Button>
              <Button
                disabled={false}
                size="small"
                onClick={() => {}}
                startIcon={<GroupWorkIcon className="text-hk-orange-400" />}
              >
                Create Questions Group
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expandedTab === "applicants"}
          onChange={handleTabToggle("applicants")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="applicants"
            id="applicants"
          >
            <AccordionTitleText
              header={`Applicants (${applicants.length})`}
              subtitle="Expand to view"
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex justify-center space-x-4 items-center">
              <ApplicantsList
                drawingId={drawingId}
                applicants={applicants}
                user={user}
                drawingDetail={drawing}
              />
            </div>
            {/* <Drawings drawings={drawings} handleClick={viewDrawingDetail} /> */}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expandedTab === "rankings"}
          onChange={handleTabToggle("rankings")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="rankings"
            id="rankings"
          >
            <AccordionTitleText
              header={`Rankings (${rankings.length})`}
              subtitle="Expand to view"
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex justify-center space-x-4 items-center">
              <RankingsList rankings={rankings} />
            </div>
            {/* <Drawings drawings={drawings} handleClick={viewDrawingDetail} /> */}
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={expandedTab === "forms"}
          onChange={handleTabToggle("forms")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="program_forms"
            id="program_forms"
          >
            <AccordionTitleText header={`Forms (2)`} subtitle="Program Forms" />
          </AccordionSummary>
          <AccordionDetails>
            {/* <div className="flex justify-center space-x-4 items-center">
              <img src={programApplicationFormImg} className="h-64 w-64" />
              <img src={enterDrawingFormImg} className="h-64 w-40" />
            </div> */}
            {/* <Drawings drawings={drawings} handleClick={viewDrawingDetail} /> */}
          </AccordionDetails>
        </Accordion>
      </section>

      <div className="flex flex-wrap m-2 sm:m-6 mt-4  text-left">
        <Dialog
          fullWidth
          open={showEditDrawingDialog}
          onClose={handleEditDrawingDialogClose}
        >
          <DialogTitle>Edit Drawing</DialogTitle>
          <DialogContent>
            <EditDrawing
              drawingId={drawingId}
              handleClose={handleEditDrawingDialogClose}
            />
          </DialogContent>
        </Dialog>
        {/* preferences */}
        <Dialog
          fullWidth
          open={showAddPrefModal}
          onClose={() => {
            dispatch({
              type: "showAddPrefModal",
              payload: false,
            });
          }}
        >
          <DialogTitle>Add Preferences To Drawing - ({drawingId})</DialogTitle>
          <DialogContent>
            <span>Select from Preference Groups</span>
            <PrefGroups
              selections={prefGroupSelection}
              handleSelection={handlePrefGroupSelection}
              prefGroups={programPrefGroups}
            />
            <span>OR</span>
            <section>
              <h3>Select Individual Preferences</h3>
            </section>
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                onClick={() =>
                  dispatch({
                    type: "showAddPrefModal",
                    payload: false,
                  })
                }
              >
                Cancel
              </Button>
              <Button onClick={addPreferences}>Add</Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          open={showAddQuestionsModal}
          onClose={() => {
            dispatch({
              type: "showAddQuestionsModal",
              payload: false,
            });
          }}
        >
          <DialogTitle>Add Questions</DialogTitle>
          <DialogContent>
            <span>Question Groups</span>
            <QuestionsGroups
              questionGroups={programQuestionsGroups}
              selections={questionsGroupSelection}
              handleSelection={handleQuestionsGroupSelection}
              prefGroups={programQuestionsGroups}
            />
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                onClick={() =>
                  dispatch({ type: "showAddQuestionsModal", payload: false })
                }
              >
                Cancel
              </Button>
              <Button onClick={addQuestions}>Add</Button>
            </div>
          </DialogActions>
        </Dialog>

        <RankingManager
          open={showCreateRankingModal}
          onClose={() =>
            dispatch({
              type: "showCreateRankingModal",
              payload: false,
            })
          }
        />
      </div>
    </section>
  );

  function handleTabToggle(panel) {
    return function(event, newExpanded) {
      dispatch({
        type: "expandedTab",
        payload: newExpanded ? panel : false,
      });
    };
  }

  function viewApplicants() {
    history.push({
      pathname: `/programs/applicants`,
      search: `?programId=`,
    });
  }

  function handlePrefGroupSelection(group_id) {
    dispatch({
      type: "perfGroupSelection",
      payload: group_id,
    });
  }

  function handleQuestionsGroupSelection(group_id) {
    dispatch({
      type: "questionsGroupSelection",
      payload: group_id,
    });
  }

  function eventsFilter(event = "") {
    return event.args.includes(`drawingId=${drawingId}`);
  }

  function handleEditDrawingClick() {
    dispatch({
      type: "showEditDrawingDialog",
      payload: true,
    });
  }

  function handleEditDrawingDialogClose() {
    dispatch({
      type: "showEditDrawingDialog",
      payload: false,
    });

    fetchDrawingDetail();
  }

  async function editPreferences(edits) {
    console.log("editPreferences", edits);
    const resp = await axios.put(
      `${getBaseUrl()}/drawings/${drawingId}/preferences`,
      edits
    );
  }

  async function handleDelete(prefId) {
    console.log("prefId", prefId);
    const resp = await axios.delete(
      `${getBaseUrl()}/drawings/${drawingId}/preferences/${prefId}`
    );
    fetchDrawingPrefs();
  }

  async function addQuestions() {
    const questions = programQuestionsGroups[questionsGroupSelection].map(
      (q) => q.question_id
    );

    const resp = await axios.post(
      `${getBaseUrl()}/drawings/${drawingId}/questions`,
      {
        questions,
      }
    );
    fetchDrawingQuestions();
  }

  async function addPreferences() {
    const prefIds = programPrefGroups[prefGroupSelection].map(
      (p) => p.preference_id
    );

    const resp = await axios.post(
      `${getBaseUrl()}/drawings/${drawingId}/preferences`,
      {
        preferences: programPrefGroups[prefGroupSelection].map(
          (p) => p.preference_id
        ),
      }
    );
    fetchDrawingPrefs();
  }

  async function removeOpportunity(event, rowData) {
    const { OpportunityID } = rowData;
    const resp = await axios.delete(
      `${getBaseUrl()}/drawings/${drawingId}/opportunities/${OpportunityID}`
    );
    //  fetchOpportunities();
  }

  async function addOppsToDrawing(oppIds) {
    const resp = await axios.post(
      `${getBaseUrl()}/drawings/${drawingId}/opportunities`,
      {
        oppIds,
      }
    );

    // fetchOpportunities();
  }
}

function PrefGroups({ prefGroups, selections, handleSelection }) {
  return (
    <div className="flex space-x-4 ">
      {Object.entries(prefGroups).map(([groupId, prefs]) => (
        <div
          onClick={() => handleSelection(groupId)}
          key={groupId}
          className="border border-gray-500  p-4  w-full"
        >
          {selections === groupId && <CheckCircleIcon />}
          {prefs.map((p) => (
            <div key={p.preference_id} className="bg-gray-100 py-2 mt-2 px-2">
              <span>{p.title}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function QuestionsGroups({ questionGroups = {}, selections, handleSelection }) {
  return (
    <div className="flex space-x-4 ">
      {Object.entries(questionGroups).map(([groupId, questions]) => (
        <div
          onClick={() => handleSelection(groupId)}
          key={groupId}
          className="border border-gray-500  p-4  w-full"
        >
          {selections === groupId && <CheckCircleIcon />}
          {questions.map((q) => (
            <div key={q.question_id} className="bg-gray-100 py-2 mt-2 px-2">
              <span>{q.question}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function Rankings({ rankings, onClick }) {
  return (
    <div className="flex flex-col items-start p-4 space-y-2">
      {rankings.map((r) => (
        <div key={r.ranking_id} onClick={(e) => onClick(r.ranking_id)}>
          <span>{r.description}</span>
        </div>
      ))}
    </div>
  );
}

function Applicants({ data, onClick }) {
  return (
    <div className="flex flex-col items-start p-4 space-y-2">
      {data.map((a) => (
        <div
          key={a.submission_id}
          onClick={(e) => onClick(a.submission_id)}
          className="flex space-x-2"
        >
          <span>{a.firstName}</span>
          <span>{a.lastName}</span>
        </div>
      ))}
    </div>
  );
}

function AccordionTitleText({ header, subtitle }) {
  return (
    <div className="flex flex-col items-start">
      <span>{header}</span>
      <span className="pl-4 pt-1 text-xs text-gray-500">{subtitle}</span>
    </div>
  );
}

function Preferences({ prefs, handleEdit, handleDelete }) {
  const [edits, setEdits] = useState({});

  useEffect(() => {
    setEdits(
      prefs.reduce(
        (acc, curr) => {
          const { preference_id, description, preference_value } = curr;
          acc[preference_id] = {
            description,
            editAllowed: false,
            preference_value,
          };
          return acc;
        },
        { changes: {} }
      )
    );
  }, [prefs.length]);

  const { changes, ...rest } = edits;

  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2 mb-4">
      {Object.entries(rest).map(([prefId, pref]) => (
        <div key={prefId} className={` w-full flex h-10 items-center p-2 `}>
          <TextField
            value={pref.description}
            name="description"
            onChange={handleChange(prefId)}
            disabled={!pref.editAllowed}
            className="bg-gray-50 flex-grow text-left p-2 text-gray-700 text-sm"
          />

          <TextField
            style={{ width: "60px", marginLeft: "10px" }}
            size="small"
            name="preference_value"
            onChange={handleChange(prefId)}
            disabled={!pref?.editAllowed}
            variant="outlined"
            label={pref.preference_type === "tiers" && "Tier "}
            value={pref.preference_value}
          />

          {!pref.editAllowed && (
            <Tooltip title="Edit">
              <IconButton onClick={() => setEditAllowed(prefId)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {pref.editAllowed && (
            <Tooltip title="Save">
              <IconButton onClick={() => editPref(prefId)}>
                <CheckIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDelete(prefId)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      ))}
    </div>
  );

  function editPref(prefId) {
    handleEdit(changes);
    setEdits((prev) => ({
      ...prev,
      [prefId]: { ...prev[prefId], editAllowed: false },
      changes: {
        ...prev["changes"],
        [prefId]: {},
      },
    }));
  }

  function handleChange(id) {
    return function(e) {
      const { name, value } = e.target;
      setEdits((prev) => ({
        ...prev,
        [id]: { ...prev[id], [name]: value },
        changes: {
          ...prev["changes"],
          [id]: { ...prev["changes"][id], [name]: value },
        },
      }));
    };
  }
  function setEditAllowed(id) {
    setEdits((prev) => ({
      ...prev,
      [id]: { ...prev[id], editAllowed: true },
    }));
  }
}

export function Opportunities({
  opps,
  handleClick,
  handleSelect,
  drawingId,
  selections,
}) {
  return (
    <Grid container spacing={3} className="flex space-x-4 flex-wrap ">
      {opps.map((o) => {
        const {
          programName,
          amiPct,
          opportunityId,
          price,
          address,
          bedAndBath,
          incomeLevel,
          maxHHLDSize,
          minHHLDSize,
          preComponentName,
        } = o;
        const [beds, baths] = bedAndBath.split("|");
        const [street, city, zip, state] = address.split("|");

        return (
          <Grid
            item
            spacing={3}
            key={opportunityId?.[0]}
            className="border border-gray-100  rounded-md pb-4 w-56 shadow-md"
          >
            <div className="bg-hk-orange-500 h-12 border-t rounded-t flex justify-center items-center">
              <div className="p-2 text-xs text-gray-800 tracking-wide">
                <span>{programName}</span>
                {drawingId && <span>({drawingId})</span>}
              </div>
            </div>
            <div className="">
              <img src={noImg} className="h-32 w-full" />
            </div>
            <div className="flex p-2 text-sm justify-around items-baseline">
              <span className="text-md font-medium">
                {formatAsUSCurrency(price)}
              </span>
              <div className="flex flex-col text-xs text-gray-500 text-tiny">
                <SingleBedIcon fontSize="small" />
                <span>{beds} bed</span>
              </div>

              <div className="flex flex-col text-xs text-gray-500 text-tiny">
                <BathtubIcon fontSize="small" />
                <span>{baths} baths</span>
              </div>
            </div>
            <div className="h-10 text-xs  text-gray-600 px-2 ">
              {street && <span className="tracking-wide">{street}</span>}
              {city && <span>, {city}</span>}
              {zip && <span>, {zip}</span>}
              {state && <span>, {state}</span>}
            </div>
            <div className="flex justify-center">
              <span className="bg-gray-100 text-xs p-1 border rounded-sm text-gray-600">
                Income Level: {incomeLevel}
              </span>
            </div>

            {handleSelect && (
              <div className="flex justify-center mt-4">
                {selections.includes(opportunityId?.[0]) ? (
                  <CheckCircleIcon
                    fontSize="small"
                    className="text-green-600"
                  />
                ) : (
                  <SelectButton
                    variant="contained"
                    size="small"
                    onClick={() => handleSelect(o)}
                  >
                    Select
                  </SelectButton>
                )}
              </div>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

function Questions({
  questions,
  handleQuestionSelection,
  selectedQuestions = [],
}) {
  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {questions.map((q) => (
        <div
          key={q.question_id}
          onClick={() => handleQuestionSelection(q.question_id)}
          className={`bg-gray-50 w-full flex h-10 items-center p-2 `}
        >
          <span>{q.question}</span>
          {selectedQuestions.includes(q.question_id) && <CheckCircleIcon />}
        </div>
      ))}
    </div>
  );
}
