import React, { createContext, useContext, useState } from "react";

interface LanguageCtx {
  preferredLang: "en" | "es";
}

const LanguageContext = createContext<LanguageCtx>();

function LanguageProvider({ children }) {
  const [preferredLang, setPreferredLang] = useState("en");

  const value = { preferredLang, setPreferredLang };
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

function usePreferredLanguage() {
  const ctx = useContext(LanguageContext);

  return ctx;
}

export { LanguageProvider, usePreferredLanguage };
