import React, { useState, useEffect, useContext } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import UserContext from "../user/UserContext";
import MaterialUITable from "../shared/Table/MaterialUITable";

export default function HhldActivityLog() {
  const [activities, setActivities] = useState([]);

  const { user } = useContext(UserContext) || {};

  useEffect(() => {
    fetchHhldActivities();
  }, []);

  const tableColumns = [
    { title: "Provider", field: "EntityName" },
    { title: "Subject", field: "ModuleName" },
    { title: "Description", field: "ActivityDescription" },
    { title: "Timestamp", field: "ActivityTimestamp" },
  ];

  return (
    <section className="min-h-screen">
      <h1 className="hidden">Household Activities</h1>
      Activities
      <div className="mt-4">
        <MaterialUITable
          columns={tableColumns}
          stickyHeader
          className="p-4 mt-4"
          data={activities}
          title={`Activities (${activities.length})`}
          options={{
            exportButton: true,
            pageSize: 10,
            grouping: true,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    </section>
  );

  async function fetchHhldActivities() {
    try {
      const {
        data: { data: activities },
      } = await axios.get(
        `${getBaseUrl()}/user/${user.userId}/activities?rowCnt=100&page=1`
      );

      setActivities(activities);
    } catch (err) {
      console.error(`error getting activities list ${err}`);
      setActivities([]);
    }
  }
}
