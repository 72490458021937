import React from "react";
import { Button, IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import hhldSvg from "../../../media/HK-assets/hhld_search-house.svg";
import agencySvg from "../../../media/HK-assets/onboarding-agency.svg";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export default function OnBoarding() {
  const history = useHistory();
  return (
    <section className="min-h-screen py-4">
      <p className="mt-4 font-semibold pt-4 text-lg text-gray-600">
        Tell us a little more about yourself so that we can help you better.
      </p>
      <div className="mt-8">
        <p className="text-gray-700 text-4xl  uppercase">I want to </p>
        <div className="flex  flex-col sm:flex-row justify-center pt-4 px-2 gap-2">
          <div className="border border-gray-300 p-4 flex flex-col rounded sm:w-1/3">
            <img className="h-32" src={hhldSvg} alt="searching houses" />
            <hr className="mt-2 -p-4" />
            <p className="pt-6 text-xl tracking-wide">Applicant</p>
            <p className="text-gray-400 text-sm">
              Find and apply for affordable housing
            </p>
            <div className="mt-4 h-32 flex items-end justify-center">
              <IconButton
                className="bg-hk-cyan-400"
                style={{
                  backgroundColor: "#41C5B0",
                  color: "#fff",
                }}
                onClick={() => history.push("/onboarding/hhld")}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>

          {/* participant */}
          <div className="border border-gray-300 p-4 flex flex-col rounded sm:w-1/3">
            <img className="h-32" src={agencySvg} alt="searching houses" />
            <hr className="mt-2 -p-4" />
            <p className="pt-6 text-xl tracking-wide">Participant</p>
            <p className="text-gray-400 pt-4 text-sm">
              For exisiting homeowners and tenants
            </p>
            <div className="mt-4 h-32 flex items-end justify-center">
              <IconButton
                disabled
                className="bg-hk-cyan-400"
                style={{
                  backgroundColor: "#41C5B0",
                  color: "#fff",
                }}
                onClick={() => history.push("/onboarding/participant")}
              >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
