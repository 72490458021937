import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { Badge, Button, Step, Stepper, StepLabel } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { extractFormFields } from "../../../utils/hhldUtils";
import OpportunitiesSorter from "./OpportunitiesSorter";
import Questions from "./Questions";

import PopperMsg from "../../shared/misc/PopperMsg";
import HHLDRegSummary from "../dashboard/HHLDRegSummary";
import LoadingBackDrop from "../../shared/misc/LoadingBackDrop";
import UserContext from "../../user/UserContext";
import Loading from "../../shared/misc/Loading";
import ProgramApplication from "../ProgramApplication";
import { useDrawingPreferences } from "../../../queries/programs/useDrawingPreferences";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: "green",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#41C5B0",
    fontWeight: "bolder",
    fontSize: "1rem",
  },
}))(Badge);

function formReducer(state, action) {
  if (action.mode === "multiple") {
    return {
      ...state,
      [action.type]: {
        ...state[action.type],
        ansType: action.payload.ansType,
        value: { ...state[action.type]?.value, ...action.payload.value },
      },
    };
  }
  if (action.type === "preference") {
    return {
      ...state,
      preferences: {
        ...state.preferences,
        [action.payload.id]: action.payload.value,
      },
    };
  }
  if (action.type === "question") {
    return {
      ...state,
      questions: {
        ...state.questions,
        [action.payload.id]: action.payload.value,
      },
    };
  }
  if (action.type === "choices") {
    return {
      ...state,
      choices: { ...state.choices, [action.payload.oppId]: action.payload.sel },
    };
  }
  return { ...state, [action.type]: action.payload };
}

export default function EnterDrawingForm() {
  const { drawingId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [opps, setOpps] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [elig, setEligQuestions] = useState([]);
  const [qualificationQuestions, setQualificationQuestions] = useState([]);
  const [generalQuestions, setGeneralQuestions] = useState([]);
  const [profile, setProfile] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const fetchedQuestions = useRef(false);
  const [hhldProgramId, setHhldProgramId] = useState(
    location?.state?.hhldProgramId
  );

  const [state, dispatch] = useReducer(formReducer, {
    submission: {},
    loading: false,
    submissionComplete: false,
    submittingDrawing: false,
    showFailureMsg: false,
    showEntryFailureMsg: false,
    showSuccessMsg: false,
    choices: {},
    preferences: {},
    questions: {},
  });

  const preferences = useDrawingPreferences(drawingId);

  const { user } = useContext(UserContext);

  let { agency, program, programId, from } = location?.state ?? {};

  const {
    AMIPct,
    ApplicantUpLoadFlag,
    IncomeCategoryDesc,
    PreComponentName,
    TotalApplicantCnt,
  } = profile;

  const {
    submission,
    submittingDrawing,
    loading,
    showEntryFailureMsg,
    showFailureMsg,
    showSuccessMsg,
  } = state;

  function isNextDisabled() {
    if (activeStep === 0) {
      //check if opportunities have been sorted
      if (opps.length < 2) return false;
    }
    if (activeStep === 1) {
      //check if all preferences and questions marked as required have been answered
      const allPreferencesAnswered =
        Object.keys(state.preferences).length === preferences.length;

      const allRequiredQuestionsAnswered =
        Object.keys(state.questions).length >=
        questions.filter((q) => q.required).length;

      console.log(
        `allPreferencesAnswered ${allPreferencesAnswered} allRequiredQuestionsAnswered ${allRequiredQuestionsAnswered}`
      );

      return allRequiredQuestionsAnswered && allPreferencesAnswered
        ? false
        : true;
    }
    return false;
  }

  useEffect(() => {
    fetchSubmission();
    fetchDrawingQuestions();
    fetchOpportunities();
    fetchDrawingProfile();
  }, []);

  /*   useEffect(() => {
    if (!opps.length) return;
    (async () => {
      await Promise.all([
        fetchEligQuestions(),
        fetchGeneralQuestions(),
        fetchQualificationQuestions(),
      ]);

      fetchedQuestions.current = true;
    })();
  }, [opps.length]); */

  useEffect(() => {
    if (
      fetchedQuestions.current &&
      questions.length < 1 &&
      preferences.length < 1
    ) {
      enterDrawing();
    }
  }, [fetchedQuestions.current]);

  async function fetchOpportunities() {
    dispatch({
      type: "loading",
      payload: true,
    });
    const {
      data: { data: opps },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/opportunities`);
    setOpps(opps);
    if (opps.length === 1) {
      //since there's only one opportunity, default choice to 1 for this opportunity
      dispatch({
        type: "choices",
        payload: {
          oppId: opps[0]?.OpportunityID,
          sel: 1,
        },
      });
    }
    dispatch({
      type: "loading",
      payload: false,
    });
  }

  async function fetchSubmission() {
    dispatch({
      type: "loading",
      payload: true,
    });
    const {
      data: { data: sub },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/submitted?hhldProgramId=${hhldProgramId}`
    );

    if (sub?.drawing?.applicant) {
      //user has entered drawing already , redirect to the drawings page
      history.push("/hhld/drawings");
    }
  }

  function handleCancel() {
    history.goBack();
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  function range(min, max) {
    return Array.from({ length: max }, (num, i) => i + 1);
  }

  const choices = range(1, opps.length).map((c) => ({
    label: c,
    value: c,
  }));

  const eligFields = extractFormFields(state, "elig");
  const qualificationFields = extractFormFields(state, "qualification");
  const genFields = extractFormFields(state, "gen");

  return (
    <form className="min-h-screen" onSubmit={handleSubmit}>
      <div className="relative w-full flex flex-col items-center">
        <h3 className="tracking-widest text-3xl sm:text-4xl bg-hk-cyan-100 flex items-center justify-center  h-32 sm:h-48 text-hk-cyan-400 w-full uppercase">
          Enter Drawing ({drawingId})
        </h3>

        <div className="shadow-md  top-40 rounded bg-white  justify-center -mt-6  p-4  mb-6 w-80">
          <p className="mt-6 flex text-gray-700 leading-relaxed flex justify-center text-lg">
            {program}
          </p>

          <div className="pt-2 flex  justify-center items-baseline text-gray-600">
            <span>{PreComponentName}</span>
            <span className="pl-2 text-xs">({agency})</span>
          </div>
          <p className="mt-1 text-sm leading-relaxed text-gray-500"></p>
          <div className="mt-4 flex items-baseline space-x-2 text-md leading-relaxed text-gray-500">
            <span className="">{AMIPct}% </span>
            <span className="text-gray-600 text-xs">(AMI)</span>
          </div>
          <div className="mt-1 flex space-x-2 text-md items-baseline leading-relaxed text-gray-500">
            <span>{IncomeCategoryDesc} </span>
            <span className="text-gray-600 text-xs">(Income Category)</span>
          </div>
          <div className="flex mt-6 items-baseline">
            <StyledBadge badgeContent={opps.length} color="primary">
              <HomeIcon className="text-hk-orange" fontSize="large" />
            </StyledBadge>
            <span className="pl-6 text-gray-600 tracking-wider">
              Opportunities
            </span>
          </div>
          <div className="flex mt-6 items-baseline">
            <StyledBadge
              badgeContent={TotalApplicantCnt}
              color="primary"
              max={999}
            >
              <GroupIcon className="text-hk-orange" fontSize="large" />
            </StyledBadge>
            <span className="pl-6 block  -mt-4 text-gray-600 tracking-wider">
              Applicants
            </span>
          </div>
        </div>
        <div className="sm:absolute sm:left-20 bottom-0 sm:top-12 max-h-80 overflow-y-scroll">
          <HHLDRegSummary />
        </div>
      </div>

      <EnterDrawingStepper
        hhldProgramId={hhldProgramId}
        getStepContent={getStepContent}
        handleFinish={enterDrawing}
        nextDisabled={isNextDisabled()}
        onProgramAppSubmit={(hhldProgramId) => setHhldProgramId(hhldProgramId)}
        submitting={
          submittingDrawing ||
          loading ||
          isNextDisabled() ||
          !fetchedQuestions.current
        }
        backDisabled={submission.LotteryRosterID != null}
        successMsg={
          showSuccessMsg ? (
            <div className="bg-green-100  p-4 text-green-700 leading-loose flex flex-col mt-8">
              <span>
                Congratulations!!! Your entry has been registered for this
                drawing.
              </span>
              <p>
                <Link
                  className="underline font-semibold"
                  to={`/drawings/${drawingId}/submitted/hhld/${submission.submission_id}`}
                >
                  View Submission
                </Link>{" "}
                <Link className="underline font-semibold" to="/hhld/programs">
                  Progam Center
                </Link>
              </p>
            </div>
          ) : null
        }
      />

      <PopperMsg
        open={showFailureMsg}
        msgType="error"
        handleClose={() => dispatch({ type: "showFailureMsg", payload: false })}
      >
        <p>
          <span>{`Your submission wasn't successful at this point.`}</span>
        </p>
      </PopperMsg>

      <PopperMsg
        open={showEntryFailureMsg}
        msgType="error"
        handleClose={() =>
          dispatch({ type: "showEntryFailureMsg", payload: false })
        }
      >
        <p>
          <span>{`We are unable to grant you authorization to enter this drawing as a result of one or more of your answers did not pass the minimum requirement specified by the Program Provider`}</span>
        </p>
      </PopperMsg>
      <LoadingBackDrop open={state.submittingDrawing} />
    </form>
  );

  async function enterDrawing() {
    dispatch({ type: "submittingDrawing", payload: true });
    const oppIds = opps.map((o) => o.OpportunityID).join(",");
    const {
      data: { data: submission },
    } = await axios
      .post(`${getBaseUrl()}/drawings/${drawingId}/enter`, {
        questions: state.questions,
        preferences: state.preferences,
        choices: state.choices,
        hhldProgramId,
        drawingId,
        programId,
        opportunityId: oppIds,
      })
      .catch((e) => {
        dispatch({ type: "submittingDrawing", payload: false });
      });

    dispatch({ type: "submission", payload: submission });
    dispatch({ type: "submittingDrawing", payload: false });
    if (ApplicantUpLoadFlag == 1 && !user.filePermissions) {
      addFilePermissions();
    }
    submission.submission_id != null &&
      dispatch({ type: "showSuccessMsg", payload: true });

    submission.submission_id == null &&
      submission.FailedQuestions &&
      dispatch({ type: "showEntryFailureMsg", payload: true });

    submission.submission_id == null &&
      dispatch({ type: "showFailureMsg", payload: true });
  }

  function addFilePermissions() {
    const {
      data: { data: perms },
    } = axios.post(`${getBaseUrl()}/files/migrate`, {
      userids: [user.userId],
    });
  }

  async function fetchDrawingQuestions() {
    const {
      data: { data: qs },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/questions?limit=100&page=1`
    );

    setQuestions(qs);
    fetchedQuestions.current = true;
  }

  async function fetchDrawingProfile() {
    const {
      data: { data: profile },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/profile`);

    setProfile(profile);
  }

  function getStepContent(stepIndex) {
    setActiveStep(stepIndex);

    switch (stepIndex) {
      case 0:
        return (
          <OpportunitiesSorter
            opps={opps}
            choices={choices}
            fields={state}
            dispatch={dispatch}
            handleChange={(sel, oppId) => {
              dispatch({
                type: "choices",
                payload: {
                  oppId,
                  sel,
                },
              });
            }}
          />
        );

      case 1:
        return (
          <div>
            {loading && <Loading />}
            <span>Preferences</span>
            <Questions
              questions={preferences.map((p) => ({
                question_id: p.preference_id,
                question: p.description,
                answer_type: "radio",
              }))}
              type="preference"
              fields={state}
              dispatch={dispatch}
            />
            <span>Questions</span>
            <Questions
              questions={questions}
              type="question"
              fields={state}
              dispatch={dispatch}
            />
          </div>
        );

      default:
        return "Unknown stepIndex";
    }
  }
}

function EnterDrawingStepper({
  getStepContent,
  hhldProgramId,
  handleFinish,
  nextDisabled,
  submitting,
  backDisabled,
  successMsg,
  onProgramAppSubmit,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps(hhldProgramId);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="sm:px-32">
        {successMsg}
        {activeStep === steps.length ? (
          <div></div>
        ) : (
          <div className="flex flex-col space-y-4 p-2">
            <div className="text-left text-xs">
              {getStepContent(activeStep)}
            </div>
            <div className="flex justify-end pt-8">
              <>
                <Button
                  disabled={activeStep === 0 || backDisabled}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    disabled={submitting}
                    color="primary"
                    onClick={(e) => {
                      handleFinish(e);
                      handleNext();
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={nextDisabled}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  function onProgramAppCompletion(app) {
    if (app.ApplicationFileID) {
      // dispatch({ type: "preAppFileId", payload: preAppResp.ApplicationFileID });
      console.log("ApplicationFileID", app.ApplicationFileID);
      onProgramAppSubmit(app.hhldProgramId);
      //    setActiveStep(activeStep + 1);
    }
  }
}

function getSteps(hhldProgramId) {
  return [
    // ...(!hhldProgramId ? ["Program Application"] : []),
    "Prioritize opportunities",
    "Questions",
  ];
}

function Question({ question }) {}
