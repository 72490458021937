import React, { useState, useEffect } from "react";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";

export function useFormQuestions(formId, programId) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (!formId) return;
    fetchQuestions();
  }, [programId]);

  return [questions, fetchQuestions];

  async function fetchQuestions() {
    const {
      data: { data: questions },
    } = await axios.get(
      `${getBaseUrl()}/forms/${formId}/questions?programId=${programId}`
    );

    setQuestions(questions);
  }
}
