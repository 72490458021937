import React from "react";
import ReactMapGL, { Marker, Layer, Source } from "react-map-gl";
import { FaMapMarkerAlt } from "react-icons/fa";
import mapMarker from "../media/HK-assets/orange-map-marker-MOD.png";

class Map extends React.Component {
  state = {
    viewport: {
      width: this.props.width || 1600,
      height: this.props.height || 2400,
      latitude: 37.0108,
      longitude: -121.567,
      zoom: 13,
      center: [37.108, -121.567]
    }
  };

  componentDidMount() {
    const { data } = this.props;
    const [first] = data;
    if (first) {
      this.setState({
        ...this.state.viewport,
        latitude: first.lat,
        longitude: first.longitude
      });
    }
  }

  /* componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const [currFirst] = data;

    //const { data: prevData } = prevProps;

    const [prevFirst] = prevData;

    if (currFirst ) {
      const { lat: currLat, longitude: currLong } = currFirst;
      const { viewport: {latitude: prevLat, longitude: prevLongitude }} = prevState;

      if (currLat !== prevLat && currLong !== prevLongitude) {
        this.setState({
          ...this.state.viewport,
          latitude: currLat,
          longitude: currLong
        });
      }
    }
  } */

  render() {
    const { data } = this.props;
    return (
      <div className="h-1/2">
        <ReactMapGL
          {...this.state.viewport}
          mapStyle="mapbox://styles/mapbox/streets-v8"
          onViewportChange={viewport => this.setState({ viewport })}
        >
          {data.map(d => {
            return (
              <Marker key={d.id} latitude={d.lat} longitude={d.longitude}>
                <div title={`${(d.address1, d.city, d.id)}`}>
                  <img src={mapMarker} className=" w-8 h-8" />
                </div>
              </Marker>
            );
          })}
          <Source
            type="vector"
            data={{
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [this.props.data.map(d => [d.longitude, d.lat])]
              }
            }}
          >
            {/* <Layer
              id="hk-layer"
              type="fill"
              paint={{
                "fill-color": {
                  property: "percentile",
                  stops: [
                    [0, "#3288bd"],
                    [1, "#66c2a5"],
                    [2, "#abdda4"],
                    [3, "#e6f598"],
                    [4, "#ffffbf"],
                    [5, "#fee08b"],
                    [6, "#fdae61"],
                    [7, "#f46d43"],
                    [8, "#d53e4f"]
                  ]
                },
                "fill-opacity": 0.8
              }}
            /> */}
            <Layer
              id="hk-circle"
              type="circle"
              paint={{
                "circle-radius": 9,
                "circle-color": "rgba(55, 148,179,1)"
              }}
            />
          </Source>
        </ReactMapGL>
      </div>
    );
  }
}

export default Map;
