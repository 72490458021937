import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import "./SearchBar.css";

export default function SearchBar(props) {
  const { setOpps } = props;
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    /* fetch(`https://api.myhousekeys.com/opportunity?SearchOption=${searchText}`)
      .then(resp => resp.json())
      .then(resp => {
        //setLoadingOpp(false);

        setOpps(resp);
      }); */
  }, [searchText]);
  return (
    <div className="flex flex-grow">
      <input
        type="text"
        className="bg-white flex-grow rounded-l-lg px-2 text-gray-700 outline-none border-none"
        name="opportunity-search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      <button
        type="button"
        className="bg-hk-orange p-2 flex items-center border-none justify-center rounded-r"
        onClick={() => {}}
      >
        <FaSearch className="text-white" />
      </button>
    </div>
  );
}
