import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { FaRegFolder, FaRegCalendar, FaHistory } from "react-icons/fa";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import { MdContacts, MdHome } from "react-icons/md";
import { MTableGroupRow } from "material-table";

import {
  Button,
  Avatar,
  Breadcrumbs,
  Chip,
  Link as MatLink,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import axios from "../../../utils/axios";
import { formatNonUTC, formatDateToTimeZone } from "../../../utils/formatters";
import { moveElemTo } from "../../../utils/jsUtils";

import { getBaseUrl } from "../../../utils/queryHelpers";
import MaterialUITable from "../../../components/shared/Table/MaterialUITable";
import CardItem from "../../../components/shared/card/CardItem";
import RankingStepper from "./ranking/RankingStepper";
import {
  getRankingStep,
  getUpdatedManualRanks,
  witnessComplete,
  getRankingType,
  sortTiers,
} from "../../../utils/drawingUtils";
import Preferences from "./ranking/Preferences";
import Witnesses from "./ranking/Witnesses";
import ManualAdjuster from "./ranking/ManualAdjuster";
import PopperMsg from "../../../components/shared/misc/PopperMsg";
import useQueryParams from "../../shared/hooks/useQuery";
import UserContext from "../../user/UserContext";
import { isInternalAdmin } from "../../../utils/authUtils";
import { getDrawingType } from "../../../utils/drawingUtils";
import OpportunityCard from "./OpportunityCard";
import { useDrawingOpportunities } from "../../../queries/programs/useDrawingOpportunities";
import RankingNotification from "./ranking/RankingNotification";
import LoadingBackDrop from "../../shared/misc/LoadingBackDrop";
import { useRanking } from "../../../queries/drawings/useRanking";
import { useDrawingDetails } from "../../../queries/programs/useDrawingDetails";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },

  currPage: {
    color: "#2d3748",
    "&:hover": {
      textDecoration: "none",
      color: "#285b6c",
    },
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const rankingSteps = {
  INITIAL_RANK: 0,
  FINALIZE_RANK: 1,
  ADJUST_RANK: 2,
  WITNESS_RANK: 3,
  CLOSE_RANK: 4,
  PUBLISH_RANK: 5,
  RESET_RANK: 6,
};

function Ranking(props) {
  const [applicants, setApplicants] = useState([]);
  const [witnesses, setWitnesses] = useState([]);
  const [notificationHistory, setNotificationHistory] = useState([]);
  const [fetchingWitnesses, setFetchingWitnesses] = useState(false);
  const [processingWitness, setProcessingWitnesses] = useState(false);

  const [loadingRankings, setLoadinRankings] = useState(false);

  const [selectedApplicant, setSelectedApplicant] = useState([]); //the current entry being adjusted

  const [adjustedApplicants, setAdjustedApplicants] = useState([]); //all entries being adjusted

  const [audits, setAudits] = useState([]);
  const [isEditable, setEditable] = useState(false);
  const [rankingMethod, setRankingMethod] = useState(null);
  const [manualRanksUpdated, setManualRanksUpdated] = useState(false);

  const rankingStep = getRankingStep(audits);

  const [activeStep, setActiveStep] = useState(rankingStep);
  const [nextAllowed, setNextAllowed] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [adjustedRankings, setAdjustedRankings] = useState([]);
  const [manualAdjustOn, toggleManualAdjust] = useState(false);

  const [showPublishMsg, setShowPublishMsg] = useState(false);

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showFailureMsg, setShowFailureMsg] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [processingEmails, setProcessingEmails] = useState(false);

  const classes = useStyles();

  const { user } = useContext(UserContext) || {};
  const qryParams = useQueryParams();

  let { drawingId, rankId } = useParams();
  if (drawingId === undefined) {
    drawingId = qryParams.get("drawingId");
  }

  const ranking = useRanking(rankId);
  const [drawing] = useDrawingDetails(drawingId);

  const allWitnessesComplete = witnessComplete(witnesses);

  useEffect(() => {
    setActiveStep(rankingStep);
  }, [rankingStep]);

  function updateNextAllowed() {
    if (!isInternalAdmin(user.profileType)) {
      setNextAllowed(false);
      return;
    }
    if (loadingRankings) {
      setNextAllowed(false);
      return;
    }
    if (activeStep === rankingSteps.INITIAL_RANK) {
      if (rankingMethod !== null) {
        setNextAllowed(true);
      } else {
        setNextAllowed(false);
      }
    }
    if (
      activeStep === rankingSteps.INITIAL_RANK &&
      rankingMethod === "manual"
    ) {
      if (!manualRanksUpdated) {
        setNextAllowed(false);
      } else {
        setNextAllowed(true);
      }
    } else if (activeStep === rankingSteps.FINALIZE_RANK) {
      setNextAllowed(true);
    } else if (activeStep === rankingSteps.WITNESS_RANK) {
      //check if all witnesses have done their part
      if (allWitnessesComplete) {
        setNextAllowed(true);
      } else {
        setNextAllowed(false);
      }
    } else if (
      activeStep === rankingSteps.CLOSE_RANK ||
      activeStep === rankingSteps.PUBLISH_RANK ||
      activeStep === rankingSteps.ADJUST_RANK
    ) {
      //
      //check if all witnesses have done their part
      setNextAllowed(true);
    }
  }

  useEffect(() => {
    updateNextAllowed();
  }, [
    rankingMethod,
    activeStep,
    manualRanksUpdated,
    audits,
    witnesses,
    loadingRankings,
  ]);

  async function resetRanks() {
    setActiveStep(0);
    setLoadinRankings(true);
    setRankingMethod();

    await axios.post(`${getBaseUrl()}/rankings/${rankId}/reset`);
    setLoadinRankings(false);
    await fetchApplicants();
  }

  useEffect(() => {
    fetchApplicants();
  }, []);

  //const [ranking = {}] = applicants;

  const opps = useDrawingOpportunities(drawingId, ranking.ProgramID);

  const tableColumns = [
    {
      title: "#",

      render: (rowData) => rowData.tableData.id + 1,
      editable: "never",
    },
    {
      title: "User ID",
      field: "user_id",
      editable: "never",
    },
    {
      title: "HH Size",
      field: "hhld_size",
      editable: "never",
      export: false,
    },
    {
      title: "Submission ID",
      field: "submission_id",
      editable: "never",
    },

    ...(ranking.ranking_basis == "tiers"
      ? [
          {
            title: "Preferences",
            field: "preferences",
            editable: "never",
            render: (rowData) => (
              <span>
                {!Array.isArray(rowData.preferences)
                  ? "No Tier"
                  : [
                      ...new Set([
                        ...rowData.preferences
                          .filter((t) => Boolean(t.response))
                          .map((t) => t.value)
                          .sort((a, b) => a - b),
                      ]),
                    ]
                      .map((t) => `Tier ${t}`)
                      .join(", ")}
              </span>
            ),
          },
        ]
      : [
          {
            title: "Total Points",
            field: "NumOfPoints",
            editable: "never",
          },
        ]),

    {
      title: "Initial Rank",
      field: "initial_rank",
    },
    {
      title: "Final Rank",
      field: "final_rank",
      editable: "never",
    },
    ...(manualAdjustOn
      ? [
          {
            title: "Adjusted Rank",
            field: "rank_adjusted",
            editable: "never",
          },
        ]
      : []),

    {
      title: "Entry Date",
      field: "date_submitted",
      editable: "never",
      render: (rowData) =>
        formatDateToTimeZone(rowData.date_submitted, "MM/dd/yyyy h:mm:ss a"),
    },
  ];

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedApplicant(data.ApplicantFileID);
    setAdjustedApplicants((prev) => [...prev, data.ApplicantFileID]);
  };

  const open = Boolean(anchorEl);

  const pageSizeOptions = [
    10,
    20,
    50,
    ...(applicants.length > 50 ? [applicants.length] : []),
  ];
  return (
    <div className="min-h-screen mt-4">
      <Breadcrumbs className="p-2" aria-label="breadcrumb">
        <MatLink color="inherit" href="/drawings">
          Drawings
        </MatLink>
        <MatLink color="inherit" href={`/drawings/${drawingId}`}>
          {drawingId}
        </MatLink>
        <MatLink color="inherit" href={`/drawings/${drawingId}/rankings`}>
          rankings
        </MatLink>
        <MatLink className={classes.currPage}>Ranking Index {rankId}</MatLink>
      </Breadcrumbs>
      <section className="mt-4 flex flex-wrap items-start p-2 ">
        <section className="p-4 w-full sm:w-1/5  flex-grow shadow-md flex flex-col  rounded items-start ">
          <CardItem
            label="Opportunity ID"
            value={opps.map((o) => o.opportunityId).join(",")}
            Icon={FaRegFolder}
          />

          <CardItem
            label="Provider"
            value={ranking.provider}
            Icon={MdContacts}
          />

          <CardItem
            label="Program"
            value={ranking.program_name}
            Icon={MdHome}
          />

          <CardItem
            label="Drawing Type"
            value={ranking.ranking_type}
            Icon={FaRegFolder}
          />

          <CardItem
            label="Ranking Type"
            value={ranking.ranking_basis}
            Icon={FaRegFolder}
          />

          <CardItem
            label="Deadline"
            value={formatDateToTimeZone(
              drawing.entry_deadline,
              "MM/dd/yyyy h:mm:ss a"
            )}
            Icon={FaRegCalendar}
          />

          <section className="mt-2 border-t-2 border-gray-200 w-full">
            <h6 className="text-gray-800 flex justify-center items-center p-1 border-b-2 border-gray-200 text-sm font-bold mt-2">
              <FaHistory className="mr-2" />
              History
            </h6>
            <div className="mt-2">
              <div className="grid grid-cols-3 gap-1 text-xs font-semibold oblique">
                <span className="underline">Action</span>
                <span className="underline">By</span>
                <span className="underline">On</span>
              </div>
            </div>
            {audits.map((a) => (
              <div
                key={a.ActionTimestamp}
                className="grid grid-cols-3 gap-1 p-2 text-xs"
              >
                <span>{a.ActionPerformed}</span>
                <span>{a.ContactName}</span>

                <span>
                  {formatNonUTC(a.ActionTimestamp, "MM/dd/yyyy h:mm:ss a")}
                </span>
              </div>
            ))}
          </section>
        </section>

        <Witnesses
          witnesses={witnesses}
          processingWitness={processingWitness}
          loading={fetchingWitnesses}
          handleRefresh={handleWitnessRefresh}
          fetchWitnesses={fetchWitnesses}
          witnessRanking={witnessRanking}
          activeStep={activeStep}
        />

        <Preferences drawingId={drawingId} programId={ranking.ProgramID} />

        <div className="mt-4  flex flex-col overflow-y-scroll shadow-md min-h-32  sm:ml-4 w-full sm:w-2/5 flex-wrap">
          <section className="lottery-result-opp-card w-full">
            <div className="opp-card-header bg-hk-dark-blue py-2 rounded-t-md w-full flex sticky top-0 z-10">
              <span className="font-semibold oblique sticky text-white px-2">
                Opportunities ({opps.length})
              </span>
            </div>

            {opps.map((o) => {
              return <OpportunityCard key={o.opportunityId} {...o} />;
            })}
          </section>
        </div>

        <PopperMsg
          open={showPublishMsg}
          handleClose={() => setShowPublishMsg(false)}
        >
          <div>
            <span>This drawing has been published. </span>
            <span>
              Click{" "}
              <Link
                id="view-published-results"
                to={`/lottery-results/${drawingId}`}
              >
                here{" "}
              </Link>
              to view the published results
            </span>
          </div>
        </PopperMsg>
      </section>

      <div className="mt-4">
        {activeStep <= rankingSteps.PUBLISH_RANK && (
          <RankingStepper
            className="mb-4"
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleAction={handleAction}
            nextAllowed={nextAllowed}
            setRankingMethod={updateRankingMethod}
            rankingMethod={rankingMethod}
            manualRanksUpdated={manualRanksUpdated}
            turnOnManualAdjust={toggleManualAdjust}
            showResetButton={
              isInternalAdmin(user.profileType) &&
              activeStep < rankingSteps.PUBLISH_RANK
            }
          />
        )}

        {rankingMethod != null && (
          <div className="p-4 flex">
            <Chip
              color="secondary"
              size="small"
              avatar={<Avatar>R</Avatar>}
              label={`Ranking Method: ${rankingMethod}`}
            />
          </div>
        )}
        {activeStep == rankingSteps.RESET_RANK && (
          <div>
            <RankingNotification
              handleAction={() => {
                setShowWarning(true);
              }}
              notificationHistory={notificationHistory}
              processingEmails={processingEmails}
            />
          </div>
        )}
        <MaterialUITable
          id="rankings-table"
          isLoading={loadingRankings}
          data={applicants}
          components={{
            EditField: (props) => {
              return (
                <TextField
                  onChange={(e) => {
                    props.onChange(e.target.value);
                  }}
                  error={validateManualEdit(props.value)["err"]}
                  value={props.value}
                  helperText={
                    validateManualEdit(props.value)["err"]
                      ? validateManualEdit(props.value)["msg"]
                      : "Edit Rank here"
                  }
                />
              );
            },
            GroupRow: (props) => {
              const col = props.groups[props.level];

              return (
                <MTableGroupRow
                  {...{
                    ...props,
                    options: {
                      groupTitle: (groupData) =>
                        `${col.title} (${groupData.data.length})`,
                    },
                  }}
                />
              );
            },
          }}
          editable={{
            ...(isEditable && {
              onBulkUpdate: async (changes) => {
                setManualRanksUpdated(true);
                await handleManualRanking(changes);
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve();
                  }, 1000);
                });
              },
            }),
          }}
          columns={tableColumns}
          onChangeRowsPerPage={handlePageSizeChange}
          title={`# Applicants (${ranking?.LotteryRankingCnt ??
            0}) in Ranking Index ${rankId}`}
          options={{
            exportButton: true,
            grouping: true,
            pageSizeOptions: pageSizeOptions,
            pageSize: 10,
          }}
          actions={[
            {
              tooltip: "Move selection around",
              icon: ControlCameraIcon,
              disabled: !manualAdjustOn,
              onClick: (evt, data) => {
                handleClick(evt, data);
              },
            },
          ]}
        />
      </div>

      <ManualAdjuster
        anchorEl={anchorEl}
        open={open}
        setAnchorEl={setAnchorEl}
        numRankings={applicants.length}
        handleManualAdjust={handleManualAdjust}
      />
      <PopperMsg
        open={showSuccessMsg}
        handleClose={() => {
          setShowSuccessMsg(false);
        }}
      >
        <p>
          <span>{`Notification emails queued for delivery.`}</span>
        </p>
      </PopperMsg>

      <PopperMsg
        open={showFailureMsg}
        msgType="alert"
        handleClose={() => {
          setShowFailureMsg(false);
          // history.goBack();
        }}
      >
        <p>
          <span>{`Your request to send notifications could not be processed at this time.`}</span>
        </p>
      </PopperMsg>
      <Dialog open={showWarning}>
        <DialogContent>
          Are you sure you want to send email notifications to all applicants?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWarning(false)}>Cancel</Button>
          <Button onClick={sendPublishRanking}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <LoadingBackDrop open={processingEmails} />
    </div>
  );

  function handleManualAdjust(adjustedRank) {
    //shift the values in the rankings list, the rankings are updated in the server
    //once the user presses Next

    if (adjustedRank !== 0) {
      const adjustedRankings = moveElemTo(
        applicants,
        adjustedRank,
        (l) => selectedApplicant === l.ApplicantFileID
      ).map((r, index) => ({ ...r, AdjustedRank: index + 1 }));

      setAdjustedRankings(adjustedRankings);
      setApplicants(adjustedRankings);
    }
  }

  function handlePageSizeChange(pageSize) {
    console.log("handlePageSizeChange", pageSize);
  }

  function validateManualEdit(rank) {
    if (rank > applicants.length) {
      return {
        err: true,
        msg: "Value entered greater than number of entries",
      };
    }
    return false;
  }

  function handleAction(activeStep) {
    if (activeStep === rankingSteps.INITIAL_RANK) {
      handleRanking(rankingMethod);
    } else if (activeStep === rankingSteps.FINALIZE_RANK) {
      finalizeRanking();
    } else if (activeStep === rankingSteps.ADJUST_RANK) {
      adjustRanking();
    } else if (activeStep === rankingSteps.WITNESS_RANK) {
      //  nothing to do here, witnessing is done by clickin on the witness dialog
      //witnessRanking();
    } else if (activeStep === rankingSteps.CLOSE_RANK) {
      closeRanking();
    } else if (activeStep === rankingSteps.PUBLISH_RANK) {
      publishRanking();
    } else if (activeStep === rankingSteps.RESET_RANK) {
      resetRanks();
    }
  }

  async function adjustRanking() {
    if (adjustedRankings.length > 0) {
      const updates = adjustedRankings.map((r) => ({
        id: r.LotteryRankingDetailID,
        adjusted:
          adjustedApplicants.includes(r.ApplicantFileID) ||
          r.AdjustedFlag === "Y"
            ? "Y"
            : "N",
        rank: r.AdjustedRank,
      }));

      await axios.post(`${getBaseUrl()}/rankings/${drawingId}/rank_adjust`, {
        rankings: updates,
      });
      setAdjustedRankings([]);
      setSelectedApplicant("");
      setAdjustedApplicants([]);
    }

    await fetchApplicants();
    toggleManualAdjust(false);
  }

  function updateRankingMethod(rankingMethod) {
    if (rankingMethod === "manual") {
      setEditable(true);
    } else {
      setEditable(false);
    }
    setRankingMethod(rankingMethod);
  }

  async function handleRanking(rankingMethod) {
    if (rankingMethod === "applicantId") {
      await applicantIdRanking();
    }
    if (rankingMethod === "random") {
      await randomRanking();
    }
    if (rankingMethod === "manual") {
      setEditable(true);
    }
    if (rankingMethod === "timestamp") {
      setLoadinRankings(true);
      await axios.post(`${getBaseUrl()}/rankings/${rankId}/rank_timestamp`);
      fetchApplicants();
    }
  }

  async function handleManualRanking(changes) {
    const updates = getUpdatedManualRanks(changes, applicants);

    await axios.post(`${getBaseUrl()}/rankings/${rankId}/rank_manual`, {
      rankings: updates,
    });

    await fetchApplicants();
  }

  async function finalizeRanking() {
    setLoadinRankings(true);

    await axios.post(
      `${getBaseUrl()}/rankings/${rankId}/finalize?drawingId=${drawingId}&rankingType=${
        ranking.ranking_type
      }&mode=${ranking.ranking_basis}` //the FinalType here referes to whether to base it on points vs preferences
    );
    setLoadinRankings(false);
    await fetchApplicants();
  }

  async function witnessRanking() {
    setLoadinRankings(true);
    setProcessingWitnesses(true);
    await axios.post(`${getBaseUrl()}/rankings/${rankId}/witness`);
    setLoadinRankings(false);
    setProcessingWitnesses(false);
    await fetchApplicants();
  }

  async function closeRanking() {
    setLoadinRankings(true);
    await axios.post(`${getBaseUrl()}/rankings/${rankId}/close`);
    setLoadinRankings(false);
    await fetchApplicants();
  }

  async function publishRanking() {
    setLoadinRankings(true);
    await axios.post(`${getBaseUrl()}/rankings/${rankId}/publish`);
    setLoadinRankings(false);
    await fetchApplicants();
    setShowPublishMsg(true);
  }

  async function applicantIdRanking() {
    setLoadinRankings(true);
    await axios.post(`${getBaseUrl()}/rankings/${rankId}/rank_applicantId`);

    fetchApplicants();
  }

  async function randomRanking() {
    setLoadinRankings(true);
    const {
      data: {
        data: {
          random: { data: randoms },
        },
      },
    } = await axios.get(
      `${getBaseUrl()}/rankings/${rankId}/randoms?applicantCnt=${
        applicants?.length
      }`
    );

    await axios.post(
      `${getBaseUrl()}/rankings/${rankId}/rank_random?drawingId=${drawingId}`,
      {
        randoms,
      }
    );

    //might take a while for the randomizer to run and the db to be updated subsequently

    await fetchApplicants();
  }

  async function fetchWitnesses() {
    setFetchingWitnesses(true);
    const {
      data: { data: witnesses },
    } = await axios.get(
      `${getBaseUrl()}/rankings/${rankId}/witnesses?drawingId=${drawingId}`
    );
    setFetchingWitnesses(false);
    setWitnesses(witnesses);
  }

  async function handleWitnessRefresh() {
    await fetchWitnesses();
    await fetchAudits();
  }

  async function fetchAudits() {
    return axios.get(`${getBaseUrl()}/rankings/${rankId}/audits`);
  }

  function sendStatusOk(status) {
    /* the sending status of the recipient Possible values: "sent", "queued", "scheduled", "rejected", or "invalid". */
    const valid_statuses = ["sent", "queued", "scheduled"];
    return valid_statuses.indexOf(status) >= 0;
  }

  async function sendPublishRanking() {
    setShowWarning(false);
    setLoadinRankings(true);
    setProcessingEmails(true);
    setShowSuccessMsg(false);
    setShowFailureMsg(false);
    let sent = 0;
    try {
      const {
        data: { data: response },
      } = await axios.put(`${getBaseUrl()}/lottery/${rankId}/notify/results`);

      setProcessingEmails(false);
      if (Array.isArray(response)) {
        for (let i = 0; i < response.length; i++) {
          if (sendStatusOk(response[i].status)) {
            sent++;
          }
        }
      } else if (typeof response !== "undefined") {
        console.log(
          `error: ${
            response.reject_reason
              ? response.reject_reason
              : JSON.stringify(response)
          }`
        );
      }
    } catch (e) {
      setProcessingEmails(false);
      setLoadinRankings(false);
      if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(e.response.data);
        console.log(e.response.status);
        console.log(e.response.headers);
      } else if (e.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(e.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", e.message);
      }
    }
    setShowSuccessMsg(sent > 0);
    setShowFailureMsg(sent == 0);
    await fetchApplicants();
  }

  async function fetchApplicants() {
    setLoadinRankings(true);

    const [
      rankingsResp,
      auditsResp,
      witnessesResp,
      sentEmails,
    ] = await Promise.all([
      axios.get(`${getBaseUrl()}/rankings/${rankId}/applicants`),
      fetchAudits(),
      axios.get(
        `${getBaseUrl()}/rankings/${rankId}/witnesses?drawingId=${drawingId}`
      ),
      axios.get(`${getBaseUrl()}/lottery/${rankId}/notify/results`),
    ]);
    const {
      data: { data: applicants = [] },
    } = rankingsResp;
    const {
      data: { data: audits = [] },
    } = auditsResp;

    const {
      data: { data: witnesses = [] },
    } = witnessesResp;

    const {
      data: { data: notificationHistory = [] },
    } = sentEmails;

    setApplicants(applicants);

    /*  setApplicants(
      applicants.map((r) => ({
        ...r,
        PreferenceValues: sortTiers(r.PreferenceValues, true),
        DateCreated: formatNonUTC(r.DateCreated, "MM/dd/yyyy h:mm:SSS a"),
      }))
    ); */
    setAudits(audits);
    setWitnesses(witnesses);
    if (notificationHistory.Count > 0 && notificationHistory.Items) {
      setNotificationHistory(notificationHistory.Items);
    } else {
      setNotificationHistory([]);
    }
    setLoadinRankings(false);
  }
}

export default Ranking;
