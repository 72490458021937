import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useProgramPreferences(programId) {
  const [prefs, setPrefs] = useState([]);

  useEffect(() => {
    if (!programId) return;
    fetchProgramPrefs();
  }, [programId]);

  return [prefs, fetchProgramPrefs];

  async function fetchProgramPrefs() {
    const {
      data: { data: prefs },
    } = await axios.get(`${getBaseUrl()}/programs/${programId}/preferences`);

    setPrefs(prefs);
  }
}
