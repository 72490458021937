import React, { useReducer } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useQuestions } from "../../../queries/questions/useQuestions";
import MaterialUITable from "../../shared/Table/MaterialUITable";
import { formatDateToTimeZone } from "../../../utils/formatters";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";

const reducer = (state, { type, payload }) => ({ ...state, [type]: payload });
export function Questions() {
  const questions = useQuestions();
  const [state, dispatch] = useReducer(reducer, {
    showDeleteAlert: false,
  });
  const cols = [
    {
      title: "ID",
      field: "question_id",
      editable: "never",
    },
    {
      title: "Question",
      field: "question",
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Created",
      field: "date_created",
      render: (rowData) =>
        formatDateToTimeZone(rowData.date_created, "MM/dd/yyyy h:mm:ss a"),
      editable: "never",
    },
    {
      title: "Created By",
      field: "created_by",
      editable: "never",
    },
  ];
  return (
    <section className="min-h-screen">
      <MaterialUITable
        title={`Questions (${questions.length})`}
        columns={cols}
        data={questions}
        cellEditable={{
          onCellEditApproved: (newValue, _, rowData, columnDef) => {
            const { title } = columnDef;
            handleUpdate(newValue, rowData, title);
          },
        }}
        options={{
          selection: true,
        }}
        actions={[
          {
            icon: DeleteIcon,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              dispatch({ type: "showDeleteAlert", payload: true });
              dispatch({
                type: "questionToDelete",
                payload: rowData.question_id,
              });
            },
            position: "row",
          },
        ]}
      />
      <DeleteAlert
        open={state.showDeleteAlert}
        handleClose={() =>
          dispatch({ type: "showDeleteAlert", payload: false })
        }
        handleConfirm={handleDelete}
      >
        <span>Are you sure you want to delete this question?</span>
      </DeleteAlert>
    </section>
  );

  async function handleUpdate(newValue, rowData, field) {
    await axios.put(`${getBaseUrl()}/questions/${rowData.question_id}`, {
      fileids: [rowData.ID],
      field,
    });
  }

  async function handleDelete() {
    console.log("questionId");
    // await axios.delete(`${getBaseUrl()}/questions/${state.questionToDelete}`);
  }
}

function DeleteAlert({ open, handleClose, handleConfirm, children }) {
  return (
    <Dialog open={open}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
