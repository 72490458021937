import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import { FormattedMessage } from "react-intl";

interface PasswordPolicyProps {
  meetsLengthReq: boolean;
  meetsLowerCaseReq: boolean;
  meetsUpperCaseReq: boolean;
  meetsNumberReq: boolean;
  meetsSpecialCharsReq: boolean;
}

export default function PasswordPolicy({
  meetsLengthReq,
  meetsLowerCaseReq,
  meetsNumberReq,
  meetsSpecialCharsReq,
  meetsUpperCaseReq,
}: PasswordPolicyProps) {
  return (
    <div className="mt-4 py-2 flex flex-col  text-left text-xs bg-hk-blue-100 text-hk-blue-400 px-2 font-semibold">
      <span>
        {meetsLengthReq && <CheckIcon />}{" "}
        <FormattedMessage
          id="signup.pw_policy_length"
          defaultMessage="Password must be at least 8
        characters."
        />
      </span>
      <span>
        {meetsLowerCaseReq && <CheckIcon />}{" "}
        <FormattedMessage
          id="signup.pw_policy_lc"
          defaultMessage="Password must contain a lowercase
          letter."
        />
      </span>
      <span>
        {meetsUpperCaseReq && <CheckIcon />}{" "}
        <FormattedMessage
          id="signup.pw_policy_uc"
          defaultMessage="Password must contain a uppercase
          letter."
        />
      </span>
      <span>
        {meetsNumberReq && <CheckIcon />}
        <FormattedMessage
          id="signup.pw_policy_number"
          defaultMessage="Password must contain a number."
        />
      </span>
      <span>
        {meetsSpecialCharsReq && <CheckIcon />}{" "}
        <FormattedMessage
          id="signup.pw_policy_special"
          defaultMessage="Password must contain a special
          character."
        />
      </span>
    </div>
  );
}
