import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useDrawingApplicants(drawingId) {
  const [applicants, setApplicants] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data: applicants },
      } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/applicants`);
      setApplicants(applicants);
    })();
  }, [drawingId]);

  return applicants;
}
