import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { format, isValid } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export default function DatePicker({ label, value, handleChange, ...other }) {
  const { required } = other;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        margin="normal"
        views={["year", "month", "date"]}
        id="dob-picker-inline"
        label={
          <div>
            {label}
            {required && <span className="px-1 text-red-600">*</span>}
          </div>
        }
        animateYearScrolling
        value={formatDate(value)}
        onChange={handleChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

function formatDate(date) {
  const isValidDate = isValid(new Date(date));
  if (!isValidDate) return null;

  const zonedDate = utcToZonedTime(new Date(date));
  return format(zonedDate, "MM/dd/yyyy");
}
