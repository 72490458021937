import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import NotAuthorized from "../shared/misc/NotAuthorized";
import UserSetup from "./setup/UserSetup";
import HHldApplication from "./setup/hhld/HHldApplication";
import { isInternalAdmin, isExternalAdmin } from "../../utils/authUtils";
import UserContext from "../user/UserContext";

export default function UserRoutes(props) {
  const { user } = useContext(UserContext) || {};

  const showAdminPages =
    isInternalAdmin(user.profileType) || isExternalAdmin(user.profileType);

  return (
    <Switch>
      <Route exact path="/user-mgmt">
        <UserSetup />
      </Route>
      <Route exact path="/user-mgmt/:userId/hhld">
        <HHldApplication />
      </Route>
    </Switch>
  );
}
