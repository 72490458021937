import React from "react";
import {
  MenuItem,
  Select as MaterialSelect,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    width: (props) => ({
      width: props.width || 500,
    }),
  },
  inputLabel: {
    fontSize: "0.8rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function Select({ value, options, onChange, label, ...other }) {
  const classes = useStyles({ width: "100%" });
  const { required } = other;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        required={required ? true : false}
        className={classes.inputLabel}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <MaterialSelect value={value} onChange={onChange} {...other}>
        {options.map((o) => (
          <MenuItem
            className="text-md"
            key={o.value}
            value={o.value}
            disabled={o.disabled}
          >
            {o.label}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
}
