import React, { useState, useContext, useEffect } from "react";

import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import UserContext from "../user/UserContext";
import OpportunityCard from "../../opportunity/OpportunityCard";
import Select from "../shared/Select";
import HkLoading from "../shared/misc/HkLoading";
import { getDrawingIdOptions } from "../../utils/drawingUtils";
import { useOpportunities } from "../../queries/hhld/useOpportunities";
import {
  propTypeOptions,
  incomeLevelOptions,
  numBedsOptions,
  opportunityStatusOptions,
} from "../../opportunity/filterOptions";

export default function HhldOpportunities(props) {
  const { user } = useContext(UserContext) || {};
  const matches = useMediaQuery("(min-width: 600px)");

  const useStyles = makeStyles(() => ({
    root: {
      paddingLeft: matches ? "5rem" : 0,
      flexGrow: 1,
    },
    searchField: {
      marginTop: "-15px",
    },
  }));
  const classes = useStyles();

  const [state, dispatch] = useOpportunities(user.userId, []);
  const {
    opportunities,
    filteredOpps = [],
    programType,
    program,
    numBeds,
    incomeCategory,
    searchText,
    loading,
    drawingId,
  } = state;

  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    dispatch({
      type: name,
      payload: value,
    });
  };

  const programOptions = getProgramOptions(opportunities);
  const drawingIdOptions = getDrawingIdOptions(opportunities);

  return (
    <section className="min-h-screen">
      <form className="flex flex-col space-y-4 sm:flex-row flex-wrap  p-4 sm:items-baseline">
        <Select
          label="Program Type"
          name="programType"
          options={propTypeOptions}
          value={programType}
          onChange={handleFilterChange}
        ></Select>
        <Select
          label="Bedrooms"
          name="numBeds"
          options={numBedsOptions}
          value={numBeds}
          onChange={handleFilterChange}
        ></Select>
        <Select
          label="Program"
          value={program}
          name="program"
          onChange={handleFilterChange}
          options={programOptions}
        ></Select>
        <Select
          label="Income Category"
          value={incomeCategory}
          name="incomeCategory"
          onChange={handleFilterChange}
          options={incomeLevelOptions}
        ></Select>
        <Select
          label="Drawing ID"
          value={drawingId}
          name="drawingId"
          onChange={handleFilterChange}
          options={drawingIdOptions}
        ></Select>
        <TextField
          className={classes.root}
          value={searchText}
          onChange={handleFilterChange}
          name="searchText"
          InputProps={{
            startAdornment: (
              <InputAdornment className={classes.searchField} position="start">
                <SearchIcon className="text-gray-500" />
              </InputAdornment>
            ),
          }}
          placeholder="Search by Program, Project, Address, Income Category"
        />
      </form>
      <h5 className="flex justify-center items-center text-2xl bg-hk-orange-100 text-hk-orange-400 h-20 uppercase">
        Opportunities ({filteredOpps.length})
      </h5>
      {loading ? (
        <HkLoading />
      ) : (
        <div className="mt-4 flex flex-col items-center justify-center sm:flex-row flex-wrap">
          {!filteredOpps.length ? (
            <div className="text-lg text-gray-700 mt-6 p-4 bg-gray-50">
              <div className="flex space-x-2 justify-center ">
                <InfoIcon className="text-gray-400" />
                <p className="uppercase">No opportunities available </p>
              </div>
              <p className="text-sm text-gray-600 mt-4 ">
                This could be because you've yet to apply to a program, or no
                opportunties have been created for the programs you've requested
                an application id for.
              </p>
            </div>
          ) : (
            filteredOpps.map((o) => (
              <OpportunityCard
                key={o.ID}
                opp={o}
                handleFav={favOpp}
                user={user}
              />
            ))
          )}
        </div>
      )}
    </section>
  );

  async function favOpp(opp, action, hhldProgramId) {
    await axios.post(
      `${getBaseUrl()}/opportunity/${opp}/fav?action=${action}&hhldProgramId=${hhldProgramId}`
    );
    //  fetchHhldOpps();
  }
}

function getProgramOptions(opps = []) {
  let hash = new Set();
  return [{ label: "All", value: "All" }].concat(
    opps.reduce((acc, curr) => {
      const { ProgramID, ProgramTitle } = curr;

      if (!hash.has(ProgramID)) {
        hash.add(ProgramID);

        acc.push({ label: ProgramTitle, value: ProgramID });
      }

      return acc;
    }, [])
  );
}
