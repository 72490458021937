import React, { useEffect, useReducer } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Step,
  Stepper,
  StepLabel,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import axios from "../../../../utils/axios";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import LoadingBackDrop from "../../../shared/misc/LoadingBackDrop";
import { formatDate } from "../../../../utils/formatters";
import PopperMsg from "../../../shared/misc/PopperMsg";
import Select from "../../../shared/Select";
import DatetimePicker from "../../../shared/form/DatetimePicker";
import { Opportunities } from "../drawings/DrawingManager";
import { useProgramOpportunities } from "../../../../queries/programs/useProgramOpportunities";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: "#fff",
  },
  title: {
    display: "flex",
    backgroundColor: "#eb7e3c",
    color: "#fff",
  },
}));

export function CreateDrawing({ open, onClose, userId, email }) {
  const classes = useStyles();

  const [state, dispatch] = useReducer(formReducer, {
    activeStep: 0,
    programs: [],
    providers: [],
    selectedProgram: "",
    selectedProvider: "",
    refNum: "",
    subject: "",
    showLoading: false,
    rankingType: 1,
    drawingType: 1,
    selectedOpps: [],
  });

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    if (state.activeStep == 1) {
      fetchPrograms();
    }
  }, [state.activeStep]);

  const programOpps = useProgramOpportunities(state.selectedProgram);
  function formReducer(state, { type, payload }) {
    return {
      ...state,
      [type]: payload,
    };
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;
    console.log(
      `handleChange name ${name} value ${value} checked ${checked} type ${type}`
    );

    dispatch({
      type: name,
      payload: value ? value : type !== "text" ? checked : "",
    });
  }

  function clearFormFields() {
    dispatch({ type: "reset" });
    onClose();
  }

  return (
    <section className="py-4">
      <Dialog open={open} onClose={clearFormFields} className={classes.root}>
        <DialogTitle className={classes.title}>
          <div className="flex bg-hk-orange text-white  items-center">
            <PersonAddIcon className="-ml-4 -mt-1 mr-2" />
            <span className="font-md text-sm font-semibold tracking-wide">
              Create Drawing
            </span>

            <IconButton
              className={classes.closeIcon}
              aria-label="close"
              onClick={clearFormFields}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <CreateDrawingStepper
            activeStep={state.activeStep}
            dispatch={dispatch}
            getStepContent={getStepContent}
            handleFinish={handleSubmit}
          />
        </DialogContent>
      </Dialog>
      <LoadingBackDrop open={state.showLoading} />
      <PopperMsg open={state.showMemberSuccess}>
        <span>Drawing Created successfully</span>
      </PopperMsg>
    </section>
  );

  async function handleSubmit(e) {
    e.preventDefault();
    const {
      applicantAcceptance,
      desc,
      drawingDeadline,
      drawingStatus,
      drawingType,
      fileSubmissionDeadline,
      incomeCategory,
      resultsPublished,
      selectedProgram,
      selectedProvider,
      selectedOpps,
      rankingType,
    } = state;

    await axios.post(`${getBaseUrl()}/drawings`, {
      applicantAcceptance,
      desc,
      drawingDeadline,
      drawingStatus,
      drawingType,
      fileSubmissionDeadline,
      incomeCategory,
      resultsPublished,
      selectedProgram,
      selectedProvider,
      selectedOpps: [...new Set(selectedOpps.flatMap((o) => o.opportunityId))],
      rankingType,
    });
  }

  function getStepContent() {
    switch (state.activeStep) {
      case 0:
        return (
          <section className="">
            <Providers
              providers={state.providers}
              dispatch={dispatch}
              value={state.selectedProvider}
            />
          </section>
        );

      case 1:
        return (
          <section>
            <Programs
              programs={state.programs}
              dispatch={dispatch}
              value={state.selectedProgram}
            />
          </section>
        );
      case 2:
        return (
          <section className="px-4">
            <h2 className="text-lg py-4 px-4">Step 3: Select Opportunities</h2>
            <Opportunities
              opps={programOpps}
              fields={state}
              handleChange={handleChange}
              handleSelect={handleOppSelection}
              selections={[
                ...new Set(state.selectedOpps.flatMap((o) => o.opportunityId)),
              ]}
            />
          </section>
        );

      case 3:
        return <DrawingForm fields={state} handleChange={handleChange} />;

      case 4:
        return (
          <div>
            <Opportunities opps={state.selectedOpps} />
            <DrawingForm fields={state} handleChange={handleChange} />;
          </div>
        );

      default:
        return "Unknown stepIndex";
    }
  }

  function handleChange(e) {
    const { value, name } = e.target;
    dispatch({
      type: name,
      payload: value,
    });
  }

  function handleOppSelection(opp) {
    dispatch({
      type: "selectedOpps",
      payload: [...state.selectedOpps, opp],
    });
  }

  async function fetchProviders() {
    const {
      data: { data: providers },
    } = await axios.get(`${getBaseUrl()}/entity?rowCnt=100&page=1`);
    dispatch({
      type: "providers",
      payload: providers,
    });
  }

  async function fetchPrograms() {
    const {
      data: { data: programs },
    } = await axios.get(
      `${getBaseUrl()}/entity/${
        state.selectedProvider
      }/programs?rowCnt=100&page=1`
    );
    dispatch({
      type: "programs",
      payload: programs,
    });
  }
}

function CreateDrawingStepper({
  activeStep,
  getStepContent,
  dispatch,
  handleFinish,
  nextDisabled,
  submitting,
  backDisabled,
  successMsg,
}) {
  const classes = useStyles();

  const steps = getSteps();

  const handleNext = () => {
    dispatch({
      type: "activeStep",
      payload: activeStep + 1,
    });
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    dispatch({
      type: "activeStep",
      payload: activeStep - 1,
    });
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    dispatch({
      type: "activeStep",
      payload: 0,
    });
    //setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form className="">
        {successMsg}
        {activeStep === steps.length ? (
          <div></div>
        ) : (
          <div className="flex flex-col space-y-4 p-2">
            <div className="text-left text-xs">
              {getStepContent(activeStep)}
            </div>
            <div className="flex justify-end pt-8">
              <Button
                disabled={activeStep === 0 || backDisabled}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  disabled={submitting}
                  color="primary"
                  onClick={(e) => {
                    handleFinish(e);
                    handleNext();
                  }}
                >
                  Create Drawing
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={nextDisabled}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

function getSteps() {
  return ["Provider", "Program", "Opportunities", "Drawing", "Review"];
}

function Providers({ providers, value, dispatch }) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" className="mb-4">
        Step 1: Select a Program Provider:
      </FormLabel>
      <RadioGroup
        aria-label="provider"
        name="provider"
        value={value}
        onChange={handleChange}
        className="grid "
      >
        <Grid container spacing={1}>
          {providers.map((p) => (
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                key={p.entityId}
                value={p.entityId}
                control={<Radio size="small" />}
                label={p.entityName}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );

  function handleChange(e) {
    const { value } = e.target;
    dispatch({
      type: "selectedProvider",
      payload: value,
    });
  }
}

function Programs({ programs, value, dispatch }) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" className="mb-4">
        Step 2: Select a Program:
      </FormLabel>
      <RadioGroup
        aria-label="program"
        name="program"
        value={value}
        onChange={handleChange}
        className="grid "
      >
        {programs.map((p) => (
          <FormControlLabel
            key={p.ProgramID}
            value={p.ProgramID}
            control={<Radio size="small" />}
            label={p.ProgramTitle}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  function handleChange(e) {
    const { value } = e.target;
    dispatch({
      type: "selectedProgram",
      payload: value,
    });
  }
}

function DrawingForm({ fields, handleChange }) {
  const {
    applicantAcceptance,
    desc,
    drawingDeadline,
    drawingStatus,
    drawingType,
    endDate,
    fileSubmissionDeadline,
    incomeCategory,
    rankingType,
    refNum,
    resultsPublished,
    selectedProvider,
    selectedProgram,
    subject,
    startDate,
  } = fields;
  return (
    <div className="flex flex-wrap space-x-2 space-y-2 items-center py-4">
      <TextField label="Provider" disabled value={selectedProvider} />
      <TextField label="Program" disabled value={selectedProgram} />

      <TextField
        value={desc}
        label="Description"
        name="desc"
        onChange={handleChange}
      />
      <div className="flex items-baseline space-x-8">
        <Select
          label="Drawing Type"
          name="drawingType"
          value={drawingType}
          onChange={handleChange}
          options={[
            { label: "Lottery Selection", value: 1 },
            { label: "First-Form First-Serve", value: 2 },
            { label: "First-File First-Serve", value: 3 },
            { label: "First-ApplicationID First-Serve", value: 4 },
            { label: "First Pre-Application Submitted", value: 5 },
          ]}
        />
        <Select
          label="Preference Type"
          name="preferenceType"
          value={rankingType}
          onChange={handleChange}
          options={[
            { label: "Tiers", value: 1 },
            { label: "Points", value: 2 },
            { label: "N/A", value: 3 },
          ]}
        />
        {/* <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Points</Grid>
            <Grid item>
              <StyledSwitch
                checked={rankingType}
                onChange={handleChange}
                name="rankingType"
              />
            </Grid>
            <Grid item>Tiers</Grid>
          </Grid>
        </Typography> */}
      </div>

      <Select
        label="Status"
        name="drawingStatus"
        value={drawingStatus}
        onChange={handleChange}
        options={[
          { label: "Open", value: 44 },
          { label: "Closed", value: 43 },
          { label: "Pending Release", value: 45 },
        ]}
      />

      <Select
        label="Applicant Acceptance"
        name="applicantAcceptance"
        value={applicantAcceptance}
        onChange={handleChange}
        options={[
          {
            label: "Accepting Entries, (Lottery Ranking Hasn't Happened yet.)",
            value: 46,
          },
          {
            label:
              "Applicant(s)-Selected. New Entrants will be added backup list.",
            value: 60,
          },
          {
            label: "Lottery Ranking Completed. Now accepting backup entrants.",
            value: 47,
          },
          { label: "Not accepting new entrants at this time", value: 55 },
        ]}
      />

      <DatetimePicker
        label="Drawing Deadline"
        required
        onChange={handleChange}
        value={drawingDeadline}
        handleChange={(date) =>
          handleChange({ target: { name: "drawingDeadline", value: date } })
        }
      />
      {/*  <DatetimePicker
        label="Results Published Date"
        required
        onChange={handleChange}
        value={resultsPublished}
        handleChange={(date) =>
          handleChange({ target: { name: "resultsPublished", value: date } })
        }
      /> */}
      <DatetimePicker
        label="File Submission Deadline"
        required
        onChange={handleChange}
        value={fileSubmissionDeadline}
        handleChange={(date) =>
          handleChange({
            target: { name: "fileSubmissionDeadline", value: date },
          })
        }
      />
    </div>
  );
}
