import React, { useReducer } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  IconButton,
  Switch,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isValid } from "date-fns";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import Stepper from "./AddhhldStepper";
import PersonalInfo from "./add_member/PersonalInfo";
import IncomeForm from "./income/IncomeForm";
import LoadingBackDrop from "../shared/misc/LoadingBackDrop";
import { formatDate } from "../../utils/formatters";
import PopperMsg from "../shared/misc/PopperMsg";
import { hasEmptyFields } from "../../utils/jsUtils";

const computeInitState = (defaults) => ({
  activeStep: 0,
  anticipatedIncomeEndDate: null,
  authorizedToManageProfile: true,

  caseDate: null,
  childDOB: null,
  contributeToExp: "",
  dob: "",
  dateIncomeStarted: null,
  dateLastEmployed: null,
  email: "",
  emailSameAsPrimary: true,
  firstName: "",
  incomeIncluded: true,
  incomeType: "",
  lastName: "",
  livesWithPrimary: true,
  maritalStatus: "",
  phone: "",
  relationToPrimary: "",
  sendInviteToMember: false,
  showLoading: false,
  yearFormed: null,
  showOnCloseAlert: false,
  ...defaults,
});

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: "#fff",
  },
  title: {
    display: "flex",
    backgroundColor: "#eb7e3c",
    color: "#fff",
  },

  incomeTypeRadio: {
    backgroundColor: "#edf2f7",
    borderRadius: "10px",
    fontSize: "0.8rem",
    padding: "2px",
  },

  checkBoxLabel: {
    "&& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",
      color: "#718096",
    },
  },

  intermediateBtn: {
    backgroundColor: "#6eb9a4",
    color: "#fff",
  },

  switchBase: { "&$checked": { backgroundColor: "green" } },
}));

export default function AddHhldMemberModal({
  open,
  onClose,
  userId,
  email,
  hhldAdminPresent,
}) {
  const classes = useStyles();

  const [state, dispatch] = useReducer(
    formReducer,
    computeInitState({ email })
  );

  function formReducer(state, action) {
    switch (action.type) {
      case "reset":
        return computeInitState({ email });
      case "phone":
        return { ...state, phone: action.payload.replace(/\D/g, "") };
      case "emailSameAsPrimary":
        return action.payload == true
          ? { ...state, emailSameAsPrimary: action.payload, email: email }
          : { ...state, emailSameAsPrimary: action.payload, email: "" };
      default:
        return { ...state, [action.type]: action.payload };
    }
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    dispatch({
      type: name,
      payload: value ? value : type !== "text" ? checked : "",
    });
  }

  const { activeStep } = state;

  function getStepContent(stepIndex) {
    /* dispatch({
      type: "activeStep",
      payload: stepIndex,
    }); */
    switch (stepIndex) {
      case 0:
        return (
          <PersonalInfo
            handleChange={handleChange}
            hhldAdminPresent={hhldAdminPresent}
            classes={classes}
            dispatch={dispatch}
            {...state}
          />
        );
      case 1:
        return (
          <IncomeForm
            showIncomeTypes
            dispatch={dispatch}
            handleChange={handleChange}
            classes={classes}
            fields={state}
          />
        );
      case 2:
        return (
          <Page3 handleChange={handleChange} classes={classes} fields={state} />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  function clearFormFields(event, reason) {
    console.log("reason", reason);
    if (reason === "backdropClick") {
      dispatch({
        type: "showOnCloseAlert",

        payload: true,
      });
      return;
    }
    //  dispatch({ type: "reset" });

    onClose();
  }

  return (
    <section className="py-4">
      <Dialog open={open} onClose={clearFormFields} className={classes.root}>
        <DialogTitle className={classes.title}>
          <div className="flex bg-hk-orange text-white  items-center">
            <PersonAddIcon className="-ml-4 -mt-1 mr-2" />
            <span className="font-md text-sm font-semibold tracking-wide">
              Add Household Member
            </span>

            <IconButton
              className={classes.closeIcon}
              aria-label="close"
              onClick={clearFormFields}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Stepper
            getStepContent={getStepContent}
            handleFinish={addMember}
            nextDisabled={isNextDisabled()}
          />
        </DialogContent>
      </Dialog>
      <LoadingBackDrop open={state.showLoading} />
      <PopperMsg
        open={state.showMemberSuccess}
        handleClose={() =>
          dispatch({
            type: "showMemberSuccess",
            payload: false,
          })
        }
      >
        <span>Member Added successfully</span>
      </PopperMsg>

      <Dialog open={state.showOnCloseAlert}>
        <DialogContent>
          You haven't finished adding this member to your household yet.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) =>
              dispatch({
                type: "showOnCloseAlert",
                payload: false,
              })
            }
          >
            Continue
          </Button>
          <Button
            onClick={(e) => {
              dispatch({
                type: "showOnCloseAlert",
                payload: false,
              });
              onClose();
            }}
            size="small"
            color="secondary"
            variant="contained"
          >
            I will do it later
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );

  function isNextDisabled() {
    if (activeStep === 0) {
      return (
        hasEmptyFields(state, [
          "firstName",
          "lastName",
          "dob",
          "maritalStatus",
          "relationToPrimary",
        ]) || !isValid(new Date(state.dob))
      );
    }
    return false;
  }

  async function addMember(e) {
    dispatch({
      type: "showLoading",
      payload: true,
    });
    e.preventDefault();

    const { data } = await axios
      .post(`${getBaseUrl()}/user/${userId}/add_member`, {
        ...state,
        dob: formatDate(state.dob),
        dateLastEmployed: formatDate(state.dateLastEmployed),
        dateIncomeStarted: formatDate(state.dateIncomeStarted),
        yearFormed: formatDate(state.yearFormed),
        caseDate: formatDate(state.caseDate),
        anticipatedIncomeEndDate: formatDate(state.anticipatedIncomeEndDate),
      })
      .catch((e) => {
        dispatch({
          type: "showLoading",
          payload: false,
        });
      });

    const memberId = data?.data?.[0]?.MemberID;

    if (memberId) {
      dispatch({
        type: "showMemberSuccess",
        payload: true,
      });
      await axios.post(`${getBaseUrl()}/user/${userId}/incomes`, {
        ...state,
        memberId,
        dateLastEmployed: formatDate(state.dateLastEmployed),
        dateIncomeStarted: formatDate(state.dateIncomeStarted),
        yearFormed: formatDate(state.yearFormed),
        caseDate: formatDate(state.caseDate),
        anticipatedIncomeEndDate: formatDate(state.anticipatedIncomeEndDate),
      });
    }

    dispatch({
      type: "showLoading",
      payload: false,
    });

    clearFormFields();
  }
}

function Page3({ classes, fields, handleChange }) {
  const {
    incomeIncluded,
    authorizedToManageProfile,
    sendInviteToMember,
    emailSameAsPrimary,
  } = fields;
  return (
    <div>
      <FormGroup row>
        <FormControlLabel
          className={classes.checkBoxLabel}
          control={
            <Switch
              classes={{ switchBase: classes.switchBase }}
              name="incomeIncluded"
              checked={incomeIncluded}
              onChange={handleChange}
            />
          }
          label="Is this member's income included in the total household income?"
          labelPlacement="start"
        />
      </FormGroup>

      <FormGroup row>
        <FormControlLabel
          className={classes.checkBoxLabel}
          control={
            <Switch
              classes={{ switchBase: classes.switchBase }}
              name="authorizedToManageProfile"
              checked={authorizedToManageProfile}
              onChange={handleChange}
            />
          }
          label="I am authorized to manage this member's profile?"
          labelPlacement="start"
        />
      </FormGroup>

      <FormGroup row>
        <FormControlLabel
          className={classes.checkBoxLabel}
          control={
            <Switch
              classes={{ switchBase: classes.switchBase }}
              name="sendInviteToMember"
              checked={emailSameAsPrimary ? false : sendInviteToMember}
              onChange={handleChange}
            />
          }
          label="Send email invite to user to join HouseKeys?"
          labelPlacement="start"
        />
      </FormGroup>
    </div>
  );
}
