import React, { useReducer } from "react";
import { FormattedMessage } from "react-intl";
import { Auth } from "aws-amplify";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { usePreferredLanguage } from "../../../translations/LanguageContext";
import messages_en from "../../../translations/en.json";
import messages_esp from "../../../translations/espanol.json";

const messages = {
  es: messages_esp,
  en: messages_en,
};

function reducer(state, action) {
  return { ...state, [action.type]: action.payload };
}

export default function ResetPw({ setStatus }) {
  const [state, dispatch] = useReducer(reducer, {
    userName: "",
  });

  const { userName } = state;
  const { preferredLang } = usePreferredLanguage();

  const handleChange = (e) => {
    const { value } = e.target;

    dispatch({
      type: "userName",
      payload: value,
    });
  };

  return (
    <form className="w-full sm:w-96 shadow-md p-8" onSubmit={handleSubmit}>
      <div className="border-b border-gray-200 py-2">
        <AccountCircleIcon fontSize="large" className="text-hk-orange-300" />
      </div>
      <p className="font-semibold  pt-4 text-gray-700">
        <FormattedMessage
          id="resetpw.header"
          defaultMessage="Reset your Password"
        />
      </p>
      <div className="mt-4 space-y-2 flex flex-col">
        <FormGroup
          label={messages[preferredLang]["resetpw.username"]}
          required
          type="text"
          handleChange={handleChange}
          value={userName}
          id="userName"
          name="userName"
        />
      </div>

      <div className="mt-6 flex flex-col sm:flex-row justify-between items-baseline text-xs">
        <div className="flex space-x-2 mt-2">
          <a
            href="#"
            id="sign-up-link"
            onClick={() => setStatus("sign-in")}
            className="text-hk-orange-400 hover:underline"
          >
            <FormattedMessage
              id="resetpw.back"
              defaultMessage="Back to Sign in"
            />
          </a>
        </div>
        <button
          disabled={!userName}
          className="w-full mt-4 tracking-wide sm:w-32 order-first sm:order-last  h-10 uppercase bg-hk-orange-400 text-white font-semibold disabled:opacity-50 disabled:cursor-default"
          type="submit"
        >
          <FormattedMessage id="resetpw.sendcode" defaultMessage="Send Code" />
        </button>
      </div>
    </form>
  );

  async function handleSubmit(e) {
    e.preventDefault();

    await Auth.forgotPassword(userName);
    setStatus("confirm-reset-pw");
  }
}

function FormGroup({
  label,
  name,
  id,
  error,
  errorMessage,
  type,
  placeholder,
  required,
  value,
  handleChange,
}) {
  return (
    <div className="mt-4 flex flex-col space-y-2">
      <label htmlFor={id} className="text-left text-gray-600 text-sm">
        {label} {required && <span className="text-red-500">*</span>}
      </label>

      <input
        id={id}
        className="w-full pl-4 h-10 border border-gray-200 placeholder-gray-400 text-sm"
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {error && (
        <span className="text-red-600 text-xs text-left">{errorMessage}</span>
      )}
    </div>
  );
}
