import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
} from "@material-ui/core";
import useCardStyles from "./cardStyles";
import { propIn } from "../../../utils/jsUtils";
import CardItem from "./CardItem";

export default function DrawingCard({ clickHandler, drawingStats }) {
  const classes = useCardStyles();
  return (
    <section
      className="drawings-card p-4 cursor-pointer w-full sm:w-1/4"
      onClick={clickHandler}
    >
      <Card className={classes.root}>
        <CardHeader className={classes.header} title="Drawings"></CardHeader>
        <Divider />
        <CardContent>
          <List>
            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumOpportunitiesCount", drawingStats)}
                label="Opportunities"
                value={drawingStats.NumOpportunitiesCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("OpenDrawingsCount", drawingStats)}
                label=" Open Drawings"
                value={drawingStats.OpenDrawingsCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumTotalApplicantsCount", drawingStats)}
                label="Applicants"
                value={drawingStats.NumTotalApplicantsCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumActiveApplicantsCount", drawingStats)}
                label="Active Applicants"
                value={drawingStats.NumActiveApplicantsCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("PublishedCount", drawingStats)}
                label="Published"
                value={drawingStats.PublishedCount}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </section>
  );
}
