import React, { useEffect, useReducer } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useHhldDrawings(userId, initState = {}) {
  const [state, dispatch] = useReducer(formReducer, {
    allDrawings: [],
    filteredDrawings: [],
    programType: "All",
    program: "All",
    drawingId: "",
    drawingIdOptions: [],
    programOptions: [],
    programTypeOptions: [],
    ...initState,
  });

  const { drawingId } = state;

  useEffect(() => {
    fetchDrawings();
  }, []);

  async function fetchDrawings() {
    const {
      data: { data: drawings },
      data: { programIdOfDrawingsEntered },
    } = await axios.get(
      `${getBaseUrl()}/user/${userId}/drawings?drawingId=${drawingId}`
    );

    dispatch({
      type: "drawings",
      payload: drawings.map((d) => ({
        ...d,
        applicantsAccepted: getApplicantAccepted(
          d.drawingStatus,
          d.applicantsAcceptedR,
          d.programId,
          programIdOfDrawingsEntered
        ),
        incomeLevelsUniq: [...new Set([...d.incomeLevels?.split(",")]).values()]
          .map((i) => incomeLevels.get(i))
          .join(","),
      })),
    });
  }

  return [state, dispatch];
}

const incomeLevels = new Map([
  ["1", "Extremely Low"],
  ["2", "Very Low"],
  ["3", "Low"],
  ["4", "Medium"],
  ["5", "Moderate"],
  ["6", "Above Moderate"],
  ["7", "Low Median"],
  ["8", "Low Moderate"],
]);

export function getApplicantAccepted(
  drawingStatus,
  applicantAcceptedR,
  programId,
  programIdsOfDrawingsEntered
) {
  //user has entered another drawing in the same program
  if (programIdsOfDrawingsEntered.includes(programId)) {
    return 2;
  }
  if (drawingStatus === "P" || drawingStatus === "C") {
    return 2;
  }
  if (
    applicantAcceptedR == 46 ||
    applicantAcceptedR == 47 ||
    applicantAcceptedR == 60
  ) {
    return 1;
  }

  return 2;
}

function formReducer(state, action) {
  switch (action.type) {
    case "drawings": {
      return {
        ...state,
        allDrawings: action.payload,
        filteredDrawings: action.payload,
        drawingIdOptions: getDrawingIdOptions(action.payload),
        programOptions: getProgramOptions(action.payload),
        programTypeOptions: getProgramTypeOptions(action.payload),
      };
    }
    case "program": {
      return action.payload === "All"
        ? {
            ...state,
            program: action.payload,
            filteredDrawings: state.allDrawings,
          }
        : {
            ...state,
            program: action.payload,
            programType: "All",
            drawingId: "All",
            filteredDrawings: state.allDrawings.filter(
              (d) => d.programId === action.payload
            ),
          };
    }
    case "programType": {
      return action.payload === "All"
        ? {
            ...state,
            programType: action.payload,
            filteredDrawings: state.allDrawings,
          }
        : {
            ...state,
            programType: action.payload,
            program: "All",
            drawingId: "All",
            filteredDrawings: state.allDrawings.filter(
              (d) => d.inventoryTypeTitle === action.payload
            ),
          };
    }
    case "drawingId": {
      return action.payload === "All"
        ? {
            ...state,
            drawingId: action.payload,
            filteredDrawings: state.allDrawings,
          }
        : {
            ...state,
            drawingId: action.payload,
            programType: "All",
            program: "All",
            filteredDrawings: state.allDrawings.filter(
              (d) => d.opportunityDrawingId === action.payload
            ),
          };
    }
    default:
      return state;
  }
}

function getDrawingIdOptions(list = []) {
  let hash = new Set();
  return [{ label: "All", value: "All" }].concat(
    list
      .reduce((acc, curr) => {
        const { opportunityDrawingId } = curr;
        if (!hash.has(opportunityDrawingId)) {
          hash.add(opportunityDrawingId);
          acc.push({
            label: opportunityDrawingId,
            value: opportunityDrawingId,
          });
        }

        return acc;
      }, [])
      .sort((a, b) => b.value - a.value)
  );
}

function getProgramOptions(drawings = []) {
  let hash = new Set();
  return [{ label: "All", value: "All" }].concat(
    drawings.reduce((acc, curr) => {
      const { programId, programName } = curr;
      if (!hash.has(programId)) {
        hash.add(programId);
        acc.push({ label: programName, value: programId });
      }

      return acc;
    }, [])
  );
}

function getProgramTypeOptions(drawings = []) {
  let hash = new Set();
  return [{ label: "All", value: "All" }].concat(
    drawings.reduce((acc, curr) => {
      const { inventoryTypeTitle } = curr;
      if (!hash.has(inventoryTypeTitle)) {
        hash.add(inventoryTypeTitle);
        acc.push({ label: inventoryTypeTitle, value: inventoryTypeTitle });
      }

      return acc;
    }, [])
  );
}
