import React from "react";
import TextField from "../../shared/form/TextField";
import Select from "../../shared/Select";
import DatePicker from "../../shared/form/DatePicker";
import AnnualIncome from "../../shared/form/AnnualIncome";

export default function SelfEmployedIncomeForm({ fields, handleChange }) {
  const {
    dateIncomeStarted,
    anticipatedIncomeEndDate,
    caseNum,
    caseDate,
    paymentFrequency = 1,
    annualIncome,
    payerName,
  } = fields;
  return (
    <form className=" mt-4 flex flex-col sm:grid grid-cols-2 sm:space-x-4 space-y-4">
      <DatePicker
        value={dateIncomeStarted}
        required
        label="Date Income Started"
        handleChange={(date) =>
          handleChange({ target: { name: "dateIncomeStarted", value: date } })
        }
      />

      <DatePicker
        value={anticipatedIncomeEndDate}
        required
        label="Anticipated End Date"
        handleChange={(date) =>
          handleChange({
            target: { name: "anticipatedIncomeEndDate", value: date },
          })
        }
      />

      <TextField
        name="caseNum"
        required
        label="Case Number"
        onChange={handleChange}
        value={caseNum}
      />

      <DatePicker
        value={caseDate}
        required
        label="Case Date"
        handleChange={(date) =>
          handleChange({
            target: { name: "caseDate", value: date },
          })
        }
      />

      <Select
        value={paymentFrequency}
        name="paymentFrequency"
        label="Payment  Frequency"
        onChange={handleChange}
        options={[
          { label: "Weekly", value: 1 },
          { label: "Bi-Weekly", value: 2 },
          { label: "Monthly Liability", value: 3 },
          { label: "Quarterly", value: 4 },
          { label: "Annually", value: 5 },
        ]}
      />
      <AnnualIncome value={annualIncome} handleChange={handleChange} />

      <TextField
        required
        name="payerName"
        value={payerName}
        label="Name of Payer"
        onChange={handleChange}
      />
    </form>
  );
}
