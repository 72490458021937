import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useCardStyles = makeStyles(() => ({
  root: {
    maxWidth: 345,
    height: 345,
  },
  typography: {
    fontSize: "14px",
    color: "#718096",
  },
  header: {
    backgroundColor: "#285b6c",
    color: "#fff",
  },
}));

export default useCardStyles;
