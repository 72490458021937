import React, { useCallback, useState, createContext, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Select from "../../../shared/Select";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import axios from "../../../../utils/axios";

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export function Questions({
  questions,
  handleQuestionSelection,
  selectedQuestions,
  children,
}) {
  console.log("questions", questions);
  const [questionConfig, setQuestionConfig] = useState(
    questions.reduce((acc, curr) => {
      const { question_id } = curr;
      acc[question_id] = {
        required: true,
        responseType: "",
        answerOptions: [],
        currentAnswer: "",
      };
      return acc;
    }, {})
  );

  console.log("questionConfig", questionConfig);
  if (questions.length < 1)
    return (
      <div className="flex py-4">
        <span className="text-gray-400 text-sm tracking-wide">
          No questions assigned currently.
        </span>
      </div>
    );

  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {children}
    </div>
  );

  function handleChange(e, question_id) {
    const { name, value } = e.target;

    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: { ...prev[question_id], [name]: value },
    }));
  }

  function addCurrentToOptions(question_id) {
    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: {
        ...prev[question_id],
        answerOptions: [
          ...prev[question_id]["answerOptions"],
          prev[question_id]["currentAnswer"],
        ],
        currentAnswer: "",
      },
    }));
  }

  function removeAnswerOption(elem, question_id) {
    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: {
        ...prev[question_id],
        answerOptions: prev[question_id]["answerOptions"].filter(
          (q, idx) => idx != elem
        ),
      },
    }));
  }
}

export function Question({
  q,
  children,
  isSelected,
  selectedQuestions = [],
  handleQuestionSelection,
  handleChange,
  questionConfig = {},
  answerOptions = [],
  showAnswerOptions,
  removeAnswerOption,
  addCurrentToOptions,
}) {
  return (
    <div
      key={q.question_id}
      className={`border rounded-sm shadow-sm border-gray-100 w-full flex ${
        isSelected ? " flex-col" : "flex-row items-center"
      }   p-2 py-6 text-xs `}
    >
      {children}
    </div>
  );
}

export function QuestionSummary({ children }) {
  return <div>{children}</div>;
}

export function QuestionTitle({ title }) {
  return (
    <div>
      <span>{title}</span>
    </div>
  );
}

export function QuestionDetail({
  addCurrentToOptions,
  handleChange,
  q,
  questionConfig,
  removeAnswerOption,
  answerOptions,
  showAnswerOptions,
}) {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <Select
          onChange={(e) => handleChange(e, q.question_id)}
          options={[
            { label: "Radio", value: "radio" },
            { label: "Checkbox", value: "cb" },
            { label: "Select", value: "select" },
            { label: "Text", value: "text" },
            { label: "Date", value: "date" },
          ]}
          label="Response Type"
          name="responseType"
          value={questionConfig[q.question_id]["responseType"]}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend" className="mb-4">
            Required
          </FormLabel>
          <RadioGroup
            row
            aria-label="required"
            name="required"
            value="yes"
            value={questionConfig[q.question_id]["required"]}
            onChange={(e) => handleChange(e, q.question_id)}
          >
            <FormControlLabel
              value="true"
              control={<Radio size="small" />}
              label="yes"
            />

            <FormControlLabel
              value="false"
              control={<Radio size="small" />}
              label="no"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {showAnswerOptions && (
        <section className="py-2 flex flex-col">
          <h3>Answers</h3>
          {answerOptions.map((a, idx) => (
            <form
              onSubmit={(e) => removeAnswerOption(idx, q.question_id)}
              className="flex mt-2 space-x-2 items-center"
            >
              <GreenCheckbox checked={true} onChange={() => {}} />
              <span>Answer #{idx + 1}</span>
              <span className="underline w-10 px-4">{a}</span>
              <Button
                type="submit"
                /* onClick={(e) => removeAnswerOption(idx, q.question_id)} */
              >
                <HighlightOffIcon fontSize="small" className="text-red-500" />
              </Button>
            </form>
          ))}
        </section>
      )}

      <FormControl className="">
        <InputLabel htmlFor="standard-adornment-password">Answer</InputLabel>
        <Input
          value={questionConfig[q.question_id]["currentAnswer"]}
          onChange={(e) => handleChange(e, q.question_id)}
          name="currentAnswer"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                fontSize="small"
                aria-label="add response"
                onClick={() => addCurrentToOptions(q.question_id)}
              >
                <AddCircleIcon className="text-hk-orange-300" />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

export function AltQuestions({ questions, formId, handleUpdate }) {
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  console.log("questions", questions);

  const [questionConfig, setQuestionConfig] = useState({});

  useEffect(() => {
    computeInitConfig();
  }, [questions.length]);

  function computeInitConfig() {
    setQuestionConfig(
      questions.reduce((acc, curr) => {
        const {
          question_id,
          answer_type = "radio",
          required = true,
          answer_options = [],
          question_type = "",
        } = curr;
        acc[question_id] = {
          required: required !== null ? required : true,
          answer_type: answer_type !== null ? answer_type : "radio",
          answer_options: answer_options !== null ? answer_options : [],
          question_type: question_type !== null ? question_type : "",
          currentAnswer: "",
        };
        return acc;
      }, {})
    );
  }

  console.log("questionConfig", questionConfig);

  function handleQuestionSelection(questionId) {
    if (selectedQuestions.includes(questionId)) {
      setSelectedQuestions((prev) => prev.filter((s) => s !== questionId));
    } else {
      setSelectedQuestions((prev) => [...prev, questionId]);
    }
  }

  if (questions.length < 1)
    return (
      <div className="flex py-4">
        <span className="text-gray-400 text-sm tracking-wide">
          No questions assigned currently.
        </span>
      </div>
    );

  return (
    <div className="flex flex-col items-start py-4 border border-gray-100 px-4 space-y-2">
      {questions.map((q) => {
        const answerOptions =
          questionConfig?.[q.question_id]?.["answer_options"] ?? [];
        const showAnswerOptions =
          (questionConfig?.[q.question_id]?.["answer_type"] === "radio" ||
            questionConfig?.[q.question_id]?.["answer_type"] === "cb") ??
          false;

        return (
          <div
            key={q.question_id}
            className="border rounded-sm shadow-sm border-gray-100 w-full flex flex-col  p-2 py-6 text-xs"
          >
            <div className="mr-auto">
              <IconButton>
                <EditIcon />
              </IconButton>
              <span>{q.question}</span>
            </div>
            <form className="flex flex-col">
              <div className="flex flex-col sm:flex-row justify-between">
                <Select
                  onChange={(e) => handleChange(e, q.question_id)}
                  options={[
                    { label: "Radio", value: "radio" },
                    { label: "Checkbox", value: "cb" },
                    { label: "Select", value: "select" },
                    { label: "Text", value: "text" },
                    { label: "Date", value: "date" },
                  ]}
                  label="Response Type"
                  name="answer_type"
                  value={
                    questionConfig?.[q.question_id]?.["answer_type"] || "radio"
                  }
                />
                <Select
                  onChange={(e) => handleChange(e, q.question_id)}
                  options={[
                    { label: "Eligibility", value: "E" },
                    { label: "Qualification", value: "Q" },
                    { label: "General", value: "G" },
                  ]}
                  label="Type"
                  name="question_type"
                  value={
                    questionConfig?.[q.question_id]?.["question_type"] || "E"
                  }
                />
                <FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-4">
                    Required
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="required"
                    name="required"
                    value={String(
                      questionConfig?.[q.question_id]?.["required"]
                    )}
                    onChange={(e) => handleChange(e, q.question_id)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio size="small" />}
                      label="yes"
                    />

                    <FormControlLabel
                      value="false"
                      control={<Radio size="small" />}
                      label="no"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {showAnswerOptions && (
                <section className="py-4 flex flex-col">
                  <h3 className="self-start py-2">Answers</h3>
                  {answerOptions.map((a, idx) => {
                    const [label, isCorrect, id] = a;
                    console.log(`idx ${idx} isCorrect ${isCorrect}`);

                    return label !== null ? (
                      <section
                        key={id || idx}
                        className="flex mt-2 space-x-2 items-center"
                      >
                        <Button
                          size="small"
                          onClick={() =>
                            markAsCorrect(q.question_id, idx, isCorrect)
                          }
                          variant="contained"
                          style={{
                            backgroundColor: isCorrect ? "#4299e1" : "#cbd5e0",
                            color: isCorrect ? "#fff" : "#22543d",
                          }}
                        >
                          Correct
                        </Button>

                        <span className="underline w-10 px-4">{label}</span>
                        <Button
                          onClick={(e) =>
                            removeAnswerOption({
                              index: idx,
                              questionId: q.question_id,
                              optionId: id,
                            })
                          }
                        >
                          <HighlightOffIcon
                            fontSize="small"
                            className="text-red-500"
                          />
                        </Button>
                      </section>
                    ) : null;
                  })}
                </section>
              )}
              {showAnswerOptions && (
                <FormControl className="">
                  <InputLabel htmlFor="standard-adornment-password">
                    Answer
                  </InputLabel>
                  <Input
                    value={questionConfig?.[q.question_id]?.["currentAnswer"]}
                    onChange={(e) => handleChange(e, q.question_id)}
                    name="currentAnswer"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          fontSize="small"
                          aria-label="add response"
                          onClick={() => addCurrentToOptions(q.question_id)}
                        >
                          <AddCircleIcon className="text-hk-orange-300" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              )}
              <div className="flex mt-2">
                <Button
                  size="small"
                  onClick={() =>
                    handleUpdate({
                      ...questionConfig[q.question_id],
                      question_id: q.question_id,
                    })
                  }
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        );
      })}
    </div>
  );

  function handleChange(e, question_id) {
    const { name, value } = e.target;

    setQuestionConfig((prev) => {
      return {
        ...prev,
        [question_id]: { ...prev[question_id], [name]: value },
      };
    });
  }

  function addCurrentToOptions(question_id) {
    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: {
        ...prev[question_id],
        answer_options: [
          ...prev[question_id]["answer_options"],
          [prev[question_id]["currentAnswer"]],
        ],
        currentAnswer: "",
      },
    }));
  }

  function markAsCorrect(question_id, index, update) {
    setQuestionConfig((prev) => ({
      ...prev,
      [question_id]: {
        ...prev[question_id],
        answer_options: prev[question_id]["answer_options"].map((a, i) => {
          if (i === index) {
            let entry = [...a];
            entry[1] = !update;
            return entry;
          } else {
            return a;
          }
        }),
      },
    }));
  }
  function removeAnswerOption(option) {
    const { index, questionId, optionId } = option;
    console.log("removeAnswerOption option", option);
    axios.delete(
      `${getBaseUrl()}/forms/${formId}/questions/${questionId}/options/${optionId}`
    );
    setQuestionConfig((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        answer_options: prev[questionId]["answer_options"].filter(
          (q, idx) => idx != index
        ),
      },
    }));
  }
}
