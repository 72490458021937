import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useProgramOpportunities(programId) {
  const [opps, setOpps] = useState([]);

  useEffect(() => {
    if (!programId) return;
    (async () => {
      const {
        data: { data: opps },
      } = await axios.get(
        `${getBaseUrl()}/programs/${programId}/opportunities`
      );
      setOpps(opps);
    })();
  }, [programId]);

  return opps;
}
