import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Breadcrumbs, Link as MatLink } from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityIcon from "@material-ui/icons/Visibility";
import axios from "../../../../utils/axios";

import { formatDate } from "../../../../utils/formatters";

import MaterialUITable from "../../../shared/Table/MaterialUITable";
import Select from "../../../shared/Select";
import RankingTimeline from "../ranking/RankingTimeline";
import { getBaseUrl } from "../../../../utils/queryHelpers";

function RankingsList(props) {
  const [rankings, setRankings] = useState([]);
  const [isLoadingRecords, setLoading] = useState(false);

  const [rankingStatus, setRankingStatus] = useState("A");
  const history = useHistory();

  const { url } = useRouteMatch();

  async function fetchRankings() {
    setLoading(true);
    const {
      data: { data: rankings },
    } = await axios.get(`${getBaseUrl()}/rankings/`);
    setLoading(false);
    setRankings(rankings);
  }

  useEffect(() => {
    fetchRankings();
  }, []);

  const handleChange = (event) => {
    setRankingStatus(event.target.value);
  };

  const tableColumns = [
    {
      title: "Index",
      field: "LotteryRankingSmryID",
    },
    {
      title: "Drawing Id",
      field: "LotteryScheduleID",
    },
    {
      title: "Provider",
      field: "EntityName",
    },
    {
      title: "Program",
      field: "ProgramTitle",
    },
    {
      title: "Status",
      field: "RecordStatus",
      render: (rowData) =>
        rowData.RecordStatus === "A" ? "Active" : "InActive",
    },

    {
      title: "Created",
      field: "DateCreated",
      render: (rowData) => formatDate(rowData.DateCreated),
    },
    {
      title: "Last Action",
      field: "LotteryStatusDesc",
    },
    {
      title: "# Applicants",
      field: "NumApplicants",
    },
  ];

  const filteredRankings =
    rankingStatus === "All"
      ? rankings
      : rankings.filter((r) => r.RecordStatus === rankingStatus);

  return (
    <div className="min-h-screen p-2">
      <Breadcrumbs className="p-2 mt-4" aria-label="breadcrumb">
        <MatLink color="inherit">Rankings</MatLink>
      </Breadcrumbs>

      <div className="mt-4 flex justify-end p-2 items-end w-full flex-wrap">
        <section className="filters mt-4 p-2 border-1 border-gray-300 flex flex-col shadow w-full sm:w-1/4 sm:h-56 ">
          <h6 className="text-gray-700 font-semibold oblique">Filters</h6>
          <Select
            options={[
              { label: "Active", value: "A" },
              { label: "InActive", value: "I" },
              { label: "All", value: "All" },
            ]}
            onChange={handleChange}
            value={rankingStatus}
          />
        </section>
      </div>

      <div className="mt-4">
        <MaterialUITable
          isLoading={isLoadingRecords}
          columns={tableColumns}
          data={filteredRankings}
          title={`Rankings (${filteredRankings.length})`}
          options={{
            exportButton: true,
            pageSize: 10,
            grouping: true,
            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: ArrowForwardIosIcon,
              tooltip: "Process this record",
              onClick: (event, rowData) => {
                history.push(
                  `${url}/${rowData.LotteryRankingSmryID}?drawingId=${rowData.LotteryScheduleID}`
                );
              },
              disabled: rowData.RecordStatus !== "A",
            }),
            (rowData) => ({
              icon: FileCopyIcon,
              tooltip: "Clone this Ranking",
              onClick: cloneRanking,
            }),
            (rowData) => ({
              icon: VisibilityIcon,
              tooltip: "Review",
              onClick: reviewRanking,
            }),
          ]}
          detailPanel={(rowData) => {
            return <RankingTimeline rankingId={rowData.LotteryRankingSmryID} />;
          }}
        />
      </div>
    </div>
  );

  async function cloneRanking(e, rowData) {
    setLoading(true);
    const {
      data: { data: ranking },
    } = await axios
      .post(`${getBaseUrl()}/rankings/${rowData.LotteryRankingSmryID}/clone`, {
        drawingId: rowData.LotteryScheduleID,
      })
      .catch((err) => {
        setLoading(false);
      });
    setLoading(false);

    if (ranking?.LotteryRankingSmryID) {
      history.push(
        `${url}/${ranking.LotteryRankingSmryID}?drawingId=${rowData.LotteryScheduleID}`
      );
    }

    //setRankings(rankings);
  }

  async function reviewRanking(e, rowData) {
    history.push(
      `${url}/${rowData.LotteryRankingSmryID}/review?drawingId=${rowData.LotteryScheduleID}&programId=${rowData.ProgramID}`
    );

    //setRankings(rankings);
  }
}

//function Stepper(activeStep) {}
export default RankingsList;
