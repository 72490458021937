const { REACT_APP_API_URL } = process.env;

export function paramify(url, params) {
  const urlParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value) acc.push(`${key}=${value}`);
    return acc;
  }, []);
  return `${url}?${urlParams.join("&")}`;
}

export function pluck(obj, fields) {
  return fields.reduce((acc, curr) => {
    if (obj[curr]) {
      acc[curr] = obj[curr];
    }
    return acc;
  }, {});
}

export function getBaseUrl() {
  return REACT_APP_API_URL;
}
