import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PopperMsg from "../../shared/misc/PopperMsg";
import LoadingBackDrop from "../../shared/misc/LoadingBackDrop";
import { getBaseUrl } from "../../../utils/queryHelpers";
import axios from "../../../utils/axios";

export default function DrawingOptOut() {
  const [drawingProfile, setDrawingProfile] = useState({});
  const [optOutReason, setOptOutReason] = useState("");
  const [allPartiesAgree, setAllPartiesAgree] = useState(false);
  const [optOutFlag, setOptOutFlag] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showFailuresMsg, setShowFailureMsg] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const location = useLocation();
  const { drawingId } = useParams();
  const history = useHistory();

  const { hhldProgramId, agency, program, drawingDesc } = location.state;

  const submitAllowed = optOutReason != "" && optOutFlag && allPartiesAgree;

  useEffect(() => {
    fetchDrawingProfile();
  }, []);

  const updateOptOutReason = (e) => {
    setOptOutReason(e.target.value);
  };

  const updateAllPartiesAgree = (e) => {
    setAllPartiesAgree(e.target.checked);
  };

  const updateOptOutFlag = (e) => {
    setOptOutFlag(e.target.checked);
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <section className="min-h-screen p-8 sm:p-16">
      <h3 className="text-xl uppercase bg-hk-orange-400 p-4 text-white mt-4">
        Drawing Opt out Request Form ({drawingId})
      </h3>
      <hr />

      <div className="flex flex-col pt-8 sm:w-3/5 ml-auto mr-auto">
        <span className="text-gray-700">{program}</span>
        <span className="text-gray-500 py-2">{agency}</span>
        <p
          dangerouslySetInnerHTML={{ __html: drawingDesc }}
          className=" mt-4 p-4 bg-hk-orange-100 text-hk-orange-400 leading-loose text-left text-xs"
        />
      </div>

      <div className="mt-8 text-red-500 text-sm flex justify-center">
        <span className="text-left">
          Please read and review all the information on this page before you
          fill it out.
        </span>
      </div>

      <ol className="mt-6 flex gap-4  flex-col text-left py-4  px-8  bg-gray-100 text-gray-600   rounded  w-full  items-start sm:w-1/2 ml-auto mr-auto text-xs">
        <li className="border-l-2 border-gray-300  transition duration-500 ease-in-out hover:border-gray-500 p-2 text-xs tracking-wide leading-relaxed">
          Verify that the Opportunity Drawing selected {drawingId} applies to
          this request.
        </li>
        <li className="border-l-2 border-gray-300 transition duration-500 ease-in-out hover:border-gray-500 p-2 text-xs leading-relaxed ">
          Fill out this Opt Out Form (Withdraw Form) completely.
        </li>
        <li className="border-l-2 border-gray-300 transition duration-500 ease-in-out hover:border-gray-500 p-2 text-xs leading-relaxed tracking-wide">
          Submit Form in agreement with all the applicants in your household.
        </li>
        <li className="border-l-2 border-gray-300 transition duration-500 ease-in-out hover:border-gray-500 p-2  text-xs leading-relaxed tracking-wide ">
          Opt Out Forms submissins are{" "}
          <strong className="text-red-500 font-semibold">FINAL</strong> - as
          soon as you click "SUBMIT" at the bottom of this form, you will not be
          considered for the Drawing you indicated.
        </li>
        <li className="border-l-2 border-gray-300 transition duration-500 ease-in-out hover:border-gray-500 hover:text-gray-400 p-2  text-xs leading-relaxed tracking-wide">
          Upon successful submission, you can enter a different drawing.
        </li>
      </ol>

      <form className="mt-8 sm:px-56 flex flex-col gap-2" onSubmit={optOut}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={optOutFlag}
                onChange={updateOptOutFlag}
                name="checkedA"
              />
            }
            label={
              <div className="text-xs -ml-4 border-l sm:flex pt-2 items-baseline justify-center pt-4 border-gray-400 px-2">
                <span>Please confirm your household wants to</span>{" "}
                <span className="text-red-600 font-semibold ml-1">
                  OPT OUT - Withdraw
                </span>
                <span className="ml-2">from the Drawing you indicated.</span>
                <span className="text-red-600 text-xs ml-2 font-semibold">
                  (The Opt Out is final)
                </span>
              </div>
            }
            labelPlacement="start"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={allPartiesAgree}
                onChange={updateAllPartiesAgree}
                name="checkedA"
              />
            }
            label={
              <div className="-ml-4 border-l border-gray-500 mt-4  text-xs px-2">
                <span>
                  Are all Applicants and Co-Applicants in agreement with the
                  information entered on this Opt Out Form.
                </span>{" "}
                <span className="text-red-600 ml-2 text-xs font-semibold">
                  (Note: All must be in agreement before submitting this form)
                </span>
              </div>
            }
            labelPlacement="start"
          />
        </FormGroup>

        <TextField
          multiline
          required
          style={{ marginTop: "2rem", maxWidth: "30rem" }}
          size="small"
          variant="outlined"
          label="Why did you decide to withdraw?"
          onChange={updateOptOutReason}
          value={optOutReason}
        />

        <section className="mt-6">
          <p className="text-sm leading-relaxed border-b border-gray-400 pb-4 text-left">
            By submitting this form, you are certifying that you've read,
            understand and agree with all the information presented on this form
            and that the information entered on this form is true and accurate,
            I/we certify this under penalty of perjury.
          </p>
          <div className="text-xs leading-relaxed text-gray-500 break-words flex flex-col gap-4 text-left h-64 sm:h-full overflow-y-scroll sm:overflow-y-none">
            <p className="mt-4">
              A. I/we understand that once I/we submit this Opt Out (withdraw
              form), my/our interest, my/our Opportunity Drawing Submission Form
              and Priority Number are null and void.
            </p>
            <p>
              B. I/we understand that if I/we are interested in future
              opportunities, I/we must enter the drawing.
            </p>
            <p>
              C. I/we understand that its my/our responsibility to keep checking
              the website for updated information, instructions, opportunities
              and deadlines.
            </p>
            <p>
              D. I/we understand that by submitting this form, all applicants in
              the household are in agreement with the information entered in
              this form.
            </p>
            <p>
              E. I/we understand that if I/we give inaccurate information,
              submit altered documentation or submit false certifications or if
              one of our household members gives inaccurate information, submits
              altered documentation or submits false certifications, I/We and
              our household will be disqualified from the BMR Program and from
              participating in future BMR opportunites. I/We understand that if
              this happens, I/we will be in violation of the BMR Program and
              Deed Restrictions. There are consequences to this fraud, even if
              the discrepancy is found after I/we have (if found eligible) moved
              in the BMR home or are approved for the BMR Program.
            </p>
            <p>
              F. I/we understand that HouseKeys, the BMR Administrator can't
              hold or reserve properties for applicants that are in the middle
              of the application process, suspended, denied or in the appeal
              process. HouseKeys will immediately move on to the next applicant.
            </p>
            <p>
              G. I/we understand that if any portion of this form is left blank,
              the opt out form will still be processed and my/our priority
              number and/or runner up number will be null and void for all the
              properties associated with the drawing/lottery indicated on this
              form.
            </p>
            <p>
              H. I/we understand that we can't have more than one priority
              number at a time. I/we must fill out the form completely.
            </p>
            <p>
              I. In order to improve the BMR Program, I/we authorize HousekKeys
              to share this information with the City.
            </p>
            <p>
              J. I/we understand that if I/we sbmitted an email to HouseKeys to
              notify them that I/we were no longer interested in a specific
              property, that notice is valid even if I don't submit this form.
            </p>
            <p>
              K. I/we understand that my priority number or runner up number are
              not transferable between Drawings/Lotteries/Opportunities.
            </p>
            <p>
              L. Opt Out Forms submission are{" "}
              <span className="text-red-600 font-semibold">FINAL</span> - as
              soon as you click "SUBMIT" at the bottom of this form, you will
              not be considered for the Drawing you indicated.
            </p>
          </div>
        </section>
        <div className="flex gap-2 justify-end mt-8">
          <Button size="small" variant="contained" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            onClick={() => setShowWarning(true)}
            disabled={!submitAllowed}
            size="small"
            color="secondary"
            variant="contained"
          >
            OPT OUT
          </Button>
        </div>
      </form>

      <PopperMsg
        open={showSuccessMsg}
        handleClose={() => {
          setShowSuccessMsg(false);
          history.goBack();
        }}
      >
        <p>
          <span>{`Your request to opt of this drawing has been processed.`}</span>
        </p>
      </PopperMsg>

      <PopperMsg
        open={showFailuresMsg}
        msgType="alert"
        handleClose={() => {
          setShowFailureMsg(false);
          // history.goBack();
        }}
      >
        <p>
          <span>{`Your request to opt of this drawing could not be processed at this time.`}</span>
        </p>
      </PopperMsg>
      <Dialog open={showWarning}>
        <DialogContent>
          Are you sure you want to opt out? Opt outs are final!!
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWarning(false)}>Cancel</Button>
          <Button onClick={optOut}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <LoadingBackDrop open={processing} />
    </section>
  );

  async function fetchDrawingProfile() {
    const {
      data: { data: drawingProfile },
    } = await axios.get(
      `${getBaseUrl()}/user/drawing_profile?hhldProgramId=${hhldProgramId}`
    );
    setDrawingProfile(drawingProfile);
  }

  async function optOut(e) {
    e.preventDefault();
    setShowWarning(false);
    setProcessing(true);

    const {
      data: { data: opt },
    } = await axios
      .post(`${getBaseUrl()}/drawings/${drawingId}/opt-out`, {
        hhldProgramId,
        optOutFlag: optOutFlag === true ? 1 : 0,
        optOutReason,
        allPartiesAgree: allPartiesAgree == true ? 1 : 0,
      })
      .catch((err) => {
        setShowFailureMsg(true);
        setProcessing(false);
      });
    setProcessing(false);
    if (opt?.[0]?.EntityName) {
      setShowSuccessMsg(true);
      history.goBack();
    }

    setDrawingProfile(drawingProfile);
  }
}
