import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import UserContext from "../UserContext";

export default function UserSetup() {
  const { user } = useContext(UserContext) || {};
  const history = useHistory();
  const { path, url } = useRouteMatch();

  return (
    <section className="mt-4 min-h-screen">
      <h5 className="text-gray-800">{`Welcome, ${user.firstName}`}</h5>
      <p className="text-gray-600">
        Tell us a little more about yourself so that we can help you better
      </p>
      <p className="text-center">I am a</p>
      <div className="p-2 grid gap-1 grid-cols-3  justify-center row-wrap">
        <button
          onClick={() => history.push(`${path}/${user.userId}/hhld`)}
          className="p4 bg-blue-300 h-64 self-end"
        >
          Household Applicant
        </button>
        <button className="p4 bg-blue-300 h-64 ">City Agent</button>
        <button className="p4 bg-blue-300 h-64">Builder Agent</button>
      </div>
    </section>
  );
}
