import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";

export default function AnnualIncome({
  name,
  label,
  handleChange,
  value,
  ...other
}) {
  const fieldLabel = label || "Annual Income";
  const fieldName = name || "annualIncome";
  return (
    <TextField
      required
      value={value}
      type="text"
      placeholder="numbers only"
      name={fieldName}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      label={fieldLabel}
      onChange={(e) => {
        //workaround for when users enter non numeric characters
        //https://github.com/facebook/react/issues/13752
        const { value: newVal } = e.target;
        const val = /^\d+$/.test(newVal) || newVal === "" ? newVal : value;
        handleChange({
          target: {
            name: fieldName,
            value: val,
          },
        });
      }}
      inputMode="numeric"
      pattern="[0-9]*"
      InputLabelProps={{ shrink: true }}
      {...other}
    />
  );
}
