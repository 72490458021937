import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#eb7e3c",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#eb7e3c",
    },
  },
  disabled: {
    backgroundColor: "#FFE7D9",
    color: "#fff",
  },
}))((props) => <Button {...props} />);

export default function PrimaryButton({ label, children, ...other }) {
  return (
    <StyledButton size="small" variant="contained" {...other}>
      {children}
    </StyledButton>
  );
}
