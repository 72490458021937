import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import Loading from "../shared/misc/Loading";


export default function EventDetail({ userId, error }) {
    const [details, setDetails] = useState({})
    const [loadingDetails, setLoadingDetails] = useState(true);

    useEffect(() => {
        (async () => {
            setLoadingDetails(true);
            const {
                data: { data: details = [] },
            } = await axios.get(`${getBaseUrl()}/track/user_detail/${userId}`);
            setDetails(details);
            setLoadingDetails(false);
        })();
    }, []);

    if (loadingDetails) return <Loading />;

    return (
        <section className="p-1">
            <div className="grid grid-cols-6 gap-1 text-xs rounded-sm bg-hk-green p-2 text-white">
                <span>Profile Type</span>
                <span>Address</span>
                <span>City</span>
                <span>State</span>
                <span>Zip Code</span>
                <span>Member Since</span>
            </div>
            {details.map((detail, index) => {
                return (
                    <div className="grid grid-cols-6 gap-1 text-blue-600 bg-blue-100 p-4 font-md mt-1 text-xs ">
                        <span>{detail.profileType}</span>
                        <span>{detail.address1}</span>
                        <span>{detail.city}</span>
                        <span>{detail.state}</span>
                        <span>{detail.zip}</span>
                        <span>{detail.created}</span>
                    </div>
                )
            })}
        </section>
    )
}
