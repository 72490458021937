import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { CircularProgress, Collapse, IconButton } from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import { DrawingCard } from "./HhldOpportunityDrawings";
import UserContext from "../user/UserContext";
import OpportunityCard from "../../opportunity/OpportunityCard";
import { getApplicantAccepted } from "../../queries/hhld/useHhldDrawings";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export function HhldMarketplace() {
  const [drawings, setDrawings] = useState([]);
  const [opps, setOpps] = useState([]);
  const [loadingDrawings, setLoadingDrawings] = useState(false);
  const [loadingOpps, setLoadingOpps] = useState(false);
  const { user } = useContext(UserContext) || {};
  const [drawingsExpanded, setDrawingsExpanded] = useState(true);
  const [oppsExpanded, setOppsExpanded] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    fetchDrawings();
    fetchOpps();
  }, []);

  return (
    <section className="min-h-screen ">
      <h1>Marketplace</h1>
      <section className="mt-6 min-h-64">
        <div className="flex items-center space-x-2 px-10">
          <h2>Drawings ({drawings.length})</h2>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: drawingsExpanded,
            })}
            onClick={handleDrawingExpandClick}
            aria-expanded={drawingsExpanded}
            aria-label="show more drawings"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        {loadingDrawings && <CircularProgress />}

        <Collapse in={drawingsExpanded} timeout="auto" unmountOnExit>
          <div className="flex flex-row flex-wrap justify-around">
            {drawings.map((drawing) => (
              <DrawingCard
                key={drawing.opportunityDrawingId}
                drawing={drawing}
                user={user}
              />
            ))}
          </div>
        </Collapse>
      </section>

      <section className="mt-4 min-64">
        <div className="flex items-center space-x-2 px-10">
          <h2>Opportunities ({opps.length})</h2>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: oppsExpanded,
            })}
            onClick={handleOppsExpandClick}
            aria-expanded={oppsExpanded}
            aria-label="show more opportunities"
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        {loadingOpps && <CircularProgress />}
        <Collapse in={oppsExpanded} timeout="auto" unmountOnExit>
          <div className="flex flex-wrap justify-around">
            {opps.map((o) => (
              <OpportunityCard
                key={o.ID}
                opp={o}
                handleFav={(favOpp) => {}}
                user={user}
              />
            ))}
          </div>
        </Collapse>
      </section>
    </section>
  );

  async function fetchDrawings() {
    setLoadingDrawings(true);
    const {
      data: { data: drawings },
      data: { drawingsEntered },
      data: { programIdOfDrawingsEntered },
    } = await axios.get(`${getBaseUrl()}/drawings/mktplace`);
    setDrawings(
      mergeDrawings(drawings, drawingsEntered)?.map((d) => ({
        ...d,
        applicantsAccepted: getApplicantAccepted(
          d.drawingStatus,
          d.applicantsAcceptedR,
          d.programId,
          programIdOfDrawingsEntered
        ),
      }))
    );
    setLoadingDrawings(false);
  }

  async function fetchOpps() {
    setLoadingOpps(true);
    const {
      data: { data: opps = [] },
    } = await axios.get(`${getBaseUrl()}/opportunities?rowCnt=500&page=1`);
    setOpps(opps);
    setLoadingOpps(false);
  }

  function handleDrawingExpandClick() {
    setDrawingsExpanded(!drawingsExpanded);
  }

  function handleOppsExpandClick() {
    setOppsExpanded(!oppsExpanded);
  }
}

function mergeDrawings(allDrawings = [], myDrawings = []) {
  return allDrawings
    .map((a) => {
      const match = myDrawings.find(
        (d) =>
          d.lotteryScheduleId == a.opportunityDrawingId && d.optOutFlag != 1
      );
      const optedOut = myDrawings.find(
        (d) =>
          d.lotteryScheduleId == a.opportunityDrawingId && d.optOutFlag == 1
      );
      return {
        ...a,
        ...(match && { entryNo: match.entryNo }),
        ...(optedOut && { optedOut: true }),
      };
    })
    .sort((a, b) => {
      if (a.entryNo != null) return -1;
      return 0;
    });
}
