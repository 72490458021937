import React from "react";
import { renderFormField } from "../../../utils/hhldUtils";

export default function Questions({
  questions,
  type,
  dispatch,
  fields,
  disabled,
}) {
  return (
    <section className="p-6 sm:p-16">
      {questions.map((e) => {
        return (
          <div key={`${type}_${e.question_id}`}>
            {renderFormField(e, {
              keyId: "question_id",
              dispatch,
              type,
              fields,
              disabled,
            })}
          </div>
        );
      })}
    </section>
  );
}
