import React from "react";
import SkeletonWrapper from "../../shared/SkeletonWrapper";
import { Typography } from "@material-ui/core";
import useCardStyles from "./cardStyles";

export default function CardItem({ skeletonCond, label, value }) {
  const classes = useCardStyles();

  return (
    <div className="flex items-center w-full">
      <span className="text-gray-800  w-1/2">{label} </span>
      <SkeletonWrapper w={35} h={18} condition={skeletonCond}>
        <Typography className={classes.typography}>{value}</Typography>
      </SkeletonWrapper>
    </div>
  );
}
