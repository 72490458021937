import React from "react";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useFormQuestions } from "../../../../queries/forms/questions/useFormQuestions";
import { useFormDetail } from "../../../../queries/forms/useFormDetail";
import { useProgramDetail } from "../../../../queries/programs/useProgramDetail";
import {
  Questions,
  Question,
  QuestionSummary,
  AltQuestions,
} from "../../program/config/Questions";
import axios from "../../../../utils/axios";
import { getBaseUrl } from "../../../../utils/queryHelpers";

export function ProgramForm() {
  const { programId, formId } = useParams();

  const [questions] = useFormQuestions(formId, programId);
  const form = useFormDetail(formId);
  const program = useProgramDetail(programId);

  return (
    <section className="min-h-screen max-w-6xl ml-auto mr-auto">
      <h3 className="flex flex-col space-y-2 py-4">
        <div>
          <span className="uppercase">{form.title}</span>
        </div>
        <span className="px-2 text-gray-700">{program.programName}</span>
      </h3>
      <div className="text-left py-4 text-gray-700">Questions</div>
      <AltQuestions
        questions={questions}
        handleUpdate={handleUpdate}
        formId={formId}
      />
      {/* <Questions questions={questions} selectedQuestions={[]}>
        {questions.map((q) => {
          return (
            <Question q={q} key={q.question_id}>
              <QuestionSummary allowSelection>
                <span>{q.question}</span>
              </QuestionSummary>
            </Question>
          );
        })}
      </Questions> */}
      <Button size="small">Add Questions</Button>
    </section>
  );

  async function handleUpdate(updates) {
    console.log("updates", updates);
    const { currentAnswer, ...rest } = updates;
    const {
      data: { data: updated },
    } = await axios.put(
      `${getBaseUrl()}/forms/${formId}/questions?programId=${programId}`,
      rest
    );

    console.log("updated", updated);
  }
}
