import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Button, Step, Stepper, StepLabel } from "@material-ui/core";
import { formatAsUSCurrency } from "../../../utils/formatters";

const theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        fontSize: "0.8rem",
      },
    },

    MuiFormControlLabel: {
      label: {
        fontSize: "0.8rem",
        textAlign: "left",
        placeItems: "self-start",
      },
    },

    MuiCheckbox: {
      root: {
        placeItems: "self-end",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function HouseholdOnboardingStepper({
  getStepContent,
  handleFinish,
  isNextDisabled,
  submitting,
  submitSuccessful,
  fields,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div className="w-full">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ThemeProvider theme={theme}>
        <form className="sm:px-52">
          {submitSuccessful && (
            <div className=" p-4  leading-loose flex flex-col mt-8 items-start">
              <span className="text-lg">
                You've completed your initial registration for your MyHouseKeys
                Account.
              </span>
              <div className="flex flex-col items-start mt-4 space-y-2">
                <p className="text-sm">
                  You indicated the following when you signed up:
                </p>
                <div className="flex space-x-2 mt-2 text-sm">
                  <span className="text-gray-500">
                    Total Household Members:{" "}
                  </span>
                  <span className="text-gray-800">{fields.numBuyers}</span>
                </div>
                <div className="flex space-x-2 text-sm">
                  <span className="text-gray-500 ">
                    Total Household Income:
                  </span>
                  <span className="text-gray-800">
                    {formatAsUSCurrency(fields.totalIncome)}
                  </span>
                </div>

                <div className="flex space-x-2 text-sm">
                  <span className="text-gray-500">
                    {" "}
                    Total Monthly Housing Payment:
                  </span>
                  <span className="text-gray-800">
                    {formatAsUSCurrency(fields.totalHousingPmt)}
                  </span>
                </div>
              </div>
              <p className="text-left mt-4">
                The next screen will take you to the Information Profiles where
                you will be asked to add the remaining members of your household
                and create Income Profiles for each of your income sources.
                <Link
                  className="underline font-semibold text-lg"
                  to="/hhld/profiles"
                >
                  Go to Information profiles screen
                </Link>{" "}
              </p>
            </div>
          )}
          {activeStep === steps.length ? (
            <div></div>
          ) : (
            <div className="min-h-screen">
              <div className="p-4">{getStepContent(activeStep)}</div>
              <div className="flex justify-end mt-auto items-end pt-8">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>

                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      handleFinish(e);
                      handleNext();
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isNextDisabled}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          )}
        </form>
      </ThemeProvider>
    </div>
  );
}

function getSteps() {
  return ["Personal Information", "Preferences"];
}
