import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import { formatAsUSCurrency, formatDate } from "../../../utils/formatters";
import UserContext from "../../user/UserContext";
import { combined } from "../../../utils/hhldUtils";
import { useHhldRegSummary } from "../../../queries/hhld/useHhldRegSummary";
import { useHhldMembers } from "../../../queries/hhld/useHhldMembers";
import { useHhldIncomes } from "../../../queries/hhld/useHhldIncomes";

export default function HHLDRegSummary({ user }) {
  const { user: ctxUser } = useContext(UserContext) || {};
  const history = useHistory();
  const { userId, memberId, firstName, lastName } = user || ctxUser;

  const regSummary = useHhldRegSummary(userId);
  const members = useHhldMembers(userId);
  const incomes = useHhldIncomes(userId, memberId);

  const { amiPct, county, amiLevel, totalIncome = 0 } = regSummary;

  const mergedProfile = combined(incomes, members);

  return (
    <div className="flex flex-col items-start bg-white py-4 px-8 space-y-2 mb-6 shadow-md rounded">
      <div
        onClick={() => history.push("/hhld/profiles")}
        className="flex uppercase space-x-2 text-lg tracking-wider text-hk-orange-400  hover:underline cursor-pointer"
      >
        <span>{firstName}</span>
        <span>{lastName}</span>
      </div>
      {mergedProfile.map((m) => (
        <HhldMember key={m?.member?.MemberID} profile={m} />
      ))}

      <div className="flex items-baseline w-64">
        <span className="text-xs  text-gray-500 tracking-wide">
          # of Household Members
        </span>
        <span className="ml-auto">{members.length}</span>
      </div>

      <div className="flex items-baseline w-64">
        <span className="text-xs  text-gray-500 tracking-wide">
          Total Income
        </span>
        <span className="ml-auto">{formatAsUSCurrency(totalIncome)} </span>
      </div>
      <div className="pt-2">
        <LineItem label="AMI" value={amiPct} />
        <LineItem label="Income Category" value={amiLevel} />
        <LineItem label="County" value={county} />
      </div>
    </div>
  );
}

function HhldMember({ profile }) {
  const {
    member: { FirstName, LastName, Email, MobilePhone, DOB },
    incomes,
  } = profile;
  //extract date only and subtract the tz offset
  //https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
  const dt = new Date(DOB);
  const dobDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );

  return (
    <div className="flex flex-col pt-2  w-64">
      <div className="flex space-x-2 items-center">
        <AccountCircleIcon className="text-gray-400" />
        <span className="uppercase text-xs text-gray-700">
          {FirstName} {LastName}
        </span>
      </div>
      <span className="text-gray-500 self-start pl-8   text-sm">{Email} </span>
      <div className="flex items-baseline pl-8 space-x-2 mt-1 mb-2">
        <span className="text-xs text-gray-500 font-semibold">DOB</span>
        <span className="text-xs text-gray-700">{formatDate(dobDateOnly)}</span>
      </div>
      <div className="flex items-baseline pl-8 text-gray-500">
        <PhoneIcon fontSize="small" />
        <span className="text-gray-500 self-start pl-2   text-sm">
          {MobilePhone}{" "}
        </span>
      </div>

      {incomes.map((i) => (
        <div
          key={i.IncomeID}
          className="pt-2 flex gap-2 pl-8 text-sm text-gray-400"
        >
          <span className="">{i.CompanyName}</span>
          <span className="ml-auto  ">{formatAsUSCurrency(i.Income)}</span>
        </div>
      ))}
      <hr className="mt-1" />
    </div>
  );
}

function LineItem({ label, value }) {
  return (
    <div className="flex pt-2   items-baseline w-64 text-xs">
      <span className=" text-gray-500  tracking-wide">{label}</span>
      <span className="ml-auto text-gray-500 text-xs tracking-wide">
        {value}
      </span>
    </div>
  );
}
