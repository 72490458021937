import React, { useState } from "react";
import { Button, Divider, TextField, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

export default function ManualAdjuster({
  anchorEl,
  setAnchorEl,
  open,
  numRankings,
  handleManualAdjust,
}) {
  const [adjustedRank, setAdjustedRank] = React.useState(0);

  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    handleManualAdjust(adjustedRank);
    setAdjustedRank(0);
  };

  return (
    <Popover
      className={classes.root}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      onClose={handleClose}
    >
      <section className="p-4 flex flex-col justify-start">
        <form noValidate autoComplete="off" onSubmit={handleConfirm}>
          <TextField
            onChange={(e) => {
              setAdjustedRank(e.target.value);
            }}
            error={adjustedRank > numRankings || adjustedRank < 1}
            value={adjustedRank}
            helperText={`Enter a value between 1 and ${numRankings}`}
          />
          <Divider />
          <div className="flex justify-end -mb-3 mt-2">
            <Button size="small" onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="button"
              size="small"
              onClick={handleConfirm}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </div>
        </form>
      </section>
    </Popover>
  );
}
