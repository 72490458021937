import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useQuestions() {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchQuestions();
  }, []);

  async function fetchQuestions() {
    const {
      data: { data: questions },
    } = await axios.get(`${getBaseUrl()}/questions`);

    setQuestions(questions);
  }

  return [questions, fetchQuestions];
}
