export function isOdd(num: number): boolean {
  return num % 2 !== 0;
}

export function propIn(prop: any, obj = {}): boolean {
  return obj !== null ? obj.hasOwnProperty(prop) : false;
}

/**
 *
 * @param {*} list
 * @param {*} itemToMove
 * @param {*} to
 * given an array, and an element in the array to move to a pos (1 based)
 * returns a new array with the items shifted in the requested order
 */
export function moveElemTo(
  list: any[],
  to: number,
  finder: (a: any) => number
): any[] {
  //items before the target
  const before = list.slice(0, to - 1);
  const currAtPos = list[to - 1];

  const indexOfElementToMove = list.findIndex(finder);
  const elemToMove = list[indexOfElementToMove];

  //items after the target
  const after = list.slice(to);

  return [
    ...before.filter((elem) => elem !== elemToMove),
    ...(to <= indexOfElementToMove
      ? [elemToMove, currAtPos]
      : [currAtPos, elemToMove]),
    ...after.filter((elem) => elem !== elemToMove),
  ];
}

export function validateEmail(email: string): boolean {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function usStatesOptions(states: {
  abbr: string;
  full: string;
}): { label: string; value: string }[] {
  return Object.entries(states).map(([abbr, name]) => ({
    label: name,
    value: abbr,
  }));
}

/**
 * check if an object has any missing props, or any of the values , specified in the fields list, is empty
 * useful for validating when some required values are missing in a form
 * @param {*} obj
 * @param {*} fields
 */
export function hasEmptyFields(obj: any, fields: any[]): boolean {
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const fieldValue = obj[field];

    if (fieldValue == null) return true;

    if (fieldValue === "") return true;
  }

  return false;
}

export function lengthGreaterThanOrEqual(len: number) {
  return function(val: string): boolean {
    return val.length >= len;
  };
}

export function containsLowerCase(val: string): boolean {
  return val.toUpperCase() != val;
}

export function containsUpperCase(val: string): boolean {
  return val.toLowerCase() != val;
}

export function containsNumber(val: string): boolean {
  return /\d/.test(val);
}

export function hasSpecialChars(val: string): boolean {
  const reg = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return reg.test(val);
}

let lastId = 0;
export function newId(prefix = "id") {
  lastId++;
  return `${prefix}_${lastId}`;
}
