import React from "react";
import notFoundSVG from "../../../media/HK-assets/undraw_not_found_60pq.svg";

export default function NoMatch() {
  return (
    <section className="min-h-screen  flex-col flex justify-center items-center">
      <img alt="no match found" className="w-1/2 h-auto" src={notFoundSVG} />
      <h5 className="text-gray-700 mt-4">Page Not Found</h5>
    </section>
  );
}
