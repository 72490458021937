import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useFormDetail(formId) {
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    if (!formId) return;
    fetchForm();
  }, []);

  return detail;

  async function fetchForm() {
    const {
      data: { data: detail },
    } = await axios.get(`${getBaseUrl()}/forms/${formId}`);

    setDetail(detail);
  }
}
