import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import hkSpinner from "../../../media/HK-assets/hk_spinner.png";

export default function Loading({ containerHeight = "", size = 40 }) {
  return (
    <div className={`flex justify-center py-2 items-center ${containerHeight}`}>
      <CircularProgress size={size} />
    </div>
  );
}
