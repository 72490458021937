import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function usePreferences(refetch = false) {
  const [prefs, setPrefs] = useState([]);

  useEffect(() => {
    fetchPrefs();
  }, []);

  return [prefs, fetchPrefs];

  async function fetchPrefs() {
    const {
      data: { data: prefs },
    } = await axios.get(`${getBaseUrl()}/preferences`);

    setPrefs(prefs);
  }
}
