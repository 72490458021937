import React from "react";

export default function NotAuthorized() {
  return (
    <div className="min-h-screen flex justify-center items-center">
      <p className="text-lg font-bold text-gray-800">
        You are not authorized to view this page.{" "}
      </p>
    </div>
  );
}
