import React, { useState, useContext, useEffect } from "react";
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Popover,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import HomeIcon from "@material-ui/icons/Home";
import { getBaseUrl } from "../../utils/queryHelpers";
import axios from "../../utils/axios";
import { getDrawingDisabledReasons } from "../../utils/hhldUtils";
import { getDrawingType } from "../../utils/drawingUtils";
import clsx from "clsx";

import Select from "../shared/Select";
import UserContext from "../user/UserContext";

import { formatAsUSCurrency, formatDateAndTime } from "../../utils/formatters";
import useLocalStorage from "../shared/hooks/useLocalStorage";
import { useHhldDrawings } from "../../queries/hhld/useHhldDrawings";

export default function HhldOpportunityDrawings(props) {
  const { user } = useContext(UserContext) || {};

  const [
    {
      allDrawings,
      filteredDrawings,
      programType,
      program,
      drawingId,
      drawingIdOptions,
      programOptions,
      programTypeOptions,
    },
    dispatch,
  ] = useHhldDrawings(user.userId);

  function handleChange(e) {
    const { name, value } = e.target;
    dispatch({
      type: name,
      payload: value,
    });
  }

  return (
    <section className="min-h-screen ">
      <form className="flex items-baseline flex-col p-4 sm:flex-row sm:pl-30">
        <Select
          name="program"
          options={programOptions}
          label="Programs"
          onChange={handleChange}
          value={program}
        ></Select>
        <Select
          name="programType"
          options={programTypeOptions}
          label="Type"
          onChange={handleChange}
          value={programType}
        ></Select>
        <Select
          name="drawingId"
          options={drawingIdOptions}
          label="Drawing ID"
          onChange={handleChange}
          value={drawingId}
        ></Select>
      </form>
      <h5 className="flex justify-center items-center text-2xl bg-hk-orange-100 text-hk-orange-400 h-20 uppercase">
        Drawings ({allDrawings.length})
      </h5>
      <div className="mt-4  sm:p-8 flex flex-col  justify-center">
        {filteredDrawings.length > 0 && (
          <div className="p-8 text-gray-600 self-start">
            Showing ({filteredDrawings.length}) drawings
          </div>
        )}
        <div className="flex flex-col  space-y-8 items-center justify-center sm:flex-row flex-wrap">
          {!filteredDrawings.length ? (
            <div className="text-lg text-gray-700 mt-6 p-4 bg-gray-50">
              <div className="flex space-x-2 justify-center ">
                <InfoIcon className="text-gray-400" />
                <p className="uppercase">No drawings available </p>
              </div>
              <p className="text-sm text-gray-600 mt-4 ">
                This could be because you've yet to apply to a program, or no
                drawings have been created for the programs you've requested an
                application id for.
              </p>
            </div>
          ) : (
            filteredDrawings.map((drawing) => (
              <DrawingCard
                key={drawing.OpportunityDrawingID}
                drawing={drawing}
                user={user}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#285b6c",
    color: "#fff",
    fontWeight: 600,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    minHeight: 450,

    margin: "20px",
    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    cursor: "pointer",
    "&:hover": {
      boxShadow: "10px 10px 10px 10px  #edf2f7",
    },
  },

  actions: {
    marginTop: "",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    marginLeft: "auto",
    backgroundColor: "#eb7e3c",
    color: "#fff",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },

  avatarNumOpp: {
    backgroundColor: "#4986a7",
  },
  oppPopOver: {
    pointerEvents: "none",
  },
}));

export function DrawingCard({ drawing, user }) {
  const {
    applicantCount,
    applicationEntryDate,
    applicationEntryTime,
    applicantOptOutRequestId,
    applicantsAccepted,
    applicationStatusDesc,
    candidateIncomeLevelDesc: IncomeCategory,
    drawingType,
    drawing_id,
    entityName,
    entryNo,
    fileSubmissionDate,
    fileSubmissionTime,
    file_submission_deadline,
    entry_deadline,
    fileSubmissionDeadline,
    finalType,
    hhldProgramId,

    incomeLevelsUniq,
    lotteryDate,
    lotteryRosterId,
    opportunityDrawingId,
    opportunityDrawingDescription,
    opportunitesCount,
    optedOut,

    preComponentName,
    programId,
    programName = "",
    programType,
    submitDate,
    submitTime,
    submission_id,
  } = drawing;
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const [oppAnchor, setOppAnchorEl] = useState(null);
  const [emulatedUser] = useLocalStorage("emulatedUser");

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const handleOppPopoverClose = () => {
    setOppAnchorEl(null);
  };

  const handleOppPopoverHover = (e) => {
    setOppAnchorEl(e.currentTarget);
  };

  const showOppPopover = Boolean(oppAnchor);

  const drawingDisabledReasons = getDrawingDisabledReasons({
    user,
    OpportunityDrawingApplicantID: entryNo,
    ApplicantsAccepted: applicantsAccepted,
    OpportunityDrawingID: drawing_id,
  });

  const enableEnterDrawing =
    drawingDisabledReasons.length < 1 && !emulatedUser?.userName;

  const opportunityDrawingEntryNo = `${lotteryRosterId}-${entryNo}`;
  const location = useLocation();

  return (
    <Card className={classes.root}>
      <CardHeader
        className="h-32"
        avatar={
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={opportunitesCount}
          >
            <Avatar
              aria-label="number of opportunities in this drawing"
              className={classes.avatarNumOpp}
            >
              <HomeIcon />
            </Avatar>
          </StyledBadge>
        }
        /*  action={
          <IconButton aria-label="settings">
            <MoreVertIcon  />
          </IconButton>
        } */
        title={
          <span onClick={viewProgramDetails}>
            {programName} ({drawing_id})
          </span>
        }
        subheader={
          <div className="flex flex-col mt-2">
            <span>{entityName}</span>
            <span className="pt-1 text-xs">({programType})</span>

            <span className="pt-2 uppercase">{preComponentName}</span>
          </div>
        }
      />

      <CardContent>
        <DrawingDetails
          drawingDate={drawingType != 3 ? formatDateAndTime(lotteryDate) : ""}
          fileSubmissionDeadline={formatDateAndTime(file_submission_deadline)}
          deadline={drawingType != 3 ? formatDateAndTime(entry_deadline) : ""}
          drawingType={getDrawingType(drawingType, finalType)}
          incomeLevel={incomeLevelsUniq}
        />
        {!!applicationStatusDesc && (
          <div className="text-center mt-4 uppercase text-xs tracking-wide text-hk-blue-400  bg-hk-blue-100 p-2">
            {applicationStatusDesc}
          </div>
        )}
        <div
          className={`mt-4 tracking-wide flex space-x-2  rounded flex justify-center bg-hk-orange-100 text-hk-orange-400 text-xs p-1 h-10 items-center ${
            entryNo != null ? "" : "opacity-0"
          }`}
        >
          <span className="font-semibold">Entry No:</span>
          <span className=" ">{opportunityDrawingEntryNo}</span>
        </div>

        <span
          className={`p-2 bg-red-600 text-white text-xs mt-2 block tracking-wider flex justify-center items-center rounded-full h-10 ${
            optedOut ? "" : "opacity-0"
          }`}
        >
          <span>{optedOut && "OPTED OUT"}</span>
        </span>

        {/*     <div className="flex mt-10 mb-4 items-baseline space-x-2">
          <Badge badgeContent={applicantCount} color="primary">
            <PeopleAltIcon />
          </Badge>
          <div className="block text-xs text-gray-600 oblique -mb-2">
            Other applicants in this drawing ({opportunityDrawingId})
          </div>
        </div> */}
      </CardContent>

      <CardActions disableSpacing className={classes.actions}>
        {submission_id != null && (
          <Button
            variant="contained"
            size="small"
            onClick={viewSubmission}
            style={{
              backgroundColor: "#4986a7",
              color: "#fff",
            }}
          >
            View Submission
          </Button>
        )}

        <Button
          disabled={submission_id !== null}
          variant="contained"
          size="small"
          color="primary"
          onClick={viewDrawingDetails}
        >
          Enter Drawing
        </Button>

        {!optedOut && submission_id && !emulatedUser && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{
              marginLeft: "5px",
            }}
            onClick={optOut}
          >
            Opt out
          </Button>
        )}

        <Button
          className={classes.expand}
          size="small"
          onClick={viewDrawingDetails}
          aria-expanded={expanded}
          aria-label="show drawing description"
        >
          view more
        </Button>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            dangerouslySetInnerHTML={{ __html: opportunityDrawingDescription }}
          />
        </CardContent>
      </Collapse>

      {/* opp popover */}

      <OpportunityPopover
        anchorEl={oppAnchor}
        drawingId={drawing_id}
        open={showOppPopover}
        handleClose={handleOppPopoverClose}
      />
    </Card>
  );

  function viewDrawing(e) {
    e.stopPropagation();
    history.push(`/drawings?drawingId=${drawing_id}`);
  }

  function viewDrawingDetails() {
    history.push(`/hhld/drawings/${drawing_id}`);
  }

  function viewProgramDetails(e) {
    e.stopPropagation();
    history.push(`/programs/${programId}`);
  }

  function enterDrawing(e) {
    e.stopPropagation();

    history.push({
      pathname: `/drawings/${drawing_id}/enter`,
      search: `?programId=${programId}`,
      state: {
        hhldProgramId,
        agency: entityName,
        program: programName,
        programId: programId,
        from: location.pathname,
      },
    });
  }

  function viewSubmission(e) {
    e.stopPropagation();

    history.push(`/drawings/${drawing_id}/submitted/${submission_id}`);
  }

  function optOut(e) {
    e.stopPropagation();
    history.push({
      pathname: `/drawings/${drawing_id}/opt-out`,
      state: {
        hhldProgramId,
        agency: entityName,
        program: programName,
        programId,
        drawingDesc: opportunityDrawingDescription,
      },
    });
  }
}

function DrawingDetails({
  drawingDate,
  fileSubmissionDeadline,
  deadline,
  drawingType,
  incomeLevel,
}) {
  return (
    <div className="flex flex-col text-xs space-y-2 pt-4">
      <DrawingDetailItem label="Entry Deadline" value={deadline} />
      <DrawingDetailItem label="Results Published Date" value={drawingDate} />
      <DrawingDetailItem
        label="File Submission Deadline"
        value={fileSubmissionDeadline}
      />
      <DrawingDetailItem label="Drawing Type" value={drawingType} />
      <DrawingDetailItem label="Income Level" value={incomeLevel} />
    </div>
  );
}

function DrawingDetailItem({ label, value }) {
  return (
    <div className="flex h-16 flex-col space-y-2 uppercase text-sm bg-gray-50  py-2 rounded">
      <span className=" uppercase tracking-wider text-gray-500 self-center">
        {label}
      </span>

      <span className="tracking-wider text-gray-900">{value}</span>
    </div>
  );
}

function OpportunityPopover({ anchorEl, open, handleClose, drawingId }) {
  const [opps, setOpps] = useState([]);

  useEffect(() => {
    if (!open) return;
    fetchOpportunities();
  }, [open]);

  const classes = useStyles();

  return (
    <Popover
      className={classes.oppPopOver}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      onClose={handleClose}
      disableRestoreFocus
    >
      {opps.map((o) => (
        <Opportunity
          key={o.OpportunityID}
          price={o.PurchaseRentalFinanceAmt}
          subject={o.OppSubject}
          incomeCategory={o.IncomeCategory}
          ami={o.AMIPct}
        />
      ))}
    </Popover>
  );

  async function fetchOpportunities() {
    const {
      data: { data: opps },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/opportunities`);
    setOpps(opps);
  }
}

function Opportunity({ price, ami, incomeCategory, subject }) {
  return (
    <div className="p-4 grid grid-cols-6 gap-4 items-baseline border-b border-gray-300">
      <Avatar className="col-span-1">
        <HomeIcon />
      </Avatar>

      <div className="flex flex-col items-baseline place-self-start col-span-5">
        <span className="text-sm gray-700 mt-4">{subject}</span>
        <div className="flex mt-2 items-baseline">
          <span className="text-xl">{`${formatAsUSCurrency(price)}`}</span>
        </div>

        <div className="flex items-baseline">
          <span className="">{ami}</span>
          <span className="text pl-1">%</span>
          <span className="text-gray-500 text-sm pl-2">AMI</span>
          <span className="text-gray-500 text-sm px-2">({incomeCategory})</span>
        </div>
      </div>
    </div>
  );
}
