import React, { useContext, useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import MaterialUITable from "../shared/Table/MaterialUITable";

import UserContext from "../user/UserContext";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import { useFiles } from "../../queries/files/useFiles";
import { formatBytes, formatDateToTimeZone } from "../../utils/formatters";

export function FileDownloads() {
  const { user } = useContext(UserContext);
  const [refetch, setRefetch] = useState(false);
  const files = useFiles(user, "downloads", refetch);
  const [showFileDeleteAlert, setShowFileDeleteAlert] = useState(false);
  const [fileToDelete, setFileToDelete] = useState("");

  useEffect(() => {
    //what a hack to re trigger refetch, will need to think about it more
    if (!refetch) return;
    setRefetch(false);
  }, [refetch]);

  const cols = [
    {
      title: "File Name",
      field: "Filename",
    },
    {
      title: "Document Type",
      field: "ContentType",
    },
    {
      title: "Requested Date",
      field: "CreatedOn",
      render: (rowData) =>
        formatDateToTimeZone(rowData.CreatedOn, "MM/dd/yyyy h:mm:ss a"),
    },
    {
      title: "File Size",
      field: "FileSize",
      render: (rowData) => `${formatBytes(rowData.FileSize)}`,
    },
  ];
  return (
    <section className="min-h-screen p-4">
      <h1 className="hidden">Downloads</h1>
      <MaterialUITable
        columns={cols}
        data={files}
        title={
          <div className="flex space-x-2 items-center">
            <span className="text-lg text-gray-700">
              My Downloads ({files.length})
            </span>
            <IconButton onClick={() => setRefetch(true)}>
              <RefreshIcon style={{ color: "#eb7e3c" }} />
            </IconButton>
          </div>
        }
        options={{
          headerStyle: {
            backgroundColor: "#fff",
          },
        }}
        actions={[
          {
            icon: CloudDownloadIcon,
            tooltip: "Download file",
            onClick: (event, rowData) => {
              handleDownload(rowData.ID, rowData.Filename);
            },
          },
          {
            icon: DeleteIcon,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              setShowFileDeleteAlert(true);
              setFileToDelete(rowData.ID);
            },
          },
        ]}
      />
      <Dialog open={showFileDeleteAlert}>
        <DialogContent>
          Are you sure you want to delete this file?
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setShowFileDeleteAlert(false)}>
            Cancel
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => {
              handleDelete(fileToDelete);
              setShowFileDeleteAlert(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );

  async function handleDelete(fileId: string) {
    await axios.delete(
      `${getBaseUrl()}/user/${user.userId}/files/${fileId}?memberId=${
        user.memberId
      }`
    );
    setRefetch(true);
  }

  async function handleDownload(fileId: string, fileName: string) {
    const { data } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/files/${fileId}?memberId=${
        user.memberId
      }`
    );

    if (data?._link?.download) {
      const {
        _link: {
          download: { href },
        },
      } = data;

      await fetch(href, {
        method: "GET",
      })
        .then(async (res) => {
          return {
            filename: fileName,
            blob: await res.blob(),
            ctype: res.headers.get("content-type"),
          };
        })
        .then((resObj) => {
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([resObj.blob], { type: resObj.ctype });

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement("a");
            link.href = objUrl;
            link.download = resObj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch((error) => {
          console.log("DOWNLOAD ERROR", error);
        });
    }
  }
}
