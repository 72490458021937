import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useDrawingPreferences(drawingId, programId) {
  const [prefs, setPrefs] = useState([]);

  useEffect(() => {
    fetchPrefs();
  }, [drawingId]);

  async function fetchPrefs() {
    const {
      data: { data: prefs },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/preferences`);

    setPrefs(prefs);
  }

  return [prefs, fetchPrefs];
}
