import React, { useEffect, useReducer, useState } from "react";

import { useRouteMatch, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MaterialUITable from "../../shared/Table/MaterialUITable";

import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";

import { formatDateToTimeZone } from "../../../utils/formatters";
import AddOrgModal from "./AddOrg";

export default function UserMgmt(props) {
  const [orgs, setOrgs] = useState([]);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);

  const { url } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetchOrgs();
  }, []);

  const orgColumns = [
    {
      title: "Name",
      field: "EntityName",
    },
    {
      title: "Category",
      field: "EntityCategoryDesc",
    },
    {
      title: "Type",
      field: "EntityTypeDesc",
    },
    {
      title: "Sub Type",
      field: "EntitySubTypeDesc",
    },
    {
      title: "# Users",
      field: "UserCount",
    },
  ];

  return (
    <section className="min-h-screen">
      <h1 className="hidden">User Mgmt</h1>
      <div className="flex justify-end p-4">
        <Button
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => setShowAddOrgModal(true)}
        >
          Add Org
        </Button>
      </div>
      <AddOrgModal
        open={showAddOrgModal}
        onClose={() => setShowAddOrgModal(false)}
      />
      <MaterialUITable
        columns={orgColumns}
        isLoading={loadingOrgs}
        stickyHeader
        className="p-4 mt-4"
        data={orgs}
        title={`Orgs (${orgs.length})`}
        options={{
          exportButton: true,
          pageSize: 10,
          grouping: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: ArrowForwardIosIcon,
            tooltip: "View Users",

            onClick: (event, rowData) => {
              history.push(`${url}/${rowData.EntityID}/users`);
            },
          },
        ]}
      />
    </section>
  );

  async function fetchOrgs() {
    setLoadingOrgs(true);
    const {
      data: { data: orgs },
    } = await axios.get(`${getBaseUrl()}/entity?rowCnt=100&page=1`);
    setOrgs(orgs);
    setLoadingOrgs(false);
    axios.post(`${getBaseUrl()}/activity`, {
      type: 0,
      userId: 0,
      moduleType: 33,
      moduleName: "org list",
      entityName: "abc",
      performedBy: "abc test",
      desc: "Get org list",
      appInfoId: 0,
      oppId: 0,
      oppDrawingId: 0,
    });
  }
}

function flattenUserAttributes(users = []) {
  return users.reduce((acc, user) => {
    const { Attributes } = user;

    const flattenedAttributes = Attributes.reduce((acc, attr) => {
      const { Name, Value } = attr;

      acc[Name] = Value;
      return acc;
    }, {});

    const flattened = {
      created: formatDateToTimeZone(user.UserCreateDate),
      modified: formatDateToTimeZone(user.UserLastModifiedDate),
      enabled: user.Enabled,
      status: user.UserStatus,
      ...flattenedAttributes,
    };
    acc.push(flattened);
    return acc;
  }, []);
}
