import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Typography, TextField } from "@material-ui/core";
//import { CloudinaryContext, Image, Transformation } from "cloudinary-react";
import HotelIcon from "@material-ui/icons/Hotel";
import BathtubIcon from "@material-ui/icons/Bathtub";
import SquareFootIcon from "@material-ui/icons/SquareFoot";
import ImageIcon from "@material-ui/icons/Image";

import "./OpportunityDetails.css";
import { getBaseUrl } from "../utils/queryHelpers";
import useQueryParams from "../components/shared/hooks/useQuery";
import axios from "../utils/axios";

import Label from "./Label";

import Map from "../maps/MapGl";

import { extractLatAndLong } from "../utils/mapHelpers";
import {
  removeHTMLTagsFromString,
  formatAsUSCurrency,
} from "../utils/formatters";

function OpportunityDetail(props) {
  const [opportunity, setOpps] = useState({});
  const [drawing, setDrawing] = useState({});
  const [drawingPrefs, setDrawingPreferences] = useState([]);
  const [images, setImages] = useState([]);
  const { id } = useParams();

  const qParams = useQueryParams();

  const drawingId = qParams.get("drawingId");

  useEffect(() => {
    fetchOpportunityDetail();
    drawingId && fetchDrawingDetails();
  }, []);

  const {
    ProgramID,
    EntityID,
    LotSquareFt,
    MaxHHLDSize = "",
    MinHHLDSize = "",
    PurchaseRentalFinanceAmt = "",
    ZipCode,
    State,
    Address1,
    Address2,
    City,
    PreComponentName,
    Longitude,
    Latitude,
    HOAFee = "",
    AMIPct = "",
    BathRoomCount: BathRoomCnt = "",
    BedroomCount: BedRoomCnt = "",
    IncomeLevelDesc = "",
    LotSquareFt: SquareFt = "",
    OpportunityTypeDesc = "",
    ProgramTitle = [],
    OppDescription = "",
  } = opportunity;

  useEffect(() => {
    if (!EntityID || !ProgramID) return;
    fetchImages();
    drawingId && fetchDrawingPrefs();
  }, [ProgramID, EntityID]);

  if (!opportunity) return <div className="min-h-screen">Loading...</div>;

  return (
    <div className="w-full bg-custom-white min-h-screen px-2 sm:px-20">
      <section className="w-full flex gap-2">
        <h3 className="hidden">Address</h3>
        <span>{Address1}</span>
        <span>{Address2}</span>
        <span>{City}</span>
        <span>{State}</span>
        <span>{ZipCode}</span>
      </section>

      <section className="w-full flex flex-col py-4 h-24 bg-hk-dark-blue text-white">
        <h3 className="hidden">Provider and Program Information</h3>
        <span className="text-md font-semilbold ">{ProgramTitle[1]}</span>
        <span className="text-sm ">{PreComponentName}</span>
        <span
          className="text-gray-500 text-md mt-12 block p-4 text-left text-white"
          dangerouslySetInnerHTML={{ __html: OppDescription }}
        ></span>
      </section>

      {/* <section className="w-full p-2 min-h-1/4 h-full sm:h-64">
        <CloudinaryContext cloudName="housekeys">
          <div className="flex  items-center flex-wrap min-h-1/4">
            {images.map((r) => {
              return (
                <a href={r.secure_url}>
                  <Image
                    key={r.public_id}
                    publicId={r.public_id}
                    className="thumbnail inline ml-2 mt-2 rounded-sm"
                    width="350"
                    height="350"
                    crop="fit"
                    quality="80"
                  >
                    <Transformation quality="auto" fetchFormat="auto" />
                  </Image>
                </a>
              );
            })}
          </div>
        </CloudinaryContext>
      </section> */}

      <div className="flex flex-col  sm:flex-row flex-wrap gap-2">
        <div className="bg-white border-1 rounded-lg py-8 mt-2 sm:w-1/2">
          <span className="text-2xl px-6 flex">
            {formatAsUSCurrency(PurchaseRentalFinanceAmt)}
          </span>
          <div className="flex gap-2 items-baseline  py-4 px-6">
            <div className="flex gap-2 items-center">
              <HotelIcon className="text-blue-500" />
              <span className="text-gray-700">{BedRoomCnt}</span>
            </div>
            <div className="flex gap-2 items-center">
              <BathtubIcon className="text-blue-500" />
              <span className="text-gray-700">{BathRoomCnt}</span>
            </div>
            <div className="flex gap-2 items-center">
              <SquareFootIcon className="text-blue-500" />
              <span className="text-gray-700">{SquareFt} </span>
              {SquareFt && (
                <span className="text-gray-500 text-sm">Sq.Ft.</span>
              )}
            </div>
          </div>
          <LineItem label="AMI" value={`${AMIPct} %`} />
          <LineItem label="HOA" value={`${formatAsUSCurrency(HOAFee)} / MO.`} />
          <LineItem label="Max HHLD Size" value={MaxHHLDSize} />
          <LineItem label="Min HHLD Size" value={MinHHLDSize} />
          <LineItem label="Income Level" value={IncomeLevelDesc} />

          <LineItem label="Drawing Date" value={drawing.StartDate} />
          <LineItem
            label="Application Deadline"
            value={drawing.ApplicationEntryDate}
          />
          <LineItem label="Applicant Count" value={drawing.ApplicantCount} />
        </div>

        <section className="py-4 bg-gray-50 border-1 min-h-32 rounded-lg mt-12 sm:w-1/2">
          <h2 className="text-3xl uppercase text-gray-500 border-b border-gray-200 tracking-wider p-4  font-semibold rounded">
            Description
          </h2>
          <div className="flex justify-center">
            <span
              className="text-gray-700 leading-relaxed text-sm mt-12 block p-4 text-left"
              dangerouslySetInnerHTML={{ __html: OppDescription }}
            ></span>
          </div>
        </section>

        <div className="flex flex-col sm:flex-row gap-6">
          <section className="p-4 mt-12  bg-gray-50 border-1 min-h-64 rounded-lg sm:w-1/2">
            <h2
              className="text-3xl bg-gray-50  text-gray-500 uppercase border-b border-hk-gray-200 tracking-wider font-semibold rounded  p-4  
          "
            >
              Eligibility{" "}
            </h2>

            <ol className="flex flex-col text-sm gap-4 justify-baseline items-baseline pt-4">
              {drawingPrefs.map((dp) => (
                <li
                  className=" text-left border-l-2 border-gray-300 text-ls pl-2 pt-2"
                  key={dp.ID}
                >
                  {dp.PreferenceTitle}
                </li>
              ))}
            </ol>
          </section>
        </div>

        {/* <section className="p-4">
          <h2 className="text-left text-xl text-gray-600">
            Calculate Eligibility
          </h2>
          <form className="flex flex-col sm:flex-row gap-2 items-baseline py-2">
            <TextField variant="outlined" size="small" label="Income" />
            <TextField variant="outlined" size="small" label="Household Size" />
            <Button color="primary" size="small" variant="contained">
              Submit
            </Button>
          </form>
        </section> */}

        {/* <section className="hidden sm:block w-3/5 mt-6 ml-2">
            <Map
              width={400}
              height={400}
              data={extractLatAndLong([opportunity])}
            />
          </section> */}
      </div>
    </div>
  );

  async function fetchOpportunityDetail() {
    const {
      data: { data: detail },
    } = await axios.get(`${getBaseUrl()}/opportunities/${id}`);

    setOpps(detail);
  }

  async function fetchDrawingDetails() {
    const {
      data: { data: drawing },
    } = await axios.get(`${getBaseUrl()}/drawings?drawingId=${drawingId}`);

    setDrawing(drawing?.[0] ?? {});
  }

  async function fetchDrawingPrefs() {
    const {
      data: { data: prefs },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/preferences?programId=${ProgramID}&rowCnt=100&page=1`
    );

    setDrawingPreferences(prefs);
  }

  async function fetchImages() {
    //setLoadingImages(true);
    const {
      data: { data: images },
    } = await axios.get(
      `${getBaseUrl()}/images?prefix=${EntityID}/${ProgramID}/${id}`
    );

    //setLoadingImages(false);
    setImages(images.resources);
  }
}

function LineItem({ label, value }) {
  return (
    <section className="flex w-full  px-6 pt-2 text-sm text-left">
      <span
        className="w-1/3 text-md text-gray-500"
        align="left"
        variant="subtitle2"
      >
        {label}
      </span>
      <span
        className="w-2/3 text-gray-900  text-right"
        color="textPrimary"
        variant="subtitle2"
      >
        {value}
      </span>
    </section>
  );
}

export default OpportunityDetail;
