import React, { useContext, useEffect, useReducer } from "react";
import { TextField, Button } from "@material-ui/core";
import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import MaterialUITable from "../shared/Table/MaterialUITable";
import DatetimePicker from "../shared/form/DatetimePicker";
import { formatDateToTimeZone } from "../../utils/formatters";
import EventDetail from "./EventDetail";

const DEFAULT_TZ = "America/Los_Angeles";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss zzz";

function reducer(state, { type, payload }) {
  if (type === "events") {
    return {
      ...state,
      events: payload,
      showLoading: false,
    };
  }
  return { ...state, [type]: payload };
}

function isError({ alert }) {
  console.log("alert", alert);
  return alert === "ERROR";
}

type AuditEvent = {
  userId: string;
  created: string;
  event: string;
  alert: string;
  memberId: string;
  entityId: string;
  message: string;
  email: string;
  username: string;
  sourceIp: string;
  browser: string;
  device: string;
  city: string;
  state: string;
  postcode: string;
  county: string;
  country: string;
  latitude: string;
  longitude: string;
  position: string;
};

export function Events(props) {
  const {initFilter} = props; 
  const start = new Date();
  start.setHours(start.getHours() - 1);
  const end = new Date();

  const [
    { userId, events, startTime, endTime, showLoading, timezone = DEFAULT_TZ },
    dispatch,
  ] = useReducer(reducer, {
    userId: "",
    events: [],
    showLoading: false,
    startTime: formatDateToTimeZone(start, DEFAULT_DATE_FORMAT, DEFAULT_TZ),
    endTime: formatDateToTimeZone(end, DEFAULT_DATE_FORMAT, DEFAULT_TZ),
  });

  useEffect(() => {
    fetchRecentEvents();
  }, []);

  const tableColumnLookup = tableColumnWithLookup(events);

  const cols = [
    tableColumnLookup("UserId", "userId"),
    tableColumn("Created", "created"),
    tableColumnLookup("Event", "event"),
    tableColumnLookup("Alert Level", "alert"),
    tableColumnLookup("Emulated User", "emulatedUser"),
    tableColumnLookup("MemberId", "memberId"),
    tableColumnLookup("EntityId", "entityId"),
    tableColumn("Message", "message"),
    tableColumn("Email", "email"),
    tableColumn("User Name", "username"),
    tableColumnLookup("IP Address", "sourceIp"),
    tableColumnLookup("Browser", "browser"),
    tableColumnLookup("Device", "device"),
    tableColumnLookup("City", "city"),
    tableColumnLookup("State", "state"),
    tableColumnLookup("Postal Code", "postcode"),
    tableColumnLookup("County", "county"),
    tableColumnLookup("Country", "country"),
    tableColumnLookup("Position", "position"),
  ];

  return (
    <section className="min-h-screen p-4">
      Events here
      <form className="flex space-x-2 p-4 items-baseline">
        <TextField
          name="userId"
          label="User Id"
          value={userId}
          onChange={handleChange}
        />
        <DatetimePicker
          label="Start Time"
          value={startTime}
          handleChange={handleStartTimeChange}
        />
        <DatetimePicker
          label="End Time"
          value={endTime}
          handleChange={handleEndTimeChange}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={fetchRecentEvents}
        >
          Search
        </Button>
      </form>
      <MaterialUITable
        columns={cols}
        data={events}
        isLoading={showLoading}
        title={`Events (${events.length})`}
        options={{
          filtering: true,
          pageSize: 10,
          search: false,
          grouping: true,
          rowStyle: (rowData) => {
            return isError(rowData)
              ? {
                  backgroundColor: "#e53e3e",
                  color: "#fff",
                }
              : {};
          },
        }}
        detailPanel={(rowData) => {
          return <EventDetail userId={rowData.userId}/>;
        }}
      />
    </section>
  );

  function handleChange(event: any) {
    const { name, value } = event.target;
    console.log(`userId change: ${name}=${value}`);
    dispatch({
      type: name,
      payload: value,
    });
  }
  function handleStartTimeChange(event: Date) {
    dispatch({
      type: "startTime",
      payload: event,
    });
  }
  function handleEndTimeChange(event: Date) {
    dispatch({
      type: "endTime",
      payload: event,
    });
  }

  async function fetchRecentEvents() {
    dispatch({
      type: "showLoading",
      payload: true,
    });
    const utcStartTime = zonedTimeToUtc(
      new Date(startTime),
      timezone
    ).toISOString();
    const utcEndTime = zonedTimeToUtc(
      new Date(endTime),
      timezone
    ).toISOString();
    const {
      data: { data: events },
    } = await axios.get(
      `${getBaseUrl()}/track/list?userId=${userId}&start=${utcStartTime}&end=${utcEndTime}`
    );
    const timeFormattedItems: AuditEvent = events.hasOwnProperty("Items")
      ? events.Items.map((item: { created: Date, position: string, latitude: string, longitude: string }) => {
          return {
            ...item,
            created: formatDateToTimeZone(
              item.created,
              DEFAULT_DATE_FORMAT,
              timezone
            ),
            position: item.position && item.position !== ""
                ? item.position
                : item.latitude && item.latitude !== ""
                    ? `${item.latitude}, ${item.longitude}`
                    : "",
          };
        })
      : events;
    dispatch({ type: "events", payload: typeof initFilter === 'function' ? timeFormattedItems.filter(initFilter) : timeFormattedItems });
  }

  function tableColumn(title: string, field: string) {
    return {
      title,
      field,
    };
  }

  function lookupHelper(list: AuditEvent[], attribute: string) {
    const lookup = list
      .filter((event) => event.hasOwnProperty(attribute))
      .reduce((acc, curr) => {
        acc[curr?.[attribute]] = curr?.[attribute];
        return acc;
      }, {});
    return lookup;
  }

  function tableColumnWithLookup(list: AuditEvent[]) {
    return function(title: string, field: string) {
      return {
        title,
        field,
        lookup: lookupHelper(list, field),
      };
    };
  }
}
