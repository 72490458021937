import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function SkeletonWrapper({
  w = 10,
  h = 10,
  variant = "text",
  condition,
  children,
  ...props
}) {
  return condition ? (
    <Skeleton variant={variant} width={w} height={h} {...props} />
  ) : (
    children
  );
}
