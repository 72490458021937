import { format, isBefore, isValid } from "date-fns";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";

export function formatDate(date, dateFormat = "MM/dd/yyyy") {
  return date != null && isValid(new Date(date))
    ? format(new Date(date), dateFormat)
    : "";
}

export function formatDateToTimeZone(
  date,
  dateFormat = "MM/dd/yyyy",
  tz = "America/Los_Angeles"
) {
  if (date == null || isBefore(new Date(date), new Date(1900, 1, 1)))
    return null;
  const isValidDate = isValid(new Date(date));
  if (!isValidDate) return null;

  const zonedDate = utcToZonedTime(new Date(date), tz);
  return formatTz(zonedDate, dateFormat, { timeZone: tz });
}

//most dates are currenly not utc, so use this instead
export function formatNonUTC(date, dateFormat = "MM/dd/yyyy") {
  if (date == null || isBefore(new Date(date), new Date(1900, 1, 1)))
    return null;
  const isValidDate = isValid(new Date(date));
  if (!isValidDate) return null;

  const zonedDate = utcToZonedTime(new Date(date));
  return formatTz(zonedDate, dateFormat);
}

export function formatDateAndTime(date, time) {
  return isBefore(new Date(date), new Date(1900, 1, 1))
    ? ""
    : `${formatDate(date, "eee, MMM dd, yyyy")}${
        !time ? "" : `, at ${formatTime(time)}`
      }`;
}

export function formatTime(time = "") {
  const [hours24, mins24, seconds] = time?.split(":") ?? [0, 0, 0];

  const hours = ((parseInt(hours24) + 11) % 12) + 1;

  const amPm = hours24 > 11 ? "pm" : "am";

  return `${hours}:${mins24}:${seconds} ${amPm}`;
}

export function applyTZOffset(date) {
  //extract date only and subtract the tz offset
  //https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
  const dt = new Date(date);
  const dtOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  return formatDate(dtOnly);
}

export function formatAsUSCurrency(num) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(num);
}

export function removeHTMLTagsFromString(str = "") {
  return String(str).replace(/<[^>]*>/g, "");
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes == null) return "N/A";
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
