import React, { useContext, useReducer, useState } from "react";
import { isValid } from "date-fns";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import HouseholdOnboardingStepper from "./HhldOnboardingStepper";
import UserContext from "../UserContext";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { hasEmptyFields } from "../../../utils/jsUtils";
import { formatDate } from "../../../utils/formatters";
import Preferences from "./HhldPrefs";
import LoadingBackdrop from "../../shared/misc/LoadingBackDrop";
import Address from "../../shared/form/Address";
import DatePicker from "../../shared/form/DatePicker";
import Select from "../../shared/Select";
import AnnualIncome from "../../shared/form/AnnualIncome";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: "#fff",
  },
  title: {
    display: "flex",
    backgroundColor: "#eb7e3c",
    color: "#fff",
  },

  incomeTypeRadio: {
    backgroundColor: "#edf2f7",
    borderRadius: "10px",
    fontSize: "0.8rem",
    padding: "2px",
  },

  checkBoxLabel: {
    "&& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",
      color: "#718096",
    },
  },

  intermediateBtn: {
    backgroundColor: "#6eb9a4",
    color: "#fff",
  },

  switchBase: { "&$checked": { backgroundColor: "green" } },
}));

const localTheme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.8rem",
        textAlign: "left",
        placeItems: "self-start",
      },
    },
    MuiFormGroup: {
      root: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      },
    },
  },
});

const initRace = {
  asian: false,
  asianIndian: false,
  chinese: false,
  filipino: false,
  japanese: false,
  vietnamese: false,
  korean: false,
  otherAsian: false,
  americanIndian: false,
  hispanicOrLatino: false,
  mexican: false,
  puertoRican: false,
  cuban: false,
  otherHispanic: false,
  africanAmerican: false,
  hawaiianOrPacificIslander: false,
  nativeHawaiian: false,
  guamanian: false,
  samoan: false,
  otherPacificIslander: false,
  white: false,
  noAnswer: false,
};

function reducer(state, action) {
  if (action.type === "phone")
    return { ...state, phone: action.payload.replace(/\D/g, "") };
  if (action.type === "race") {
    const { field, value } = action.payload;
    if (field === "noAnswer" && value) {
      return {
        ...state,
        race: { ...initRace, noAnswer: true },
      };
    }

    //uncheck all sub options, if the top level category(asian, hispanic, hawaiian) is unchecked
    if (field === "asian" && value == false) {
      return {
        ...state,
        race: {
          ...state.race,
          asianIndian: false,
          korean: false,
          japanese: false,
          chinese: false,
          asian: false,
          vietnamese: false,
          filipino: false,
          otherAsian: false,
        },
      };
    }
    if (field === "hispanicOrLatino" && value == false) {
      return {
        ...state,
        race: {
          ...state.race,
          hispanicOrLatino: false,
          mexican: false,
          cuban: false,
          puertoRican: false,
          otherHispanic: false,
        },
      };
    }

    if (field === "hawaiianOrPacificIslander" && value == false) {
      return {
        ...state,
        race: {
          ...state.race,
          hawaiianOrPacificIslander: false,
          nativeHawaiian: false,
          guamanian: false,
          samoan: false,
          otherPacificIslander: false,
        },
      };
    }
    return {
      ...state,
      race: { ...state.race, [field]: value },
    };
  }
  return { ...state, [action.type]: action.payload };
}

export default function HhldOnboarding(props) {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext) || {};
  const [activeStep, setActiveStep] = useState(0);

  const [state, dispatch] = useReducer(reducer, {
    dob: "",
    phone: "",
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    submitting: false,
    submitSuccessful: false,
    totalIncome: "",
    totalHousingPmt: "",
    numBuyers: "",

    race: initRace,
  });

  return (
    <section className="min-h-screen ">
      <div className="flex items-center flex-col w-full">
        <h3 className="text-2xl uppercase w-full bg-hk-orange-100 text-hk-orange-400 h-32 flex items-center justify-center">
          Household onboarding
        </h3>

        <div className="flex flex-col bg-white -mt-4 shadow-md p-4 w-64 rounded justify-center">
          <span className="uppercase text-gray-700">
            {user.firstName} {user.lastName}
          </span>

          <span className="text-gray-500">{user.email}</span>
        </div>
      </div>

      <div className="p-4 sm:px-16">
        <HouseholdOnboardingStepper
          getStepContent={getStepContent}
          handleFinish={handleSubmit}
          isNextDisabled={isNextDisabled()}
          submitting={state.submitting}
          submitSuccessful={state.submitSuccessful}
          fields={state}
        />
      </div>

      <LoadingBackdrop open={state.submitting} />
    </section>
  );

  async function handleSubmit(e) {
    dispatch({
      type: "submitting",
      payload: true,
    });
    e.preventDefault();
    await axios
      .post(`${getBaseUrl()}/user/${user.userId}/update_profile`, {
        ...state,
        dob: formatDate(state.dob),
      })
      .catch((e) => {
        dispatch({
          type: "submitFailed",
          payload: true,
        });
      });
    dispatch({
      type: "submitSuccessful",
      payload: true,
    });
    dispatch({
      type: "submitting",
      payload: false,
    });
    await setUser();
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    dispatch({
      type: name,
      payload: value ? value : type !== "text" && type !== "tel" ? checked : "",
    });
  }

  function handleRaceChange(e) {
    const { name, checked } = e.target;
    dispatch({
      type: "race",
      payload: {
        field: name,
        value: checked,
      },
    });
  }

  function isNextDisabled() {
    if (activeStep === 0) {
      return (
        hasEmptyFields(state, [
          "memberAddr",
          "memberCity",
          "memberState",
          "memberZip",
          "dob",
          "maritalStatus",
          "phone",
          "race",
          "sex",
          "totalIncome",
          "totalHousingPmt",
          "numBuyers",
        ]) || !isValid(new Date(state.dob))
      );
    }
  }

  function getStepContent(stepIndex) {
    setActiveStep(stepIndex);
    switch (stepIndex) {
      case 0:
        return (
          <PersonalInfo
            self
            classes={classes}
            fields={state}
            handleChange={handleChange}
            handleRaceChange={handleRaceChange}
            updateProfile={updateProfile}
            handleAddressChange={handleAddressChange}
          />
        );
      case 1:
        return <Preferences fields={state} handleChange={handleChange} />;

      default:
        return "Unknown stepIndex";
    }
  }

  async function updateProfile() {
    await axios.post(`${getBaseUrl()}/user/${user.userId}/update_profile`);
    await setUser();
    //history.push("/hhld/program-center");
  }

  async function handleAddressChange(address) {
    dispatch({
      type: "memberAddr",
      payload: address?.street_line ?? "",
    });
    dispatch({
      type: "memberCity",
      payload: address?.city ?? "",
    });
    dispatch({
      type: "memberState",
      payload: address?.state ?? "",
    });
    dispatch({
      type: "memberZip",
      payload: address?.zipcode ?? "",
    });
    dispatch({
      type: "memberCounty",
      payload: address?.county ?? "",
    });
  }
}

function PersonalInfo({
  fields,
  handleAddressChange,
  handleChange,
  handleRaceChange,
}) {
  const {
    dob,
    ethnicity,
    maritalStatus,
    memberAddr,
    memberCity,
    memberZip,
    memberState,
    numBuyers,
    phone,
    race,
    sex,
    totalIncome,
    totalHousingPmt,
  } = fields;

  const numBuyersOptions = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: "8 or more", value: 8 },
  ];

  console.log("dob", dob);
  return (
    <>
      <div className="flex flex-col w-full sm:w-64 space-y-4">
        <DatePicker
          label="DOB"
          required
          onChange={handleChange}
          value={dob}
          handleChange={(date) =>
            handleChange({ target: { name: "dob", value: date } })
          }
        />
        <TextField
          value={phone}
          name="phone"
          label="Phone number"
          type="tel"
          variant="outlined"
          inputProps={{ maxLength: 10 }}
          onChange={handleChange}
        />
        <Select
          value={numBuyers}
          onChange={handleChange}
          options={numBuyersOptions}
          name="numBuyers"
          label="Total household members"
        />
        <AnnualIncome
          value={totalIncome}
          label="Total Income"
          name="totalIncome"
          handleChange={handleChange}
          variant="outlined"
        ></AnnualIncome>
        <AnnualIncome
          value={totalHousingPmt}
          label="Total Monthly Housing Pmt"
          name="totalHousingPmt"
          handleChange={handleChange}
          variant="outlined"
        ></AnnualIncome>
      </div>

      <ThemeProvider theme={localTheme}>
        <section className="mt-8 flex flex-wrap">
          <FormControl component="fieldset">
            <FormControl
              className="text-sm text-left font-semibold"
              component="legend"
            >
              Marital Status
            </FormControl>
            <RadioGroup
              aria-label="Marital Status"
              name="maritalStatus"
              value={maritalStatus}
              onChange={handleChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Single" />
              <FormControlLabel value="2" control={<Radio />} label="Married" />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Domestic Partner"
              />

              <FormControlLabel value="4" control={<Radio />} label="Widowed" />
              <FormControlLabel
                value="5"
                control={<Radio />}
                label="Divorced"
              />
            </RadioGroup>
          </FormControl>
        </section>
      </ThemeProvider>

      <section className=" mt-8">
        <Address
          address={memberAddr}
          city={memberCity}
          state={memberState}
          zip={memberZip}
          onAddressChange={handleAddressChange}
        />
      </section>

      <ThemeProvider them={localTheme}>
        <section className="demographics mt-8">
          <div className="flex items-center">
            <h4 className="text-gray-600 uppercase tracking-wide sticky top-0">
              Demographics
            </h4>

            <Tooltip
              title=" The purpose of collecting this information is to help ensure that
            all applicants are treated fairly and that the programmatic and
            housing needs of the communities and neighborhoods are being
            fulfilled. When engaged in mortgage lending, federal law requires
            lenders to ask applicants for their demographic information
            (ethnicity, sex, and race) in order to monitor compliance with equal
            credit opportunity, fair housing, and home mortgage disclosure laws.
            In addition to federal requirements, state and local governments may
            require us to report on who is being served by our platform. You are
            not required to provide this information, but we encourage you to do
            so. The law provides that housing providers, as well as lenders, may
            not discriminate on the basis of this information, or on whether you
            choose to provide it. When completing reports to the regulators and
            auditors, we may be asked to provide aggregate data on who we served
            based on visual observation or surname. This information is
            collected for each individual household member."
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>

          <section className="sex mt-6 flex flex-col">
            <h4 className="hidden">Sex</h4>
            <FormControl component="fieldset">
              <FormControl
                className="text-sm text-left font-semibold"
                component="legend"
              >
                Sex
              </FormControl>
              <div className="flex">
                <RadioGroup
                  className="grid grid-cols-2"
                  aria-label="Sex"
                  name="sex"
                  value={sex}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Prefer not to answer"
                  />
                </RadioGroup>
              </div>
            </FormControl>
          </section>

          <section className="ethnicity mt-8 flex flex-col">
            <h4 className="hidden">Ethnicity</h4>
            <FormControl component="fieldset">
              <FormControl
                className="text-sm text-left font-semibold"
                component="legend"
              >
                Ethnicity
              </FormControl>
              <div className="flex">
                <RadioGroup
                  aria-label="Ethnicity"
                  name="ethnicity"
                  value={ethnicity}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Hispanic or Latino"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Not Hispanic or Latino"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Prefer not to answer"
                  />
                </RadioGroup>
              </div>
            </FormControl>
          </section>

          <Race race={race} handleChange={handleRaceChange} />
        </section>
      </ThemeProvider>
    </>
  );
}

function Race({ race, handleChange }) {
  const [expandedOption, setExpandedOption] = useState("");
  const {
    asian,

    americanIndian,
    hispanicOrLatino,

    africanAmerican,
    hawaiianOrPacificIslander,

    white,
    noAnswer,
  } = race;

  return (
    <section className="race mt-6 flex flex-col">
      <h4 className="hidden ">Race</h4>
      <FormControl component="fieldset">
        <FormControl
          className="text-sm text-left font-semibold"
          component="legend"
        >
          Race
        </FormControl>
        <div className="flex flex-wrap ">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="americanIndian"
                  checked={americanIndian}
                  onChange={(e) => {
                    setExpandedOption("");
                    handleChange(e);
                  }}
                />
              }
              label="American Indian or Alaska Native"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="asian"
                  checked={asian}
                  onChange={handleChange}
                />
              }
              label="Asian"
            />

            <AsianRaceOptions race={race} handleChange={handleChange} />

            <FormControlLabel
              control={
                <Checkbox
                  name="hispanicOrLatino"
                  checked={hispanicOrLatino}
                  onChange={handleChange}
                />
              }
              label="Hispanic or Latino"
            />

            <HispanicRaceOptions race={race} handleChange={handleChange} />

            <FormControlLabel
              control={
                <Checkbox
                  name="africanAmerican"
                  checked={africanAmerican}
                  onChange={handleChange}
                />
              }
              label="Black or African American"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="hawaiianOrPacificIslander"
                  checked={hawaiianOrPacificIslander}
                  onChange={handleChange}
                />
              }
              label="Native Hawaiian or Other Pacific Islander"
            />

            <HawaiianRaceOptions race={race} handleChange={handleChange} />

            <FormControlLabel
              control={
                <Checkbox
                  checked={white}
                  name="white"
                  onChange={handleChange}
                />
              }
              label="White"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="noAnswer"
                  checked={noAnswer}
                  onChange={handleChange}
                />
              }
              label="Prefer not to answer"
            />
          </FormGroup>
        </div>
      </FormControl>
    </section>
  );
}

function AsianRaceOptions({ race, handleChange }) {
  const {
    asian,
    asianIndian,
    chinese,
    filipino,
    vietnamese,
    japanese,
    korean,
    otherAsian,
  } = race;
  return (
    <div className="border-b border-t border-gray-100 p-4">
      <FormGroup className="flex pl-4" aria-label="Race">
        <div className="flex flex-wrap">
          <FormControlLabel
            control={
              <Checkbox
                disabled={!asian}
                checked={asianIndian}
                name="asianIndian"
                onChange={handleChange}
              />
            }
            label="Asian Indian"
          />

          <FormControlLabel
            control={
              <Checkbox
                disabled={!asian}
                checked={chinese}
                name="chinese"
                onChange={handleChange}
              />
            }
            label="Chinese"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!asian}
                checked={filipino}
                name="filipino"
                onChange={handleChange}
              />
            }
            label="Filipino"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!asian}
                checked={japanese}
                name="japanese"
                onChange={handleChange}
              />
            }
            label="Japanese"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!asian}
                checked={vietnamese}
                name="vietnamese"
                onChange={handleChange}
              />
            }
            label="Vietnamese"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!asian}
                checked={korean}
                name="korean"
                onChange={handleChange}
              />
            }
            label="Korean"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={otherAsian}
                name="otherAsian"
                disabled={!asian}
              />
            }
            label="Other"
            onChange={handleChange}
          />
        </div>
      </FormGroup>
    </div>
  );
}

function HispanicRaceOptions({ race, handleChange }) {
  const { hispanicOrLatino, mexican, cuban, puertoRican, otherHispanic } = race;
  return (
    <div className="border-b border-t border-gray-100 p-4">
      <FormGroup className="flex pl-4" aria-label="Race">
        <div className="flex flex-wrap">
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hispanicOrLatino}
                checked={mexican}
                name="mexican"
                onChange={handleChange}
              />
            }
            label="Mexican"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hispanicOrLatino}
                checked={puertoRican}
                name="puertoRican"
                onChange={handleChange}
              />
            }
            label="Puerto Rican"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hispanicOrLatino}
                chceked={cuban}
                name="cuban"
                onChange={handleChange}
              />
            }
            label="Cuban"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hispanicOrLatino}
                checked={otherHispanic}
                name="otherHispanic"
                onChange={handleChange}
              />
            }
            label="Other Hispanic or Latino"
          />
        </div>
      </FormGroup>
    </div>
  );
}

function HawaiianRaceOptions({ race, handleChange }) {
  const {
    hawaiianOrPacificIslander,
    nativeHawaiian,
    guamanian,
    samoan,
    otherPacificIslander,
  } = race;
  return (
    <div className="border-b border-t border-gray-100 p-4 ">
      <FormGroup className="flex pl-4" aria-label="Race" name="hawaiianOptions">
        <div className="flex flex-wrap">
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hawaiianOrPacificIslander}
                checked={nativeHawaiian}
                name="nativeHawaiian"
                onChange={handleChange}
              />
            }
            label="Native Hawaiian"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hawaiianOrPacificIslander}
                checked={guamanian}
                name="guamanian"
                onChange={handleChange}
              />
            }
            label="Guamanian Or Chamorro"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hawaiianOrPacificIslander}
                checked={samoan}
                name="samoan"
                onChange={handleChange}
              />
            }
            label="Samoan"
          />
          <FormControlLabel
            control={
              <Checkbox
                disabled={!hawaiianOrPacificIslander}
                checked={otherPacificIslander}
                name="otherPacificIslander"
                onChange={handleChange}
              />
            }
            label="Other Pacific Islander"
          />
        </div>
      </FormGroup>
    </div>
  );
}
