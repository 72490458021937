import React, { useContext, useState, useEffect } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";

import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";

import RefreshIcon from "@material-ui/icons/Refresh";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import UserContext from "../user/UserContext";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import { formatBytes, formatDateToTimeZone } from "../../utils/formatters";
import MaterialUITable from "../shared/Table/MaterialUITable";
import Loading from "../shared/misc/Loading";
import { useProgramApplications } from "../../queries/programs/useProgramApplications";
import PopperMsg from "../shared/misc/PopperMsg";
import { hhldFilesTabs } from "./fileUtils";
import useLocalStorage from "../../components/shared/hooks/useLocalStorage";
import {
  ApplicationDocs,
  Assets,
  Income,
  Others,
  Preference,
  QualificationDocs,
} from "./info";

type TagType = {
  imageId: string;
  tags: string[];
};

type UploadedFile = {
  name: string;
  tags: string[];
  file: any;
  preview: string;
};

const useStyles = makeStyles((theme) => ({
  backdropContainer: {
    position: "relative",
    zIndex: 0,
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    minWidth: "90%",
    height: "90%",
  },

  listItemText: {
    root: {
      fontWeight: 500,
    },
    primary: {
      fontWeight: 600,
    },
  },
}));

const HelpButton = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    textTransform: "none",

    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      paddingRight: "2rem",
    },

    alignItems: "center",
  },
}))((props) => <Button {...props} />);

const StyledListItem = withStyles((theme) => ({
  root: {
    backgroundColor: "#f3f3f3",
    color: "#504e4e",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: "10px",
    width: "350px",
    textAlign: "center",
    margin: "0 5px 0",
    fontSize: "1rem",
    "&.Mui-selected": {
      backgroundColor: "#ed7d31",
      color: "#fff",
    },
  },
}))((props) => <ListItem {...props} />);

export default function FileCabinet({ userId, memberId, adminMode }) {
  let { user: sessionUser } = useContext(UserContext);

  let user = adminMode ? { userId, memberId } : sessionUser;
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [showFileDialog, setShowFileDialog] = useState(false);
  const [showBulkDownloadMessage, setshowBulkDownloadMessage] = useState(false);

  const [fileLink, setFileLink] = useState("");
  const [fetchingFileLink, setFetchingFileLink] = useState(false);
  // const docTypes = useFileDocTypes();
  const [files, setFiles] = useState<Array<UploadedFile>>([]);
  const [bulkFiles, setBulkFiles] = useState<Array<string>>([]);
  const [moveAnchorEl, setMoveAnchorEl] = useState(null);
  const [showBulkFileDeleteAlert, setShowBulkFileDeleteAlert] = useState(false);

  const [addToProgramAnchorEl, setAddToProgramAnchorEl] = useState(null);
  const [showAttachSuccess, setShowAttachSuccess] = useState(false);
  const [showAttachFailure, setShowAttachFailure] = useState(false);
  const [fileAttachInProgress, setFileAttachInProgress] = useState(false);

  const [showUpdateDialog = true, setShowUpdateDialog] = useLocalStorage(
    "showUpdateDialog"
  );
  const [showNewFeatureNotification, setShowNewFeatureNotification] = useState(
    showUpdateDialog
  );

  const [showTabHelp, setShowTabHelp] = useState({
    appIds: false,
    incomes: false,
    assets: false,
    qualDocs: false,
    prefernces: false,
    other: false,
  });

  const [activeTab, setActiveTab] = useState(0);

  const programApplications = useProgramApplications(userId);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchFiles();
  }, []);

  function handleMoveClick(event) {
    setMoveAnchorEl(event.currentTarget);
  }

  function handleMoveClose() {
    setMoveAnchorEl(null);
  }

  function handleAddToProgramClick(event) {
    setAddToProgramAnchorEl(event.currentTarget);
  }

  function handleAddToProgramClose() {
    setAddToProgramAnchorEl(null);
  }

  const filteredFiles =
    activeTab === 0
      ? uploadedFiles
      : uploadedFiles.filter(
          (f) => f.Filepath === `/${hhldFilesTabs.get(activeTab)?.id}`
        );

  const [activeHelpTab, showTabHelpDialog] = Object.entries(showTabHelp).find(
    ([key, value]) => value === true
  ) || ["all", false];

  return (
    <section className="min-h-screen">
      <h1 className="hidden  h-32 uppercase text-2xl flex items-center justify-center">
        File Cabinet
      </h1>
      <Tabs
        handleTabChange={handleTabChange}
        activeTab={activeTab}
        classes={classes}
        files={uploadedFiles}
        handleHelpClick={handleHelpClick}
      />
      <div className="w-full flex justify-end">
        <HelpButton
          endIcon={
            <HelpIcon
              style={{
                fontSize: "14px",
              }}
              className="text-gray-500"
            />
          }
          onClick={(e) => setShowNewFeatureNotification(true)}
        >
          Info
        </HelpButton>
      </div>
      <div className="flex space-x-2 flex-col sm:flex-row items-center px-2 sm:px-8 py-2">
        <div className="order-2 sm:order-1 mt-6 self-start">
          <span className="sm:text-2xl">{`${
            hhldFilesTabs.get(activeTab)?.title
          } Files (${filteredFiles.length})`}</span>
          <Tooltip title="Refresh">
            <IconButton onClick={fetchFiles}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>

          {bulkFiles.length > 0 && (
            <>
              <Tooltip title="Download">
                <IconButton onClick={handleBulkDownload}>
                  <CloudDownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => setShowBulkFileDeleteAlert(true)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Move to">
                <IconButton onClick={handleMoveClick}>
                  <ControlCameraIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add to Application">
                <IconButton onClick={handleAddToProgramClick}>
                  <AddBoxIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>

        <Button
          className="order-1 sm:order-2"
          onClick={() => {
            history.push(`/file-cabinet/uploads#${activeTab}`);
          }}
          style={{
            backgroundColor: "#ed7d31",
            color: "#fff",
            marginLeft: "auto",
          }}
        >
          Upload New Document
        </Button>
      </div>

      <Menu
        anchorEl={moveAnchorEl}
        open={Boolean(moveAnchorEl)}
        onClose={handleMoveClose}
      >
        {[...hhldFilesTabs.entries()]
          .filter(([key]) => key !== 0)
          .map(([key, value]) => {
            return (
              <MenuItem
                key={key}
                onClick={(e) => handleFilePathUpdate(`/${value.id}`, key)}
              >
                {value.title}
              </MenuItem>
            );
          })}
      </Menu>
      <div className={classes.backdropContainer}>
        <Menu
          anchorEl={addToProgramAnchorEl}
          open={Boolean(addToProgramAnchorEl)}
          onClose={handleAddToProgramClose}
        >
          {programApplications
            .sort((a, b) => {
              if (a.ProgramTitle < b.ProgramTitle) return -1;
              if (a.ProgramTitle > b.ProgramTitle) return 1;
              return 0;
            })
            .map((p) => (
              <MenuItem
                key={p.PreApplicationInfoID}
                onClick={() =>
                  attachFilesToProgramApplication(
                    p.PreApplicationInfoID,
                    p.ProgramID
                  )
                }
              >
                <div className="flex space-x-1 items-baseline">
                  <span className="text-gray-900">{p.ProgramTitle}</span>
                  <span className="text-gray-600 text-sm">
                    ({p.ApplicationFileID})
                  </span>
                </div>
              </MenuItem>
            ))}
        </Menu>
        <Backdrop
          className={classes.backdrop}
          open={fileAttachInProgress}
          onClick={() => setFileAttachInProgress(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <PopperMsg
        open={showAttachSuccess}
        handleClose={() => setShowAttachSuccess(false)}
      >
        <span>Files were attached successfully.</span>
      </PopperMsg>
      <PopperMsg
        msgType="error"
        open={showAttachFailure}
        handleClose={() => setShowAttachFailure(false)}
      >
        <span>Files could not be attached.</span>
      </PopperMsg>

      <FileDeleteAlert
        open={showBulkFileDeleteAlert}
        handleClose={() => setShowBulkFileDeleteAlert(false)}
        handleConfirm={() => {
          handleBulkDelete();
          setShowBulkFileDeleteAlert(false);
        }}
      >
        <span>Are you sure you want to delete these files?</span>
      </FileDeleteAlert>

      <section className="mt-4 min-h-64 sm:px-8">
        <RecentFiles
          activeTab={activeTab}
          files={filteredFiles}
          loading={fetchingFiles}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
          handleFileNameUpdate={handleFileNameUpdate}
          handleLabelUpdate={handleLabelUpdate}
          handlePreview={handlePreview}
          handleFileSelection={handleFileSelection}
        />

        <Dialog
          classes={{ paper: classes.paper }}
          open={showFileDialog}
          onClose={handleClose}
        >
          <DialogContent>
            <div>
              {fetchingFileLink && <Loading />}
              <iframe
                src={fileLink}
                allowFullScreen
                width="100%"
                height="500"
              />
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={showBulkDownloadMessage}
          onClose={handleDownloadModalClose}
        >
          <DialogContent>
            <div className="flex p-4">
              <AnnouncementIcon style={{ color: "#4fd1d9" }} />
              <p className="ml-2  text-gray-800">
                Your download request has been received. <br />
                An email will be sent to you when your files are ready for
                download. <br />
                To check the status of your download, please visit the
                <Link
                  className="underline text-blue-600"
                  to="/file-cabinet/downloads"
                >
                  {" "}
                  Download page.
                </Link>
              </p>
            </div>
          </DialogContent>
        </Dialog>
      </section>

      <Dialog open={showTabHelpDialog} onClose={handleTabHelpDialogClose}>
        <DialogContent>
          <ul className="mt-4">
            <li className="py-2">
              {activeHelpTab === "appIds" && <ApplicationDocs />}
              {activeHelpTab === "assets" && <Assets />}
              {activeHelpTab === "incomes" && <Income />}
              {activeHelpTab === "qualDocs" && <QualificationDocs />}
              {activeHelpTab === "preferences" && <Preference />}
              {activeHelpTab === "others" && <Others />}
            </li>
          </ul>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showNewFeatureNotification}
        onClose={(e) => {
          setShowNewFeatureNotification(false);
          setShowUpdateDialog(false);
        }}
      >
        <DialogContent>
          <section>
            <h3 className="uppercase text-gray-900 text-xs font-semibold sm:text-xl text-center py-2 ">
              Welcome to the new File Cabinet
            </h3>
            <div className="text-sm leading-loose text-gray-700">
              <p>
                We've made changes to the file cabinet to better prepare
                applicants for the application process. Your file cabinet is
                where you will provide supporting documents that prove your
                eligibility for the program you are interested in.
              </p>

              <p className="py-2 text-gray-800">
                There are icons that you will use to make changes to the files
                once uploaded.
              </p>
              <ul className="flex space-x-2 py-2">
                <li>
                  <div className="flex flex-col space-y-2">
                    <RefreshIcon fontSize="small" className="self-center" />
                    <span className="text-xs">
                      Allows you to update the page as you add or change files
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col space-y-2">
                    <CloudDownloadIcon
                      fontSize="small"
                      className="self-center"
                    />
                    <span className="text-xs">
                      Allows you to select and download files that you've
                      uploaded.
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col space-y-2">
                    <DeleteIcon fontSize="small" className="self-center" />
                    <span className="text-xs">
                      Allows you to delete selected files. Please be careful
                      with this function.
                    </span>
                  </div>
                </li>
                <li>
                  <div className="flex flex-col space-y-2">
                    <ControlCameraIcon
                      fontSize="small"
                      className="self-center"
                    />
                    <span className="text-xs">
                      Allows you to move selected files to the proper tabs. You
                      can select 1 or more file at a time.{" "}
                    </span>
                  </div>
                </li>
              </ul>
              <Divider />
              <p className="mt-3 text-gray-800">
                You'll see that there are 5 tabs.These tabs allow you to
                organize your files so that it can be reviewed for eligibility.
                You will see "file submission deadlines" posted next to
                available housing opportunities. The file cabinet will be where
                you upload documents before those deadlines pass. Click on the
                icon next to each tab name for an explanation.
              </p>
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </section>
  );

  async function attachFilesToProgramApplication(appId, programId) {
    setFileAttachInProgress(true);
    setAddToProgramAnchorEl(null);
    //check if there's a doc Id
    try {
      let resp = await axios.get(
        `${getBaseUrl()}/programs/${programId}/preapp/${appId}/supportdocs`
      );
      console.log("get resp", resp);
      if (!resp?.data?.data?.length > 0) {
        //fetch a new one

        resp = await axios.post(
          `${getBaseUrl()}/programs/${programId}/preapp/${appId}/supportdocs`
        );
      }
      if (resp?.data?.data) {
        const [docId] = resp.data.data;
        const attachResp = await axios.post(
          `${getBaseUrl()}/programs/${programId}/preapp/${appId}/supportdocs/${
            docId.id
          }/files`,
          {
            items: [{ id: docId.items[0].id, files: bulkFiles }],
          }
        );

        console.log("attachResp", attachResp);
        if (attachResp?.data?.statusCode === 201) {
          //files attached successfully
          setBulkFiles([]);
          setFileAttachInProgress(false);
          setShowAttachSuccess(true);
          setAddToProgramAnchorEl(null);
        }
      }
    } catch (err) {
      setShowAttachFailure(true);
      setFileAttachInProgress(false);
      setAddToProgramAnchorEl(null);
    }
  }

  async function handleFilePathUpdate(path, tabIndex) {
    setMoveAnchorEl(null);
    await axios.put(`${getBaseUrl()}/user/${user.userId}/files`, {
      memberId: user.memberId,
      files: [
        {
          fileids: bulkFiles,
          path,
        },
      ],
    });
    await fetchFiles();
    setActiveTab(tabIndex);
    setBulkFiles([]);
  }

  function handleFileSelection(selections) {
    setBulkFiles(selections.map((s) => s.ID));
  }

  async function handleBulkDownload() {
    await axios.post(`${getBaseUrl()}/user/${user.userId}/files/archive`, {
      memberId: user.memberId,
      fileIds: bulkFiles,
    });
    setshowBulkDownloadMessage(true);
    setBulkFiles([]);
  }

  async function handleBulkDelete() {
    await Promise.all(bulkFiles.map((f) => handleDelete(f)));
    setBulkFiles([]);
  }

  function handleClose() {
    setShowFileDialog(false);
    setFileLink("");
  }

  function handleDownloadModalClose() {
    setshowBulkDownloadMessage(false);
  }

  function handleTabChange(event, newTab) {
    setActiveTab(newTab);
  }

  async function handlePreview(fileId: string) {
    setShowFileDialog(true);
    setFetchingFileLink(true);
    const { data } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/files/${fileId}?memberId=${
        user.memberId
      }`
    );

    if (data?._link?.download) {
      const {
        _link: {
          download: { href },
        },
      } = data;
      setFileLink(href);
    }
    setFetchingFileLink(false);
  }

  async function handleDownload(fileId: string, fileName: string) {
    const { data } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/files/${fileId}?memberId=${
        user.memberId
      }`
    );

    if (data?._link?.download) {
      const {
        _link: {
          download: { href },
        },
      } = data;

      await fetch(href, {
        method: "GET",
      })
        .then(async (res) => {
          return {
            filename: fileName,
            blob: await res.blob(),
            ctype: res.headers.get("content-type"),
          };
        })
        .then((resObj) => {
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([resObj.blob], { type: resObj.ctype });

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement("a");
            link.href = objUrl;
            link.download = resObj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch((error) => {
          console.log("DOWNLOAD ERROR", error);
        });
    }
  }

  async function handleDelete(fileId: string) {
    await axios.delete(
      `${getBaseUrl()}/user/${user.userId}/files/${fileId}?memberId=${
        user.memberId
      }`
    );
    fetchFiles();
  }

  async function fetchFiles() {
    setFetchingFiles(true);
    const {
      data: { data: files },
    } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/files?memberId=${
        user.memberId
      }&limit=500&view=tree`
    );
    setUploadedFiles(files.filter((f) => !f.Filepath.startsWith("/downloads")));
    setFetchingFiles(false);
  }

  async function handleLabelUpdate(tags, rowData) {
    await axios.put(`${getBaseUrl()}/user/${user.userId}/files`, {
      memberId: user.memberId,
      files: [
        {
          fileids: [rowData.ID],
          label: tags,
        },
      ],
    });
    fetchFiles();
  }
  async function handleFileNameUpdate(update: string, rowData: FileRow) {
    await axios.put(`${getBaseUrl()}/user/${user.userId}/files`, {
      memberId: user.memberId,
      files: [
        {
          fileids: [rowData.ID],
          filename: update,
        },
      ],
    });
    fetchFiles();
  }

  function handleHelpClick(id: string) {
    setShowTabHelp((prev) => {
      return {
        ...prev,
        [id]: true,
      };
    });
  }

  function handleTabHelpDialogClose() {
    setShowTabHelp((prev) => {
      return Object.entries(prev).reduce((acc, [key, value]) => {
        acc[key] = false;

        return acc;
      }, {});
    });
  }
}

type FileRow = {
  Bucket: string;
  ContentType: string;
  CreatedBy: string;
  CreatedOn: string;
  FileKey: string;
  Filename: string;
  Filepath: string;
  ID: string;
  Label: string;
  Region: string;
  ToDelete: boolean;
  UpdatedOn: string;
  Visible: true;
};

type RecentFiles = {
  files: FileRow[];
  activeTab: number;
  handleDownload: (id: string, fileName: string) => void;
  handleDelete: (id: string) => void;
  handleFileNameUpdate: (update: string, rowData: FileRow) => void;
  handleLabelUpdate: (update: string, rowData: FileRow) => void;
  handlePreview: (fileId: string) => void;
  loading: boolean;
};

function RecentFiles({
  files,
  activeTab,
  handleDownload,
  handleDelete,
  handleFileNameUpdate,
  handleLabelUpdate,
  loading,
  handlePreview,
  handleFileSelection,
}: RecentFiles) {
  const [showFileDeleteAlert, setShowFileDeleteAlert] = useState(false);
  const [fileToDelete, setFileToDelete] = useState("");

  const cols = [
    {
      title: "Name",
      field: "Filename",
    },
    ...(activeTab === 0
      ? [
          {
            title: "Folder",
            field: "Filepath",
            editable: "never",
          },
        ]
      : []),

    {
      title: "Type",
      field: "ContentType",
      editable: "never",
    },
    {
      title: "Size",
      field: "Label",
      editable: "never",
      render: (rowData) => `${formatBytes(rowData.FileSize)}`,
    },
    {
      title: "Date Uploaded",
      field: "CreatedOn",
      render: (rowData: FileRow) =>
        formatDateToTimeZone(rowData.CreatedOn, "MM/dd/yyyy h:mm:ss a"),
      editable: "never",
    },
  ];
  return (
    <section className="p-2  space-y-4 overflow-y-auto min-h-40">
      <MaterialUITable
        stickyHeader
        title=""
        cellEditable={{
          onCellEditApproved: (
            newValue: string,
            _,
            rowData: FileRow,
            columnDef
          ) => {
            const { title } = columnDef;
            if (title === "Label") {
              return handleLabelUpdate(newValue, rowData);
            }
            if (title === "Name") {
              return handleFileNameUpdate(newValue, rowData);
            }
          },
        }}
        options={{
          headerStyle: {
            backgroundColor: "#fff",
            color: "#504e4e",
            fontWeight: "500",
            fontSize: "1rem",
          },
          selection: true,
        }}
        localization={{
          toolbar: {
            nRowsSelected: `{0} file(s)  selected.`,
          },
        }}
        onSelectionChange={handleFileSelection}
        columns={cols}
        data={files}
        isLoading={loading}
        actions={[
          {
            icon: VisibilityIcon,
            tooltip: "View File",
            onClick: (event, rowData) => {
              handlePreview(rowData.ID);
            },
            position: "row",
          },
          {
            icon: CloudDownloadIcon,
            tooltip: "Download file",
            onClick: (event, rowData: FileRow) => {
              handleDownload(rowData.ID, rowData.Filename);
            },
            position: "row",
          },

          {
            icon: DeleteIcon,
            tooltip: "Delete",
            onClick: (event, rowData: FileRow) => {
              setShowFileDeleteAlert(true);
              setFileToDelete(rowData.ID);
            },
            position: "row",
          },
        ]}
      />
      <FileDeleteAlert
        open={showFileDeleteAlert}
        handleClose={() => setShowFileDeleteAlert(false)}
        handleConfirm={() => {
          handleDelete(fileToDelete);
          setShowFileDeleteAlert(false);
        }}
      >
        <span>Are you sure you want to delete this file?</span>
      </FileDeleteAlert>
    </section>
  );
}

function FileDeleteAlert({ open, handleClose, handleConfirm, children }) {
  return (
    <Dialog open={open}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Tabs({ handleTabChange, activeTab, classes, files, handleHelpClick }) {
  return (
    <div className="w-full overflow-x-scroll px-8 py-6">
      <List component="ul" className="flex flex-row text-xs text-red">
        {[...hhldFilesTabs.entries()].map(([key, value]) => {
          return (
            <StyledListItem
              key={key}
              button
              selected={activeTab === key}
              onClick={(event) => handleTabChange(event, key)}
            >
              <ListItemText
                className={classes.listItemText.primary}
                primary={`${value.title} (${
                  value.id === "all"
                    ? files.length
                    : files.filter((f) => f.Filepath.startsWith("/" + value.id))
                        .length
                })`}
              />
              {value.id !== "all" && (
                <HelpIcon
                  fontSize="small"
                  onClick={() => handleHelpClick(value.id)}
                />
              )}
            </StyledListItem>
          );
        })}
      </List>
    </div>
  );
}
