import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useFiles(user, path, refetch = false) {
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    if (!refetch) return;
    fetchFiles();
  }, [refetch]);

  return files;

  async function fetchFiles() {
    const {
      data: { data: files },
    } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/files?memberId=${
        user.memberId
      }&limit=500&view=tree${path ? `&path=/${path}` : ""}`
    );
    setFiles(files);
  }
}
