import React from "react";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import DatePicker from "../../shared/form/DatePicker";

export default function AffidavitIncomeForm({ fields, handleChange }) {
  const { dateLastEmployed, declaration, receiveUnEmployment } = fields;
  return (
    <form className=" mt-4 flex flex-col max-w-md">
      <DatePicker
        value={dateLastEmployed}
        required
        label="Date Last Employed"
        handleChange={(date) =>
          handleChange({ target: { name: "dateLastEmployed", value: date } })
        }
      />

      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              name="receiveUnEmployment"
              checked={receiveUnEmployment}
              onChange={handleChange}
            />
          }
          label="Receive UnEmployment"
          labelPlacement="start"
        />
      </FormGroup>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              name="declaration"
              checked={declaration}
              onChange={handleChange}
            />
          }
          label="Declaration"
          labelPlacement="start"
        />
      </FormGroup>
    </form>
  );
}
