import React, { useState, useEffect } from "react";
import Amplify from "aws-amplify";

import { Auth, Hub } from "aws-amplify";
import awsconfig from "../../aws/config";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import SignIn from "./signIn/SignIn";
import Signup from "./signup/Signup";
import ConfirmSignup from "./signup/ConfirmSignup";
import ResetPw from "./reset-pw/ResetPw";
import ConfirmResetPw from "./reset-pw/ConfirmResetPw";
import PopperMsg from "../shared/misc/PopperMsg";

Amplify.configure(awsconfig);

function HkAuth({ children, setUser }) {
  const [status, setStatus] = useState("sign-in");
  const [user, updateUser] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [hkUser, setHkUser] = useState(null);
  const [error, setError] = useState(null);

  async function handleSignIn(user) {
    updateUser(user);
    await fetchUser();
  }

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        updateUser(user);
      })
      .catch(() => console.log("No signed in user."));
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          return handleSignIn(data.payload.data);

        case "signOut":
          return updateUser(null);

        case "signUp_failure":
        case "signIn_failure":
        case "forgotPassword_failure":
        case "forgotPasswordSubmit_failure":
          showError(data.payload.data);
          break;

        case "forgotPassword":
          setNewUser(data.payload.data.username);
          break;
      }
    });
    return () => Hub.remove("auth");
  }, []);

  async function fetchUser() {
    const {
      data: { data: user },
    } = await axios.get(`${getBaseUrl()}/user/me`);

    const {
      data: { data: org },
    } = await axios.get(`${getBaseUrl()}/entity/${user.entityId}`);
    user.org = org?.[0];
    setHkUser(user);
    setUser(user);
  }

  function showError(data) {
    switch (data.code) {
      case "UsernameExistsException":
        setError("An account with the given username already exists.");
        break;
      case "InvalidParameterException":
        setError("All Required fields were not provided.");
        break;
      case "UserNotFoundException":
        setError("Username doesn't exist");
        break;
      case "NotAuthorizedException":
        setError(`${data.message}.`);
        break;
      case "UserNotConfirmedException":
        setError(
          `You've have not confirmed your account yet. You should have received a confirmation code in the email when you signed up.`
        );
        setStatus("confirm-sign-up");
        break;
      case "PasswordResetRequiredException":
        setError(`Password reset required. Please reset your password.`);
        setStatus("reset-pw");
        break;
      case "InvalidPasswordException":
        setError("Reset password failed");
        break;
      case "LimitExceededException":
        setError("Attempt limit exceeded, please try after some time");
        break;
      case "CodeMismatchException":
        setError("Verification code doesn't match. Please check your code.");
        break;
      default:
        setError("Sign up failed");
    }
  }

  if (user) {
    return children;
  }
  return (
    <div className="flex justify-center h-screen items-center">
      {status === "sign-in" ? <SignIn setStatus={setStatus} /> : null}
      {status === "sign-up" ? (
        <Signup setStatus={setStatus} setUser={setUser} />
      ) : null}
      {status === "confirm-sign-up" ? (
        <ConfirmSignup setStatus={setStatus} />
      ) : null}

      {status === "reset-pw" ? <ResetPw setStatus={setStatus} /> : null}
      {status === "confirm-reset-pw" ? (
        <ConfirmResetPw setStatus={setStatus} userName={newUser} />
      ) : null}
      <PopperMsg
        open={error != null}
        msgType="error"
        handleClose={() => setError(null)}
      >
        <span>{error}</span>
      </PopperMsg>
    </div>
  );
}

export default HkAuth;
