import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { isOdd } from "../../../../utils/jsUtils";
import axios from "../../../../utils/axios";
import { getBaseUrl } from "../../../../utils/queryHelpers";

export default function Preferences({ programId, drawingId }) {
  const [prefs, setPrefs] = useState([]);

  async function fetchPrefs() {
    const {
      data: { data: prefs },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/preferences?programId=${programId}&rowCnt=200&page=1`
    );
    setPrefs(prefs);
    // setPrefs(prefs.filter((pref) => pref.status === "A"));
  }

  useEffect(() => {
    if (drawingId && programId) fetchPrefs();
  }, [drawingId, programId]);

  return (
    <section className="shadow-md p-2 sm:ml-6 flex-grow w-full  h-64 overflow-y-scroll">
      <div className="flex items-center ">
        <h6 className="text-gray-800  p-1  text-sm font-bold mt-2">
          Preferences
        </h6>
      </div>

      <Divider />

      <div className="sticky top-0">
        <div className="grid grid-cols-12 gap-1 text-sm items-center bg-hk-orange text-white p-2">
          <span className="underline col-span-2">Tier</span>
          <span className="underline col-span-8">Preference</span>
          <span className="underline col-span-2">Points</span>
        </div>
      </div>
      {prefs.map((p, index) => (
        <div
          key={p.ID}
          className={`grid grid-cols-12 gap-1 p-2 text-xs text-gray-600 ${
            isOdd(index) ? "bg-white" : "bg-gray-100"
          }`}
        >
          <span className="col-span-2">{p.preference_value}</span>
          <span className="col-span-8">{p.title}</span>
          <span className="col-span-2">
            {p.preference_type === "points" ? p.preference_value : 0}
          </span>
        </div>
      ))}
    </section>
  );
}
