import React from "react";
import OpportunityList from "./OpportunityList";
import OpportunityDetail from "./OpportunityDetails";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import ImageUpload from "../components/uploads/images/ImageUpload";

export default function OpportunityHome(props) {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path="/opportunities/:id">
        <OpportunityDetail />
      </Route>

      <Route exact path="/opportunities/:id/uploads">
        <ImageUpload />
      </Route>

      <Route exact path="/opportunities">
        <OpportunityList />
      </Route>
    </Switch>
  );
}
