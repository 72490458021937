import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  CircularProgress,
  Collapse,
  Avatar,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/Home";

import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import {
  formatDateToTimeZone,
  formatAsUSCurrency,
} from "../../../utils/formatters";
import Questions from "./Questions";
import { extractFormFields } from "../../../utils/hhldUtils";
import PopperMsg from "../../shared/misc/PopperMsg";
import { useDrawingDetails } from "../../../queries/programs/useDrawingDetails";
import { useDrawingOpportunities } from "../../../queries/programs/useDrawingOpportunities";

const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: "0.75rem",
  },
}))((props) => <Tab {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
    margin: "20px",
    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    cursor: "pointer",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatarOpen: {
    backgroundColor: green[500],
  },
  avatarClosed: {
    backgroundColor: red[500],
  },
  price: {
    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
  },
  address: {
    "&& .MuiTypography-body2": {
      fontSize: "0.8rem",
    },
  },
}));

function formReducer(state, action) {
  if (
    action.type.startsWith("elig") ||
    action.type.startsWith("qualification") ||
    action.type.startsWith("gen")
  ) {
    if (action.mode === "multiple") {
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          ansType: action.payload.ansType,
          value: { ...state[action.type]?.value, ...action.payload.value },
        },
        edits: {
          ...state.edits,
          [action.type]: {
            ...state.edits[action.type],
            ansType: action.payload.ansType,
            value: {
              ...state[action.type]?.value,
              ...state.edits[action.type]?.value,
              ...action.payload.value,
            },
          },
        },
      };
    }

    return {
      ...state,
      [action.type]: action.payload,
      edits: { ...state.edits, [action.type]: action.payload },
    };
  }

  if (action.type === "fields") {
    return {
      ...state,
      ...action.payload,
    };
  }
  if (action.type === "choices") {
    return {
      ...state,
      choices: { ...state.choices, [action.payload.oppId]: action.payload.sel },
    };
  }
  return { ...state, [action.type]: action.payload };
}

export default function DrawingSubmitted() {
  const [hhldOpps, setHhldOpps] = useState([]);
  const [results, setResults] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const { drawingId, submissionId } = useParams();
  const [submitted, setSubmitted] = useState({
    drawing: {},
    questions: [],
    preferences: [],
  });

  const [drawingDetail] = useDrawingDetails(drawingId);

  useEffect(() => {
    fetchHhldOpportunities();
    fetchSubmitted();
    fetchDrawingResults();
  }, []);

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const {
    drawing: { applicant = {} },
    questions,
    preferences,
  } = submitted;

  //const responseQuestions = buildQuestions(questions);
  //const responsePrefs = buildPreferences(preferences);

  const [state, dispatch] = useReducer(formReducer, {
    edits: {},
    loading: false,
    submissionComplete: false,
    submittingDrawing: false,
    showFailureMsg: false,
    showEntryFailureMsg: false,
    showSuccessMsg: false,
    editSubmission: false,
  });

  const submitDisabled = Object.keys(state.edits).length < 1 || state.loading;

  return (
    <section className="min-h-screen">
      <div className="flex flex-col items-center sm:px-2">
        <div className="bg-hk-blue-100 flex flex-col   w-full h-40 flex justify-center items-center   tracking-wider">
          <h3 className="uppercase text-3xl text-hk-blue-400">
            Drawing ({drawingDetail.drawing_id})
          </h3>
        </div>

        <div className="bg-white -mt-4 sm:-mt-6 shadow min-h-64 w-64 sm:w-96 rounded p-4 flex flex-col">
          <span className="text-lg text-gray-900 uppercase">
            Submitted: {formatDateToTimeZone(applicant.entryDate)}
          </span>

          <span className="text-sm text-hk-orange uppercase">
            {applicant.applicationFileId}
          </span>

          <span className="pt-4 uppercase text-gray-800">
            {drawingDetail.provider}
          </span>
          <span className="pt-1 text-sm text-gray-700">
            {drawingDetail.Title}
          </span>
          <span className="py-1 text-sm text-gray-500">
            {drawingDetail.pre_component_name}
          </span>
          <div className="pt-4">
            <HeaderLineItem label="AMI" value={`${drawingDetail.ami_pct} %`} />
            <HeaderLineItem
              label="Income Category"
              value={drawingDetail.IncomeCategoryDesc}
            />
            <HeaderLineItem
              label="Max HHLD"
              value={drawingDetail.max_hhld_size}
            />
            <HeaderLineItem
              label="Min HHLD"
              value={drawingDetail.min_hhld_size}
            />
            <HeaderLineItem
              label="Total Applicants"
              value={drawingDetail.TotalApplicantCnt}
            />
            <HeaderLineItem
              label="Target  Delivery"
              value={formatDateToTimeZone(
                drawingDetail.TargetApplicantDeliveryDate
              )}
            />
          </div>
        </div>

        <p
          className="text-sm w-full p-4 mr-2 ml-2 text-left px-8 mt-4 bg-hk-orange-100 sm:px-32 text-hk-orange-400 h-80 sm:h-32"
          dangerouslySetInnerHTML={{ __html: drawingDetail.description }}
        />
      </div>

      <div className="py-4">
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Submitted Drawing tabs"
          variant="scrollable"
          scrollButtons="on"
        >
          <StyledTab label="Results" />
          <StyledTab label="Opportunities" />
          <StyledTab label="Answers" />
          <StyledTab label="Preferences" />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <DrawingResults
            results={results}
            appId={applicant.applicationFileId}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <div className="mt-2 w-full flex flex-col sm:flex-row flex-wrap  shadow   sm:px-24">
            {hhldOpps.map((o) => (
              <Opportunity key={o.ID} opp={o} />
            ))}
          </div>
        </TabPanel>

        <TabPanel value={activeTab} index={2}>
          {applicant.optOutFlag !== 1 && (
            <div className="flex justify-end p-4">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() =>
                  dispatch({
                    type: "editSubmission",
                    payload: true,
                  })
                }
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </div>
          )}
          <div className="mt-2 w-full shadow p-4 ml-1 mr-1 relative">
            <Backdrop
              open={state.loading}
              style={{ position: "absolute" }}
              onClick={(e) =>
                dispatch({
                  type: "loading",
                  payload: false,
                })
              }
            >
              <CircularProgress />
            </Backdrop>
            <Questions
              type="question"
              questions={questions}
              fields={state}
              dispatch={dispatch}
            />
            {/* <EligibilityQuestions
              elig={
                questions
                  ?.filter((q) => q.QuestionType === "E")
                  ?.map((q) => ({
                    ...q,
                    EligibilityQuestionID: q.QuestionID,
                  })) ?? []
              }
              disabled={true}
              fields={eligFields}
              dispatch={dispatch}
            />

            <QualificationQuestions
              questions={
                questions
                  ?.filter((q) => q.QuestionType === "Q")
                  ?.map((q) => ({
                    ...q,
                    QualificationQuestionID: q.QuestionID,
                  })) ?? []
              }
              fields={qualificationFields}
              dispatch={dispatch}
              disabled={!state.editSubmission}
            />

            <GeneralQuestions
              questions={
                questions
                  ?.filter((q) => q.QuestionType === "OD")
                  ?.map((q) => ({
                    ...q,
                    InventoryGeneralQuestionID: q.QuestionID,
                  })) ?? []
              }
              fields={genFields}
              dispatch={dispatch}
              disabled={!state.editSubmission}
            /> */}
            {applicant.optOutFlag !== 1 && (
              <div className="flex space-x-1 justify-center">
                <Button size="small" variant="contained" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleEdit}
                  disabled={submitDisabled}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <div className="mt-2 w-full shadow p-4 ml-1 mr-1">
            <Questions
              type="preference"
              questions={preferences.map((p) => ({
                question: p.description,
                question_id: p.preference_id,
                answer_type: "radio",
              }))}
              fields={state}
              dispatch={dispatch}
            />
            {/* {prefs.map((p) => (
              <Preference pref={p} key={p.PreferenceID} />
            ))} */}
          </div>
        </TabPanel>
      </div>
      <PopperMsg
        open={state.showSuccessMsg}
        handleClose={() =>
          dispatch({
            type: "showSuccessMsg",
            payload: false,
          })
        }
      >
        <span>Changes successful.</span>
      </PopperMsg>
    </section>
  );

  function handleCancel() {
    dispatch({
      type: "edits",
      payload: {},
    });
    fetchSubmitted();
  }

  async function handleEdit() {
    dispatch({
      type: "loading",
      payload: true,
    });

    await axios.put(`${getBaseUrl()}/drawings/${drawingId}/submission`, {
      submissionId,
      elig: extractFormFields(state.edits, "elig"),
      qual: extractFormFields(state.edits, "qualification"),
      gen: extractFormFields(state.edits, "gen"),
    });
    dispatch({
      type: "loading",
      payload: false,
    });
    dispatch({
      type: "showSuccessMsg",
      payload: true,
    });

    dispatch({
      type: "edits",
      payload: {},
    });

    dispatch({
      type: "editSubmission",
      payload: false,
    });

    fetchSubmitted();
  }

  async function fetchHhldOpportunities() {
    const {
      data: { data: opps },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/opportunities`);
    setHhldOpps(opps);
  }

  async function fetchDrawingResults() {
    const {
      data: { data: results },
    } = await axios.get(`${getBaseUrl()}/lottery/${drawingId}/results`);
    setResults(results);
  }

  async function fetchSubmitted() {
    const {
      data: { data: sub },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/submitted?submissionId=${submissionId}`
    );
    setSubmitted(sub);
    const questions = buildQuestions(sub.questions);
    const preferences = buildPreferences(sub.preferences);
    console.log("questions", questions);
    console.log("preferences", preferences);
    dispatch({
      type: "fields",
      payload: {
        questions,
        preferences,
      },
    });
  }
}

function HeaderLineItem({ label, value }) {
  if (!value) return null;
  return (
    <div className="flex space-x-2 justify-between py-1 items-baseline">
      <span className="text-gray-700">{value}</span>
      <span className="text-gray-500 text-xs">{label}</span>
    </div>
  );
}

function DrawingResults({ results, appId }) {
  if (results.length < 1)
    return <div className="">Results have not been published yet</div>;
  const myRank = results.find((r) => r.ApplicationFileID == appId);

  return myRank ? (
    <div className="flex flex-col  p-6 sm:w-2/5">
      <div className="flex items-baseline gap-4">
        <span className="text-gray-700">Current Rank:</span>
        <span className="text-2xl text-gray-600">{myRank.LastRank}</span>
      </div>
      <div className="flex items-baseline mt-4 justify-between ">
        <span className="text-gray-700">Drawing Method:</span>
        <span className="text-sm text-gray-500">
          {myRank.RankingDrawingDesc}
        </span>
      </div>
      <div className="flex items-baseline mt-1 justify-between">
        <span className="text-gray-700">Drawing Date:</span>
        <span className="text-sm text-gray-500">
          {myRank.RankingDrawingDate}
        </span>
      </div>
    </div>
  ) : (
    <div className="p-6 text-gray-700">
      <span>
        We couldn't find any results for{" "}
        <span className="bg-hk-orange-100  text-hk-orange-400 px-2 py-1 leading-relaxed">
          {appId}
        </span>{" "}
        at this time. Please check back later.
      </span>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="min-h-64 max-w-6xl"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function Opportunity({ opp }) {
  const classes = useStyles();
  const {
    AMIPct = "",
    IncomeCategory = "",
    OppDeliveryDate = "",
    OppDescription = "",
    OppSubject = "",
    PurchaseRentalFinanceAmt = "",
    OppPriority,
  } = opp;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const oppAvailable = true;

  return (
    <div className=" ">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={
                oppAvailable ? classes.avatarOpen : classes.avatarClosed
              }
            >
              <HomeIcon />
            </Avatar>
          }
          title={OppSubject}
        />

        <CardContent>
          <div className="flex space-x-2 items-baseline">
            <Typography align="left">
              {formatAsUSCurrency(PurchaseRentalFinanceAmt)}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              ({IncomeCategory})
            </Typography>
          </div>
          <div className="flex">
            <Typography
              color="textSecondary"
              align="left"
              variant="subtitle2"
            >{`AMI ${AMIPct}`}</Typography>
          </div>
          <div className="flex gap-2 mt-4 items-baseline">
            <span className="text-sm tracking-wide">Delivery Date: </span>
            <span className="text-gray-600">{OppDeliveryDate}</span>
          </div>

          <div className="flex gap-2  items-baseline">
            <span className="text-sm tracking-wide">Priority: </span>
            <Tooltip title="As assigned during drawing submission">
              <span className="text-gray-600">{OppPriority}</span>
            </Tooltip>
          </div>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <span
              className="text-gray-600 leading-relaxed text-left text-sm"
              dangerouslySetInnerHTML={{ __html: OppDescription }}
            ></span>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
/**
 *
 * @param  question
 * this is messed up how the api returns answers to questions,
 * the response is
 * {
 * AnswerOptions: "1~<p>Yes. I/we have not owned a home within the past three years</p>~0~|3~<p>Yes. I owned a home in the past 3 years, but I believe we qualify for an exception (example: displacement hardship)</p>~0~|4~<p>No. I/we have owned a home within the past three years</p>~0~|"
 * AnswerType: 3
 * CheckboxResponse: "0"
 * DecimalResponse: 0
 * DropDownResponse: 1
 * ExpectedAnswer: "1"
 * PassFailFlag: "P"
 * Question: "Are you eligible to be considered a first-time homebuyer?"
 * RadioResponse: "0"
 * }
 * The approach here is to figure out what kind of response was applied to the question, and then extract
 * the response from the AnswerOptions field
 * The api could simply just return the answer, instead of returning various fields like
 * DropDownResponse, CheckboxResponse etc
 *
 */
export function getAnswer(question) {
  const { AnswerOptions = "", AnswerType } = question;

  //build a map of values to the actual answer

  const ansMap =
    AnswerOptions?.split("|").reduce((acc, curr) => {
      const [value, option] = curr.split("~");
      if (option) {
        acc.set(option, value);
      }

      return acc;
    }, new Map()) ?? new Map();

  const answer = question[getAnswerType(AnswerType)] ?? "";

  return ansMap.get(String(answer)) || answer;
}

function getAnswerType(ansType) {
  switch (ansType) {
    case 1:
      return "CheckboxResponse";
    case 2:
      return "RadioResponse";

    case 3:
      return "DropDownResponse";

    case 4:
      return "DecimalResponse";
    case 5:
      return "TextResponse";

    case 6:
      return "DateResponse";
    case 7:
      return "NumericResponse";
  }
}

function buildQuestions(questions) {
  return questions.reduce((acc, curr) => {
    const { question_id, response } = curr;
    acc[question_id] = response;

    return acc;
  }, {});
}

function buildPreferences(preferences) {
  return preferences.reduce((acc, curr) => {
    const { preference_id, response } = curr;
    acc[preference_id] = response == true ? "1" : "2";

    return acc;
  }, {});
}
