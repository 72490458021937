import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

export default function PopperMsg({ open, handleClose, children, msgType }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      key={"topcenter"}
      autoHideDuration={120000}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={msgType}
      >
        {children}
      </MuiAlert>
    </Snackbar>
  );
}
