import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Select from "../../shared/Select";
import Address from "../../shared/form/Address";
import DatePicker from "../../shared/form/DatePicker";

const useStyles = makeStyles((theme) => ({
  checkBoxLabel: {
    "&& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",
      marginLeft: "-10px",
      color: "#718096",
    },
  },
  addressSwitchBoxLabel: {
    "&& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",

      color: "#718096",
    },
  },

  switchBase: { "&$checked": { backgroundColor: "green" } },
}));

export default function PersonalInfo({
  dispatch,
  dob,
  email,
  emailSameAsPrimary = true,
  firstName,
  handleChange,
  hhldAdminPresent,
  lastName,
  livesWithPrimary,
  maritalStatus,
  memberAddress,
  memberCity,
  memberZip,
  memberState,
  phone,
  relationToPrimary,
}) {
  const classes = useStyles();
  let primaryAppRelationOptions = [
    { label: "Spouse/Domestic Partner", value: 2 },
    { label: "Dependent", value: 3 },
    { label: "Parent", value: 4 },
    { label: "Sibling", value: 5 },
    { label: "Other", value: 6 },
  ];

  if (!hhldAdminPresent) {
    primaryAppRelationOptions.shift({ label: "Primary Applicant", value: 1 });
  }
  return (
    <>
      <div className="flex flex-col space-y-4">
        <TextField
          required
          name="firstName"
          size="small"
          InputLabelProps={{ shrink: true }}
          label="First Name"
          onChange={handleChange}
          value={firstName}
          variant="outlined"
        ></TextField>
        <TextField
          name="lastName"
          required
          InputLabelProps={{ shrink: true }}
          size="small"
          variant="outlined"
          label="Last Name"
          onChange={handleChange}
          value={lastName}
        ></TextField>
        <TextField
          name="phone"
          required
          InputLabelProps={{ shrink: true }}
          size="small"
          variant="outlined"
          label="Phone"
          onChange={handleChange}
          value={phone}
          inputProps={{ maxLength: 10 }}
        />
        <FormGroup row>
          <FormControlLabel
            className={classes.checkBoxLabel}
            control={
              <Switch
                classes={{ switchBase: classes.switchBase }}
                name="emailSameAsPrimary"
                checked={emailSameAsPrimary}
                onChange={handleChange}
              />
            }
            label="Email same as primary applicant"
            labelPlacement="start"
          />
        </FormGroup>
        <TextField
          variant="outlined"
          disabled={emailSameAsPrimary}
          size="small"
          name="email"
          label="Email"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          value={email}
        ></TextField>
        <DatePicker
          label="DOB"
          value={dob}
          handleChange={(date) =>
            handleChange({ target: { name: "dob", value: date } })
          }
        />
      </div>
      <div className="mt-4">
        <FormControl component="fieldset">
          <FormControl className="text-sm font-semibold" component="legend">
            Marital Status
          </FormControl>
          <RadioGroup
            aria-label="Marital Status"
            name="maritalStatus"
            value={Number(maritalStatus)}
            onChange={handleChange}
          >
            <FormControlLabel value={1} control={<Radio />} label="Single" />
            <FormControlLabel value={2} control={<Radio />} label="Married" />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label="Domestic Partner"
            />

            <FormControlLabel value={4} control={<Radio />} label="Widowed" />
            <FormControlLabel value={5} control={<Radio />} label="Divorced" />
          </RadioGroup>
        </FormControl>
      </div>

      <Select
        onChange={handleChange}
        name="relationToPrimary"
        label="Relationship with Primary Applicant"
        options={primaryAppRelationOptions}
        value={relationToPrimary}
      />

      <div className="-ml-4 mt-4 flex flex-col space-y-4">
        <FormGroup row>
          <FormControlLabel
            className={classes.addressSwitchBoxLabel}
            control={
              <Switch
                classes={{ switchBase: classes.switchBase }}
                name="livesWithPrimary"
                checked={livesWithPrimary}
                onChange={handleChange}
              />
            }
            label="Address same as primary applicant"
            labelPlacement="start"
          />
        </FormGroup>
      </div>

      <div className="flex flex-col sm:flex-row space-y-2">
        <Address
          onAddressChange={handleAddressChange}
          address={memberAddress}
          city={memberCity}
          state={memberState}
          zip={memberZip}
        />
      </div>
    </>
  );

  async function handleAddressChange(address) {
    dispatch({
      type: "memberAddr",
      payload: address?.street_line ?? "",
    });
    dispatch({
      type: "memberCity",
      payload: address?.city ?? "",
    });
    dispatch({
      type: "memberState",
      payload: address?.state ?? "",
    });
    dispatch({
      type: "memberZip",
      payload: address?.zipcode ?? "",
    });
    dispatch({
      type: "memberCounty",
      payload: address?.county || "",
    });
  }
}
