import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { formatDate } from "../../../utils/formatters";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import Address from "../../shared/form/Address";
import DatePicker from "../../shared/form/DatePicker";

function reducer(state, action) {
  switch (action.type) {
    case "loadingMember":
      return { ...state, loadingMember: action.payload };
    case "member":
      return { ...state, member: action.payload };
    case "firstName":
      return {
        ...state,
        member: { ...state.member, firstName: action.payload },
      };
    case "lastName":
      return {
        ...state,
        member: { ...state.member, lastName: action.payload },
      };
    case "email":
      return { ...state, member: { ...state.member, email: action.payload } };
    case "maritalStatus":
      return {
        ...state,
        member: { ...state.member, maritalStatus: action.payload },
      };
    case "dob":
      return {
        ...state,
        member: { ...state.member, dob: action.payload },
      };

    case "address":
      return { ...state, member: { ...state.member, address: action.payload } };
    case "city":
      return { ...state, member: { ...state.member, city: action.payload } };
    case "state":
      return { ...state, member: { ...state.member, state: action.payload } };
    case "zip":
      return { ...state, member: { ...state.member, zip: action.payload } };
    case "county":
      return { ...state, member: { ...state.member, county: action.payload } };
    case "phone":
      return { ...state, member: { ...state.member, phone: action.payload } };

    default:
      return state;
  }
}

export default function EditMember({
  memberId,
  userId,
  handleSubmit,
  onClose,
}) {
  const [fields, dispatch] = useReducer(reducer, {
    member: {},
    loadingMember: false,
  });

  useEffect(() => {
    fetchMember();
  }, []);

  const {
    address = "",
    address2,
    city = "",
    dob,
    email,
    firstName,
    lastName,
    maritalStatus,
    phone = "",
    roleAdmin = [],
    state = "",
    zip = "",
  } = fields.member;

  function handleChange(e) {
    const { name, value } = e.target;

    dispatch({
      type: name,
      payload: value,
    });
  }

  return (
    <form>
      <div className="flex flex-col space-y-4 mt-4">
        <TextField
          required
          name="firstName"
          size="small"
          InputLabelProps={{ shrink: true }}
          label="First Name"
          onChange={handleChange}
          value={firstName}
          variant="outlined"
        ></TextField>
        <TextField
          name="lastName"
          required
          InputLabelProps={{ shrink: true }}
          size="small"
          variant="outlined"
          label="Last Name"
          onChange={handleChange}
          value={lastName}
        ></TextField>
        <TextField
          required
          variant="outlined"
          size="small"
          name="email"
          label="Email"
          disabled={roleAdmin.includes(1)}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          value={email}
        ></TextField>
        <TextField
          required
          variant="outlined"
          size="small"
          name="phone"
          label="Phone"
          type="tel"
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          value={phone}
        ></TextField>

        <DatePicker
          label="DOB"
          value={dob}
          handleChange={(date) =>
            handleChange({ target: { name: "dob", value: date } })
          }
        />
      </div>
      <div className="mt-4">
        <FormControl component="fieldset">
          <FormControl className="text-sm font-semibold" component="legend">
            Marital Status
          </FormControl>
          <RadioGroup
            aria-label="Marital Status"
            name="maritalStatus"
            value={String(maritalStatus)}
            onChange={handleChange}
          >
            <FormControlLabel value="1" control={<Radio />} label="Single" />
            <FormControlLabel value="2" control={<Radio />} label="Married" />
            <FormControlLabel
              value="3"
              control={<Radio />}
              label="Domestic Partner"
            />

            <FormControlLabel value="4" control={<Radio />} label="Widowed" />
            <FormControlLabel value="5" control={<Radio />} label="Divorced" />
          </RadioGroup>
        </FormControl>
      </div>
      <Address
        onAddressChange={handleAddressChange}
        address={address}
        city={city}
        state={state}
        zip={zip}
        defaultValue={`${address}, ${city}, ${state}, ${zip}`}
      />

      <div className="flex space-x-2 py-4 justify-end">
        <Button variant="contained" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => handleSubmit(fields.member)}
          style={{ color: "#fff", backgroundColor: "#eb7e3c" }}
        >
          Submit
        </Button>
      </div>
    </form>
  );

  async function handleAddressChange(address) {
    dispatch({
      type: "address",
      payload: address?.street_line ?? "",
    });
    dispatch({
      type: "city",
      payload: address?.city ?? "",
    });
    dispatch({
      type: "state",
      payload: address?.state ?? "",
    });
    dispatch({
      type: "zip",
      payload: address?.zipcode ?? "",
    });
    dispatch({
      type: "county",
      payload: address?.county ?? "",
    });
  }

  async function fetchMember() {
    dispatch({
      type: "loadingMember",
      payload: true,
    });
    const {
      data: { data: member },
    } = await axios.get(
      `${getBaseUrl()}/user/${userId}/hhld_members/${memberId}`
    );

    dispatch({
      type: "loadingMember",
      payload: false,
    });
    dispatch({
      type: "member",
      payload: member?.map((m) => ({
        firstName: m.FirstName,
        lastName: m.LastName,
        email: m.Email,
        dob: formatDOB(m.DOB),
        maritalStatus: m.MaritalStatus,
        phone: m.MobilePhone?.[0],
        address: m.MemberAddress1,
        city: m.MemberCity,
        state: m.MemberState,
        zip: m.MemberZip,
        roleAdmin: m["Role_Admin"],
      }))[0],
    });
  }
}

function formatDOB(dob) {
  //extract date only and subtract the tz offset
  //https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
  const dt = new Date(dob);
  const dobDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );

  return formatDate(dobDateOnly);
}
