import React, { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import HistoryIcon from "@material-ui/icons/History";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import axios from "../../../../utils/axios";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import useQueryParams from "../../../shared/hooks/useQuery";
import Preferences from "./Preferences";
import MaterialUITable from "../../../shared/Table/MaterialUITable";
import LoadingBackdrop from "../../../shared/misc/LoadingBackDrop";
import { getDrawingType } from "../../../../utils/drawingUtils";
import {
  formatAsUSCurrency,
  formatNonUTC,
  formatDateAndTime,
  removeHTMLTagsFromString,
} from "../../../../utils/formatters";
import { useDrawingOpportunities } from "../../../../queries/programs/useDrawingOpportunities";
import { useDrawingDetails } from "../../../../queries/programs/useDrawingDetails";
import { useDrawingPreferences } from "../../../../queries/programs/useDrawingPreferences";
import logo from "../../../../media/HK-assets/hk_logo_mk.png";
import OpportunityCard from "../OpportunityCard";

function reducer(state, { payload, type }) {
  return {
    ...state,
    [type]: payload,
  };
}
export function RankingReview() {
  const [
    {
      fetchingWitnesses,
      witnesses,
      applicants,
      audits,
      showLoading,
      showOnlyUsersWithFileUploads,
    },
    dispatch,
  ] = useReducer(reducer, {
    applicants: [],
    audits: [],
    fetchingWitnesses: false,
    page: 0,
    rowsPerPage: 10,
    showLoading: false,
    witnesses: [],
    showOnlyUsersWithFileUploads: false,
  });

  let { drawingId, rankId } = useParams();
  const qryParams = useQueryParams();
  if (drawingId === undefined) {
    drawingId = qryParams.get("drawingId");
  }
  const programId = qryParams.get("programId");
  const opps = useDrawingOpportunities(drawingId, programId);
  const [drawing] = useDrawingDetails(drawingId);
  const preferences = useDrawingPreferences(drawingId, programId);

  const pageSizeOptions = [
    10,
    20,
    50,
    ...(applicants.length > 50 ? [applicants.length] : []),
  ];

  useEffect(() => {
    fetchWitnesses();
    fetchAudits();
    fetchApplicants();
  }, []);
  const opportunity = opps?.[0] ?? {};

  const tableColumns = [
    {
      title: "#",
      render: (rowData) => rowData.tableData.id + 1,
    },
    {
      title: "User ID",
      field: "userId",
      editable: "never",
    },
    {
      title: "Applicant ID",
      field: "applicationFileId",
    },
    {
      title: "Preferences",
      field: "prefTiers",
    },
    {
      title: "Total Points",
      field: "noOfPoints",
    },

    {
      title: "First",
      field: "firstName",
    },
    {
      title: "Last",
      field: "lastName",
    },

    {
      title: "Initial Rank",
      field: "initialRank",
    },
    {
      title: "Final Rank",
      field: "finalRanking",
    },
    {
      title: "File Submissions",
      field: "fileCount",
    },
    {
      title: "Entry Date",
      field: "entryDate",
    },
  ];

  const filteredApplicants = showOnlyUsersWithFileUploads
    ? applicants.filter((a) => a.fileCount > 0)
    : applicants;
  return (
    <section className="min-h-screen">
      <LoadingBackdrop open={showLoading} handleClose={() => {}} />
      <h2 className="flex justify-center items-center text-2xl bg-hk-orange-100 text-hk-orange-400 h-20 uppercase">
        Ranking Review
      </h2>
      <div className="flex space-x-2 items-start justify-end p-4">
        <Button
          color="primary"
          size="small"
          variant="contained"
          endIcon={<SaveAltIcon />}
          onClick={exportReview}
        >
          Export
        </Button>
      </div>
      <section className="flex flex-col w-full sm:w-1/2 p-4">
        <DrawingDetail label="Drawing Id" value={drawingId} />
        <DrawingDetail label="Ranking Id" value={rankId} />
        <DrawingDetail
          label="Ranking Type"
          value={getDrawingType(drawing.drawingType, drawing.prefTypeOption)}
        />
        <DrawingDetail label="City" value={drawing.entityName} />
        <DrawingDetail label="Program" value={drawing.programName} />
        <DrawingDetail
          label="Entry Deadline"
          value={formatDateAndTime(
            drawing.applicationEntryDate,
            drawing.applicationEntryTime
          )}
        />
        <DrawingDetail
          label="File Submission Deadline"
          value={formatDateAndTime(drawing.fileSubmissionDeadline)}
        />
      </section>
      <section className="flex flex-col sm:flex-row p-4">
        <Witnesses witnesses={witnesses} />

        <Preferences drawingId={drawingId} programId={programId} />
        <div className="mt-4  flex flex-col  overflow-y-scroll shadow-md min-h-32  sm:ml-4 w-full sm:w-2/5 flex-wrap">
          <section className="lottery-result-opp-card w-full">
            <div className="opp-card-header bg-hk-dark-blue py-2 rounded-t-md w-full flex sticky top-0 z-10">
              <span className="font-semibold oblique sticky text-white px-2">
                Opportunities ({opps.length})
              </span>
            </div>

            {opps.map((o) => {
              return <OpportunityCard key={o.OpportunityID} {...o} />;
            })}
          </section>
        </div>
      </section>
      <History audits={audits} drawing={drawing} />

      <div className="flex pt-8 px-4 justify-end">
        <FormControlLabel
          control={
            <Checkbox
              checked={showOnlyUsersWithFileUploads}
              onChange={() =>
                dispatch({
                  type: "showOnlyUsersWithFileUploads",
                  payload: !showOnlyUsersWithFileUploads,
                })
              }
              name="showOnlyUsersWithFileUploads"
            />
          }
          label="Show only users with file uploads"
        />
      </div>
      <section className="mt-4">
        <MaterialUITable
          id="rankings-table"
          isLoading={fetchingWitnesses}
          data={filteredApplicants}
          columns={tableColumns}
          title={`# Applicants (${applicants.length}) in Ranking Index ${rankId}`}
          options={{
            exportButton: true,

            grouping: true,

            pageSizeOptions: pageSizeOptions,
            pageSize: 10,
          }}
        />
      </section>
    </section>
  );

  async function fetchWitnesses() {
    dispatch({
      type: "fetchingWitnesses",
      payload: true,
    });

    const {
      data: { data: witnesses },
    } = await axios.get(
      `${getBaseUrl()}/rankings/${rankId}/witnesses?drawingId=${drawingId}`
    );
    dispatch({
      type: "fetchingWitnesses",
      payload: false,
    });
    dispatch({
      type: "witnesses",
      payload: witnesses,
    });
  }

  async function fetchAudits() {
    const {
      data: { data: audits },
    } = await axios.get(`${getBaseUrl()}/rankings/${rankId}/audits`);
    dispatch({
      type: "audits",
      payload: audits,
    });
  }

  async function fetchApplicants() {
    const {
      data: { data: applicants },
    } = await axios.get(
      `${getBaseUrl()}/rankings/${rankId}/applicants?drawingId=${drawingId}&programId=${programId}`
    );

    dispatch({
      type: "applicants",
      payload: applicants.map((a) => ({
        ...a,
        entryDate: formatNonUTC(a.entryDate, "MM/dd/yyyy h:mm:SSS a"),
      })),
    });
  }

  function exportReview() {
    dispatch({ type: "showLoading", payload: true });

    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    let doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(10);
    doc.addImage(logo, "png", 30, 20, 100, 30);

    doc.text(`${opportunity.EntityName}`, 40, 80);
    doc.text(`${opportunity.ProgramTitle}`, 40, 100);
    doc.text(
      `Ranking Type: ${getDrawingType(
        drawing.drawingType,
        drawing.prefTypeOption
      )}`,
      40,
      120
    );
    doc.text(`Drawing Id: ${drawingId}, ranking: ${rankId}`, 40, 140);

    doc.text(
      `Max hhld Size : ${drawing.maxHHLDSize}, Min hhld Size : ${drawing.minHHLDSize}`,
      40,
      160
    );

    doc.text(
      ` Entry Deadline : ${formatDateAndTime(
        drawing.applicationEntryDate,
        drawing.applicationEntryTime
      )}`,
      40,
      180
    );

    doc.text(
      `File Submission Deadline : ${formatDateAndTime(
        drawing.fileSubmissionDeadline
      )}`,
      40,
      200
    );

    doc.text(removeHTMLTagsFromString(drawing.lotteryDescription), 40, 220, {
      maxWidth: 800,
    });
    const lastAuditEntry = audits[audits.length - 1];
    if (lastAuditEntry && Object.keys(lastAuditEntry).length > 0) {
      const { ActionPerformed, ActionTimestamp } = lastAuditEntry;
      doc
        .setTextColor("#eb7e3c")
        .setFontSize(15)
        .text(
          `Milestone: ${ActionPerformed} ${formatNonUTC(
            ActionTimestamp,
            "MM/dd/yyyy h:mm:ss a"
          )} `,
          40,
          300
        );
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
    }
    doc.text("History", 180, 320);
    let history = {
      startY: 340,
      head: [["Action", "By", "On"]],
      body: audits.map((a) => [
        a.ActionPerformed?.includes("Initial")
          ? `${a.ActionPerformed} using ${getDrawingType(
              drawing.drawingType,
              drawing.prefTypeOption
            )}`
          : a.ActionPerformed,
        a.ContactName,
        formatNonUTC(a.ActionTimestamp, "MM/dd/yyyy h:mm:ss a"),
      ]),
    };
    doc.autoTable(history);
    //witnesses
    doc.addPage(size, orientation);
    doc.text(`Witnesses (${witnesses.length})`, 180, 40);

    let witnessContent = {
      startY: 60,
      head: [["Name", "Email", "Witnessed"]],
      body: witnesses.map((w) => [
        `${w.FirstName} ${w.LastName}`,
        w.Email,
        w.WitnessFlag,
      ]),
    };
    doc.autoTable(witnessContent);
    //opportunities
    doc.addPage(size, orientation);
    doc.text(`Opportunities (${opps.length})`, 80, 40);
    let oppsContent = {
      startY: 60,
      head: [
        [
          "Address",
          "City",
          "State",
          "Zip",
          "Income Level",
          "Price",
          "Max hhld Size",
          "Min hhld Size",
          "PreComponent Name",
          "AMI",
        ],
      ],
      body: opps.map((o) => [
        o.Address1,
        o.City,
        o.State,
        o.ZipCode,
        o.CandidateIncomeLevelDesc,
        formatAsUSCurrency(o.PurchaseRentalFinanceAmt),
        o.MaxHHLDSize,
        o.MinHHLDSize,
        o.PreComponentName,
        `${o.AMIPct} %`,
      ]),
    };
    doc.autoTable(oppsContent);

    //preferences
    doc.addPage(size, orientation);
    doc.text(`Preferences (${preferences.length})`, 80, 40);
    let prefsContent = {
      startY: 60,
      head: [["Tier", "Preference", "Points"]],
      body: preferences.map((p) => [p.Priority, p.PreferenceTitle, p.Points]),
    };
    doc.autoTable(prefsContent);
    //applicants
    doc.addPage(size, orientation);
    doc.text(
      `Applicants (${applicants.length}) in Ranking Index ${rankId}, Drawing Index ${drawingId} `,
      80,
      40
    );

    if (lastAuditEntry && Object.keys(lastAuditEntry).length > 0) {
      const { ActionPerformed, ActionTimestamp } = lastAuditEntry;
      doc
        .setTextColor("#eb7e3c")
        .text(
          `Milestone: ${ActionPerformed} ${formatNonUTC(
            ActionTimestamp,
            "MM/dd/yyyy h:mm:ss a"
          )} `,
          80,
          60
        );
    }
    let applicantsContent = {
      startY: 80,
      head: [tableColumns.flatMap((t) => t.title)],
      body: applicants.map((a, i) => {
        return [
          i + 1, //start at 1 to match the order shown in the MaterialTable
          a.userId,
          getAbbreviatedAppId(a.applicationFileId),
          a.prefTiers,
          a.noOfPoints,
          a.firstName,
          a.lastName,
          a.initialRank,
          a.finalRanking,
          a.fileCount,
          a.entryDate,
        ];
      }),
    };
    doc.autoTable(applicantsContent);
    doc
      .save(`Drawing(${drawingId})_review.pdf`, { returnPromise: true })
      .then(() => {
        dispatch({ type: "showLoading", payload: false });
      });
  }
}

function getAbbreviatedAppId(appId) {
  const [
    _1,
    _2,
    _3,
    _4,
    _5,
    _6, //year
    _7, //hk assigned Id
  ] = appId.split("-");
  if (!_7) {
    return `${_5}-${_6}`; //possible when there are only 6 values separated by - (for certain programs)
  }
  return `${_6}-${_7}`;
}

function Witnesses({ witnesses }) {
  return (
    <div className="h-64 overflow-y-scroll shadow-md w-full sm:w-64">
      <div className="flex justify-between items-center bg-gray-100 p-1">
        <h6 className="text-sm font-semibold px-2">{`Witnesses (${witnesses.length})`}</h6>
      </div>
      {witnesses.map((w) => (
        <div
          key={w.UserID}
          className="flex justify-between p-4 text-xs border-b border-gray-200"
        >
          <div className="flex  items-center">
            {w.WitnessFlag === "Y" ? (
              <CheckCircleIcon fontSize="small" className="text-green-500" />
            ) : (
              <CancelIcon fontSize="small" color="secondary" />
            )}

            <span className="px-2">
              {w.FirstName} {w.LastName}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

function History({ audits, drawing }) {
  return (
    <section className="mt-2 border-t-2 border-gray-200 w-full">
      <h6 className="text-gray-800 flex justify-center items-center p-1 border-b-2 border-gray-200 text-sm font-bold mt-2">
        <HistoryIcon className="mr-2" />
        History
      </h6>
      <div className="mt-2">
        <div className="grid grid-cols-3 gap-1 text-xs font-semibold oblique">
          <span className="underline">Action</span>
          <span className="underline">By</span>
          <span className="underline">On</span>
        </div>
      </div>
      {audits.map((a) => (
        <div
          key={a.ActionTimestamp}
          className="grid grid-cols-3 gap-1 p-2 text-xs"
        >
          <span>
            {a.ActionPerformed?.includes("Initial")
              ? `${a.ActionPerformed} using ${getDrawingType(
                  drawing.drawingType,
                  drawing.prefTypeOption
                )}`
              : a.ActionPerformed}
          </span>
          <span>{a.ContactName}</span>

          <span>{formatNonUTC(a.ActionTimestamp, "MM/dd/yyyy h:mm:ss a")}</span>
        </div>
      ))}
    </section>
  );
}

function DrawingDetail({ label, value }) {
  return (
    <div className="grid grid-cols-3 space-y-2 text-sm sm:text-md items-center">
      <span className="col-span-1 text-gray-700  text-left">{label} :</span>
      <span className="col-span-2 text-gray-500 text-left">{value}</span>
    </div>
  );
}
