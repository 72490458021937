import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";
import useCardStyles from "./cardStyles";

import { propIn } from "../../../utils/jsUtils";
import CardItem from "./CardItem";

export default function RankingCard({ clickHandler, rankingStats }) {
  const classes = useCardStyles();
  return (
    <section
      className="drawings p-4 cursor-pointer w-full sm:w-1/4"
      onClick={clickHandler}
    >
      <Card className={classes.root}>
        <CardHeader className={classes.header} title="Rankings"></CardHeader>
        <Divider />
        <CardContent>
          <List>
            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumRankingsCount", rankingStats)}
                label="Total"
                value={rankingStats.NumRankingsCount}
              />
            </ListItem>
            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumOpportunitiesCount", rankingStats)}
                label="Opportunities"
                value={rankingStats.NumOpportunitiesCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("OpenRankingsCount", rankingStats)}
                label="Open Rankings"
                value={rankingStats.OpenRankingsCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumTotalApplicantsCount", rankingStats)}
                label="Applicants"
                value={rankingStats.NumTotalApplicantsCount}
              />
            </ListItem>
            <ListItem>
              <CardItem
                skeletonCond={!propIn("NumActiveApplicantsCount", rankingStats)}
                label="Active Applicants"
                value={rankingStats.NumActiveApplicantsCount}
              />
            </ListItem>

            <ListItem>
              <CardItem
                skeletonCond={!propIn("PublishedCount", rankingStats)}
                label="Published"
                value={rankingStats.PublishedCount}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </section>
  );
}
