import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PhoneIcon from "@material-ui/icons/Phone";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";

export default function MemberLineItem({ profile, removeMember, editMember }) {
  const { MemberID, FirstName, LastName } = profile;
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [emulatedUser] = useLocalStorage("emulatedUser");
  return (
    <div className=" py-2">
      <MemberMobileView
        profile={profile}
        setShowRemoveAlert={setShowRemoveAlert}
        removeMember={removeMember}
        editMember={editMember}
        emulatedUser={emulatedUser}
      />

      <Dialog open={showRemoveAlert}>
        <DialogContent>
          Are you sure you want to remove {FirstName} {LastName}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRemoveAlert(false)}>Cancel</Button>
          <Button
            style={{ color: "red" }}
            onClick={() => {
              removeMember(MemberID);
              setShowRemoveAlert(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MemberDesktopView
        profile={profile}
        emulatedUser={emulatedUser}
        setShowRemoveAlert={setShowRemoveAlert}
        removeMember={removeMember}
        editMember={editMember}
      />
    </div>
  );
}

function MemberMobileView({
  profile,
  setShowRemoveAlert,
  editMember,
  emulatedUser,
}) {
  const {
    Address,
    City,
    DeleteOpt,
    Email,
    FirstName,

    LastName,
    MemberID,
    MobilePhone,
    Role_Admin,
    State,
    ZipCode,
  } = profile;
  return (
    <div className="sm:hidden">
      <div className=" flex  w-full  px-2 items-baseline text-sm ">
        <div className="mr-2">
          <AccountCircleIcon className="text-gray-400" />
        </div>

        <div className="flex flex-col text-left ">
          <div className="flex gap-1 items-baseline">
            <span className="uppercase tracking-wider">{`${FirstName} ${LastName}`}</span>
            {Role_Admin == 1 && (
              <span className="text-xs text-hk-orange">(Admin)</span>
            )}
          </div>
          <div className="text-gray-500 flex flex-col">
            <span className="pt-2">{Email}</span>
            {MobilePhone && (
              <span className="flex gap-1 ">
                <PhoneIcon fontSize="small" />
                <span className="mt-px">{MobilePhone}</span>
              </span>
            )}
          </div>
        </div>

        <div className="ml-auto">
          <IconButton
            disabled={emulatedUser}
            onClick={() => editMember(MemberID)}
          >
            <EditIcon
              styles={{
                fontSize: "16px",
              }}
              className="text-blue-600"
              size="small"
            />
          </IconButton>
          {DeleteOpt === "Y" && Role_Admin != 1 && !emulatedUser && (
            <Tooltip title={`remove ${FirstName} ${LastName} from household`}>
              <IconButton
                onClick={() => {
                  setShowRemoveAlert(true);
                }}
              >
                <DeleteIcon
                  styles={{
                    fontSize: "12px",
                  }}
                  size="small"
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex gap-1 text-xs pt-4 pb-2 pl-10 text-gray-500">
        <span className="">{Address}</span>
        <span>{City}</span>
        <span>, {State}</span>
        <span>, {ZipCode}</span>
      </div>
    </div>
  );
}

function MemberDesktopView({
  profile,
  setShowRemoveAlert,
  editMember,
  emulatedUser,
}) {
  const {
    Address,
    City,
    DeleteOpt,
    Email,
    FirstName,
    InviteeStatus,
    LastName,
    MemberID,
    MobilePhone,

    Role_Admin,
    State,
    ZipCode,
  } = profile;
  return (
    <div className="hidden sm:grid grid-cols-12 mt-2 text-gray-700 text-sm">
      <div className="col-span-2">
        <MemberName
          firstName={FirstName}
          lastName={LastName}
          isAdmin={Role_Admin == 1}
        />
      </div>

      <span className="col-span-1"></span>
      <div className="col-span-2 flex flex-col text-sm">
        <span>{Email}</span>
        {MobilePhone && (
          <span className="flex gap-1 justify-center">
            <PhoneIcon fontSize="small" />
            <span className="mt-px">{MobilePhone}</span>
          </span>
        )}
      </div>

      <div className="col-span-4 flex items-baseline space-x-1 text-xs  text-gray-500">
        <span className="">{Address}</span>
        <span>{City}</span>
        <span>, {State}</span>
        <span>, {ZipCode}</span>
      </div>

      <span className="col-span-1"></span>
      <span className="col-span-1">{InviteeStatus ? "Y" : "N"}</span>
      <div className="col-span-1 -mt-4">
        <IconButton
          disabled={emulatedUser}
          onClick={() => editMember(MemberID)}
        >
          <EditIcon
            styles={{
              fontSize: "16px",
            }}
            className="text-blue-600"
            size="small"
          />
        </IconButton>
        {DeleteOpt === "Y" && Role_Admin != 1 && !emulatedUser && (
          <Tooltip title={`remove ${FirstName} ${LastName} from household`}>
            <IconButton
              onClick={() => {
                setShowRemoveAlert(true);
              }}
            >
              <DeleteIcon
                styles={{
                  fontSize: "12px",
                }}
                size="small"
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

function MemberName({ isAdmin, firstName, lastName }) {
  return (
    <div className="flex items-baseline text-sm">
      <div className="mr-2">
        <AccountCircleIcon className="text-gray-400" />
      </div>

      <div className="flex flex-col text-left ">
        <div className="flex space-x-1 items-baseline">
          <span className="uppercase tracking-wider text-gray-700">{`${firstName} ${lastName}`}</span>
          {isAdmin && <span className="text-xs text-hk-orange">(Admin)</span>}
        </div>
      </div>
    </div>
  );
}
