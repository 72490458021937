import React, { useState } from "react";

export default function useCreateRankingDialog() {
  const [showRankingDialog, toggleRankingDialog] = useState(false);

  return {
    showRankingDialog,
    toggleRankingDialog,
  };
}
