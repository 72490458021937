import React, { useReducer, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import EmailIcon from "@material-ui/icons/Email";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RefreshIcon from "@material-ui/icons/Refresh";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SyncIcon from "@material-ui/icons/Sync";
import FileCabinet from "../../files/FileCabinet";
import AdminMemberFileList from "../../files/AdminMemberFileList";
import HHLDRegSummary from "../../hhld/dashboard/HHLDRegSummary";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { hasHKEmail } from "../../../utils/authUtils";

const { REACT_APP_STAGE } = process.env;

type ReducerState = {
  members: [];
  showLoading: boolean;
  incomes: [];
  files: [];
  user: {};
  updatedPassword: string;
  showSetPasswordModal: boolean;
  showPassword: boolean;
};

function reducer(state: ReducerState, action) {
  return {
    ...state,
    [action.name]: action.payload,
  };
}

export default function UserDetails() {
  const { userId } = useParams();

  const [
    {
      members,
      incomes,
      files,
      showLoading,
      user,
      updatedPassword,
      showSetPasswordModal,
      showPassword,
    },
    dispatch,
  ] = useReducer(reducer, {
    members: [],
    showLoading: false,
    incomes: [],
    files: [],
    user: {},
    updatedPassword: "",
    showSetPasswordModal: false,
    showPassword: false,
  });

  useEffect(() => {
    fetchUserDetail();

    fetchMembers();
  }, []);

  useEffect(() => {
    if (!user.MemberID) return;
    // fetchIncomes();
    fetchFiles();
  }, [user.MemberID]);

  /* const allowCognitoSync =
    REACT_APP_STAGE === "prod" ? true : hasHKEmail(user.Username); */

  const allowCognitoSync = true;

  return (
    <section className="min-h-screen ">
      <div className="p-2">
        <aside className="">
          <HHLDRegSummary
            user={{
              userId: userId,
              firstName: user.FirstName,
              lastName: user.LastName,
              memberId: user.MemberID,
            }}
          />
        </aside>
        {user.MemberID && (
          <FileCabinet
            adminMode
            userId={user.UserID}
            memberId={user.MemberID}
          />
        )}
        <div className="sm:flex sm:space-x-8">
          {allowCognitoSync && (
            <section className=" shadow-md mt-4  w-full ">
              <h3 className="w-full bg-hk-cyan-100 text-hk-cyan-400 h-10  flex justify-center items-center uppercase text-xl ">
                Auth
              </h3>

              <div className="p-4 flex space-x-2 flex-wrap items-start">
                <Action
                  handler={handleResetPasword}
                  label="Reset Password"
                  title="This will mark the user's account to require password reset."
                  subtitle="A verification code will be sent to the user."
                  icon={<RefreshIcon fontSize="large" />}
                />

                <Action
                  handler={showUpdatePasswordModal}
                  label="Set Password"
                  title="This will update the user's password ."
                  subtitle="No email is sent to the user for now. Admin users will have to send the password separately (securely) manually for now."
                  icon={<RefreshIcon fontSize="large" />}
                />

                {user.AccountStatus === "A" && (
                  <Action
                    handler={handleDeactivateUser}
                    label="De-Activate user"
                    title="This will mark the user's account as inactive in the db ."
                    subtitle="This does not disable/delete the users account from cognito."
                    icon={<PersonAddDisabledIcon fontSize="large" />}
                  />
                )}
                {user.AccountStatus !== "A" && (
                  <Action
                    handler={handleActivateUser}
                    label="Activate user"
                    title="This will mark the user's account as active in the db ."
                    subtitle="This does not affect the users account in cognito."
                    icon={<RefreshIcon fontSize="large" />}
                  />
                )}
                {user.userExistsInCognito && (
                  <Action
                    handler={handleDeleteUser}
                    label="Delete user"
                    title="This will delete the user's account from cognito ."
                    subtitle="The user won't be able to log in anymore."
                    icon={
                      <RemoveCircleIcon
                        className="text-red-500"
                        fontSize="large"
                      />
                    }
                  />
                )}

                {!user.userExistsInCognito && (
                  <Action
                    handler={handleSyncToCognito}
                    label="Sync User to Cognito"
                    title="This can be used when a user registered outside of the react app. This will create an account for the user in the react app."
                    subtitle="A welcome email will be sent to the user with a temporary password."
                    icon={<SyncIcon fontSize="large" />}
                  />
                )}
                <Action
                  handler={confirmUserSignup}
                  label="Confirm user signup"
                  title="This can be used to confirm a users signup."
                  subtitle="No emails/messages sent to the user currently."
                  icon={<ConfirmationNumberIcon fontSize="large" />}
                />
                <Action
                  handler={verifyEmail}
                  label="Verify email"
                  title="This can be used to verify a users email (if it's not been verified during initial signup)."
                  subtitle="No emails/messages sent to the user currently."
                  icon={<EmailIcon fontSize="large" />}
                />
              </div>
            </section>
          )}
        </div>
      </div>
      <Dialog open={showSetPasswordModal}>
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent>
          <form>
            <TextField label="Username" value={user.Username} disabled />
            <FormControl>
              <InputLabel htmlFor="updated-password">New Password</InputLabel>
              <Input
                id="updated-password"
                type={showPassword ? "text" : "password"}
                value={updatedPassword}
                onChange={(e) => {
                  dispatch({
                    name: "updatedPassword",
                    payload: e.target.value,
                  });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch({
                name: "showSetPasswordModal",
                payload: false,
              });
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleSetPasword}>Update</Button>
        </DialogActions>
      </Dialog>
    </section>
  );

  async function fetchMembers() {
    try {
      const {
        data: { data: members },
      } = await axios.get(`${getBaseUrl()}/user/${userId}/hhld_members`);

      dispatch({ name: "members", payload: members });
    } catch (e) {}
  }

  async function fetchUserDetail() {
    try {
      const {
        data: { data: user },
      } = await axios.get(`${getBaseUrl()}/user/${userId}`);

      dispatch({ name: "user", payload: user });
    } catch (e) {}
  }

  async function fetchFiles() {
    const {
      data: { data: files },
    } = await axios.get(
      `${getBaseUrl()}/user/${userId}/files?memberId=${user.MemberID}`
    );
    dispatch({ name: "files", payload: files });
  }

  async function handleResetPasword() {
    const {
      data: { data: User },
    } = await axios.post(`${getBaseUrl()}/user/${user.UserID}/reset_pw`, {
      userName: user.Username,
    });
  }

  async function handleSetPasword() {
    const {
      data: { data: User },
    } = await axios.put(`${getBaseUrl()}/user/${user.UserID}/update_pw`, {
      userName: user.Username,
      pw: updatedPassword,
    });
    dispatch({
      name: "showSetPasswordModal",
      payload: false,
    });
  }

  async function confirmUserSignup() {
    const {
      data: { data: User },
    } = await axios.put(`${getBaseUrl()}/user/${user.UserID}/confirm_signup`, {
      userName: user.Username,
    });
  }

  async function verifyEmail() {
    const {
      data: { data: User },
    } = await axios.put(`${getBaseUrl()}/user/${user.UserID}/verify_email`, {
      userName: user.Username,
    });
  }

  function showUpdatePasswordModal() {
    dispatch({
      name: "showSetPasswordModal",
      payload: true,
    });
  }
  async function handleDeactivateUser() {
    await axios.post(`${getBaseUrl()}/user/${userId}`, {
      status: "I",
    });
    fetchUserDetail();
  }

  async function handleActivateUser() {
    await axios.post(`${getBaseUrl()}/user/${userId}`, {
      status: "A",
    });
    fetchUserDetail();
  }

  async function handleDeleteUser() {
    await axios.delete(`${getBaseUrl()}/user/${userId}`);
  }

  function handleClickShowPassword() {
    dispatch({
      name: "showPassword",
      payload: !showPassword,
    });
    // setValues({ ...values, showPassword: !values.showPassword });
  }

  async function handleSyncToCognito() {
    const {
      data: { data: files },
    } = await axios.post(`${getBaseUrl()}/user/cognito_sync`, {
      userName: user.Username,
      firstName: user.FirstName,
      lastName: user.LastName,
    });
    fetchUserDetail();
  }
}

function Permissions() {
  return (
    <section className="shadow-md mt-4 w-full sm:w-80">
      <h3 className="bg-hk-orange-100 w-full p-2 text-hk-orange-400">
        Manage Permissions
      </h3>
      <section className="p-2 flex flex-col items-start">
        <Tooltip title="Add file permissions">
          <Button
            size="small"
            className="text-hk-cyan-400"
            startIcon={<FileCopyIcon />}
          >
            Add File Permissions
          </Button>
        </Tooltip>
        <Tooltip title="Make hhld Admin">
          <Button size="small" startIcon={<SupervisorAccountIcon />}>
            Make Admin
          </Button>
        </Tooltip>
      </section>
    </section>
  );
}

type Action = {
  icon: React.ReactElement;
  handler: () => void;
  label: string;
  title: string;
  subtitle: string;
};

function Action({ icon, handler, label, title, subtitle }: Action) {
  return (
    <div className="shadow-md flex flex-col justify-center items-center h-72 p-4 w-full sm:w-80 text-sm">
      {icon}
      <p className="mt-4 text-gray-700">{title}</p>
      <span className="text-gray-500 mt-2 text-xs">{subtitle}</span>
      <div className="mt-auto">
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handler}
        >
          {label}
        </Button>
      </div>
    </div>
  );
}
