import React, { useContext } from "react";

import HHLDDashboard from "../hhld/dashboard/HHLDDashboard";
import AgencyDashboard from "../agency/dashboard/AgencyDashboard";
import UserContext from "../user/UserContext";
import {
  isInternalAdmin,
  isExternalAdmin,
  isHHldApplicant,
} from "../../utils/authUtils";

function UserHome() {
  const { user } = useContext(UserContext) || {};

  return (
    <div className="min-h-screen flex flex-col flex-wrap">
      {isHHldApplicant(user.profileType) && <HHLDDashboard user={user} />}
      {(isInternalAdmin(user.profileType) ||
        isExternalAdmin(user.profileType)) && <AgencyDashboard />}
    </div>
  );
}

export default UserHome;
