import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useProgramApplications(userId) {
  const [programApplications, setProgramApplications] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data: applications },
      } = await axios.get(
        `${getBaseUrl()}/user/${userId}/applications?rowCnt=100&page=1`
      );

      setProgramApplications(
        applications.filter((a) => a.ApplicationFileID != "")
      );
    })();
  }, []);

  return programApplications;
}
