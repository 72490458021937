import React, { useContext } from "react";
import FileCabinet from "./FileCabinet";
import AdminFileCabinet from "./AdminFileCabinet";
import AdminMemberFileList from "./AdminMemberFileList";
import { FileDownloads } from "./FileDownloads";
import { AllUploads } from "./AllUploads";
import { Route, Switch } from "react-router-dom";

export default function FileCabinetRoutes() {
  return (
    <Switch>
      <Route exact path="/file-cabinet">
        <FileCabinet />
      </Route>
      <Route exact path="/file-cabinet/downloads">
        <FileDownloads />
      </Route>
      <Route exact path="/file-cabinet/uploads">
        <AllUploads />
      </Route>
      <Route exact path="/file-cabinet/admin">
        <AdminFileCabinet />
      </Route>
      <Route exact path="/file-cabinet/admin/files/:memberId">
        <AdminMemberFileList />
      </Route>
    </Switch>
  );
}
