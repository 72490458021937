import React, { useReducer, useEffect, useContext } from "react";
import { Button, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HouseIcon from "@material-ui/icons/House";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import MaterialUITable from "../../shared/Table/MaterialUITable";

import { usePrograms } from "../../../queries/programs/usePrograms";
import { removeHTMLTagsFromString } from "../../../utils/formatters";

export default function Programs(props) {
  const history = useHistory();

  const programs = usePrograms();

  const columns = [
    {
      title: "ID",
      field: "ProgramID",
    },
    {
      title: "Date Created",
      field: "DateCreated",
    },
    {
      title: "Title",
      field: "Title",
    },
    {
      title: "Description",
      field: "Description",
      render: (row) => removeHTMLTagsFromString(row.Description),
    },
    {
      title: "Program Type",
      field: "ProgramType",
    },
    {
      title: "# Applicants",
      field: "numApplicants",
    },
    {
      title: "Published",
      field: "PublishFlag",
      render: (row) => (row.PublishFlag == 1 ? "Y" : "N"),
    },
  ];

  return (
    <div className="min-h-screen  py-4 mt-2">
      <form className="flex justify-end p-2">
        <Button
          disabled
          startIcon={<AddCircleIcon />}
          variant="contained"
          color="primary"
          size="small"
        >
          Add Program
        </Button>
      </form>
      <section className="p-2">
        <MaterialUITable
          title={`Programs (${programs.length})`}
          columns={columns}
          data={programs}
          actions={[
            {
              icon: ChevronRightIcon,
              tooltip: "View Details",
              onClick: (event, row) =>
                history.push(`/programs/${row.ProgramID}`),
            },
            {
              icon: HouseIcon,
              tooltip: "View Inventory",
              onClick: (event, row) =>
                history.push(`/opportunities?programId=${row.ProgramID}`),
            },
            {
              icon: "D",
              tooltip: "View Drawings",
              onClick: (event, row) =>
                history.push(`/drawings?programId=${row.ProgramID}`),
            },
            {
              icon: PeopleAltIcon,
              tooltip: "View Applicants",
              onClick: (event, row) =>
                history.push(`/programs/applicants?programId=${row.ProgramID}`),
            },
          ]}
        />
      </section>
    </div>
  );
}
