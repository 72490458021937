import React from "react";
import { TextField } from "@material-ui/core";
import Select from "../../shared/Select";
import DatePicker from "../../shared/form/DatePicker";
import Address from "../../shared/form/Address";
import AnnualIncome from "../../shared/form/AnnualIncome";

export default function RetirementIncomeForm({
  fields,
  handleChange,
  handleEmployerAddressChange,
}) {
  const {
    dateIncomeStarted,
    retirementIncomeSource,
    paymentFrequency,
    annualIncome,
    retirementInstName,
    employerAddr,
    employerCity,
    employerState,
    employerZip,
  } = fields;
  return (
    <form className=" mt-4 flex flex-col sm:grid grid-cols-2 gap-4">
      <DatePicker
        value={dateIncomeStarted}
        required
        label="Date Income Started"
        handleChange={(date) =>
          handleChange({ target: { name: "dateIncomeStarted", value: date } })
        }
      />

      <Select
        value={retirementIncomeSource}
        onChange={handleChange}
        name="retirementIncomeSource"
        label="Income Source"
        required
        options={[
          { label: "Social Security", value: "ssn" },
          { label: "State Retirement", value: "state" },
          { label: "Local Government", value: "localGov" },
          { label: "Union Pension", value: "unionPension" },
          { label: "Private Pension", value: "privatePension" },
          { label: "Other", value: "other" },
        ]}
      />

      <Select
        value={paymentFrequency}
        onChange={handleChange}
        name="paymentFrequency"
        label="Payment  Frequency"
        options={[
          { label: "Weekly", value: 1 },
          { label: "Bi-Weekly", value: 2 },
          { label: "Monthly Liability", value: 3 },
          { label: "Quarterly", value: 4 },
          { label: "Annually", value: 5 },
        ]}
      />
      <AnnualIncome value={annualIncome} handleChange={handleChange} />

      <TextField
        onChange={handleChange}
        value={retirementInstName}
        required
        name="retirementInstName"
        label="Institution Name"
      />

      <Address
        onAddressChange={handleEmployerAddressChange}
        address={employerAddr}
        required
        city={employerCity}
        state={employerState}
        zip={employerZip}
        defaultValue={`${employerAddr}, ${employerCity}, ${employerState}, ${employerZip} `}
      />
    </form>
  );
}
