import React, { useReducer } from "react";
import { Auth } from "aws-amplify";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FormGroup from "../../shared/form/FormGroup";
import PopperMsg from "../../shared/misc/PopperMsg";

type ConfirmSignupProps = {
  setStatus: Function;
};

interface ReducerState {
  userName: string;
  confirmationCode: string;
  processing: boolean;
  showSuccess: boolean;
  showError: boolean;

  message: string;
}

type ReducerAction = {
  type: string;
  payload: string | number | boolean;
};

function reducer(state: ReducerState, action: ReducerAction) {
  switch (action.type) {
    case "userName":
      return { ...state, userName: action.payload };
    case "confirmationCode":
      return { ...state, confirmationCode: action.payload };
    case "processing":
      return { ...state, processing: action.payload };
    case "showSuccess":
      return {
        ...state,
        showSuccess: action.payload,
        showError: false,
        message: "Account Verified. Please sign in to proceed.",
      };
    case "showError":
      return {
        ...state,
        showSuccess: false,
        showError: action.payload,
        message: "Verification failed. Please check your verification code.",
      };
    default:
      return state;
  }
}

export default function ConfirmSignup({ setStatus }: ConfirmSignupProps) {
  const [state, dispatch] = useReducer(reducer, {
    confirmationCode: "",
    userName: "",
    processing: false,
    showSuccess: false,
    showError: false,
    message: "",
  });

  const {
    confirmationCode,
    userName,
    showSuccess,
    showError,
    processing,

    message,
  } = state;

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    dispatch({
      type: name,
      payload: value,
    });
  };

  return (
    <form className="w-full sm:w-96 shadow-md p-8" onSubmit={handleSubmit}>
      <p className="font-bold text-left pt-4">Confirm Sign up</p>
      <div className="mt-4 space-y-2 flex flex-col">
        <FormGroup
          label="Username"
          required
          type="text"
          handleChange={handleChange}
          value={userName}
          id="userName"
          name="userName"
        />
      </div>
      <FormGroup
        label="Confirmation Code"
        required
        type="text"
        handleChange={handleChange}
        value={confirmationCode}
        id="passwoconfirmationCoderd"
        placeholder="Enter your code"
        name="confirmationCode"
      />

      <div className="flex space-x-2 text-xs mt-3">
        <span className="text-gray-400">Lost your code?</span>
        <span className="text-hk-orange-400">Resend code</span>
      </div>
      <div className="mt-6 flex flex-col sm:flex-row justify-between items-baseline text-xs">
        <div className="flex space-x-2 mt-2">
          <span className="text-gray-400">No account?</span>
          <a
            href="#"
            id="sign-up-link"
            onClick={() => setStatus("sign-in")}
            className="text-hk-orange-400 hover:underline"
          >
            Back to Sign In
          </a>
        </div>
        <button
          disabled={!userName || !confirmationCode}
          className="w-full mt-4 tracking-wide sm:w-32 order-first sm:order-last  h-10 uppercase bg-hk-orange-400 text-white font-semibold disabled:opacity-50 disabled:cursor-default"
          type="submit"
        >
          Confirm
        </button>
      </div>
      <PopperMsg
        open={showSuccess || showError}
        msgType={showSuccess ? "success" : "error"}
        handleClose={() => {
          dispatch({ type: "showError", payload: false });
          dispatch({ type: "showSuccess", payload: false });
        }}
      >
        <span>{message}</span>
      </PopperMsg>
    </form>
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    Auth.confirmSignUp(userName, confirmationCode)
      .then((resp) => {
        dispatch({
          type: "processing",
          payload: false,
        });

        dispatch({
          type: "showSuccess",
          payload: true,
        });
        setTimeout(() => {
          setStatus("sign-in");
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: "processing",
          payload: false,
        });
        dispatch({
          type: "showError",
          payload: true,
        });
      });
  }
}
