import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useDrawingRankings(drawingId) {
  const [rankings, setRankings] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data: rankings },
      } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/rankings`);
      setRankings(rankings);
    })();
  }, [drawingId]);

  return rankings;
}
