import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupIcon from "@material-ui/icons/Group";
import MemberLineItem from "./MemberEntry";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";

const useStyles = makeStyles({
  button: {
    edit: {
      fontSize: "12px",
      textTransform: "none",
    },
  },
});

export default function HhldMembers({
  members,
  openMemberModal,
  removeMember,
  editMember,
}) {
  const classes = useStyles();
  const [emulatedUser] = useLocalStorage("emulatedUser");
  return (
    <section
      id="hhldProfile_members"
      className="hhldProfiles_members flex flex-col mt-8 min-h-48"
    >
      <div className="flex border-b-2 border-hk-blue bg-gray-100">
        <h5 className=" flex items-center uppercase text-sm gap-2 text-left p-2 mr-auto">
          <GroupIcon className="text-gray-600" />
          <span>Members ({members.length})</span>
        </h5>
        <Button
          size="small"
          endIcon={<AddIcon />}
          disabled={emulatedUser}
          className={classes.button.edit}
          color="default"
          onClick={openMemberModal}
        >
          Add Member
        </Button>
      </div>

      <section className="hidden sm:grid grid-cols-12 mt-2 border-b border-gray-400 p-2 text-xs text-gray-500 uppercase">
        <span className="justify-self-start col-span-2">Name</span>
        <span className="col-span-1">Relation to Primary</span>
        <span className="col-span-2">Contact</span>
        <span className="col-span-4 justify-self-start">Address</span>
        <span className="col-span-1">Age</span>
        <span className="col-span-1">Invited? (Y/N)</span>
        <span className="justify-self-end col-span-1">Actions</span>
      </section>

      {members.map((profile) => (
        <MemberLineItem
          key={profile.MemberID}
          profile={profile}
          removeMember={removeMember}
          editMember={editMember}
        />
      ))}
    </section>
  );
}
