import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRouteMatch, useHistory } from "react-router-dom";
import GroupIcon from "@material-ui/icons/Group";
import MaterialUITable from "../../../components/shared/Table/MaterialUITable";
import { formatDate } from "../../../utils/formatters";

import { getBaseUrl } from "../../../utils/queryHelpers";

export default function LotteryResults(props) {
  const [results, setResults] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetchLotteryResults();
  }, []);

  async function fetchLotteryResults() {
    setLoading(true);
    const {
      data: { data: results },
    } = await axios.get(`${getBaseUrl()}/drawings/results?rowCnt=100&page=1`);
    setResults(results);
    setLoading(false);
  }

  const tableColumns = [
    {
      title: "Drawing ID",
      field: "LotteryScheduleID",
    },
    {
      title: "Provider",
      field: "EntityName",
    },
    {
      title: "Program",
      field: "ProgramTitle",
    },

    {
      title: "Ranking Type",
      field: "RankingDesc",
    },
    {
      title: "Published",
      field: "PublishDate",
      render: (rowData) =>
        formatDate(rowData.PublishDate, "MM/dd/yyyy/ HH:mm:ss a"),
    },
    {
      title: "# Applicants",
      field: "NumApplicants",
    },
  ];

  return (
    <section className="min-h-screen p-2">
      <div className="mt-4">
        <MaterialUITable
          isLoading={isLoading}
          columns={tableColumns}
          data={results}
          title={`Drawing Results (${results.length})`}
          options={{
            exportButton: true,
            pageSize: 10,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: GroupIcon,
              tooltip: "View Entries",
              onClick: (event, rowData) => {
                history.push({
                  pathname: `${path}/${rowData.LotteryScheduleID}`,
                  state: {
                    rankType: rowData.RankingType,
                    programId: rowData.ProgramID,
                    lotteryDate: rowData.LotteryDate,
                  },
                });
              },
            },
          ]}
        />
      </div>
    </section>
  );
}
