import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Step,
  Stepper,
  StepLabel,
} from "@material-ui/core";
import PrimaryButton from "../shared/form/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  nextBtn: {
    color: "#fff",
    backgroundColor: "#eb7e3c",
  },
}));

export default function HorizontalLabelPositionBelowStepper({
  getStepContent,
  handleFinish,
  nextDisabled,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Member Successfully added
            </Typography>
            <Button onClick={handleReset}>Add Another Member</Button>
          </div>
        ) : (
          <div className="">
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
            <div className="flex justify-end pt-8">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    handleFinish(e);
                    handleNext();
                  }}
                >
                  Finish
                </Button>
              ) : (
                <PrimaryButton
                  disabled={nextDisabled}
                  onClick={handleNext}
                  label="Next"
                >
                  Next
                </PrimaryButton>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

function getSteps() {
  return ["Personal Information", "Income", "Complete"];
}
