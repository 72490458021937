import React from "react";
import { Button, Tooltip } from "@material-ui/core";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Loading from "../../../../shared/misc/Loading";

export default function AddWitness({
  authEntities,
  isLoading,
  allowedEntities = [],
  processingAdd = false,
  selectedWitnessId,
  addWitnessToRankingHandler,
}) {
  return (
    <div className=" bg-gray-100">
      {isLoading ? (
        <Loading />
      ) : (
        authEntities.map((a) => {
          const userIsWitness = !allowedEntities.includes(a.UserID);
          const { Firstname, Lastname, UserID, Title } = a;
          return (
            <div
              key={UserID}
              className="grid grid-cols-12  p-2 gap-1 text-xs text-gray-600 border-b border-gray-300"
            >
              <div className="flex flex-col col-span-7 items-start">
                <span className="text-sm text-gray-800">
                  {Firstname} {Lastname}
                </span>
                <span className="px-2 pt-1 text-gray-600">{Title}</span>
              </div>

              <div className="col-span-3 text-center ml-auto flex items-center">
                {!userIsWitness && (
                  <div className="flex justify-center items-center text-lg">
                    {processingAdd && UserID === selectedWitnessId ? (
                      <Loading size={20} />
                    ) : (
                      <Tooltip title="Add">
                        <Button
                          startIcon={<PersonAddIcon />}
                          color="primary"
                          onClick={addWitnessToRankingHandler(UserID)}
                        ></Button>
                      </Tooltip>
                    )}
                    {/* <Tooltip title="Mail">
                          <button>
                            <FaCheckCircle />
                          </button>
                        </Tooltip> */}
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
