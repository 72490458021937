import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  Tooltip,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import PanToolIcon from "@material-ui/icons/PanTool";
import RefreshIcon from "@material-ui/icons/Refresh";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import ReactCardFlip from "react-card-flip";
import Loading from "../../../../components/shared/misc/Loading";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import useQueryParams from "../../../../components/shared/hooks/useQuery";
import axios from "../../../../utils/axios";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import { showWitnessButton } from "../../../../utils/drawingUtils";
import AddWitnessModal from "../drawings/mgr/AddWitness";
import UserContext from "../../../user/UserContext";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "auto",
    width: "0.75rem",
    minWidth: "0.75rem",
  },
  actionButton: {
    minWidth: 0,
  },
}));

export default function Witnesses({
  witnesses = [],
  allowAddWitnesses = true,
  loading,
  fetchWitnesses,
  handleRefresh,
  witnessRanking,
  processingWitness,
  activeStep,
}) {
  const classes = useStyles();
  const [loadingDrawingWitnesses, setLoadingDrawingWitnesses] = useState(false);
  const [drawingWitnesses, setDrawingWitnesses] = useState([]);
  const [processingDelete, setProcessingDelete] = useState(false);
  const [selectedWitnessId, setSelectedWitnessId] = useState("");
  const [isFlipped, setIsFlipped] = useState(false);
  const [processingAdd, setProcessingAdd] = useState(false);
  const [showWitnessDialog, toggleWitnessDialog] = useState(false);
  const qryParams = useQueryParams();

  let { drawingId, rankId } = useParams();

  if (drawingId === undefined) {
    drawingId = qryParams.get("drawingId");
  }

  const { user } = useContext(UserContext) || {};

  async function fetchDrawingWitnesses() {
    setLoadingDrawingWitnesses(true);
    const {
      data: { data: witnesses },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/authorizations?mhkType=39&rowCnt=200&page=1`
    );

    setDrawingWitnesses(witnesses);
    setLoadingDrawingWitnesses(false);
  }

  useEffect(() => {
    fetchDrawingWitnesses();
  }, []);

  const addWitnessHandler = (witnessId) => async () => {
    setSelectedWitnessId(witnessId);
    setProcessingAdd(true);

    await axios.post(
      `${getBaseUrl()}/rankings/${rankId}/witnesses/${witnessId}`
    );

    await fetchWitnesses();
    setProcessingAdd(false);
    setIsFlipped(false);
    setSelectedWitnessId("");
  };

  const removeWitnessFromRanking = (witnessId) => async () => {
    setSelectedWitnessId(witnessId);
    setProcessingDelete(true);
    await axios.delete(
      `${getBaseUrl()}/rankings/${rankId}/witnesses/${witnessId}`
    );

    await fetchWitnesses();
    setProcessingDelete(false);
  };

  const handleWitness = (witnessId) => (async) => {
    setSelectedWitnessId(witnessId);
    toggleWitnessDialog(true);
  };

  const allowedWitnesses = drawingWitnesses
    .filter((a) => !witnesses.find((w) => w.UserID === a.UserID))
    .map((a) => a.UserID);

  return (
    <section className="p-4 sm:ml-6 w-full sm:w-1/5   oblique shadow-md ">
      <div className="text-gray-800 flex justify-center items-center p-1  text-sm font-bold mt-2">
        {!isFlipped && (
          <>
            <h6 className="">{`Witnesses (${witnesses.length})`}</h6>
            <Tooltip title="Refresh">
              <Button startIcon={<RefreshIcon />} onClick={handleRefresh} />
            </Tooltip>
          </>
        )}
        {isFlipped && (
          <h6 className="">{`Authorizations (${drawingWitnesses.length})`}</h6>
        )}
        {allowAddWitnesses && (
          <Tooltip title={!isFlipped ? "Add Witnesses" : "Back to Witnesses"}>
            <Button
              color="primary"
              size="small"
              onClick={() => setIsFlipped(!isFlipped)}
              className={classes.button}
            >
              {!isFlipped ? <AddCircleIcon /> : <FlipCameraAndroidIcon />}
            </Button>
          </Tooltip>
        )}
      </div>

      <Divider />
      {loading ? (
        <Loading />
      ) : (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          {witnesses.map((w) => (
            <div
              key={w.UserID}
              className="flex justify-between p-4 text-xs border-b border-blue-300 bg-blue-100"
            >
              <div className="flex  items-center">
                <Tooltip title={getTooltipMessage(w)}>
                  {w.WitnessFlag === "Y" ? (
                    <CheckCircleIcon
                      fontSize="small"
                      className="text-green-500"
                    />
                  ) : (
                    <CancelIcon fontSize="small" color="secondary" />
                  )}
                </Tooltip>

                <span className="px-2">
                  {w.FirstName} {w.LastName}
                </span>
              </div>

              <div className="flex justify-end items-center">
                <Tooltip title="Mail">
                  <Button
                    className={classes.actionButton}
                    disabled
                    startIcon={<MailOutlineIcon />}
                  ></Button>
                </Tooltip>
                {processingDelete && selectedWitnessId === w.UserID ? (
                  <Loading size={20} />
                ) : (
                  <Tooltip title="Remove">
                    <Button
                      color="secondary"
                      className={classes.actionButton}
                      startIcon={<RemoveCircleIcon />}
                      onClick={removeWitnessFromRanking(w.UserID)}
                    ></Button>
                  </Tooltip>
                )}
                {processingWitness && selectedWitnessId === w.UserID ? (
                  <Loading size={20} />
                ) : (
                  <Tooltip title="Witness">
                    <Button
                      className={classes.actionButton}
                      color="primary"
                      disabled={
                        !showWitnessButton(
                          user.userId,
                          w.UserID,
                          activeStep,
                          witnesses
                        )
                      }
                      startIcon={<PanToolIcon />}
                      onClick={handleWitness(w.UserID)}
                    ></Button>
                  </Tooltip>
                )}
              </div>
            </div>
          ))}

          <AddWitnessModal
            isLoading={loadingDrawingWitnesses}
            processingAdd={processingAdd}
            authEntities={drawingWitnesses}
            selectedWitnessId={selectedWitnessId}
            addWitnessToRankingHandler={addWitnessHandler}
            allowedEntities={allowedWitnesses}
          />
        </ReactCardFlip>
      )}
      <Dialog
        open={showWitnessDialog}
        onClose={() => toggleWitnessDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">Witness Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirm witness to this lottery?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => toggleWitnessDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={() => {
              witnessRanking();
              toggleWitnessDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );

  function getTooltipMessage({ WitnessFlag = "N", FirstName }) {
    return WitnessFlag === "Y"
      ? `${FirstName} has witnessed already.`
      : `${FirstName} has not witnessed yet.`;
  }
}
