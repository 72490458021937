import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useProgramDetail(programId) {
  const [program, setProgram] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { data: program },
      } = await axios.get(`${getBaseUrl()}/programs/${programId}`);
      setProgram(program);
    })();
  }, [programId]);

  return program;
}
