import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";

import "./blueprint-overrides.css";
import logo from "./media/HK-assets/hk_logo_mk.png";
import "./App.css";
import { BrowserRouter, Link } from "react-router-dom";
import Routes from "./Routes";
import HkLoading from "./components/shared/misc/HkLoading";
import Footer from "./Footer";
import User from "./components/user/User";
import { Auth } from "aws-amplify";
import axios from "./utils/axios";
import UserContext from "./components/user/UserContext";
import HkAuth from "./components/auth/Auth";
import Navbar from "./components/nav/Navbar";
import ErrorBoundary from "./components/shared/error/ErrorBoundary";
import { getBaseUrl } from "./utils/queryHelpers";
import useLocalStorage from "./components/shared/hooks/useLocalStorage";

import messages_en from "../src/translations/en.json";
import messages_esp from "../src/translations/espanol.json";

import { usePreferredLanguage } from "./translations/LanguageContext";

const messages = {
  es: messages_esp,
  en: messages_en,
};

function App() {
  const [user, setUser] = useState(null);
  const [emulatedUser, setEmulatedUser] = useLocalStorage("emulatedUser");
  const { preferredLang } = usePreferredLanguage();

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, []);

  async function fetchUser() {
    const {
      data: { data: user = {} },
    } = await axios.get(`${getBaseUrl()}/user/me`);

    const profileType = user?.profileType?.substring(0, 1);

    if (profileType === "A" || profileType === "D") {
      axios.get(`${getBaseUrl()}/entity/${user.entityId}`).then((resp) => {
        const {
          data: { data: org },
        } = resp;
        user.org = org?.[0];
      });
    }
    setUser(user);
  }

  async function handleLogout() {
    setUser(null);
    setEmulatedUser(null);
    await Auth.signOut();
  }

  return (
    <IntlProvider locale={preferredLang} messages={messages[preferredLang]}>
      <BrowserRouter>
        <div
          className={`App ${
            emulatedUser && emulatedUser.userName
              ? "border-4 border-hk-orange-400"
              : ""
          }`}
        >
          <header className="flex p-2 border-b border-opacity-50 border-hk-orange items-baseline">
            <div className="">
              <Link to="/">
                <img className="h-12" src={logo} alt="HouseKeys logo" />
              </Link>
            </div>

            {user !== null ? (
              <UserContext.Provider value={{ user: user, setUser: fetchUser }}>
                <User logoutHandler={handleLogout} />
              </UserContext.Provider>
            ) : null}
          </header>

          {user != null && (
            <UserContext.Provider value={{ user: user, setUser: fetchUser }}>
              <Navbar />
            </UserContext.Provider>
          )}

          <HkAuth setUser={setUser}>
            <UserContext.Provider value={{ user: user, setUser: fetchUser }}>
              {user !== null ? (
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              ) : (
                <HkLoading containerHeight="min-h-screen" />
              )}
            </UserContext.Provider>
          </HkAuth>

          <Footer />
        </div>
      </BrowserRouter>
    </IntlProvider>
  );
}

//export default withAuthenticator(App, true);
export default App;
