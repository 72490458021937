import React from "react";

import "./Footer.css";

const GroupHeading = ({ children }) => {
  return (
    <h5 className="text-md mb-2 font-semibold tracking-wider">{children}</h5>
  );
};

export default function Footer() {
  return (
    <footer className="flex p-4 text-sm flex-col md:flex-row bg-hk-dark-blue text-white">
      <section className="flex items-start sm:w-1/4">
        <section className="items-start p-2 flex flex-col">
          <h5 className="text-md mb-4 font-semibold tracking-wider">
            Contact HouseKeys
          </h5>
          <span className="flex text-white h-8">
            <input
              type="text"
              placeholder="email address"
              className="p-2 email font-thin outline-none"
            />
            <button
              type="button"
              className="text-xs px-2 w-16 text-center bg-orange-500 rounded-r-md signup-btn"
            >
              Sign Up
            </button>
          </span>
          <span className="text-sm mt-2 font-thin tracking-wider">
            Phone: 1-877-460-KEYS(5397)
          </span>
        </section>
        {/* <div className="sm:hidden flex mt-2" data-test="footer-socials">
          <SocialButton>
            <FaFacebook className="w-4 h-4" />
          </SocialButton>
          <SocialButton>
            <FaTwitter className="w-4 h-4" />
          </SocialButton>
          <SocialButton>
            <FaInstagram className="w-4 h-4" />
          </SocialButton>
          <SocialButton>
            <FaPinterest className="w-4 h-4" />
          </SocialButton>
        </div> */}
      </section>
      <section className="flex sm:w-3/4 sm:ml-2 md:ml-16">
        {/* <section className="items-start p-2 flex flex-col flex-1">
          <GroupHeading>Portals</GroupHeading>
          <FooterSubLink>Real Estate Agents</FooterSubLink>
          <FooterSubLink>Housing Managers</FooterSubLink>
          <FooterSubLink>Applicants</FooterSubLink>
          <FooterSubLink>Builders</FooterSubLink>
          <FooterSubLink>Loan Officers</FooterSubLink>
          <FooterSubLink to="/upload">Uploads</FooterSubLink>
        </section> */}
        <section className="items-start p-2 flex flex-col flex-1">
          <GroupHeading>Company</GroupHeading>
          {/* <FooterSubLink>About</FooterSubLink>
          <FooterSubLink>Products</FooterSubLink>
          <FooterSubLink>FAQ</FooterSubLink> */}
          {/* <FooterSubLink to="/lottery-results">Lottery Results</FooterSubLink> */}
        </section>
        {/* <section className="items-start p-2 flex flex-col flex-1">
          <GroupHeading>Further Information</GroupHeading>
          <FooterSubLink>Terms of Service</FooterSubLink>
          <FooterSubLink>Policy</FooterSubLink>
          <FooterSubLink to="/admin">Admin</FooterSubLink>
        </section> */}
      </section>
      {/* <section className="order-2 hidden  sm:flex flex-row  md:order-last items-start p-2">
        <SocialButton>
          <FaFacebook />
        </SocialButton>
        <SocialButton>
          <FaTwitter className="w-10 h-10" />
        </SocialButton>
        <SocialButton>
          <FaInstagram />
        </SocialButton>
        <SocialButton>
          <FaPinterest />
        </SocialButton>
      </section> */}
      {/* <ShareSocials shareUrl="https://www.myhousekeys.org/visitor" /> */}
    </footer>
  );
}
