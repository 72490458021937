import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useHhldRegSummary(userId, deps = []) {
  const [summary, setSummary] = useState({});

  useEffect(() => {
    (async () => {
      const {
        data: { data: smry },
      } = await axios.get(`${getBaseUrl()}/user/${userId}/reg_summary`);
      setSummary(smry);
    })();
  }, [userId, ...deps]);

  return summary;
}
