import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ownership_placeholder from "../../media/HK-assets/ownership_placeholder.jpg";
import rental_placeholder from "../../media/HK-assets/rental_placeholder.jpg";
import { useOpportunityDetail } from "../../queries/hhld/useOpportunityDetail";
import { formatAsUSCurrency, formatDateAndTime } from "../../utils/formatters";
import { getDrawingType } from "../../utils/drawingUtils";
import { getDrawingDisabledReasons } from "../../utils/hhldUtils";
import useLocalStorage from "../../components/shared/hooks/useLocalStorage";
import UserContext from "../user/UserContext";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#605c5c",
    width: "242px",
    height: "36px",
    color: "#fff",
    marginTop: "20px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#c4c4c4",
      fontWeight: 600,
    },
  },
  disabled: {
    color: "#1a202c",
    backgroundColor: "#e2e8f0",
  },
}))(Button);

export function HhldOpportunityDetail() {
  const { opportunityId } = useParams();

  const oppDetail = useOpportunityDetail(opportunityId);
  const [emulatedUser] = useLocalStorage("emulatedUser");
  const { user } = useContext(UserContext) || {};
  const history = useHistory();

  const {
    Address1 = "",
    Address2,
    AMIPct,
    AddlDescription1,
    ApplicantsAccepted,
    BathRoomCount = 3,
    BedroomCount = 2,

    City = "",
    DrawingDeadlineDate,
    DrawingDeadlineTime,
    DrawingType,
    EntityName,
    FileSubmissionDeadlineDate,
    FileSubmissionDeadlineTime,
    FinalType,
    HHLDProgramID,
    HOAFee,
    IncomeLevelDesc,
    LotSquareFt,
    MaxHHLDSize,
    MinHHLDSize,
    OppDeliveryDate,
    OppDescription,
    OpportunityDrawingID,
    OpportunityDrawingApplicantID,
    OpportunityInventoryType,
    PreComponentName,
    ProgramID,
    ProgramTitle,
    PublishDrawingPgmCtrFlag,
    PurchaseRentalFinanceAmt = 0,
    State = "",
    ZipCode = "",
  } = oppDetail;

  const drawingDisabledReasons = getDrawingDisabledReasons({
    user,
    OpportunityDrawingApplicantID,
    ApplicantsAccepted: ApplicantsAccepted?.[1] ?? 2,
    OpportunityDrawingID,
    PublishDrawingPgmCtrFlag,
  });

  const enableEnterDrawing = drawingDisabledReasons.length < 1 && !emulatedUser;
  return (
    <section className="min-h-screen flex flex-col justify-center items-center">
      <div className="w-full max-w-6xl p-8">
        <img
          className="w-full h-96"
          src={
            OpportunityInventoryType == 1
              ? ownership_placeholder
              : rental_placeholder
          }
        />
      </div>
      <section className="px-8 py-8 sm:grid grid-cols-2 max-w-6xl">
        <section className="flex flex-col">
          <section className="flex flex-col py-4">
            <div className="flex space-x-2">
              <span>{Address1}</span>
              <span>, {City}</span>
              <span>, {State}</span>
              <span>, {ZipCode}</span>
            </div>

            <div className="flex space-x-4 text-2xl mt-2">
              <span>{BedroomCount} bed</span>
              <span>.</span>
              <span>{BathRoomCount} bath</span>
              <span>.</span>
              <span>
                {LotSquareFt} <span className="text-xs">sqft</span>
              </span>
            </div>
            <div className="text-left mt-4 min-h-10">
              <span>Household Size</span>
            </div>
            <div className="flex items-baseline text-gray-600">
              <span>Max </span>
              <span className="text-lg ml-1"> {MaxHHLDSize} / </span>
              <span className="ml-2">Min </span>
              <span className="text-lg ml-1"> {MinHHLDSize}</span>
            </div>
          </section>

          <section className="flex  flex-col py-4">
            <h3 className="text-left text-lg">Description</h3>
            <p
              className="text-sm text-left mt-2 tracking-wide leading-loose"
              dangerouslySetInnerHTML={{ __html: OppDescription }}
            ></p>
          </section>
        </section>{" "}
        {/* end of grid */}
        <section className="w-full  py-2 px-4 bg-gray-100 flex flex-col space-y-2 sm:ml-4 mt-4 sm:w-96 justify-self-end place-self-start">
          <div className="text-2xl tracking-wider sm:text-3xl text-left text-gray-900 flex items-center">
            {formatAsUSCurrency(PurchaseRentalFinanceAmt)}
            <RentalOrSaleSign flag={OpportunityInventoryType} />
          </div>
          {!!HOAFee && (
            <span className="text-left text-gray-600">
              HOA {formatAsUSCurrency(HOAFee)}
            </span>
          )}

          <div className="flex items-baseline space-x-2">
            <span className="text-sm text-gray-600 text-left">
              {IncomeLevelDesc}
            </span>
            <span className="text-xs">AMI ({AMIPct})</span>
          </div>
          <div className="flex space-x-2 text-sm text-gray-900">
            <span className="text-left">Drawing Deadline:</span>
            <span>
              {formatDateAndTime(
                DrawingDeadlineDate?.[1] ?? "",
                DrawingDeadlineTime?.[1] ?? ""
              )}
            </span>
          </div>
          <div className="flex space-x-2 text-sm">
            <span className="text-left">Delivery Date:</span>
            <span>{formatDateAndTime(OppDeliveryDate)}</span>
          </div>
          {FileSubmissionDeadlineDate?.[1] && (
            <div className="flex space-x-2 text-sm">
              <span className="text-left">File Submission Deadline Date:</span>
              <span>
                {formatDateAndTime(
                  FileSubmissionDeadlineDate?.[1] ?? "",
                  FileSubmissionDeadlineTime?.[1] ?? ""
                )}
                )
              </span>
            </div>
          )}
          <div className="flex space-x-2 text-sm">
            <span className="text-left">Drawing Type:</span>
            <span>{getDrawingType(DrawingType?.[1], FinalType)}</span>
          </div>

          <StyledButton disabled={!enableEnterDrawing} onClick={enterDrawing}>
            Enter Drawing
          </StyledButton>
          <span className="text-sm  text-gray-800 mt-4">
            {ProgramTitle?.[1] ?? ""}
          </span>
          <span className="text-sm text-gray-700 -pt-1">
            {PreComponentName}
          </span>
        </section>
      </section>
    </section>
  );

  function enterDrawing(e) {
    e.stopPropagation();

    history.push({
      pathname: `/drawings/${OpportunityDrawingID}/enter`,
      state: {
        hhldProgramId: HHLDProgramID,
        agency: EntityName,
        program: ProgramTitle?.[1],
        programId: ProgramID,
      },
    });
  }
}

function RentalOrSaleSign({ flag }) {
  return (
    <div className="h-6 p-2 border-3 bg-red-400 border- flex justify-center items-center ml-2">
      <span className="uppercase text-xs text-white">
        For {flag == 1 ? "Sale" : "Rent"}
      </span>
    </div>
  );
}
