import React, { useState, useEffect } from "react";
import { format, isValid } from "date-fns";
import { useHistory, useRouteMatch } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
import { Breadcrumbs, Button, Link as MatLink } from "@material-ui/core";

import { FaUsers, FaRegFolder } from "react-icons/fa";
import { MdEventAvailable } from "react-icons/md";
import {
  getDrawingType,
  getDrawingStats,
} from "../../../../utils/drawingUtils";
import MaterialUITable from "../../../shared/Table/MaterialUITable";
import CardItem from "../../../shared/card/CardItem";
import Select from "../../../shared/Select";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";
import useQueryParams from "../../../shared/hooks/useQuery";
import { CreateDrawing } from "./CreateDrawing";
import axios from "../../../../utils/axios";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import { formatDate } from "../../../../utils/formatters";
import PrimaryButton from "../../../shared/form/PrimaryButton";

function Drawings(props) {
  const [drawings, setDrawings] = useState([]);
  const [loadingOpps, setLoadingOpps] = useState(false);
  const [drawingStatus, setDrawingStatus] = React.useState("Open");
  const [showCreateDrawingModal, setShowCreateDrawingModal] = React.useState(
    false
  );

  const qryParams = useQueryParams();

  const handleChange = (event) => {
    setDrawingStatus(event.target.value);
  };

  const history = useHistory();

  const [selectedDrawing, setSelectedDrawing] = useLocalStorage(
    "selectedDrawing",
    {}
  );

  async function fetchOppDrawings() {
    setLoadingOpps(true);
    const programId = qryParams.get("programId") || "";
    try {
      const {
        data: { data: drawings },
      } = await axios.get(
        `${getBaseUrl()}/drawings?rowCnt=500&page=1&programId=${programId}`
      );

      setDrawings(drawings);
      setLoadingOpps(false);
    } catch (err) {
      console.error(`error getting drawings list ${err}`);
      setDrawings([]);
      setLoadingOpps(true);
    }
  }

  useEffect(() => {
    fetchOppDrawings();
  }, []);

  const filteredOpps =
    drawingStatus === "All"
      ? drawings
      : drawings.filter((o) => o.DrawingStatusDesc === drawingStatus);

  const drawingStats = getDrawingStats(drawings);

  const [opp = {}] = drawings;

  return (
    <div className="min-h-screen p-2">
      {/*       <Breadcrumbs className="p-2" aria-label="breadcrumb">
        <MatLink color="inherit" href="/drawings">
          Opportunity Drawings
        </MatLink>
      </Breadcrumbs> */}
      <section className="flex space-x-4 items-baseline p-2">
        <span className="sm:text-2xl tracking-wide">Opportunity Drawings </span>
        <PrimaryButton
          variant="contained"
          color="primary"
          size="small"
          onClick={launchCreateDrawingModal}
        >
          Create New Drawing
        </PrimaryButton>
      </section>

      <section className="flex   p-2 mt-4">
        <section className="flex space-x-4 space-y-2 items-baseline flex-wrap w-full sm:w-3/4">
          <h3 className="hidden">Cards</h3>
          <Card label="Total Applicants" value={12940} />
          <Card label="Total Opportunities" value={406} />
          <Card label="Published to Marketplace" value={78} />
          <Card label="Open Drawings" value={83} />
          <Card label="Closed Drawings" value={228} />
          <Card label="Published to Program Center" value={51} />
        </section>

        <section>
          <h3 className="text-left py-2 tracking-wide">Upcoming Drawings</h3>
          <UpComingDrawings drawings={drawings.slice(0, 5)} />
        </section>
      </section>

      <div className="mt-4 flex justify-end p-2 items-end w-full flex-wrap mb-4">
        {/* <div className=" mr-auto shadow-md flex flex-col  rounded items-start w-full sm:w-1/5 ">
          <div className="opp-card-header bg-hk-dark-blue py-4 rounded-t-md w-full flex h-10" />
          <div className="p-4  w-full flex flex-col rounded items-start   ">
            <CardItem
              Icon={FaUsers}
              label="Applicants"
              value={opp.TotalApplicantCount}
            />

            <CardItem
              Icon={FaRegFolder}
              label="Opportunities"
              value={opp.TotalOpportunityCount}
            />

            <CardItem
              Icon={MdEventAvailable}
              label="Open"
              value={drawingStats.totalOpen}
            />
            
            <h6 className="border-b p-2 border-t border-gray-200 w-full divide-y divider-gray-400 text-gray-700 text-sm">
              Published To :
            </h6>

            <section className="flex w-full justify-around mt-4">
              <div className="flex flex-col items-center">
                <div className="flex p-2 border-2 w-10 h-10 rounded-full border-hk-green flex-col">
                  <span className="text-gray-600 text-sm ">
                    {drawingStats.totalPublished}
                  </span>
                </div>
                <span className="mt-2 text-gray-800 text-xs font-semibold tracking-wide">
                  Marketplace
                </span>
              </div>

              <div className="flex items-center  flex-col">
                <div className="flex p-2 border-2 w-10 h-10 rounded-full border-hk-green flex-col">
                  <span className="text-gray-600 text-sm">
                    {drawingStats.publishedToPrgCtr}
                  </span>
                </div>
                <span className="mt-2 text-gray-800 text-xs font-semibold tracking-wide">
                  Program Center
                </span>
              </div>
            </section>
          </div>
        </div> */}

        {/*  <section className="filters mt-4  border-1 border-gray-300 flex flex-col shadow w-full   sm:w-3/5">
          
          <span className="font-semibold oblique text-white px-2">Filters</span>

          <Select
            options={[
              { label: "Open", value: "Open" },
              { label: "Closed", value: "Closed" },
              { label: "All", value: "All" },
            ]}
            onChange={handleChange}
            value={drawingStatus}
          />
        </section> */}
      </div>

      <CreateDrawing
        open={showCreateDrawingModal}
        onClose={() => setShowCreateDrawingModal(false)}
      />

      <DrawingsList
        drawings={drawings}
        loading={loadingOpps}
        cloneDrawing={cloneDrawing}
        setSelectedDrawing={setSelectedDrawing}
      />
    </div>
  );

  function launchCreateDrawingModal() {
    setShowCreateDrawingModal(true);
  }
}

async function cloneDrawing(event, rowData) {
  const { drawing_id } = rowData;
  const resp = await axios.post(`${getBaseUrl()}/drawings/${drawing_id}/clone`);
}

function getPublishedToDesc(data) {
  const { PublishFlag, PublishPgmCtrFlag } = data;
  if (PublishFlag && PublishPgmCtrFlag) return "Program Center / Market Place";
  if (PublishPgmCtrFlag) return "Program Center";
  if (PublishFlag) return "Market Place";
  return "N/A";
}

export default Drawings;

function Card({ label, value }) {
  return (
    <div className="bg-gray-50 border border-gray-100 rounded-md flex flex-col w-full sm:w-64 h-32 p-4">
      <span className="text-gray-600">{label}</span>
      <span className="text-2xl mt-4">{value}</span>
    </div>
  );
}

function UpComingDrawings({ drawings }) {
  return (
    <section className="border border-gray-100 rounded-md bg-gray-50 p-2">
      {drawings.slice(0, 5).map((d) => (
        <div
          key={d.drawing_id}
          className="flex space-x-2 space-y-2 items-center mt-2 border-b border-b-gray-200 py-2"
        >
          <DrawingDate date={d.entry_deadline} />
          <span className="text-sm">
            {d.drawing_id} - {d.program_name}
          </span>
        </div>
      ))}
    </section>
  );
}

function DrawingDate({ date }) {
  const month = isValid(new Date(date)) ? format(new Date(date), "LLL") : "";
  const day = isValid(new Date(date)) ? format(new Date(date), "c") : "";

  return (
    <div className="flex flex-col space-y-1 border border-gray-200 rounded-md p-2 text-sm">
      <span className="text-gray-600 uppercase">{month}</span>
      <span className="font-semibold">{day}</span>
    </div>
  );
}

export function DrawingsList({
  drawings,
  loading,
  cloneDrawing,
  setSelectedDrawing,
}) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const tableColumns = [
    {
      title: "Subject",
      field: "description",
      render: (rowData) => (
        <span dangerouslySetInnerHTML={{ __html: rowData.description }} />
      ),
    },
    {
      title: "Drawing Id",
      field: "drawing_id",
      render: (rowData) => (
        <span className="font-semibold">{rowData.drawing_id}</span>
      ),
    },
    { title: "PreComponent", field: "PreComponentName" },
    { title: "Provider", field: "provider" },
    { title: "Program", field: "program_id" },
    {
      title: "Type",
      field: "drawing_type",
    },
    {
      title: "Drawing Status",
      field: "status",
    },
    {
      title: "Acceptance Status",
      field: "ApplicantsAcceptedDesc",
    },
    {
      title: "Published To: ",
      field: "PublishFlg",
      render: (rowData) => getPublishedToDesc(rowData),
    },
    { title: "Applicant Entry Attempts", field: "ApplicantCountEntered" },
    { title: "Active Applicants", field: "submissions" },
    { title: "Unsuccessful Entries", field: "ApplicantCountDenied" },
    { title: "# Opportunities", field: "opps_count" },
    { title: "Created By", field: "created_by" },
  ];
  return (
    <MaterialUITable
      isLoading={loading}
      columns={tableColumns}
      detailPanel={[
        {
          tooltip: "View more",
          icon: ArrowForwardIosIcon,
          render: (rowData) => {
            return (
              <section className="p-1">
                <div className="grid grid-cols-5 gap-1 text-xs rounded-sm bg-hk-green p-2 text-white">
                  <span>Start Date </span>
                  <span>End Date </span>
                  <span>File Submission Deadline</span>
                  <span>Results Publish Date</span>
                  <span>Application Deadline</span>
                </div>
                <div className="grid grid-cols-5 gap-1 text-blue-600 bg-blue-100 p-4 font-md mt-1 text-xs ">
                  <span>
                    {formatDate(rowData.StartDate, "MM/dd/yyyy/ HH:mm:ss a")}
                  </span>
                  <span>
                    {formatDate(rowData.EndDate, "MM/dd/yyyy/ HH:mm:ss a")}
                  </span>
                  <span>{rowData.FileSubmissionDeadline}</span>
                  <span>{rowData.DrawingResultsPublishDate}</span>
                  <span>{rowData.ApplicationEntryDate}</span>
                </div>
              </section>
            );
          },
        },
      ]}
      /* components={{ GroupRow: prop => <div>Group Here</div> }} */
      stickyHeader
      className="p-4 mt-4"
      data={drawings}
      actions={[
        /*  {
          icon: FileCopyIcon,
          tooltip: "Clone Drawing",
          onClick: cloneDrawing,
        }, */
        {
          icon: SettingsIcon,
          tooltip: "Manage Drawing",

          onClick: (event, rowData) => {
            history.push({
              pathname: `/drawings/${rowData.drawing_id}`,
              state: {
                programId: rowData.program_id,
              },
            });
          },
        },
        {
          icon: GroupIcon,
          tooltip: "View Applicants",

          onClick: (event, rowData) => {
            setSelectedDrawing({
              drawingId: rowData.drawing_id,
              programId: rowData.program_id,
              deadline: rowData.entry_deadline,
              program: rowData.programName,
              provider: rowData.EntityName,
              numOpps: rowData.opportunities_count,
              drawingType: getDrawingType(
                rowData.DrawingType,
                rowData.FinalType
              ),
            });
            history.push(`${path}/${rowData.drawing_id}/applicants`);
          },
        },
        (rowData) => ({
          icon: ArrowForwardIosIcon,
          tooltip: "View Rankings",
          onClick: (event, rowData) => {
            setSelectedDrawing({
              drawingId: rowData.drawing_id,
              programId: rowData.program_id,
              deadline: rowData.entry_deadline,
              program: rowData.programName,
              provider: rowData.provider,
              numOpps: rowData.opportunities_count,
              drawingType: getDrawingType(
                rowData.DrawingType,
                rowData.FinalType
              ),
            });
            history.push(`${path}/${rowData.drawing_id}/rankings`);
          },
        }),

        (rowData) => ({
          icon: ConfirmationNumberIcon,
          tooltip: "View Results",
          onClick: (event, rowData) => {
            history.push({
              pathname: `${path}/${rowData.drawing_id}/results`,
              state: {
                programId: rowData.program_id,
              },
            });
          },
          disabled: !rowData.PublishFlag,
        }),
      ]}
      title={`Drawings (${drawings.length})`}
      options={{
        exportButton: true,
        pageSize: 10,
        grouping: true,
        actionsColumnIndex: -1,
      }}
    />
  );
}
