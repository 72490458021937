const drawingTypes = {
  1: "Lottery Selection",
  2: "First-Form First-Serve",
  3: "First-File First-Serve",
  4: "First-ApplicationID First-Serve",
  5: "First Pre-Application Submitted",
};

const rankingTypes = {
  "0": "Random",
  "1": "Manual",
  "2": "Timestamp",
};
export function getDrawingType(typeInt, rankType = 1) {
  return `${drawingTypes[typeInt]} (${getRankingType(rankType)})` || "";
}

export function getDrawingStats(drawings) {
  const result = drawings.reduce(
    (acc, curr) => {
      if (curr.PublishFlag !== 0) {
        acc.totalPublished = acc.totalPublished + 1;
      }
      if (curr.DrawingStatusDesc === "Open") {
        acc.totalOpen = acc.totalOpen + 1;
      }
      if (curr.PublishPgmCtrFlag) {
        acc.publishedToPrgCtr = acc.publishedToPrgCtr + 1;
      }
      return acc;
    },
    { oppCnt: 0, totalPublished: 0, totalOpen: 0, publishedToPrgCtr: 0 }
  );

  return result;
}

/**
 * given a set of actions performed on the ranking, returns what step
 * of the process a given ranking is based on any prior actions performed
 * @param {*} actions
 */
export function getRankingStep(actions = []) {
  if (actions.length < 1) return 0;
  const latestAction = sortActionsByTimeStamp(actions)[0];

  if (latestAction) {
    switch (latestAction.ActionPerformed) {
      case "Process Initial Ranking":
        return 1;
      case "Process Final Ranking":
        return 2;
      case "Witness Certification":
        return 3;
      case "Ranking Close Out":
        return 5;
      case "Ranking Published":
        return 6;
      default:
        return 0;
    }
  }
  return 0;
}

export function getRankingStepsWithDate(actions = []) {
  //if (actions.length < 1) return 0;
  const sortedByEarliest = sortActionsByTimeStamp(actions, "asc");
  return Array.from(
    ["Ranked", "Finalized", "Witnessed", "Closed", "Published"],
    (l, i) => ({
      action: l,
      date: sortedByEarliest[i]?.ActionTimestamp ?? "N/A",
    })
  );
}
//sorts the actions list by ActionTimeStamp, latest first
export function sortActionsByTimeStamp(actions, order = "latest") {
  return actions.sort((action1, action2) => {
    const date1 = new Date(action1.ActionTimestamp);
    const date2 = new Date(action2.ActionTimestamp);
    return order === "latest" ? date2 - date1 : date1 - date2;
  });
}

export function witnessComplete(witnesses = []) {
  const result = witnesses.every((w) => w.WitnessFlag && w.WitnessFlag === "Y");

  return result;
}

/**
 * returns a merged set(if needed) of updated manual edits and orig rankings
 * @param {*} changes {0: oldData, newData}
 * @param {*} origRankings
 */
export function getUpdatedManualRanks(changes, origRankings) {
  const updates = Object.entries(changes).map(([key, value]) => {
    const { LotteryRankingDetailID, RandomizedRank } = value["newData"];
    return {
      ID: ~~LotteryRankingDetailID,
      RandomizedRank: ~~RandomizedRank,
    };
  });
  if (changes.length < origRankings.length) {
    //user didn't update all the entries, so backfill the changes
    //from current rankings
    const updatedRankingIds = Object.keys(changes).map(Number);
    //build a list of all unchanged rankings
    const unChanged = origRankings
      .filter((r) => !updatedRankingIds.includes(r.tableData.id))
      .map(({ LotteryRankingDetailID, RandomizedRank }) => ({
        ID: ~~LotteryRankingDetailID,
        RandomizedRank,
      }));
    //extract ID and updated rankings from the changeset

    return [...updates, ...unChanged];
  }
  return updates;
}

//only show the witness button if the user is in the witness list,
//and only enable the logged in users entry in the witness list
// and we are in the witness step of the process
export function showWitnessButton(
  userId,
  witnessId,
  activeStep = 0,
  witnesses = []
) {
  const inWitnessListAndIsCurrentUser = witnesses.some(
    (w) => w.UserID == userId && userId === witnessId && w.WitnessFlag === "N"
  );
  return (
    inWitnessListAndIsCurrentUser && (activeStep === 2 || activeStep === 3)
  );
}

/**
 * extracts preference tiers from the PreferenceOptions: "21~1|24~2|
 * field in the drawing applicants response
 * @param {*} preferences
 * @returns a comma separated string in the form of Preference Tier {}
 */
export const getPreferenceTiers = (preferences = "") => {
  if (!preferences) return "";
  return [
    ...preferences.split("|").reduce((acc, curr) => {
      const [_, pref] = curr.split("~");
      if (pref && pref != 0) {
        acc.add(pref);
      }

      return acc;
    }, new Set()),
  ]
    .sort((a, b) => a - b)
    .map((t) => `Tier ${t}`)
    .join(", ");
};

export function getRankingType(rankType) {
  return rankType == 1 ? "Tiers" : rankType == 2 ? "Points" : "No Apply";
}

export function getDrawingIdOptions(list = []) {
  let hash = new Set();
  return [{ label: "All", value: "All" }].concat(
    list
      .reduce((acc, curr) => {
        const { OpportunityDrawingID } = curr;
        if (!hash.has(OpportunityDrawingID)) {
          hash.add(OpportunityDrawingID);
          acc.push({
            label: OpportunityDrawingID,
            value: OpportunityDrawingID,
          });
        }

        return acc;
      }, [])
      .sort((a, b) => b.value - a.value)
  );
}

export function sortTiers(preferences = "", showMaxTierOnly = false) {
  const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
  const numTiers = preferences.match(NUMERIC_REGEXP);

  if (numTiers?.length > 0 && showMaxTierOnly) {
    return `Tier ${Math.min(...numTiers)}`;
  }
  return (
    numTiers
      ?.sort((a, b) => a - b)
      .map((tier) => `Tier ${tier}`)
      .join(", ") ?? "No Preference"
  );
}
