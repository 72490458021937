import React from "react";

export function ApplicationDocs() {
  return (
    <section>
      <h4 className="text-lg text-gray-900">Application Docs</h4>
      <p className="text-sm py-1 text-gray-700 leading-loose">
        The Application Docs tab is where you’ll upload any completed
        Application Forms, Checklist Documents, Exhibits, or other forms
        provided to you from the Administrator or downloaded from the Program
        Website. We have two website links that explain what is contained in an
        application package.
      </p>
      <div className="flex flex-col space-x-1">
        <a href="www.housekeys.org/buyerfile" className="underline">
          www.housekeys.org/buyerfile
          <span className="none">for homebuyer</span>
        </a>
        <a href="www.housekeys.org/renterfile" className="underline">
          www.housekeys.org/renterfile <span>for renters</span>
        </a>
      </div>
    </section>
  );
}

export function Assets() {
  return (
    <section>
      <h4 className="text-lg text-gray-900">Assets</h4>
      <p className="text-sm py-1 text-gray-700 leading-loose">
        Assets are considered items of monetary value. This can include
        properties, cash, stock, and other assets. The program administrator
        will ask for at least 3 months worth of statements for any asset
        accounts. If you only get quarterly or annual statements, please make
        sure to provide the last 2 statements. If you own any properties out of
        state or out of the country, please note that the program administrator
        may inquire about the property and ask you for documentation to show
        ownership and value. Some programs have a first-time homebuyer
        requirement and don’t allow previous or current property ownership.
        Please make sure to check the guidelines for the program that you are
        applying for. Your asset balance will be added to your total income.
        This is done using a percentage that can range from 2.5% to 10% of total
        account balances. Example: $100,000 Balance x 2.5% = $2,500 added to
        your total household income. That means if you earn $50,000 in wages,
        your total household income could be calculated at $52,500.
      </p>
    </section>
  );
}

export function Income() {
  return (
    <section>
      <h4 className="text-lg text-gray-900">Income</h4>
      <p className="text-sm py-1 text-gray-700 leading-loose">
        Program Administrators must verify income and calculate a total gross
        projected income (this is the income before federal, state, or other
        taxes are taken out) for the next 12 months. This 12-month projection is
        used to verify that your total household income is below the maximum
        income limits set by federal and state governments. This is the most
        important part of being considered “eligible” for a program. Income Docs
        Example: Wage Earner For a basic wage earner employed by an employer
        that issues a regularly scheduled paycheck, providing your most recent 3
        months of paystubs, W2s for the previous 2 years, and last 2 years of
        filed tax returns would be a good start. If your income is more
        complicated (contractor income, self-employed, retirement, investment,
        etc.) then the program administrator may request additional
        documentation. As a rule of thumb, start with the last 2 years of tax
        documents and the last 3 months of current income information.
      </p>
    </section>
  );
}

export function Others() {
  return (
    <section>
      <h4 className="text-lg text-gray-900">Others</h4>
      <p className="text-sm py-1 text-gray-700 leading-loose">
        Other Docs includes the documents that don’t go in the other 5 tabs:
        Application Docs, Income, Assets, Qualification, and Preference. A good
        example of this would be your identification docs (e.g., driver’s
        license), bankruptcy, divorce documentation, etc.
      </p>
    </section>
  );
}

export function Preference() {
  return (
    <section>
      <h4 className="text-lg text-gray-900">Preference</h4>
      <p className="text-sm py-1 text-gray-700 leading-loose">
        A “Preference” is a special designation given to applicants who meet a
        target housing goal for the program. For example, a program may want to
        prioritize local employees who work in the city in order to reduce
        traffic congestion. If you met this criteria, you’d provide proof of
        employment and proof of your employer’s location showing that you work
        in the city where the program is located. There are other preferences
        (e.g., Veterans, First Responders, Residents, etc.) and any documents
        that you have that show you meet this criteria would be uploaded here.
      </p>
    </section>
  );
}

export function QualificationDocs() {
  return (
    <section>
      <h4 className="text-lg text-gray-900">Qualification</h4>
      <p className="text-sm py-1 text-gray-700 leading-loose">
        When you apply for an affordable housing program, the administrator is
        verifying that you are “eligible” for the program. This mainly means
        that your household size and total income are below the maximum income
        limits for the category that you are applying for. Your “qualification”
        for the housing opportunity is based on making sure that you earn enough
        to make the housing payment. If you are purchasing a home, the
        qualification doc would be your mortgage pre-approval letter and other
        documents provided by your loan officer. If you are renting a home, the
        qualification doc would be your tenant approval provided by the property
        management company.
      </p>
    </section>
  );
}
