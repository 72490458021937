import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formField: {
    width: "200px",
  },
}));

export default function AddFilePerms({ users, open, onClose, handleAdd }) {
  const classes = useStyles();

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        Add File Permissions
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p className="text-gray-700 text-sm">
          {" "}
          File permissions will be added to the following user, please review
          and click Submit to proceed.
        </p>
        <div className="flex mt-4  flex-col justify-center items-start">
          {users.map((u) => (
            <div className="text-xs text-gray-500" key={u.userId}>
              {u.firstName}, {u.lastName}
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAdd}
          size="small"
          variant="contained"
          color="primary"
        >
          Add Permissions
        </Button>
        <Button
          onClick={onClose}
          size="small"
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
