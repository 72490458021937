import React from "react";
import {
  FormControl,
  RadioGroup,
  Radio,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledFormControlLabel = withStyles((theme) => ({
  label: {
    fontSize: "0.8rem",
    fontWeight: 600,
  },
}))(FormControlLabel);

const StyledRadio = withStyles((theme) => ({
  root: {
    width: "10px",
    height: "10px",
    padding: "20px",
  },
}))((props) => <Radio {...props} />);

export default function IncomeTypes({ incomeType, handleChange }) {
  return (
    <div className="border-b border-hk-dark-blue pb-2">
      <FormControl component="fieldset">
        <FormControl className="text-sm font-semibold" component="legend">
          Income Type
        </FormControl>
        <RadioGroup
          className="grid grid-cols-2 gap-2"
          style={{ display: "grid" }}
          aria-label="Income  Type"
          name="incomeType"
          value={incomeType}
          onChange={handleChange}
        >
          <div className="flex flex-col gap-2">
            <Tooltip title="No Income">
              <StyledFormControlLabel
                value="Z"
                control={<StyledRadio />}
                label="No Income"
              />
            </Tooltip>
            <Tooltip title="W2 Income from an employer">
              <StyledFormControlLabel
                value="E"
                control={<StyledRadio />}
                label="Employer"
              />
            </Tooltip>

            <Tooltip title="1099 Income, or a separate business entity that the member owns">
              <StyledFormControlLabel
                value="S"
                control={<StyledRadio />}
                label="Self-Employed"
              />
            </Tooltip>

            <Tooltip title="Unemployment benefit payments">
              <StyledFormControlLabel
                value="U"
                control={<Radio />}
                label="Unemployed"
              />
            </Tooltip>

            <Tooltip title="Retirement income benefits (Social Security, Pension e.t.c)">
              <StyledFormControlLabel
                value="R"
                control={<Radio />}
                label="Retirement"
              />
            </Tooltip>
          </div>

          <div className="flex flex-col gap-2">
            <Tooltip title="Disability-related monthly payments">
              <StyledFormControlLabel
                value="D"
                control={<Radio />}
                label="Disability"
              />
            </Tooltip>

            <Tooltip title="Court-ordered spousal support payments">
              <StyledFormControlLabel
                value="SS"
                control={<Radio />}
                label="Spousal Support"
              />
            </Tooltip>

            <Tooltip title="Court-ordered child support payments">
              <StyledFormControlLabel
                value="CS"
                control={<Radio />}
                label="Child Support"
              />
            </Tooltip>

            <Tooltip title="An affidavit certifying that household members, age 18 or older, do not currently earn income and have no plans of earning income in the next 12 months">
              <StyledFormControlLabel
                value="A"
                control={<Radio />}
                label="Affidavit"
              />
            </Tooltip>
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  );
}
