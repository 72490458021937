import React from "react";
import { usePreferences } from "../../../queries/preferences/usePreferences";
import MaterialUITable from "../../shared/Table/MaterialUITable";
import { formatDateToTimeZone } from "../../../utils/formatters";

export function Preferences() {
  const [prefs] = usePreferences();
  const cols = [
    {
      title: "ID",
      field: "preference_id",
    },
    {
      title: "Description",
      field: "title",
    },
    {
      title: "Created ",
      field: "date_created",
      render: (rowData) =>
        formatDateToTimeZone(rowData.date_created, "MM/dd/yyyy h:mm:ss a"),
    },
  ];
  return (
    <section className="min-h-screen">
      <MaterialUITable
        title={`Preferences (${prefs.length})`}
        columns={cols}
        data={prefs}
      />
    </section>
  );
}
