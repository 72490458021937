import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PeopleIcon from "@material-ui/icons/People";
import Loading from "../components/shared/misc/Loading";
import Select from "../components/shared/Select";
import axios from "../utils/axios";
import { getBaseUrl } from "../utils/queryHelpers";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function AddOpportunity({
  open,
  onClose,
  processing,
  handleProcessing,
  programId,
}) {
  const classes = useStyles();
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);

  useEffect(() => {
    if (!open) return;
    fetchProviders();
  }, [open]);

  useEffect(() => {
    if (!selectedProvider) return;
    fetchPrograms();
  }, [selectedProvider]);

  useEffect(() => {
    if (!selectedProgram) return;
    fetchProgramComponents();
  }, [selectedProgram]);

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        Add Opportunity
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <section className="mt-4">
          <div className="flex justify-center flex-col">
            <div className="flex flex-col justify-center items-center">
              <PeopleIcon />

              <span className="px-2 pt-2">Select Provider</span>
              {processing && <Loading />}
              <Select
                options={providers.map((p) => ({
                  label: p.EntityName,
                  value: p.EntityID,
                }))}
                label="Provider"
                value={selectedProvider}
                onChange={handleProviderChange}
              />
              {selectedProvider && (
                <Select
                  options={programs.map((p) => ({
                    label: p.ProgramTitle,
                    value: p.ProgramID,
                  }))}
                  label="Program"
                  value={selectedProgram}
                  onChange={handleProgramChange}
                />
              )}

              {selectedProgram && (
                <Select
                  options={components.map((c) => ({
                    label: `${c.ComponentSubject} - ${c.ComponentDescription}`,
                    value: c.ComponentID,
                  }))}
                  label="Component"
                  value={selectedComponent}
                  onChange={handleComponentChange}
                />
              )}
              {selectedComponent && <OpportunityForm />}
            </div>
          </div>
        </section>
      </DialogContent>
    </Dialog>
  );

  async function fetchProviders() {
    try {
      const {
        data: { data: providers },
      } = await axios.get(`${getBaseUrl()}/providers?rowCnt=100&page=1`);

      setProviders(providers);
    } catch (err) {
      console.error(`error getting providers ${err}`);
      setProviders([]);
    }
  }

  function handleProviderChange(e) {
    setSelectedProvider(e.target.value);
  }

  async function fetchPrograms() {
    try {
      const {
        data: { data: programs },
      } = await axios.get(
        `${getBaseUrl()}/providers/${selectedProvider}/programs?rowCnt=100&page=1`
      );

      setPrograms(programs);
    } catch (err) {
      console.error(`error getting programs ${err}`);
      setPrograms([]);
    }
  }

  function handleProgramChange(e) {
    setSelectedProgram(e.target.value);
  }

  async function fetchProgramComponents() {
    try {
      const {
        data: { data: comps },
      } = await axios.get(
        `${getBaseUrl()}/programs/${selectedProgram}/components?rowCnt=100&page=1`
      );

      setComponents(comps);
    } catch (err) {
      console.error(`error getting programs ${err}`);
      setComponents([]);
    }
  }

  function handleComponentChange(e) {
    setSelectedComponent(e.target.value);
  }
}

function OpportunityForm() {
  return (
    <form className="flex flex-col gap-3 border-t-2 border-gray-200 pt-4">
      <legend className="text-center ">Opportunity Detail</legend>
      <TextField label="Roster ID" />
      <TextField label="Description" />
      <TextField label="Est. Delivery Date" />
      <Select
        label="Income Level"
        options={[
          { label: "Extremely Low", value: 1 },
          { label: "Very Low", value: 1 },
          { label: "Low", value: 1 },
          { label: "Moderate Low", value: 1 },
        ]}
      />
      <TextField label="Hhld Income" />
      <TextField label="Hhld Size" />
      <TextField label="Purchase Price" />
      <TextField label="HOA Fee" />
      <TextField label="Expected AMI Level" />
      <TextField label="Max Hhld Size" />
      <TextField label="Min Hhld Size" />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox name="pubToMP" />}
          label="Publish to MarketPlace"
        />
        <FormControlLabel
          control={<Checkbox name="pubToPC" />}
          label="Publish to Program Center"
        />
      </FormGroup>

      <Button size="small" color="primary">
        Save
      </Button>
    </form>
  );
}
