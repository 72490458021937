import React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import Select from "../../shared/Select";

export default function Preferences({ fields, handleChange }) {
  const {
    pref_ownHome,
    pref_vet,
    pref_milMember,
    pref_survivingSpouse,
    pref_nativeAmerican,
    pref_cityDept,
    pref_countyDept,
    pref_disabled,
    pref_disabledDoc,
    pref_healthCarePro,
    pref_workForFire,
    pref_workForLawEnforcement,
    pref_workForSchool,
    pref_,
  } = fields;
  return (
    <section className="sm:px-32">
      <div className="flex flex-col gap-4">
        <Select
          name="pref_ownHome"
          size="small"
          label="Do you currently own a home?"
          onChange={handleChange}
          options={[
            { label: "No", value: "no" },
            { label: "Yes", value: "yes" },
          ]}
          value={pref_ownHome}
        ></Select>

        <FormGroup className="mt-4" row>
          <FormControlLabel
            control={
              <Checkbox
                className="self-end"
                required
                checked={pref_vet}
                onChange={handleChange}
                name="pref_vet"
              />
            }
            label="Are you or any of the members of your household a veteran?"
            labelPlacement="start"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_milMember}
                onChange={handleChange}
                name="pref_milMember"
              />
            }
            label="Are you or any of the members of your household an active member of the U.S military?"
            labelPlacement="start"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_survivingSpouse}
                onChange={handleChange}
                name="pref_survivingSpouse"
              />
            }
            label="Are you a surviving spouse of a military member ?"
            labelPlacement="start"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_nativeAmerican}
                onChange={handleChange}
                name="pref_nativeAmerican"
              />
            }
            label="Are you or any of the members of your household Native American ?"
            labelPlacement="start"
          />
        </FormGroup>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                classes={{ alignSelf: "end" }}
                className="ml-auto"
                checked={pref_workForSchool}
                onChange={handleChange}
                name="pref_workForSchool"
              />
            }
            label="Work for the School District ?"
            labelPlacement="start"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_workForLawEnforcement}
                onChange={handleChange}
                name="pref_workForLawEnforcement"
              />
            }
            label="Work in Law Enforcement for a City or County Department ?"
            labelPlacement="start"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_workForFire}
                onChange={handleChange}
                name="pref_workForFire"
              />
            }
            label="Work for a City or County Fire Department ?"
            labelPlacement="start"
          />
        </FormGroup>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_healthCarePro}
                onChange={handleChange}
                name="pref_healthCarePro"
              />
            }
            label="Work as a healthcare professional ?"
            labelPlacement="start"
          />
        </FormGroup>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_cityDept}
                onChange={handleChange}
                name="pref_cityDept"
              />
            }
            label="Work for a City Department or office ?"
            labelPlacement="start"
          />
        </FormGroup>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_countyDept}
                onChange={handleChange}
                name="pref_countyDept"
              />
            }
            label="Work for a County Department or office ?"
            labelPlacement="start"
          />
        </FormGroup>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_disabled}
                onChange={handleChange}
                name="pref_disabled"
              />
            }
            label="Do you or any of your household members have a disability ?"
            labelPlacement="start"
          />
        </FormGroup>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                required
                checked={pref_disabledDoc}
                onChange={handleChange}
                name="pref_disabledDoc"
              />
            }
            label="If Yes, could you provide documentation ?"
            labelPlacement="start"
          />
        </FormGroup>
      </div>
    </section>
  );
}
