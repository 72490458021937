import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
    Button,
    Typography,
} from "@material-ui/core";

export default function RankingNotification({
    handleAction,
    notificationHistory,
    processingEmails
}) {
    const lastAction = (notificationHistory) => {
        if (notificationHistory.length > 0) {
            const last = notificationHistory.sort(function(a, b) {
                const keyA = new Date(a.created),
                    keyB = new Date(b.created);
                return keyB - keyA })[0]
            const dt = new Date(last.created)
            const formattedDate = dt.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            return `${formattedDate} by ${last.username}`
        } else {
            return "Never"
        }
    }
    return  <div>
        <div className="text-gray-800  p-1  text-sm font-bold mt-2">
            Published Results Email Notification
        </div>
        <div className="text-gray-400  p-1  text-sm">
            Last sent: {lastAction(notificationHistory)}
        </div>
        <Button
            variant="contained"
            color="primary"
            active={!processingEmails}
            onClick={handleAction}
        >
            Send Email
        </Button>
    </div>
}