import React, {
  useContext,
  useState,
  useEffect,
  useReducer,
  useRef,
} from "react";

import {
  Button,
  Divider,
  Stepper,
  StepLabel,
  Step,
  Typography,
} from "@material-ui/core";
import { useParams, useHistory, Redirect } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Loading from "../shared/misc/Loading";
import LoadingBackDrop from "../shared/misc/LoadingBackDrop";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import { extractFormFields, renderFormField } from "../../utils/hhldUtils";
import UserContext from "../user/UserContext";
import useQueryParams from "../shared/hooks/useQuery";

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#eb7e3c",
    color: "#fff",
  },
  disabled: {
    backgroundColor: "#FFE7D9",
    color: "#fff",
  },
}))((props) => <Button {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    "&& .MuiFormLabel-root": {
      fontSize: "0.75rem",
    },
  },
  formGroup: {
    display: "flex",
  },
  checkBoxLabel: {
    "&& .MuiFormControlLabel-label": {
      fontSize: "0.75rem",
    },
  },
  selectMenuLabel: {
    fontSize: "0.65rem",
  },
}));

function formReducer(state, action) {
  return { ...state, [action.type]: action.payload };
}

export default function ProgramApplication({ onSubmit }) {
  const [program, setProgram] = useState({});
  const [programQuestions, setProgramQuestions] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const fetchedQuestions = useRef(false);
  const [hasAppId, setHasAppId] = useState(true);

  const { user } = useContext(UserContext) || {};

  const [state, dispatch] = useReducer(formReducer, {
    submission: {},
    submissionComplete: false,
    submittingDrawing: false,
    showFailureMsg: false,
    showSuccessMsg: false,
    submitting: false,
  });

  const qryParams = useQueryParams();

  let { programId } = useParams();
  const history = useHistory();
  if (!programId) {
    programId = qryParams.get("programId");
  }

  async function fetchProgram() {
    //setLoading(true);
    const {
      data: { data: q },
    } = await axios.get(`${getBaseUrl()}/programs/${programId}`);

    setProgram(q?.[0]);
    //setLoading(false);
  }

  async function fetchProgramQuestions() {
    setLoading(true);
    const {
      data: { data: q },
    } = await axios.get(
      `${getBaseUrl()}/programs/${programId}/preapp_questions`
    );

    setProgramQuestions(q);
    fetchedQuestions.current = true;
    setLoading(false);
  }

  async function checkAppId() {
    setLoading(true);
    const {
      data: { data: q },
    } = await axios.get(
      `${getBaseUrl()}/user/${user.userId}/applications?programId=${programId}`
    );

    setLoading(false);
    //redirect the user to the programs home page, if the user has an application id for this program already
    if (typeof q?.applicationFileId == "undefined") {
      setHasAppId(false);
    } else {
      history.push("/hhld/programs");
    }
  }

  useEffect(() => {
    checkAppId();
  }, []);
  useEffect(() => {
    if (!hasAppId) {
      fetchProgram();
      fetchProgramQuestions();
    }
  }, [hasAppId]);

  useEffect(() => {
    if (fetchedQuestions.current) {
      const { elig = [], qual = [], gen = [] } = programQuestions;
      if (elig.length < 1 && qual.length < 1 && gen.length < 1) {
        submitApplication();
      }
    }
  }, [fetchedQuestions.current]);

  const { elig = [], qual = [], gen = [] } = programQuestions;

  if (loading)
    return (
      <section className="min-h-screen flex flex-col justify-center items-center">
        <Loading />
      </section>
    );

  const eligFields = extractFormFields(state, "elig");
  const qualificationFields = extractFormFields(state, "qualification");
  const genFields = extractFormFields(state, "gen");

  return (
    <section className="min-h-screen sm:px-2">
      <div className="flex flex-col items-center h-40 space-y-2  py-8 px-4  bg-hk-orange-100 text-hk-orange-400">
        <div className="flex flex-col space-y-2 justify-center items-start p-4 ">
          <div className="flex space-x-2">
            <AssignmentIndIcon className=" " />
            <h5 className=" uppercase  text-xl sm:3xl">Pre-Application Form</h5>
          </div>
          <div className="flex justify-center flex-col text-hk-orange-300">
            <span className="mt-2">{program.Title}</span>
            <span className="text-center">{program.EntityName}</span>
          </div>
        </div>
        <p className="-mt-4 text-gray-500  shadow-md text-xs text-left leading-loose tracking-wider w-72 sm:w-1/2  mb-4  bg-white rounded  px-4 py-4">
          For any household members age 18 or older, who are not earning income,
          a Zero-Income Affidavit will need to be used to certify the
          truthfulness and accuracy of the claim. We have to do this to ensure
          that all of the income in the Household is being calculated –
          especially because it determines the household’s income category
        </p>
      </div>

      <Divider />
      <div className="mt-56 sm:mt-32 p-4 flex justify-center">
        <FormStepper
          getStepContent={getStepContent}
          handleFinish={submitApplication}
          nextDisabled={isNextDisabled()}
        />
      </div>
      <LoadingBackDrop open={state.submitting} />
    </section>
  );

  async function submitApplication() {
    dispatch({
      type: "submitting",
      payload: true,
    });
    const {
      data: { data: preAppResp },
    } = await axios.post(
      `${getBaseUrl()}/programs/${programId}/preapp/submission`,
      {
        elig: eligFields,
        qual: qualificationFields,
        gen: genFields,
      }
    );

    dispatch({
      type: "submitting",
      payload: false,
    });
    if (preAppResp.ApplicationFileID) {
      dispatch({ type: "preAppFileId", payload: preAppResp.ApplicationFileID });

      dispatch({ type: "showSucessMsg", payload: true });

      if (typeof onSubmit === "function") {
        onSubmit(preAppResp);
      } else {
        history.push(`/programs/${programId}/preApp/${preAppResp.preAppId}`);
      }
    }
  }

  function isNextDisabled() {
    //if there are no questions, advance to the next step
    if (elig.length < 1 && qual.length < 1 && gen.length < 1) {
      return false;
    }
    if (activeStep === 0) {
      //check if all eligibility questions have been answered
      const eligAnswers = Object.entries(state).filter(
        ([key, value]) => key.startsWith("elig") && value.value != ""
      );

      const qualAnswers = Object.entries(state).filter(
        ([key, value]) => key.startsWith("qual") && value.value != ""
      );

      const genAnswers = Object.entries(state).filter(
        ([key, value]) => key.startsWith("gen") && value.value != ""
      );

      return genAnswers.length !== gen.length ||
        qualAnswers.length !== qual.length ||
        eligAnswers.length !== elig.length
        ? true
        : false;
    }
  }

  function getStepContent(stepIndex) {
    return (
      <div>
        <EligibilityQuestions
          elig={elig}
          fields={eligFields}
          dispatch={dispatch}
        />
        <QualificationQuestions
          questions={qual}
          fields={qualificationFields}
          dispatch={dispatch}
        />
        <GeneralQuestions
          questions={gen}
          fields={genFields}
          dispatch={dispatch}
        />
      </div>
    );
  }
}

function FormStepper({ getStepContent, handleFinish, nextDisabled }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="w-full">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form className="sm:px-40">
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Application successfully submitted
            </Typography>
          </div>
        ) : (
          <div className="">
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
            <div className="flex justify-end sm:p pt-8">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  disabled={false}
                  color="primary"
                  onClick={(e) => {
                    handleFinish(e);
                  }}
                >
                  Apply
                </Button>
              ) : (
                <StyledButton
                  variant="contained"
                  onClick={handleNext}
                  disabled={nextDisabled}
                >
                  Next
                </StyledButton>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

function getSteps() {
  return ["Questions"];
}

function EligibilityQuestions({ elig, dispatch, fields }) {
  return (
    <section className="p-6 sm:p-16 min-h-64">
      {elig.map((e) => (
        <div key={e.ProgramEligibilityQuestionID}>
          {renderFormField(e, {
            form: "elig",
            keyId: "ProgramEligibilityQuestionID",
            dispatch,
            fields,
          })}
        </div>
      ))}
    </section>
  );
}

function QualificationQuestions({ questions, dispatch, fields }) {
  return (
    <section className={`${questions.length ? "p-6 sm:p-16 h-1/2" : ""}`}>
      {questions.map((e) => (
        <div key={e.OpportunityQualificationQuestionID}>
          {renderFormField(e, {
            textFieldWidth: "full",
            txtFieldLabelFontSize: "0.8rem",
            form: "qualification",
            keyId: "ProgramQualificationQuestionID",
            dispatch,
            fields,
          })}
        </div>
      ))}
    </section>
  );
}

function GeneralQuestions({ questions, dispatch, fields }) {
  return (
    <section className={`${questions.length ? "p-6 sm:p-16" : ""}`}>
      {questions.map((e) => (
        <div key={e.InventoryGeneralQuestionID}>
          {renderFormField(e, {
            form: "gen",
            keyId: "InventoryGeneralQuestionID",
            dispatch,
            fields,
          })}
        </div>
      ))}
    </section>
  );
}
