import React, { useState, useEffect } from "react";
import { Link as MatLink, Breadcrumbs, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { getSub } from "../../../utils/authUtils";
import MaterialUITable from "../../shared/Table/MaterialUITable";
import AddUserModal from "./AddUser";
import PopperMsg from "../../shared/misc/PopperMsg";
import OrgDetailCard from "./OrgDetailCard";

export default function OrgUserList() {
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [orgDetail, setOrgDetail] = useState({});
  const [loadingOrgDetail, setLoadingOrgDetail] = useState(false);
  const [showUserModal, toggleAddUserModal] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const { orgId } = useParams();
  const userName = "";

  useEffect(() => {
    fetchUsers();
    fetchOrgDetail();
  }, []);

  const showAddUserModal = () => {
    toggleAddUserModal(true);
  };

  const onCloseUserModal = () => {
    toggleAddUserModal(false);
  };

  const tableColumns = [
    { title: "Username", field: "UserName" },

    { title: "ProfileType", field: "ProfileType" },
    { title: "Profile Type Desc", field: "ProfileTypeTitle" },
    { title: "ProfileType", field: "ProfileType" },
    {
      title: "In Cognito",
      field: "IncID",
      render: (rowData) => (rowData.IncID ? "Y" : "N"),
    },
    { title: "Status", field: "AccountStatus" },
  ];

  return (
    <section className="min-h-screen p-4">
      <Breadcrumbs className="p-2" aria-label="breadcrumb">
        <MatLink color="inherit" href="/user-mgmt">
          Org
        </MatLink>
        <MatLink color="inherit" href={`#`}>
          {orgId}
        </MatLink>
        <MatLink color="inherit" href="#">
          users
        </MatLink>
      </Breadcrumbs>
      <OrgDetailCard org={orgDetail} />
      <div className="p-4 flex justify-end">
        <Button
          color="primary"
          startIcon={<PersonAddIcon />}
          onClick={showAddUserModal}
        >
          Add User
        </Button>
      </div>
      <MaterialUITable
        columns={tableColumns}
        isLoading={loadingUsers}
        stickyHeader
        className="p-4 mt-4"
        data={users}
        title={`Users (${users.length})`}
        options={{
          exportButton: true,
          pageSize: 10,
          grouping: true,
          actionsColumnIndex: -1,
        }}
        actions={[
          (rowData) => ({
            icon: AddIcon,
            tooltip: "Add to Cognito",
            disabled: !!rowData.IncID,
            onClick: (event, rowData) => {
              syncToCognito(rowData);
            },
          }),
          {
            icon: DeleteIcon,
            tooltip: "Delete User",

            onClick: (event, rowData) => {
              deleteUserFromCognito(rowData.UserID);
            },
          },
          {
            icon: "R",
            tooltip: "Reset Password",

            onClick: (event, rowData) => {
              resetPassword(rowData.userId, rowData.userName);
            },
          },
        ]}
      />
      <AddUserModal
        onClose={onCloseUserModal}
        open={showUserModal}
        orgId={orgId}
        updateUserIncId={updateUserIncId}
      />
      <PopperMsg
        open={userCreated}
        handleClose={() => {
          setUserCreated(false);
        }}
      >
        <p>
          <span>A temporary password has been sent to </span>
          <span>{userName}</span>

          <span>
            . Now the user can login to the housekeys app and update their
            password.{" "}
          </span>
        </p>
      </PopperMsg>
    </section>
  );

  async function deleteUserFromCognito() {}
  async function getUserProfile() {}

  async function fetchUsers() {
    setLoadingUsers(true);
    const {
      data: { data: users },
    } = await axios.get(
      `${getBaseUrl()}/admin/orgs/${orgId}/users?rowCnt=500&page=1`
    );
    setUsers(users);
    setLoadingUsers(false);
  }

  async function fetchOrgDetail() {
    setLoadingOrgDetail(true);
    const {
      data: { data: orgs },
    } = await axios.get(`${getBaseUrl()}/entity/${orgId}`);
    setOrgDetail(orgs?.[0] || {});
    setLoadingOrgDetail(false);
  }

  async function syncToCognito(rowData) {
    const {
      UserID,
      FirstName,
      LastName,
      ProfileType,
      UserName,
      Email,
    } = rowData;
    const {
      data: { data: User },
    } = await axios.put(`${getBaseUrl()}/user/cog`, {
      userName: Email,
      firstName: FirstName,
      lastName: LastName,
      profileType: ProfileType,
      orgId: orgId,
    });

    const attrs = User?.User?.Attributes;
    if (attrs) {
      setUserCreated({
        userName,
      });

      await updateUserIncId({
        subId: getSub(attrs),
        userName: Email,
        userId: UserID,
      });
      fetchUsers();
    }
  }

  async function updateUserIncId({ subId, userName, userId }) {
    const {
      data: { data: user },
    } = await axios.post(`${getBaseUrl()}/admin/user/${userId}`, {
      userId,
      subId,
      userName,
    });
  }

  async function resetPassword(userId, userName) {
    const {
      data: { data: User },
    } = await axios.post(`${getBaseUrl()}/user/${userId}/reset_pwd`, {
      userName,
    });
  }
}
