import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Loading from "../shared/misc/Loading";
import MaterialUITable from "../shared/Table/MaterialUITable";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";
import { formatDateToTimeZone } from "../../utils/formatters";

type File = {
  Bucket: string;
  ContentType: string;
  CreatedBy: string;
  CreatedOn: string;
  FileKey: string;
  Filename: string;
  Filepath: string;
  Label: string;
  ID: string;
  Region: string;
  ToDelete: boolean;
  UpdatedOn: string;
  Visible: boolean;
};

type AdminMemberFileList = {
  userId: string;
  memberId: string;
};

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: "90%",
    minHeight: "90%",
  },
  content: {
    minWidth: "100%",
    minHeight: "100%",
  },
}));

export default function AdminMemberFileList({
  userId,
  memberId,
}: AdminMemberFileList) {
  const [files, setFiles] = useState<File[]>([]);
  const [showFileDialog, setShowFileDialog] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [fetchingFileLink, setFetchingFileLink] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    getFiles();
  }, [userId, memberId]);

  const tableColumns = [
    { title: "Filename", field: "Filename", editable: "never" },
    { title: "Type", field: "ContentType", editable: "never" },
    {
      title: "Created",
      field: "CreatedOn",
      editable: "never",
      render: (rowData) =>
        formatDateToTimeZone(rowData.CreatedOn, "MM/dd/yyyy h:mm:ss a"),
    },
    {
      title: "Tags",
      field: "Label",
    },
  ];

  return (
    <section>
      <MaterialUITable
        columns={tableColumns}
        /* components={{ GroupRow: prop => <div>Group Here</div> }} */
        stickyHeader
        className="p-4 mt-4"
        data={files}
        title={`Files (${files.length})`}
        actions={[
          {
            icon: VisibilityIcon,
            tooltip: "View File",
            onClick: (event, rowData) => {
              handlePreview(rowData.ID);
            },
            position: 'row'
          },
          {
            icon: CloudDownloadIcon,
            tooltip: "Download file",
            onClick: (event, rowData) => {
              handleDownload(rowData.ID, rowData.Filename);
            },
            position: 'row'
          },
        ]}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return handleLabelUpdate(newValue, rowData);
          },
        }}
        options={{
          exportButton: true,
          pageSize: 10,
          grouping: true,
          actionsColumnIndex: -1,
          selection: true
        }}
      />
      <Dialog
        classes={{ paper: classes.paper }}
        open={showFileDialog}
        onClose={handleClose}
      >
        <DialogContent>
          <div>
            {fetchingFileLink && <Loading />}
            <iframe allowFullScreen src={fileLink} width="100%" height="500" />
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );

  function handleClose() {
    setShowFileDialog(false);
    setFileLink("");
  }
  async function handleLabelUpdate(tags, rowData) {
    const {
      data: { data: files },
    } = await axios.put(`${getBaseUrl()}/user/${userId}/files`, {
      memberId: memberId,
      files: [
        {
          fileids: [rowData.ID],
          label: tags,
        },
      ],
    });
    getFiles();
  }

  async function getFiles() {
    const {
      data: { data: files },
    } = await axios.get(
      `${getBaseUrl()}/user/${userId}/files?memberId=${memberId}&limit=500`
    );
    setFiles(files);
  }

  async function handlePreview(fileId: string) {
    setShowFileDialog(true);
    setFetchingFileLink(true);
    const { data } = await axios.get(
      `${getBaseUrl()}/user/${userId}/files/${fileId}?memberId=${memberId}`
    );

    if (data?._link?.download) {
      const {
        _link: {
          download: { href },
        },
      } = data;
      setFileLink(href);
    }
    setFetchingFileLink(false);
  }

  async function handleDownload(fileId: string, fileName: string) {
    const { data } = await axios.get(
      `${getBaseUrl()}/user/${userId}/files/${fileId}?memberId=${memberId}`
    );

    if (data?._link?.download) {
      const {
        _link: {
          download: { href },
        },
      } = data;

      await fetch(href, {
        method: "GET",
      })
        .then(async (res) => {
         
          return {
            filename: fileName,
            blob: await res.blob(),
            ctype: res.headers.get("content-type"),
          };
        })
        .then((resObj) => {
          
          // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
          const newBlob = new Blob([resObj.blob], { type: resObj.ctype });

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement("a");
            link.href = objUrl;
            link.download = resObj.filename;
            link.click();

            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => {
              window.URL.revokeObjectURL(objUrl);
            }, 250);
          }
        })
        .catch((error) => {
          console.log("DOWNLOAD ERROR", error);
        });
    }
  }
}
