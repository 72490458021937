import React, {useState, useEffect} from "react";
import axios from "../../utils/axios";
import {getBaseUrl} from "../../utils/queryHelpers";
import {SiReact} from "react-icons/all";

type MergeVar = {
    name: string,
    note: string
}

type Template = {
    slug: string,
    title: string,
    merge_vars: MergeVar[]

}

type Status = {
    slug: string,
    status: string,
    warnings: string[]
}

function MergeVars(props: { merge_vars: MergeVar[] }) {
    const var_list: JSX.Element[] = []
    props.merge_vars.forEach(mv => {
        var_list.push(<div key={"var-"+mv.name}><code>{mv.name}</code> = {mv.note}</div>)
    })
    return <div>{var_list}</div>
}

function TemplateStatus(props: { key: any, slug: string, statuses: Status[] }) {
    const templateStatus = props.statuses.find(status => {
        return status.slug === props.slug
    })
    console.log("TemplateStatus: "+JSON.stringify(props.statuses))
    if (templateStatus) {
        const warnings: JSX.Element[] = []
        if (templateStatus.warnings) {
            let i = 1
            templateStatus.warnings.forEach(w => {
                warnings.push(<div key={"warning-"+i}>{w}</div>)
            })
        }
        return <div>
            <div>{templateStatus.status}</div>
            <div>{warnings}</div>
        </div>
    } else {
        return null
    }
}

export function Templates() {

    const [templates, setTemplates] = useState([] as Template[]);
    const [statuses, setStatuses] = useState([] as Status[])
    useEffect(() => {
        fetchTemplates();
    }, []);

    async function fetchStatus(slug: string) {
        const {
            data: {data: templateStatus},
        } = await axios.get(`${getBaseUrl()}/email/template/validate/${slug}`)
        return {
            ...templateStatus,
            slug: slug
        }
    }

    async function fetchTemplates() {
        try {
            const {
                data: {data: templates},
            } = await axios.get(`${getBaseUrl()}/email/contracts`);
            setTemplates(templates)
            let newStatuses = [] as Status[]
            setStatuses(newStatuses)
            for (let i = 0; i < templates.length; i++) {
                const templateStatus = await fetchStatus(templates[i].slug)
                newStatuses = newStatuses.concat(templateStatus)
                setStatuses(newStatuses)
            }
        } catch (err) {
            console.error(`error getting templates ${err}`);
        }
    }

    return (
        <section className="min-h-screen">
            <h3 className="bg-hk-orange-100 text-hk-orange-400 h-20 text-2xl uppercase flex items-center justify-center text-center align-middle">
                Templates
            </h3>
            <section className="mt-4 w-full sm:px-20 p-4 flex flex-col min-h-48">
                <section className="flex flex-col mt-8 min-h-48">
                    <section
                        className="hidden sm:grid grid-cols-12 mt-2 border-b border-gray-400 p-2 text-xs text-gray-500 uppercase">
                        <span className="justify-self-start col-span-2">Name</span>
                        <span className="col-span-3">Title</span>
                        <span className="col-span-3">Merge Variables</span>
                        <span className="justify-self-end col-span-4">Status</span>
                    </section>
                    {templates.map((template: Template) => (
                        <div key={template.slug} className="hidden sm:grid grid-cols-12 mt-2 text-gray-700 text-sm">
                            <div className="col-span-2">
                                {template.slug}
                            </div>
                            <div className="col-span-3">
                                {template.title}
                            </div>
                            <div className="col-span-3">
                                <MergeVars merge_vars={template.merge_vars}/>
                            </div>
                            <div className="col-span-4">
                                <TemplateStatus key={"status="+template.slug} slug={template.slug} statuses={statuses}/>
                            </div>
                        </div>
                    ))}
                </section>
            </section>
        </section>
    )
}

