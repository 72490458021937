import React from "react";
import { TextField as MaterialTextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "200px",
  },
}));
export default function TextField(props) {
  const classes = useStyles();

  return (
    <MaterialTextField
      InputLabelProps={{ shrink: true }}
      className={classes.textField}
      {...props}
    />
  );
}
