import React, { useReducer } from "react";
import { Auth } from "aws-amplify";
import { FormattedMessage } from "react-intl";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FormGroup from "../../shared/form/FormGroup";
import { usePreferredLanguage } from "../../../translations/LanguageContext";
import messages_en from "../../../translations/en.json";
import messages_esp from "../../../translations/espanol.json";

const messages = {
  es: messages_esp,
  en: messages_en,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: "auto",
    minWidth: 120,
    height: 20,
    marginTop: "20px",
    marginBottom: "40px",
    alignSelf: "flex-end",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    fontSize: "14px",
  },
  label: {
    fontSize: "14px",
  },
  select: {
    height: 30,
  },
}));

function reducer(state, action) {
  return { ...state, [action.type]: action.payload };
}

export default function SignIn({ setStatus }) {
  const [state, dispatch] = useReducer(reducer, {
    userName: "",
    password: "",
    newPassword: "",
    collectNewPassword: false,
    authUser: null,
    errors: {
      userName: "",
      password: "",
    },
  });

  const { preferredLang, setPreferredLang } = usePreferredLanguage();

  const {
    authUser,
    userName,
    password,
    newPassword,
    collectNewPassword,
  } = state;

  const classes = useStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: name,
      payload: value,
    });
  };

  return (
    <section className="min-h-screen flex flex-col items-center">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel className={classes.label} id="language-label">
          Language
        </InputLabel>
        <Select
          labelId="language-label"
          id="language-select"
          label="Language"
          className={classes.select}
          value={preferredLang}
          onChange={(e) => setPreferredLang(e.target.value)}
        >
          <MenuItem className={classes.menuItem} value="en">
            English
          </MenuItem>
          <MenuItem className={classes.menuItem} value="es">
            Español
          </MenuItem>
        </Select>
      </FormControl>
      <form className="w-full sm:w-96 shadow-md p-8" onSubmit={handleSubmit}>
        <div className="border-b border-gray-200 py-2">
          <AccountCircleIcon fontSize="large" className="text-hk-orange-300" />
        </div>

        <p className="text-center text-xl text-gray-700 pt-4">
          <FormattedMessage
            id="signin.header"
            defaultMessage="Sign in to your account"
          ></FormattedMessage>
        </p>
        <div className="mt-4 space-y-2 flex flex-col">
          <FormGroup
            label={messages[preferredLang]["signin.username"]}
            required
            formId="signin"
            type="text"
            handleChange={handleChange}
            value={userName}
            id="userName"
            placeholder={messages[preferredLang]["signin.username.placeholder"]}
            name="userName"
          />
        </div>
        <FormGroup
          label={messages[preferredLang]["signin.password"]}
          required
          formId="signin"
          type="password"
          handleChange={handleChange}
          value={password}
          id="password"
          placeholder={messages[preferredLang]["signin.password.placeholder"]}
          name="password"
        />
        {collectNewPassword && (
          <FormGroup
            label="New Password"
            required
            type="password"
            handleChange={handleChange}
            value={newPassword}
            id="newPassword"
            placeholder="Enter your New password"
            name="newPassword"
          />
        )}

        <div className="flex space-x-2 text-xs mt-3">
          <span className="text-gray-400">
            <FormattedMessage
              id="signin.forgot_password"
              defaultMessage="Forgot your password?"
            ></FormattedMessage>
          </span>
          <a
            href="#"
            id="sign-up-link"
            onClick={() => setStatus("reset-pw")}
            className="text-hk-orange-400 hover:underline"
          >
            <FormattedMessage
              id="signin.reset_password"
              defaultMessage="Reset Password"
            ></FormattedMessage>
          </a>
        </div>
        <div className="mt-6 flex flex-col sm:flex-row justify-between items-baseline text-xs">
          <div className="flex space-x-2 mt-2">
            <span className="text-gray-400">
              <FormattedMessage
                id="signin.no_account"
                defaultMessage="Reset Password"
              ></FormattedMessage>
              ?
            </span>
            <a
              href="#"
              id="sign-up-link"
              onClick={() => setStatus("sign-up")}
              className="text-hk-orange-400 hover:underline"
            >
              <FormattedMessage
                id="signin.create_account"
                defaultMessage="Create Account"
              />
            </a>
          </div>
          {!collectNewPassword ? (
            <button
              id="login-btn"
              disabled={!userName || !password}
              className="w-full mt-4 tracking-wide sm:w-32 order-first sm:order-last  h-10 uppercase bg-hk-orange-400 text-white font-semibold disabled:opacity-50 disabled:cursor-default"
              onClick={handleSubmit}
            >
              <FormattedMessage
                id="signin.sign_in_button"
                defaultMessage=" Sign In"
              ></FormattedMessage>
            </button>
          ) : (
            <button
              id="update-password-btn"
              className="w-full mt-4 tracking-wide sm:w-32 order-first sm:order-last  h-10 uppercase bg-hk-orange-400 text-white font-semibold disabled:opacity-50 disabled:cursor-default"
              onClick={handlePasswordUpdate}
            >
              Update Password
            </button>
          )}
        </div>
      </form>
    </section>
  );

  async function handleSubmit(e) {
    e.preventDefault();

    const user = await Auth.signIn(userName.trim(), password);

    //for accounts that were created by admins with a temp password
    if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
      dispatch({
        type: "collectNewPassword",
        payload: true,
      });
      dispatch({
        type: "authUser",
        payload: user,
      });
    }
  }

  async function handlePasswordUpdate(e) {
    e.preventDefault();

    await Auth.completeNewPassword(authUser, newPassword);
  }
}
