import React from "react";
import TextField from "../../shared/form/TextField";
import Select from "../../shared/Select";
import DatePicker from "../../shared/form/DatePicker";
import Address from "../../shared/form/Address";
import AnnualIncome from "../../shared/form/AnnualIncome";

export default function SelfEmployedIncomeForm({
  fields,
  handleChange,
  handleEmployerAddressChange,
}) {
  const {
    dateIncomeStarted,
    paymentFrequency = 1,
    annualIncome,
    unemploymentInstName = "",
    employerAddr = "",
    employerCity = "",
    employerState = "",
    employerZip = "",
  } = fields;
  return (
    <form className=" mt-4 flex flex-col sm:grid grid-cols-2 gap-4">
      <DatePicker
        value={dateIncomeStarted}
        required
        label="Date Income Started"
        handleChange={(date) =>
          handleChange({ target: { name: "dateIncomeStarted", value: date } })
        }
      />

      <Select
        value={paymentFrequency}
        onChange={handleChange}
        name="paymentFrequency"
        label="Payment  Frequency"
        options={[
          { label: "Weekly", value: 1 },
          { label: "Bi-Weekly", value: 2 },
          { label: "Monthly Liability", value: 3 },
          { label: "Quarterly", value: 4 },
          { label: "Annually", value: 5 },
        ]}
      />
      <AnnualIncome value={annualIncome} handleChange={handleChange} />

      <TextField
        onChange={handleChange}
        required
        value={unemploymentInstName}
        name="unemploymentInstName"
        label="Institution Name"
      />

      <Address
        onAddressChange={handleEmployerAddressChange}
        address={employerAddr}
        city={employerCity}
        state={employerState}
        zip={employerZip}
        defaultValue={`${employerAddr}, ${employerCity}, ${employerState}, ${employerZip} `}
      />
    </form>
  );
}
