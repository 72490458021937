import React, { useReducer } from "react";
import { Auth } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from "../../shared/form/FormGroup";
import PasswordPolicy from "../PasswordPolicy";
import PopperMsg from "../../shared/misc/PopperMsg";
import {
  containsLowerCase,
  containsUpperCase,
  containsNumber,
  lengthGreaterThanOrEqual,
  hasSpecialChars,
} from "../../../utils/jsUtils";

type ReducerState = {
  verificationCode: string;
  newPassword: string;
  processing: boolean;
  resetSuccessful: boolean;
};

type ReducerAction = {
  type: string;
  payload: string | number | boolean;
};

type ConfirmResetPwProps = {
  userName: string;
  setStatus: Function;
};

function reducer(state: ReducerState, action: ReducerAction) {
  return { ...state, [action.type]: action.payload };
}

export default function ConfirmResetPw({
  userName,
  setStatus,
}: ConfirmResetPwProps) {
  const [state, dispatch] = useReducer(reducer, {
    verificationCode: "",
    newPassword: "",
    processing: false,
    resetSuccessful: false,
  });

  const { verificationCode, newPassword, processing, resetSuccessful } = state;
  const passwordAtLeast8Chars = lengthGreaterThanOrEqual(8);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    dispatch({
      type: name,
      payload: value,
    });
  };

  const passwordPolicySatisfied =
    passwordAtLeast8Chars(newPassword) &&
    containsNumber(newPassword) &&
    containsUpperCase(newPassword) &&
    containsLowerCase(newPassword) &&
    hasSpecialChars(newPassword);

  const submitDisabled =
    !passwordPolicySatisfied ||
    !verificationCode ||
    !newPassword ||
    processing ||
    resetSuccessful;

  return (
    <form className="w-full sm:w-96 shadow-md p-8" onSubmit={handleSubmit}>
      <p className="font-bold text-left pt-4">Reset your Password</p>
      <div className="mt-4 space-y-2 flex flex-col">
        <FormGroup
          label="Verification Code"
          required
          type="text"
          handleChange={handleChange}
          value={verificationCode}
          id="verificationCode"
          name="verificationCode"
        />
        <FormGroup
          label="New Password"
          required
          type="password"
          handleChange={handleChange}
          value={newPassword}
          id="newPassword"
          name="newPassword"
        />
      </div>

      <PasswordPolicy
        meetsLengthReq={passwordAtLeast8Chars(newPassword)}
        meetsLowerCaseReq={containsLowerCase(newPassword)}
        meetsNumberReq={containsNumber(newPassword)}
        meetsSpecialCharsReq={hasSpecialChars(newPassword)}
        meetsUpperCaseReq={containsUpperCase(newPassword)}
      />

      <div className="mt-6 flex flex-col sm:flex-row justify-between items-baseline text-xs">
        <div className="flex space-x-2 mt-2">
          <a
            href="#"
            id="sign-up-link"
            onClick={() => setStatus("sign-in")}
            className="text-hk-orange-400 hover:underline"
          >
            Back to Sign In
          </a>
        </div>
        <button
          disabled={submitDisabled}
          className="w-full mt-4 tracking-wide sm:w-32 order-first sm:order-last  h-10 uppercase bg-hk-orange-400 text-white font-semibold disabled:opacity-50 disabled:cursor-default"
          type="submit"
        >
          {processing ? <CircularProgress /> : <span>Reset Password</span>}
        </button>
      </div>
      <PopperMsg
        open={resetSuccessful}
        msgType="success"
        handleClose={() => {
          dispatch({
            type: "resetSuccessful",
            payload: false,
          });
          setStatus("sign-in");
        }}
      >
        <span>Your password has been reset</span>
      </PopperMsg>
    </form>
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    dispatch({
      type: "processing",
      payload: true,
    });

    Auth.forgotPasswordSubmit(userName, verificationCode, newPassword)
      .then(() => {
        //setStatus("sign-in");
        dispatch({
          type: "resetSuccessful",
          payload: true,
        });
        dispatch({
          type: "processing",
          payload: false,
        });
      })
      .catch((err) => {
        console.log("password reset failed", err);
        dispatch({
          type: "processing",
          payload: false,
        });
      });
  }
}
