import React, { useContext } from "react";
import UserContext from "./UserContext";
import { propIn } from "../../utils/jsUtils";
import SkeletonWrapper from "../shared/SkeletonWrapper";
import { Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles({
  button: {
    edit: {
      fontSize: "12px",
      textTransform: "none",
    },
  },
});

function Item({ skeletenCond, label, value }) {
  return (
    <div className="flex p-4  justify-start">
      <span className="w-1/3 text-left text-sm font-semibold text-gray-800">
        {label}
      </span>
      <SkeletonWrapper w={50} h={18} condition={skeletenCond}>
        <span className="w-2/3 text-gray-700 text-sm">{value}</span>
      </SkeletonWrapper>
      <hr />
    </div>
  );
}

export default function UserProfile() {
  const { user } = useContext(UserContext) || {};
  const { org = {} } = user || {};
  const classes = useStyles();

  return (
    <section className="min-h-screen mt-4">
      <section className="mt-4 w-full   p-4 flex flex-col h-64">
        <div className="flex border-b-2 border-hk-blue bg-gray-100">
          <h5 className=" text-left p-2 mr-auto"> Profile</h5>
          <Button
            size="small"
            className={classes.button.edit}
            endIcon={<EditIcon />}
            disabled
            color="default"
          >
            Edit
          </Button>
        </div>

        <section
          className="  w-full flex flex-col sm:w-1/4 border-gray-200 "
          id="user-profile-top "
        >
          <Item
            skeletenCond={!propIn("firstName", user)}
            label="Name"
            value={`${user.firstName} ${user.lastName}`}
          />

          <Item
            skeletenCond={!propIn("email", user)}
            label="Email"
            value={user.email}
          />

          <Item
            skeletenCond={!propIn("profileDisplay", user)}
            label="Role"
            value={user.profileDisplay}
          />
        </section>

        {user.entityId != null && user.entityId != 0 && (
          <section className="org flex flex-col mt-8">
            <div className="flex border-b-2 border-hk-blue bg-gray-100">
              <h5 className=" text-left p-2 mr-auto"> Organization</h5>
              <Button
                size="small"
                endIcon={<EditIcon />}
                disabled
                className={classes.button.edit}
                color="default"
              >
                Edit
              </Button>
            </div>
            <section
              className=" w-full flex flex-col sm:w-1/4 border-gray-200 "
              id="user-profile-top "
            >
              <Item
                skeletenCond={!propIn("EntityName", org)}
                label="Name"
                value={org.EntityName}
              />

              <Item
                skeletenCond={!propIn("Address1", org)}
                label="Address"
                value={org.Address1}
              />

              <Item
                skeletenCond={!propIn("City", org)}
                label="City"
                value={org.City}
              />

              <Item
                skeletenCond={!propIn("State", org)}
                label="State"
                value={org.State}
              />

              <Item
                skeletenCond={!propIn("Zip", org)}
                label="Zip"
                value={org.Zip}
              />

              <Item
                skeletenCond={!propIn("Phone", org)}
                label="Phone"
                value={org.Phone}
              />
            </section>
          </section>
        )}
      </section>
    </section>
  );
}
