import React from "react";
import TextField from "../../shared/form/TextField";
import Select from "../../shared/Select";
import DatePicker from "../../shared/form/DatePicker";
import Address from "../../shared/form/Address";
import AnnualIncome from "../../shared/form/AnnualIncome";

export default function SelfEmployedIncomeForm({
  fields,
  handleChange,
  handleEmployerAddressChange,
}) {
  const {
    employerName,
    title,
    jobDesc,
    companyType,
    yearFormed,
    annualIncome,
    lineOfWorkYears,
    employerAddr,
    employerCity,
    employerState,
    employerZip,
  } = fields;
  return (
    <form className=" mt-4 flex flex-col sm:grid grid-cols-2 sm:space-x-4 space-y-4">
      <TextField
        name="employerName"
        required
        onChange={handleChange}
        label="Employer Name"
        value={employerName}
      />

      <TextField
        required
        name="title"
        label="Position Title"
        value={title}
        onChange={handleChange}
      />

      <TextField
        name="jobDesc"
        label="Job Description"
        value={jobDesc}
        onChange={handleChange}
      />
      <Select
        value={companyType}
        onChange={handleChange}
        name="companyType"
        label="Company Type"
        options={[
          { label: "Sole Proprietership", value: 1 },
          { label: "Partnership", value: 2 },
          { label: "Limited Liability", value: 3 },
          { label: "S-Corp", value: 4 },
          { label: "C-Corp", value: 5 },
        ]}
      />
      <DatePicker
        required
        label="Year Formed"
        handleChange={(date) =>
          handleChange({ target: { name: "yearFormed", value: date } })
        }
        value={yearFormed}
      />

      <TextField
        value={lineOfWorkYears}
        type="number"
        required
        onChange={handleChange}
        name="lineOfWorkYears"
        label="Number of years on job?"
      />
      <AnnualIncome value={annualIncome} handleChange={handleChange} />

      <Address
        onAddressChange={handleEmployerAddressChange}
        address={employerAddr}
        city={employerCity}
        state={employerState}
        zip={employerZip}
        defaultValue={`${employerAddr}, ${employerCity}, ${employerState}, ${employerZip} `}
      />
    </form>
  );
}
