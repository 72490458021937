import React, { useEffect, useReducer, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { getIncomeTypeTextFromInt } from "../../../utils/hhldUtils";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import IncomeForm from "../income/IncomeForm";

function reducer(state, action) {
  switch (action.type) {
    case "annualIncome":
      return {
        ...state,
        income: { ...state.income, annualIncome: action.payload },
      };
    case "anticipatedIncomeEndDate":
      return {
        ...state,
        income: { ...state.income, anticipatedIncomeEndDate: action.payload },
      };
    case "caseDate":
      return {
        ...state,
        income: { ...state.income, caseDate: action.payload },
      };
    case "caseNum":
      return {
        ...state,
        income: { ...state.income, caseNum: action.payload },
      };
    case "childName":
      return {
        ...state,
        income: { ...state.income, childName: action.payload },
      };
    case "chilDOB":
      return {
        ...state,
        income: { ...state.income, chilDOB: action.payload },
      };
    case "companyType": {
      return { ...state, companyType: action.payload };
    }

    case "companyDesc":
      return {
        ...state,
        income: { ...state.income, companyDesc: action.payload },
      };

    case "dateIncomeStarted":
      return {
        ...state,
        income: { ...state.income, dateIncomeStarted: action.payload },
      };

    case "employedLessThan12":
      return {
        ...state,
        income: { ...state.income, employedLessThan12: action.payload },
      };

    case "loadingMember":
      return { ...state, loadingMember: action.payload };
    case "income":
      return { ...state, income: action.payload };
    case "employerName":
      return {
        ...state,
        income: { ...state.income, employerName: action.payload },
      };
    case "title":
      return {
        ...state,
        income: { ...state.income, title: action.payload },
      };

    case "payType":
      return { ...state, income: { ...state.income, payType: action.payload } };
    case "paymentFrequency":
      return {
        ...state,
        income: { ...state.income, paymentFrequency: action.payload },
      };
    case "payerName":
      return {
        ...state,
        income: { ...state.income, payerName: action.payload },
      };

    case "jobDesc":
      return {
        ...state,
        income: { ...state.income, jobDesc: action.payload },
      };
    case "jobYears":
      return {
        ...state,
        income: { ...state.income, jobYears: action.payload },
      };
    case "lineOfWorkYears":
      return {
        ...state,
        income: { ...state.income, lineOfWorkYears: action.payload },
      };
    case "industry":
      return {
        ...state,
        income: { ...state.income, industry: action.payload },
      };
    case "employerUrl":
      return {
        ...state,
        income: { ...state.income, employerUrl: action.payload },
      };
    case "employerAddr":
      return {
        ...state,
        income: { ...state.income, employerAddr: action.payload },
      };
    case "employerCity":
      return {
        ...state,
        income: { ...state.income, employerCity: action.payload },
      };
    case "employerState":
      return {
        ...state,
        income: { ...state.income, employerState: action.payload },
      };
    case "employerZip":
      return {
        ...state,
        income: { ...state.income, employerZip: action.payload },
      };
    case "employerPhone":
      return {
        ...state,
        income: { ...state.income, employerPhone: action.payload },
      };
    case "yearFormed":
      return {
        ...state,
        income: { ...state.income, yearFormed: action.payload },
      };
    case "unemploymentInstName":
      return {
        ...state,
        income: { ...state.income, unemploymentInstName: action.payload },
      };

    default:
      return state;
  }
}

export default function EditIncome({
  open,
  income,
  userId,
  handleSubmit,
  onClose,
  processing,
}) {
  const { memberId, incomeType, incomeId } = income;

  const [fields, dispatch] = useReducer(reducer, {
    income: {},
    loadingMember: false,
  });

  useEffect(() => {
    fetchIncome();
  }, []);

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    dispatch({
      type: name,
      payload: value ? value : type !== "text" ? checked : "",
    });
  }

  return (
    <form>
      <IncomeForm
        dispatch={dispatch}
        handleChange={handleChange}
        fields={{
          ...fields.income,
          incomeType: getIncomeTypeTextFromInt(incomeType),
        }}
      />

      <div className="flex space-x-2 py-4 justify-end">
        <Button variant="contained" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={processing}
          onClick={() =>
            handleSubmit({
              ...fields.income,
              incomeType: getIncomeTypeTextFromInt(incomeType),
              employeeId: fields.income.employeeId,
              employerInfoId: fields.income.employerInfoId,
              employerId: fields.income.employerId,
              incomeId,
            })
          }
          style={{ color: "#fff", backgroundColor: "#eb7e3c" }}
        >
          {!processing && "Edit"}
          {processing && <CircularProgress size="16" />}
        </Button>
      </div>
    </form>
  );

  async function fetchIncome() {
    dispatch({
      type: "loadingIncome",
      payload: true,
    });
    const {
      data: { data: incomes },
    } = await axios.get(
      `${getBaseUrl()}/user/${userId}/incomes/${incomeId}?memberId=${memberId}&incomeType=${getIncomeTypeTextFromInt(
        incomeType
      )}`
    );

    dispatch({
      type: "loadingIncome",
      payload: false,
    });
    dispatch({
      type: "income",
      payload: incomes?.map((i) => ({
        anticipatedIncomeEndDate: i.AnticipatedEndingDate,
        caseDate: i.CaseDate,
        caseNum: i.CaseNumber,
        childDOB: i.DateOfBirthOfChild,
        companyDesc: i.Description || "",
        dateIncomeStarted: i.IncomeStartDate || i.BenefitDate,
        childName: i.NameOfChild,
        payerName: i.NameOfPayer,
        paymentFrequency: i.PaymentFrequency || i.PayFrequency,
        address: i.Address,
        annualIncome: i.Income,
        city: i.City,
        employerName: i.EmployerName || i.CompanyName,
        employerAddr: i.Address,
        employerCity: i.City,
        employerState: i.State,
        employerZip: i.Zip,
        employerPhone: i.Phone,
        industry: i.IndustryType || "",
        jobDesc: i.JobDescription,
        jobYears: i.NumOfYearsOnJob,
        lineOfWorkYears: i.NumOfYearsWorked,
        payType: i.PayType || "",
        yearFormed: i.LegalFormation,
        state: i.State,
        title: i.PositionTitle,
        zip: i.Zip,
        employeeId: i.EmployeeID,
        employerInfoId: i.EmployerInfoID,
        employerId: i.EmployerID,
        employerUrl: i.Website,
        unemploymentInstName: i.BenefitInstitution,
        employedLessThan12: i.LessThan12Months == 1 ? "yes" : "no",
      }))[0],
    });
  }
}
