import { User } from "../components/user/UserContext";

export function getSub(attributes = []) {
  return attributes.find((a) => a.Name === "sub")?.Value;
}

export function isInternalAdmin(profileType: string = ""): boolean {
  return profileType?.substring?.(0, 1) === "A" ?? false;
}

export function isExternalAdmin(profileType: string = ""): boolean {
  return profileType?.substring?.(0, 1) === "D" ?? false;
}

export function isHHldApplicant(profileType: string = ""): boolean {
  return profileType?.substring?.(0, 1) === "Z" ?? false;
}

export function isHhldAdmin(user: User): boolean {
  return user["role_admin"] == 1;
}
export function isLoanOfficer(profileType: string = ""): boolean {
  return profileType?.substring?.(0, 1) === "H" ?? false;
}
export function hasFileUploadPerms(user: User): boolean {
  return user["filePermissions"] != null;
}

export function hasFileCabinetAccess(user: User): boolean {
  return (
    (isInternalAdmin(user.profileType) || isHHldApplicant(user.profileType)) &&
    hasFileUploadPerms(user)
  );
}

export function hasHKEmail(userName: string): boolean {
  return /@housekeys.org\s*$/.test(userName);
}

export function allowUserEmulation(user: User): boolean {
  return (
    (user.profileType === "A01" || user.profileType === "A02") &&
    hasHKEmail(user.email)
  );
}
