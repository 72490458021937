import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HHLDRegSummary from "../../../../hhld/dashboard/HHLDRegSummary";

import axios from "../../../../../utils/axios";
import { getBaseUrl } from "../../../../../utils/queryHelpers";
import UserContext from "../../../../user/UserContext";
import {
  formatDateToTimeZone,
  removeHTMLTagsFromString,
} from "../../../../../utils/formatters";
import { getAnswer } from "../../../../hhld/enter_drawing/DrawingSubmitted";

const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: "0.75rem",
  },
}))((props) => <Tab {...props} />);

export default function ProgramPreAppSubmitted() {
  const [value, setValue] = useState(0);
  const [files, setFiles] = useState([]);
  const [submission, setSubmission] = useState({});

  const { elig = [], gen = [], detail = {} } = submission;

  const { programId, preAppId } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchSubmission();
    getPreAppFiles();
  }, []);

  const { submissionDate, entityName, programName, applicationFileId } = detail;
  return (
    <section className="min-h-screen relative flex  flex-col ">
      <h3 className=" tracking-wide text-2xl uppercase bg-hk-blue-100 h-32 flex items-center justify-center  p-4 text-hk-blue-400 w-full">
        Program Application
      </h3>
      <div className="-mt-6 flex justify-center ">
        <div className="  text-gray-700 bg-white shadow-md p-4 rounded self-center flex flex-col items-center w-80 h-40 text-sm">
          <div className="flex space-x-2 pt-2 ">
            <span className="text-xl">Submitted on:</span>
            <span className="text-xl">
              {formatDateToTimeZone(submissionDate)}
            </span>
          </div>
          <div className="flex space-x-2 mt-2 ">
            <span>Application ID: </span>
            <span className="">{applicationFileId} </span>
          </div>
          <span className="pt-4">{programName}</span>
          <span className="text-gray-500 pt-2">{entityName}</span>
        </div>
      </div>
      <div className="mt-6 sm:absolute sm:left-20 bottom-0 sm:top-12 max-h-80 overflow-y-scroll">
        <HHLDRegSummary />
      </div>

      <div className="max-w-full  mt-6 sm:mt-72 sm:p-16">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Program Center tabs"
          variant="scrollable"
          scrollButtons="on"
        >
          <StyledTab label="Eligiblity / Qualification" />
          <StyledTab label="General" />
          <StyledTab label="Files" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="p-4">
            {elig.map((q) => (
              <Answer question={q} key={q.QuestionID} />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="p-4">
            {gen.map((q, index) => (
              <Answer question={q} key={q.QuestionID || index} />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="p-4 flex flex-col items-start">
            <div className="py-2 pb-4">
              {files.map((q) => (
                <div key={q.id}>
                  {q?.items?.map((f) => (
                    <ol key={f.id}>
                      {f.files.map((file) => (
                        <li className="text-sm text-gray-700" key={file?.id}>
                          <span>{file?.fileName}</span>
                        </li>
                      ))}
                    </ol>
                  ))}
                </div>
              ))}
            </div>
            <Button
              onClick={(e) => history.push("/file-cabinet/uploads#1")}
              startIcon={<AddCircleIcon />}
              color="primary"
              size="small"
              variant="contained"
            >
              Add Files
            </Button>
          </div>
        </TabPanel>
      </div>
    </section>
  );

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  async function fetchSubmission() {
    const {
      data: { data },
    } = await axios.get(
      `${getBaseUrl()}/programs/${programId}/preapp/submission?preAppId=${preAppId}`
    );

    setSubmission(data);
  }

  async function getPreAppFiles() {
    const {
      data: { data: files },
    } = await axios.get(
      `${getBaseUrl()}/programs/${programId}/preapp/${preAppId}/supportdocs`
    );

    setFiles(files);
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function Answer({ question }) {
  const { Question, PassFailFlag, AnswerType } = question;

  let ans = getAnswer(question);
  if (!ans) return null;
  if (AnswerType == 6) {
    ans = formatDateToTimeZone(ans);
  }
  return (
    <div className="p-4">
      <div className="hidden sm:grid grid-cols-5 text-sm text-gray-800 p-2 gap-2 border-l-2 border-gray-300">
        <span
          dangerouslySetInnerHTML={{ __html: Question }}
          className="text-left col-span-2"
        />
        <span className="text-right text-gray-600 col-span-2 font-semibold">
          {removeHTMLTagsFromString(ans)}
        </span>
        <span className="col-span-1 text-left">
          {PassFailFlag === "P" || PassFailFlag === "N/A" ? (
            <CheckCircleIcon
              style={{ fontSize: 15 }}
              className="text-green-500"
            />
          ) : (
            <HighlightOffIcon size="small" className="text-red-500" />
          )}
        </span>
      </div>

      <div className="flex flex-col space-y-2 sm:hidden  px-2 py-2">
        <div className="text-left col-span-2 text-gray-700 px-2 text-sm ">
          <span dangerouslySetInnerHTML={{ __html: Question }} />
        </div>
        <div className="text-left text-gray-600 col-span-2 text-xs mt-1 flex px-2">
          <div className="-ml-6">
            {PassFailFlag === "P" || PassFailFlag === "N/A" ? (
              <CheckCircleIcon
                style={{ fontSize: 15 }}
                className="text-green-500"
              />
            ) : (
              <HighlightOffIcon size="small" className="text-red-500" />
            )}
          </div>
          <span className="ml-2 font-semibold">
            {removeHTMLTagsFromString(ans)}
          </span>
        </div>
        <hr />
      </div>
    </div>
  );
}
