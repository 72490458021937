import React from "react";
import { withRouter } from "react-router-dom";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import UserContext from "../../user/UserContext";

class ErrorBoundary extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const user = this.context;

    const { pathname } = this.props.location;
    axios.post(`${getBaseUrl()}/track/publish/clientErr`, {
      message: `${error} ${errorInfo}`,
      path: pathname,
      userId: user.userId,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <section className="min-h-screen">
          <h1>Something went wrong.</h1>
        </section>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
