import React, { useContext, useState, useEffect } from "react";
import { useHistory, useParams, Link, useRouteMatch } from "react-router-dom";
import Select from "../../../shared/Select";
import PrimaryButton from "../../../shared/form/PrimaryButton";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@material-ui/core";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import AddIcon from "@material-ui/icons/Add";
import { FaRegFolder, FaCalendarAlt } from "react-icons/fa";
import { Link as MatLink, Breadcrumbs } from "@material-ui/core";
import { MdContacts, MdHome } from "react-icons/md";
import axios from "../../../../utils/axios";
import { isInternalAdmin } from "../../../../utils/authUtils";

import {
  formatAsUSCurrency,
  formatNonUTC,
  formatDateToTimeZone,
} from "../../../../utils/formatters";
import MaterialUITable from "../../../shared/Table/MaterialUITable";
import PopperMsg from "../../../shared/misc/PopperMsg";
import CardItem from "../../../shared/card/CardItem";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import { sortTiers } from "../../../../utils/drawingUtils";
import useLocalStorage from "../../../shared/hooks/useLocalStorage";
import Preferences from "../ranking/Preferences";
import UserContext from "../../../user/UserContext";
import { useDrawingDetails } from "../../../../queries/programs/useDrawingDetails";

function DrawingApplicants({ programId }) {
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processingRankingCreation, setProcessingRankingCreation] = useState(
    false
  );
  const [newRanking, setNewRanking] = useState({});

  const { drawingId } = useParams();
  const [selectedDrawing] = useLocalStorage("selectedDrawing");
  const [isRankingCreationAllowed, setIsRankingCreationAllowed] = useState(
    false
  );
  const [showRankingCreateMsg, toggleRankingCreateMsg] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [fileCountMenuAnchor, setFileCountMenuAnchor] = useState(null);
  const [filters, setFilters] = useState({
    drawingStatus: "",
    drawingSubStatus: "",
    hhldSize: "",
    incomeRangeStart: "",
    incomeRangeEnd: "",
    incomeLevel: "",
    excludeUsersWithoutFileUploads: false,
    rankedFlag: 0,
  });
  const [showBulkDownloadMessage, setshowBulkDownloadMessage] = useState(false);
  const { url } = useRouteMatch();
  const history = useHistory();
  const { user } = useContext(UserContext) || {};
  const [drawingDetail] = useDrawingDetails(drawingId);

  useEffect(() => {
    fetchApplicants();

    fetchDrawingStatus();
  }, []);

  const {
    incomeLevel,
    incomeRangeEnd,
    incomeRangeStart,
    drawingStatus,
    drawingSubStatus,
    hhldSize,
    excludeUsersWithoutFileUploads,
    rankedFlag,
  } = filters;

  const tableActions = isRankingCreationAllowed
    ? [
        {
          tooltip: "Add applicants to ranking",
          icon: !processingRankingCreation ? AddIcon : HourglassEmptyIcon,
          disabled: processingRankingCreation || !isRankingCreationAllowed,
          onClick: (evt, data) => addApplicantsToRanking(data),
        },
      ]
    : [];

  const inactiveCount = applicants.filter((a) => a.OpenStatusFlag !== "Active")
    .length;

  return (
    <div className="min-h-screen mt-4">
      <Breadcrumbs className="p-2" aria-label="breadcrumb">
        <MatLink color="inherit" href="/drawings">
          Drawings
        </MatLink>
        <MatLink color="inherit" href={`/drawings/${drawingId}`}>
          {drawingId}
        </MatLink>
        <MatLink color="inherit" href="#">
          Applicants
        </MatLink>
      </Breadcrumbs>
      <section className="w-full flex flex-col sm:flex-row">
        <h6 className="hidden">Drawing Summary</h6>
        <div className="p-4  w-full sm:w-1/3 flex flex-col rounded items-start   ">
          <CardItem label="Drawing ID" value={drawingId} Icon={FaRegFolder} />

          <CardItem
            label="Provider"
            value={selectedDrawing.provider}
            Icon={MdContacts}
          />

          <CardItem
            label="Program"
            value={selectedDrawing.program}
            Icon={MdHome}
          />

          <CardItem
            label="Drawing Deadline"
            value={selectedDrawing.deadline}
            Icon={FaCalendarAlt}
          />

          <CardItem
            label="Drawing Type"
            value={selectedDrawing.drawingType}
            Icon={MdHome}
          />
        </div>

        <Preferences
          drawingId={drawingId}
          programId={programId || selectedDrawing.programId}
        />
      </section>

      <Menu
        anchorEl={fileCountMenuAnchor}
        open={Boolean(fileCountMenuAnchor)}
        onClose={handleFileCountMenuClose}
      >
        <MenuItem onClick={(e) => history.push(`/user/${selectedRow.UserID}`)}>
          View Files
        </MenuItem>
        <MenuItem onClick={handleFileDownload}>Download Files</MenuItem>
      </Menu>
      <PopperMsg
        open={showRankingCreateMsg}
        handleClose={handleRankingMsgClose}
      >
        <p>
          <span>{`New Ranking (${newRanking.ranking_id}) created. Click  `}</span>
          <Link
            className="text-green-900 text-underline font-bold italic new-ranking-notification"
            to={
              url.includes("applicants")
                ? url.replace(
                    "/applicants",
                    `/rankings/${newRanking.ranking_id}`
                  )
                : url.includes("rankings")
                ? `${url}/${newRanking.ranking_id}`
                : `${url}/rankings/${newRanking.ranking_id}`
            }
          >
            Here
          </Link>{" "}
          to view the ranking.
        </p>
      </PopperMsg>

      <form className="flex flex-col flex-wrap  sm:flex-row space-x-2 space-y-2 items-baseline px-2">
        <Select
          label="Household Size"
          options={[
            { label: "All", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10+", value: 10 },
          ]}
          name="hhldSize"
          onChange={handleChange}
          value={hhldSize}
        />

        <Select
          label="Income Level"
          options={[
            { label: "All", value: 0 },
            { label: "Extremely Low", value: "Extremely Low Income" },
            { label: "Very Low", value: "Very Low Income" },
            { label: "Low", value: "Low Income" },
            { label: "Median ", value: "Median Income" },
            { label: "Moderate", value: "Moderate Income" },
            { label: "Above Moderate", value: "Above Moderate Income" },
          ]}
          name="incomeLevel"
          onChange={handleChange}
          value={incomeLevel}
        />

        <Select
          label="Drawing Status"
          name="drawingStatus"
          options={[
            { label: "All", value: 0 },
            { label: "Open", value: "O" },
            { label: "Closed", value: "C" },
          ]}
          onChange={handleChange}
          value={drawingStatus}
        />

        <Select
          label="Drawing SubStatus"
          name="drawingSubStatus"
          options={[
            { label: "All", value: 0 },
            { label: "Opt Out", value: "CO" },
            { label: "Released", value: "CC" },
            { label: "Removed", value: "RM" },
            { label: "By Admin", value: "OA" },
            { label: "Entered Drawing", value: "OD" },
            { label: "Pending By Admin", value: "PO" },
            { label: "Ranked - Manual", value: "RRM" },
            { label: "Ranked - Randomizer", value: "RR" },
            { label: "Ranked - Score", value: "RT" },
            { label: "Ranked - Timestamp", value: "RT" },
          ]}
          onChange={handleChange}
          value={drawingSubStatus}
        />

        <Select
          label="Ranked"
          name="rankedFlag"
          options={[
            { label: "All", value: 0 },
            { label: "Ranked", value: "1" },
            { label: "Not Ranked", value: "99999" },
          ]}
          onChange={handleChange}
          value={rankedFlag}
        />
        <FormControl>
          <InputLabel htmlFor="standard-adornment-amount">
            Income Range Start
          </InputLabel>
          <Input
            value={incomeRangeStart}
            name="incomeRangeStart"
            onChange={handleChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="standard-adornment-amount">
            Income Range End
          </InputLabel>
          <Input
            value={incomeRangeEnd}
            name="incomeRangeEnd"
            onChange={handleChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={excludeUsersWithoutFileUploads}
                onChange={(e) =>
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    excludeUsersWithoutFileUploads: !prevFilters.excludeUsersWithoutFileUploads,
                  }))
                }
              />
            }
            label="Exclude users without file uploads"
          />
        </FormGroup>

        <PrimaryButton onClick={fetchApplicants}>Search</PrimaryButton>
        <Button size="small" variant="contained" onClick={resetFilters}>
          Clear
        </Button>
      </form>

      <div className="mt-2">
        <ApplicantsList
          applicants={applicants}
          loading={loading}
          drawingDetail={drawingDetail}
          handleFileCountClick={handleFileCountClick}
          drawingId={drawingId}
          user={user}
        />

        <Dialog
          open={showBulkDownloadMessage}
          onClose={handleDownloadModalClose}
        >
          <DialogContent>
            <div className="flex p-4">
              <AnnouncementIcon style={{ color: "#4fd1d9" }} />
              <p className="ml-2  text-gray-800">
                Your download request has been received. <br />
                An email will be sent to you when your files are ready for
                download. <br />
                To check the status of your download, please visit the
                <Link
                  className="underline text-blue-600"
                  to="/file-cabinet/downloads"
                >
                  {" "}
                  Download page.
                </Link>
              </p>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );

  function handleFileCountClick(event, rowData) {
    setFileCountMenuAnchor(event.currentTarget);
    setSelectedRow(rowData);
  }
  function handleFileCountMenuClose() {
    setFileCountMenuAnchor(null);
  }

  async function handleFileDownload() {
    setLoading(true);
    setFileCountMenuAnchor(null);
    //fetch memberId
    const {
      data: { data: user },
    } = await axios.get(`${getBaseUrl()}/user/${selectedRow.userId}`);

    //fetch files
    const {
      data: { data: files },
    } = await axios.get(
      `${getBaseUrl()}/user/${selectedRow.userId}/files?memberId=${
        user.memberId
      }&limit=500&view=tree`
    );
    //file archive request
    await axios.post(
      `${getBaseUrl()}/user/${selectedRow.userId}/files/archive`,
      {
        memberId: user.memberId,
        fileIds: files
          .filter((f) => Boolean(f.ID) && !f.Filepath.startsWith("/downloads"))
          .map((f) => f.ID),
      }
    );

    setLoading(false);
    setshowBulkDownloadMessage(true);
  }

  function handleDownloadModalClose() {
    setshowBulkDownloadMessage(false);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  }

  function appendParams() {
    return Object.entries(filters)
      .reduce((acc, curr) => {
        const [key, value] = curr;
        if (value) acc.push(`&${key}=${value}`);
        return acc;
      }, [])
      .join("");
  }

  async function fetchApplicants() {
    setLoading(true);
    const {
      data: { data: applicants },
    } = await axios.get(
      `${getBaseUrl()}/drawings/${drawingId}/applicants?rowCnt=10000&page=1${appendParams()}`
    );

    setApplicants(
      applicants.map((a) => ({
        ...a,
        name: `${a.firstName} ${a.lastName}`,
        dateLastFileUploaded: formatNonUTC(
          a.dateLastFileUploaded,
          "MM/dd/yyyy h:mm:ss a"
        ),
        totalIncome: formatAsUSCurrency(a.totalIncome),
      }))
    );
    setLoading(false);
  }

  async function fetchDrawingStatus() {
    const {
      data: { data: status },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/status`);

    setIsRankingCreationAllowed(status.ProcessFlag !== "N");
  }

  function handleRankingMsgClose() {
    toggleRankingCreateMsg(false);
  }

  function resetFilters() {
    setFilters({
      hhldSize: 0,
      drawingStatus: 0,
      drawingSubStatus: 0,
      incomeLevel: 0,
      incomeRangeStart: "",
      incomeRangeEnd: "",
      rankedFlag: 0,
      excludeUsersWithoutFileUploads: false,
    });
  }

  function handleSelectionChange(selectedRows) {
    setApplicants(
      applicants.map((row) =>
        selectedRows.find((s) => s.ID === row.ID)
          ? {
              ...row,
              tableData: {
                ...row.tableData,
                checked: row.OpenStatusFlag === "Active",
              },
            }
          : row
      )
    );
  }

  async function addApplicantsToRanking(applicants) {
    setProcessingRankingCreation(true);
    const applicantIds = applicants.map((a) => a.submission_id);
    console.log("applicantIds", applicantIds);
    const {
      data: { data: newRanking },
    } = await axios.post(`${getBaseUrl()}/rankings`, {
      applicants: applicantIds,
      drawingId: drawingId,
    });

    setNewRanking(newRanking);
    await fetchDrawingStatus();
    setProcessingRankingCreation(false);
    toggleRankingCreateMsg(true);
  }
}

export default DrawingApplicants;

export function ApplicantsList({
  loading,
  applicants,
  drawingDetail,
  handleFileCountClick,
  drawingId,
  user,
}) {
  const tableColumns = [
    {
      title: "#",
      field: "ID",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "File ID",
      field: "submission_id",
    },
    {
      title: "HH Size",
      field: "noOfBuyers",
    },
    {
      title: "HH Income",
      field: "totalIncome",
    },
    {
      title: "Preference Tiers",
      field: "preferences",
      customSort: (a, b) => {
        if (!Array.isArray(a.tierLevels) && !Array.isArray(b.tierLevels)) {
          return 0;
        }
        const [{ tier: highestATier }] = a.tierLevels;
        const [{ tier: highestBTier }] = b.tierLevels;

        if (highestATier === highestBTier) return 0;
        if (highestATier < highestBTier) return -1;
        return 1;
      },
      render: (rowData) => (
        <Tooltip
          title={
            !Array.isArray(rowData.preferences)
              ? "No preference applicable"
              : rowData.preferences
                  .map((t) => `${t.description}(${t.value})`)
                  .join(", ")
          }
        >
          <span>
            {!Array.isArray(rowData.preferences)
              ? "No Tier"
              : [
                  ...new Set([
                    ...rowData.preferences
                      .filter((t) => Boolean(t.response))
                      .map((t) => t.value)
                      .sort((a, b) => a - b),
                  ]),
                ]
                  .map((t) => `Tier ${t}`)
                  .join(", ")}
          </span>
        </Tooltip>
      ),
    },
    ...(drawingDetail.preference_type === "points"
      ? [
          {
            title: "Points",
            field: "noOfPoints",
            render: (rowData) => {
              return !Array.isArray(rowData.points) ? (
                rowData.points
              ) : (
                <Tooltip
                  title={
                    <div className="">
                      {rowData.points.map((p, idx) => {
                        return (
                          <div
                            key={idx}
                            className="flex space-x-4 space-y-4 items-center "
                          >
                            <span className="col-span-2">{p.points}</span>
                            <span className="col-span-4">{p.title}</span>
                          </div>
                        );
                      })}

                      <div className="mt-2 border-t border-white text-lg font-semibold">
                        {rowData.points.reduce(
                          (acc, curr) => acc + curr.points,
                          0
                        )}
                      </div>
                    </div>
                  }
                >
                  <span>{rowData.noOfPoints}</span>
                </Tooltip>
              );
            },
          },
        ]
      : []),
    {
      title: "Final Rank",
      field: "final_rank",
    },
    {
      title: "Status",
      field: "openStatus", //based on drawingStatus , if 0 => Y else N
    },
    {
      title: "Status Description",
      field: "drawingSubStatus",
    },
    {
      title: "Loan Officer Selected",
      field: "loanOfficerSelected",
    },
    {
      title: "File Count",
      field: "fileCount",
      render: (rowData) =>
        rowData.fileCount > 0 && isInternalAdmin(user.profileType) ? (
          <button
            className="text-blue-500 underline"
            onClick={(e) => handleFileCountClick(e, rowData)}
          >
            {rowData.fileCount}
          </button>
        ) : (
          rowData.fileCount
        ),
    },
    {
      title: "Date last file uploaded",
      field: "dateLastFileUploaded",
    },
    {
      title: "Entry Date",
      field: "date_submitted",
      render: (rowData) =>
        formatDateToTimeZone(rowData.date_submitted, "MM/dd/yyyy h:mm:ss a"),
    },
  ];

  const pageSizeOptions = [
    10,
    20,
    50,
    ...(applicants.length > 50 ? [applicants.length] : []),
  ];

  const inactiveCount = applicants.filter((a) => a.OpenStatusFlag !== "Active")
    .length;

  return (
    <MaterialUITable
      columns={tableColumns}
      isLoading={loading}
      className="p-4"
      data={applicants}
      detailPanel={[
        {
          tooltip: "View more",
          icon: ArrowForwardIosIcon,
          render: (rowData) => {
            return (
              <section className="p-1">
                <div className="grid grid-cols-5 gap-1 text-xs rounded-sm bg-hk-green p-2 text-white">
                  <span>City</span>
                  <span>County</span>
                  <span>Email</span>

                  <span>Income Level</span>
                  <span># Income</span>
                </div>
                <div className="grid grid-cols-5 gap-1 text-blue-600 bg-blue-100 p-4 font-md mt-1 text-xs ">
                  <span>{rowData.City}</span>
                  <span>{rowData.County}</span>
                  <span>{rowData.Email}</span>

                  <span>{rowData.IncomeLevel}</span>
                  <span>{rowData.NoOfIncome}</span>
                </div>
              </section>
            );
          },
        },
      ]}
      title={`Applicants (${applicants.length}) in Drawing ${drawingId}`}
      options={{
        exportButton: true,
        pageSize: 10,
        grouping: true,
        actionsColumnIndex: -1,
        //  selection: isRankingCreationAllowed,
        pageSizeOptions: pageSizeOptions,
        selectionProps: (rowData) => ({
          id: "select-all-check",
          //disabled: rowData.OpenStatusFlag !== "Active",
        }),
      }}
      localization={{
        toolbar: {
          nRowsSelected: `{0} row(s) out of ${applicants.length} selected. Inactive (${inactiveCount}) applicants ,opt-out or released, are automatically not selected.`,
        },
      }}
    />
  );
}
