import React, { useReducer } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Select from "../../shared/Select";
import IncomeForm from "./IncomeForm";
import { hasEmptyFields } from "../../../utils/jsUtils";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: "#fff",
  },
  title: {
    display: "flex",
    backgroundColor: "#eb7e3c",
    color: "#fff",
  },

  incomeTypeRadio: {
    backgroundColor: "#edf2f7",
    borderRadius: "10px",
    fontSize: "0.8rem",
    padding: "2px",
  },

  checkBoxLabel: {
    "&& .MuiFormControlLabel-label": {
      fontSize: "0.9rem",
      color: "#718096",
    },
  },

  intermediateBtn: {
    backgroundColor: "#6eb9a4",
    color: "#fff",
  },

  submitBtn: {
    backgroundColor: "#eb7e3c",
    color: "#fff",
  },

  switchBase: { "&$checked": { backgroundColor: "green" } },
}));

const initState = {
  annualIncome: "",
  anticipatedIncomeEndDate: "",
  incomeType: "",
  dateLastEmployed: "",
  dateIncomeStarted: "",
  employerName: "",
  employedLessThan12: "no",
  retirementInstName: "",
  payType: "",
  industry: "",
  caseDate: "",
  childDOB: "",
  yearFormed: "",
};

function formReducer(state, action) {
  if (action.type === "reset") return initState;
  return { ...state, [action.type]: action.payload };
}

export default function AddIncomeModal({
  members,
  handleSubmit,
  open,
  onClose,
  clearFormFields,
}) {
  const [state, dispatch] = useReducer(formReducer, initState);

  function clearFormFields() {
    dispatch({ type: "reset" });
    onClose();
  }

  const classes = useStyles();
  return (
    <section className="w-full">
      <Dialog open={open} onClose={clearFormFields}>
        <DialogTitle className={classes.title}>
          <div className="flex bg-hk-orange text-white  items-center">
            <PersonAddIcon className="-ml-4 -mt-1 mr-2" />
            <span className="font-md text-sm font-semibold tracking-wide">
              Submit
            </span>

            <IconButton
              className={classes.closeIcon}
              aria-label="close"
              onClick={clearFormFields}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Select
            label="Select Member"
            name="memberId"
            onChange={handleChange}
            options={members.map((m) => ({
              label: m.FirstName,
              value: m.MemberID,
            }))}
          ></Select>
          <IncomeForm
            showIncomeTypes
            classes={classes}
            handleChange={handleChange}
            fields={state}
            dispatch={dispatch}
          />
          <div className="flex mt-4 gap-4 justify-end">
            <Button size="small" variant="contained" onClick={clearFormFields}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={isSubmitDisabled()}
              className={classes.submitBtn}
              onClick={addIncome}
            >
              Add Income
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );

  function addIncome() {
    handleSubmit(state);
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    dispatch({
      type: name,
      payload: value ? value : type !== "text" ? checked : "",
    });
  }

  function isSubmitDisabled() {
    switch (state.incomeType) {
      //no income
      case "Z":
        return hasEmptyFields(state, ["memberId", "incomeType"]);
      case "E":
        return hasEmptyFields(state, [
          "memberId",
          "incomeType",
          "annualIncome",
          "industry",

          "employerName",
          "employedLessThan12",
          "lineOfWorkYears",
        ]);

      case "S":
        return hasEmptyFields(state, [
          "memberId",
          "annualIncome",
          "title",
          "employerName",
          "yearFormed",
          "lineOfWorkYears",
        ]);

      case "U":
        return hasEmptyFields(state, [
          "memberId",
          "annualIncome",
          "dateIncomeStarted",
          "unemploymentInstName",
        ]);

      case "SS":
        return hasEmptyFields(state, [
          "memberId",
          "annualIncome",
          "dateIncomeStarted",
          "anticipatedIncomeEndDate",
          "caseNum",
          "caseDate",
          "payerName",
        ]);

      case "CS":
        return hasEmptyFields(state, [
          "memberId",
          "annualIncome",
          "dateIncomeStarted",
          "anticipatedIncomeEndDate",
          "caseNum",
          "caseDate",
          "payerName",
          "childName",
          "childDOB",
        ]);

      case "A":
        return hasEmptyFields(state, ["memberId", "dateLastEmployed"]);

      case "R":
      case "D":
        return hasEmptyFields(state, [
          "memberId",
          "annualIncome",
          "dateIncomeStarted",
          "retirementInstName",
        ]);
      default:
        return true;
    }
  }
}
