import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

export function useFileDropZone(onFileDrop) {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles);
      onFileDrop(acceptedFiles);
    },
  });

  async function onDrop(acceptedFiles) {
    setFiles((prevFiles) => ({
      ...prevFiles,
      ...acceptedFiles,
    }));
  }

  return {
    files,
    getRootProps,
    getInputProps,
  };
}
