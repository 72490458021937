import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
//import Home from "./Home";
import UserHome from "./components/home/Home";
import UserProfile from "./components/user/Profile";
import OpportunityHome from "./opportunity/OpportunityHome";
import DrawingRoutes from "./components/admin/program/drawings/DrawingRoutes.js";
import ProgramRoutes from "./components/admin/program/programs/ProgramRoutes";
import UserRoutes from "./components/user/UserRoutes";
import HhldRoutes from "./components/hhld/HhldRoutes";
/* import Admin from "./components/admin/routes"; */
import Uploads from "./components/uploads/images/ImageUpload";
/*import Login from "./components/login/Login";
import FileUploader from "./components/FileUploader"; */

import UserMgmt from "./components/user/mgmt/UserMgmt";
import OrgUserList from "./components/user/mgmt/OrgUserList";
import Users from "./components/user/mgmt/Users";
import UserDetails from "./components/user/mgmt/UserDetails";
import LotteryResults from "./components/admin/program/LotteryResults";
import LotteryDetail from "./components/admin/program/LotteryResultDetail";
import UserContext from "./components/user/UserContext";

import NoMatch from "./components/shared/misc/NoMatch";
import NotAuthorized from "./components/shared/misc/NotAuthorized";
import {
  isInternalAdmin,
  isExternalAdmin,
  isHHldApplicant,
} from "./utils/authUtils";
import HhldOnboarding from "./components/user/onboarding/HhldOnboarding";
import OnBoarding from "./components/user/onboarding/OnBoarding";
import FileCabinetRoutes from "./components/files/FileCabinetRoutes";
import { Events } from "./components/events/Events";
import { Templates } from "./components/email/Templates";

export default function Routes() {
  const { user } = useContext(UserContext) || {};
  const showAdminPages = isInternalAdmin(user.profileType);

  const profileTypeKnown = user?.profileType && user.profileType != null;

  return (
    <Switch>
      {/*
      <Route path="/admin">
        <Admin />
      </Route>

      <Route path="/files">
        <FileUploader />
      </Route>

      

      <Route path="/upload">
        <Uploads />
      </Route>

      <Route path="/login">
        <Login />
      </Route> */}

      {/* <Route path="/:city">
        <Home />
      </Route> */}

      <Route path="/admin">
        {showAdminPages || isExternalAdmin(user.profileType) ? (
          <ProgramRoutes />
        ) : (
          <NotAuthorized />
        )}
      </Route>
      <Route exact path="/user">
        {showAdminPages ? <Users /> : <NotAuthorized />}
      </Route>

      <Route exact path="/user/:userId">
        {showAdminPages ? <UserDetails /> : <NotAuthorized />}
      </Route>

      <Route exact path="/orgs">
        {showAdminPages ? <UserMgmt /> : <NotAuthorized />}
      </Route>

      <Route exact path="/events">
        {showAdminPages ? <Events /> : <NotAuthorized />}
      </Route>

      <Route exact path="/templates">
        {showAdminPages ? <Templates /> : <NotAuthorized />}
      </Route>

      <Route path="/orgs/:orgId/users">
        {isInternalAdmin ? <OrgUserList /> : <NotAuthorized />}
      </Route>
      <Route path="/hhld">
        <HhldRoutes />
      </Route>

      <Route path="/opportunities">
        <OpportunityHome />
      </Route>

      <Route path="/drawings">
        <DrawingRoutes />
      </Route>

      <Route path="/rankings">
        <DrawingRoutes />
      </Route>

      <Route path="/upload">
        <Uploads />
      </Route>

      <Route path="/file-cabinet">
        {showAdminPages || isHHldApplicant(user?.profileType) ? (
          <FileCabinetRoutes />
        ) : (
          <NotAuthorized />
        )}
      </Route>

      <Route exact path="/profile">
        <UserProfile />
      </Route>

      <Route path="/programs">
        <ProgramRoutes />
      </Route>

      <Route exact path="/onboarding/hhld">
        <HhldOnboarding />
      </Route>

      {/* <Route exact path="/onboarding/hhld">
        {profileTypeKnown ? <Redirect to="/" /> : <HhldOnboarding />}
      </Route> */}

      <Route exact path="/lottery-results/:drawingId">
        {showAdminPages ? <LotteryDetail /> : <NotAuthorized />}
      </Route>

      <Route exact path="/lottery-results">
        {showAdminPages ? <LotteryResults /> : <NotAuthorized />}
      </Route>

      <Route path="/user-mgmt">
        <UserRoutes />
      </Route>

      <Route exact path="/">
        {user?.profileType !== "" ? <UserHome /> : <OnBoarding />}
      </Route>

      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}

export function LotteryResultRoute() {
  return (
    <Route exact path="/lottery-results">
      <LotteryResults />
    </Route>
  );
}
