import React, { useState, useReducer, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextareaAutosize,
  TextField,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Select from "../../shared/Select";
import Address from "../../shared/form/Address";
import axios from "../../../utils/axios";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { usStatesOptions, validateEmail } from "../../../utils/jsUtils";

import usStates from "../../../states.json";

function formReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "orgType":
      return { ...state, orgType: payload };
    case "subType":
      return { ...state, subType: payload };
    case "sector":
      return { ...state, sector: payload };
    case "description":
      return { ...state, description: payload };

    case "email":
      return { ...state, email: payload };
    case "phone":
      return { ...state, phone: payload };
    case "name":
      return { ...state, name: payload };
    case "street":
      return { ...state, street: payload };
    case "city":
      return { ...state, city: payload };
    case "zip":
      return { ...state, zip: payload };
    case "yearsInBus":
      return { ...state, yearsInBus: payload };
    case "orgState":
      return { ...state, orgState: payload };

    case "category": {
      return {
        ...state,
        category: payload,
        selectedSector: state.sectors.find((s) => s.NAICSCode === payload)
          ?.NAICSCode,
      };
    }

    case "orgRole":
      return { ...state, orgRole: payload };
    default:
      return { ...state, [type]: payload };
  }
}

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formField: {
    width: "200px",
  },
}));

export default function AddOrg({ updateUserIncId, open, onClose, orgId }) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(formReducer, {
    orgType: "",
    subType: "",
    sector: "",
    name: "",
    city: "",
    address: "",
    zip: "",
    state: "",
    email: "",
    phone: "",
    description: "",
    yearsInBus: 0,
    category: "",
    categories: [],
    selectedSector: "",
    sectors: [],
  });

  let {
    orgType,
    subType,
    sector,
    name,
    address,
    city,
    street,
    zip,
    orgState,
    email,
    phone,
    description,
    yearsInBus,
    orgRole,
    categories,
    category,
    selectedSector,
    sectors,
  } = state;
  const [errors, setErrors] = useState({});
  const [roles, setRoleOptions] = useState([]);
  const [orgTypes, setOrgTypes] = useState([]);
  const [orgSubTypes, setOrgSubTypes] = useState([]);

  const [userCreated, setUserCreated] = useState(false);

  const submitDisabled =
    !orgType || !subType || !name || Object.keys(errors).length > 0;

  const statesOptions = usStatesOptions(usStates);
  useEffect(() => {
    fetchEntityRoleOptions();
    fetchOrgSectors();
  }, []);

  useEffect(() => {
    if (!open) return;
    fetchOrgTypes();
  }, [open]);

  useEffect(() => {
    if (!orgType) {
      return;
    }
    fetchOrgSubTypes();
  }, [orgType]);

  useEffect(() => {
    if (!subType) {
      return;
    }
    fetchOrgCategories();
  }, [subType]);

  const orgTypeOptions = orgTypes.map(
    ({ TechEntityName, TechEntityTypeID: value }) => {
      return {
        label:
          TechEntityName === "Government"
            ? `${TechEntityName} (Requires Authorization)`
            : TechEntityName,
        value,
      };
    }
  );

  const orgSubTypeOptions = orgSubTypes.map(
    ({ TechEntitySubName: label, TechEntitySubTypeID: value }) => {
      return {
        label,
        value,
      };
    }
  );

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        Add Organization
        <IconButton
          className={classes.closeIcon}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} className="p-4">
          <div className="flex flex-col justify-center items-start">
            <section className="">
              <h3>General</h3>
              <div className="px-2">
                <TextField
                  error={!!errors["userName"]}
                  helperText={errors["userName"] ? errors["userName"] : ""}
                  required
                  value={name || ""}
                  onChange={handleChange}
                  label="Name"
                  name="name"
                />
              </div>
              <Select
                required
                className={classes.formField}
                label="Org Type"
                options={orgTypeOptions}
                name="orgType"
                onChange={handleChange}
                value={orgType}
              ></Select>

              <Select
                required
                className={classes.formField}
                label="Sub Type"
                options={orgSubTypeOptions}
                name="subType"
                onChange={handleChange}
                value={subType}
              ></Select>

              <Select
                required
                className={classes.formField}
                label="Category"
                options={categories.map((c) => ({
                  label: c.TechEntityCategoryName,
                  value: c.NAICSCode,
                }))}
                name="category"
                onChange={handleChange}
                value={category}
              ></Select>
              <Select
                required
                className={classes.formField}
                label="Sector"
                options={sectors.map((s) => ({
                  label: `${s.NAICSCode} | ${s.NAICSTitle}`,
                  value: s.NAICSCode,
                }))}
                name="selectedSector"
                onChange={handleChange}
                value={selectedSector}
              ></Select>
            </section>
            <Select
              required
              className={classes.formField}
              label="Role"
              options={roles}
              name="orgRole"
              onChange={handleChange}
              value={orgRole}
            ></Select>
            <section className="flex flex-col w-full sm:w-96 py-6">
              <h3>Address</h3>
              <div className="p-2">
                <Address
                  address={address}
                  city={city}
                  state={state}
                  zip={zip}
                  onAddressChange={handleAddressChange}
                />
              </div>
            </section>

            <TextField
              className={classes.formField}
              value={email || ""}
              onChange={handleChange}
              label="Email"
              name="email"
            />
            <TextField
              className={classes.formField}
              value={yearsInBus || ""}
              onChange={handleChange}
              label="Years In Business"
              name="yearsInBus"
            />

            <TextField
              required
              className={classes.formField}
              value={phone || ""}
              onChange={handleChange}
              label="Phone"
              name="phone"
            />
            <div className="pt-4 -ml-2">
              <TextareaAutosize
                name="description"
                rowsMin={3}
                placeholder="Description"
                onChange={handleChange}
                value={description}
              ></TextareaAutosize>
            </div>
          </div>

          <DialogActions>
            <Button size="small" type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={submitDisabled}
              size="small"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  async function handleAddressChange(address) {
    dispatch({
      type: "address",
      payload: address?.street_line ?? "",
    });
    dispatch({
      type: "city",
      payload: address?.city ?? "",
    });
    dispatch({
      type: "state",
      payload: address?.state ?? "",
    });
    dispatch({
      type: "zip",
      payload: address?.zipcode ?? "",
    });
    dispatch({
      type: "county",
      payload: address?.county ?? "",
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    /* const {
      data: { data: User },
    } = await axios.put(`${getBaseUrl()}/user/cog`, {
      userName: userName,
      firstName: firstName,
      lastName: lastName,
      profileType: userType,
      orgId: orgId,
    }); */

    /*   onClose(false);
    if (User) {
      setUserCreated(true);
    }
    const subId = getSub(User?.User?.Attributes);
    console.log("subId", subId);
    if (subId) {
      updateUserIncId({ subId, userName });
    } */
    //fetchCognitoUserList();
  }
  function getSub(attributes = []) {
    return attributes.find((a) => a.Name === "sub")?.Value;
  }

  async function fetchOrgTypes() {
    const {
      data: { data: orgTypes },
    } = await axios.get(`${getBaseUrl()}/entity/types?rowCnt=100&page=1`);
    setOrgTypes(orgTypes);
  }

  async function fetchOrgSubTypes() {
    const {
      data: { data: subTypes },
    } = await axios.get(
      `${getBaseUrl()}/entity/sub_types?rowCnt=100&page=1&type=${orgType}`
    );
    setOrgSubTypes(subTypes);
  }

  async function fetchOrgCategories() {
    const {
      data: { data: categories },
    } = await axios.get(
      `${getBaseUrl()}/entity/categories?rowCnt=100&page=1&type=${orgType}&sub_type=${subType}`
    );
    dispatch({
      type: "categories",
      payload: categories,
    });
  }

  async function fetchOrgSectors() {
    const {
      data: { data: sectors },
    } = await axios.get(`${getBaseUrl()}/entity/sectors`);
    dispatch({
      type: "sectors",
      payload: sectors,
    });
  }

  async function fetchEntityRoleOptions() {
    const {
      data: { data: roles },
    } = await axios.get(`${getBaseUrl()}/entity/roles`);
    setRoleOptions(
      roles.map(({ TechEntityRoleName: label, TechEntityRoleID: value }) => ({
        label,
        value,
      }))
    );
  }
  /* async function updateUserIncId(subId, userName) {
      a const {
      data: { data: user },
    } = await axios.post(`${getBaseUrl()}/admin/user/${userId}`, {
      userId,
      subId,
    });

  } */
  function handleChange(e) {
    const { value, name } = e.target;

    dispatch({
      type: name,
      payload: value,
    });
  }

  async function validateEmailAndUpdate(e) {
    //remove userName related error msgs
    const err = { ...errors };
    delete err["userName"];
    setErrors(err);
    const { name, value } = e.target;
    dispatch({
      type: name,
      payload: value,
    });

    if (validateEmail(value)) {
      const { data: msg } = await axios.post(`${getBaseUrl()}/user/cog`, {
        userName: value,
      });

      if (msg.errorMessage) {
        setErrors({ ...errors, userName: msg.errorMessage });
      }
    }
  }
}
