import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { FaRegFolder } from "react-icons/fa";
import { MdContacts, MdHome } from "react-icons/md";

import { Breadcrumbs, Link as MatLink } from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import axios from "../../../../utils/axios";

import { formatDate } from "../../../../utils/formatters";
import MaterialUITable from "../../../shared/Table/MaterialUITable";
import Select from "../../../shared/Select";
import CardItem from "../../../shared/card/CardItem";
import { getBaseUrl } from "../../../../utils/queryHelpers";
import useCreateRankingDialog from "../ranking/useCreateRankingDialog";
import RankingManager from "./mgr/RankingsManager";
import CreateRankingButton from "../ranking/CreateRankingButton";
import RankingTimeline from "../ranking/RankingTimeline";

function DrawingRankings(props) {
  const { drawingId } = useParams();

  const [rankings, setRankings] = useState([]);
  const [isLoadingRecords, setLoading] = useState(false);

  const [rankingStatus, setRankingStatus] = useState("A");
  const history = useHistory();
  const [isRankingCreationAllowed, setIsRankingCreationAllowed] = useState(
    false
  );

  const { showRankingDialog, toggleRankingDialog } = useCreateRankingDialog();

  async function fetchRankings() {
    setLoading(true);
    const {
      data: { data: rankings },
    } = await axios.get(
      `${getBaseUrl()}/lottery/${drawingId}/rankings?rowCnt=500&page=1`
    );
    setLoading(false);
    setRankings(rankings);
  }

  async function fetchDrawingStatus() {
    const {
      data: { data: status },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}/status`);

    setIsRankingCreationAllowed(status.ProcessFlag !== "N");
  }

  useEffect(() => {
    fetchRankings();
    fetchDrawingStatus();
  }, []);

  const handleChange = (event) => {
    setRankingStatus(event.target.value);
  };

  const filteredRankings =
    rankingStatus === "All"
      ? rankings
      : rankings.filter((r) => r.RecordStatus === rankingStatus);

  const [ranking = {}] = rankings;

  return (
    <div className="min-h-screen p-2">
      <Breadcrumbs className="p-2 mt-4" aria-label="breadcrumb">
        <MatLink color="inherit" href="/drawings">
          Drawings
        </MatLink>
        <MatLink color="inherit" href={`/drawings/${drawingId}`}>
          {drawingId}
        </MatLink>

        <MatLink color="inherit">Rankings</MatLink>
      </Breadcrumbs>

      <div className="mt-4 flex justify-end p-2 items-end w-full flex-wrap">
        <div className="p-4 mr-auto shadow-md flex flex-col  rounded items-start w-full sm:w-2/5 h-56">
          <div className="p-4  w-full flex flex-col rounded items-start   ">
            <CardItem
              label="Drawing ID"
              Icon={FaRegFolder}
              value={ranking.LotteryScheduleID}
            />
            <CardItem
              label="Provider"
              Icon={MdContacts}
              value={ranking.EntityName}
            />
            <CardItem
              label="Program"
              Icon={MdHome}
              value={ranking.ProgramTitle}
            />
          </div>
        </div>

        <section className="filters mt-4 p-2 border-1 border-gray-300 flex flex-col shadow w-full sm:h-56 sm:w-1/2">
          <h6 className="text-gray-700 font-semibold oblique">Filters</h6>
          <Select
            options={[
              { label: "Active", value: "A" },
              { label: "InActive", value: "I" },
              { label: "All", value: "All" },
            ]}
            onChange={handleChange}
            value={rankingStatus}
          />
        </section>
      </div>
      <div className="flex justify-end p-4">
        <CreateRankingButton
          disabled={!isRankingCreationAllowed}
          onClick={() => toggleRankingDialog(true)}
        />
      </div>

      <RankingManager
        open={showRankingDialog}
        onClose={() => toggleRankingDialog(false)}
        drawingId={drawingId}
      />

      <div className="mt-4">
        <RankingsList
          rankings={filteredRankings}
          archiveRanking={archiveRanking}
          unArchiveRanking={unArchiveRanking}
          loading={isLoadingRecords}
        />
      </div>
    </div>
  );

  async function archiveRanking(event, rowData) {
    const { LotteryRankingSmryID } = rowData;
    setLoading(true);
    await axios.post(
      `${getBaseUrl()}/rankings/${LotteryRankingSmryID}/archive?action=I`
    );
    await fetchRankings();
  }

  async function unArchiveRanking(event, rowData) {
    const { LotteryRankingSmryID } = rowData;
    setLoading(true);
    await axios.post(
      `${getBaseUrl()}/rankings/${LotteryRankingSmryID}/archive?action=A`
    );
    await fetchRankings();
  }
}

export default DrawingRankings;

export function RankingsList({
  isLoadingRecords,
  rankings,
  unArchiveRanking,
  archiveRanking,
}) {
  const tableColumns = [
    {
      title: "Index",
      field: "ranking_id",
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) =>
        rowData.RecordStatus === "A" ? "Active" : "Archived",
    },
    {
      title: "Type",
      field: "description",
    },
    {
      title: "Created",
      field: "date_created",
      render: (rowData) => formatDate(rowData.date_created),
    },
    {
      title: "Ranked",
      field: "date_added",
      render: (rowData) =>
        formatDate(rowData.date_added, "MM/dd/yyyy/ HH:mm:ss a"),
    },
    {
      title: "Last Action",
      field: "LotteryStatusDesc",
    },
    {
      title: "# Applicants",
      field: "NumApplicants",
    },
  ];

  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <MaterialUITable
      isLoading={isLoadingRecords}
      columns={tableColumns}
      data={rankings}
      title={`Rankings (${rankings.length})`}
      options={{
        exportButton: true,
        pageSize: 10,
        grouping: true,
        actionsColumnIndex: -1,
      }}
      actions={[
        (rowData) => ({
          icon: ArrowForwardIosIcon,
          tooltip: "Process this record",
          onClick: (event, rowData) => {
            history.push(`${url}/${rowData.LotteryRankingSmryID}`);
          },
          disabled: rowData.RecordStatus !== "A",
        }),
        (rowData) => ({
          icon: UnarchiveIcon,
          tooltip: "UnArchive",
          onClick: unArchiveRanking,
          disabled: rowData.RecordStatus !== "I",
        }),
        (rowData) => ({
          icon: ArchiveIcon,
          tooltip: "Archive",
          onClick: archiveRanking,
          disabled: rowData.RecordStatus === "I",
        }),
      ]}
      detailPanel={(rowData) => {
        return <RankingTimeline rankingId={rowData.LotteryRankingSmryID} />;
      }}
    />
  );
}
