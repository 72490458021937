import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { FaCity, FaUsers, FaHome, FaCalendar } from "react-icons/fa";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { BsFillHouseFill } from "react-icons/bs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import MaterialUITable from "../../../components/shared/Table/MaterialUITable";
import {
  formatAsUSCurrency,
  formatDateAndTime,
} from "../../../utils/formatters";
import { getRankingType, sortTiers } from "../../../utils/drawingUtils";
import Preferences from "./ranking/Preferences";
import logo from "../../../media/HK-assets/hk_logo_mk.png";

import OpportunityCard from "./OpportunityCard";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { useDrawingPreferences } from "../../../queries/programs/useDrawingPreferences";

function CardItem({ Icon, label, value }) {
  return (
    <span className="mt-2 flex  items-baseline">
      <Icon className="text-background-blue mr-2" />

      <span className="text-gray-700 text-left text-sm oblique">{label}:</span>
      <span className="px-2 text-gray-600 text-sm">{value}</span>
    </span>
  );
}

export default function LotteryDetail(props) {
  const [results, setResults] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [opps, setOpps] = useState([]);

  const { drawingId } = useParams();

  const [result = {}] = results;
  //const drawing = useDrawingDetails(drawingId);
  const preferences = useDrawingPreferences(drawingId, result.ProgramID);

  useEffect(() => {
    fetchLotteryResults();
  }, []);

  useEffect(() => {
    fetchOpportunity();
  }, [result.ProgramID]);

  async function fetchLotteryResults() {
    setLoading(true);
    const {
      data: { data: results },
    } = await axios.get(`${getBaseUrl()}/lottery/${drawingId}/results`);
    setResults(
      results.map((r) => {
        return {
          ...r,
          PreferenceValues: sortTiers(r.PreferenceValues, true),
        };
      })
    );
    setLoading(false);
  }

  async function fetchOpportunity() {
    if (result.ProgramID) {
      const {
        data: { data: opps },
      } = await axios.get(
        `${getBaseUrl()}/opportunities/?drawingId=${drawingId}&programId=${
          result.ProgramID
        }`
      );

      setOpps(opps);
    }
  }

  const tableColumns = [
    {
      title: "Applicant ID",
      field: "ApplicationFileID",
    },
    {
      title: "Total Points",
      field: "TotalPoints",
    },
    {
      title: "Initial Rank",
      field: "InitialRank",
    },
    {
      title: "Final Rank",
      field: "LastRank",
    },

    {
      title: "Preference Tiers",
      field: "PreferenceValues",
    },
  ];

  const opportunity = opps?.[0] ?? {};

  const pageSizeOptions = [
    10,
    20,
    50,
    ...(results.length > 50 ? [results.length] : []),
  ];

  return (
    <section className="min-h-screen p-2">
      <div className="flex space-x-2 items-start justify-end p-4">
        <Button
          color="primary"
          size="small"
          variant="contained"
          endIcon={<SaveAltIcon />}
          onClick={exportResults}
        >
          Download
        </Button>
      </div>
      <div className="flex flex-col items-start  sm:flex-row w-full flex-wrap">
        <div className="mt-4 flex justify-end  w-full sm:w-1/5 flex-grow flex-wrap h-72 overflow-y-scroll">
          <div className="opp-card-header bg-hk-dark-blue py-2 rounded-t-md w-full flex h-10" />
          <div className="p-4  mr-auto shadow-md flex flex-col  w-full rounded items-start   ">
            <CardItem
              Icon={FaHome}
              label="Drawing ID"
              value={`${drawingId} (${result.LotteryRankingSmryID || ""})`}
            />
            <CardItem
              Icon={FaCity}
              label="Program"
              value={result.ProgramName}
            />

            <CardItem
              Icon={BsFillHouseFill}
              label="Project"
              value={result.PreComponentName}
            />

            <CardItem
              Icon={FaUsers}
              label="Applicants"
              value={result.ApplicantCnt}
            />
            <CardItem
              Icon={FaCalendar}
              label="Entry Deadline"
              value={result.DrawingDate}
            />

            <CardItem
              Icon={FaCalendar}
              label="Published"
              value={result.RankingDrawingDate}
            />
            <CardItem
              Icon={FaCalendar}
              label="File submission"
              value={result.FileSubmissionDeadline}
            />

            <CardItem
              Icon={FaCalendar}
              label="Drawing Type"
              value={result.DrawingTypeDesc}
            />

            <CardItem
              Icon={FaCalendar}
              label="Ranking Type"
              value={getRankingType(result.FinalType)}
            />
          </div>
        </div>

        <div className="mt-4  flex flex-col  h-72 overflow-y-scroll shadow-md min-h-32  sm:ml-4 w-full sm:w-2/5 flex-wrap">
          <section className="lottery-result-opp-card w-full">
            <div className="opp-card-header bg-hk-dark-blue py-2 rounded-t-md w-full flex sticky top-0 z-10">
              <span className="font-semibold oblique sticky text-white px-2">
                Opportunities ({opps.length})
              </span>
            </div>

            {opps.map((o) => {
              return <OpportunityCard key={o.id} {...o} />;
            })}
          </section>
        </div>
        <Preferences drawingId={drawingId} programId={result.ProgramID} />
      </div>

      <div className="mt-4">
        <MaterialUITable
          isLoading={isLoading}
          columns={tableColumns}
          data={results}
          title={`Drawing Results for (${drawingId})`}
          options={{
            pageSizeOptions: pageSizeOptions,
            pageSize: 10,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    </section>
  );
  function exportResults() {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    let doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(10);
    doc.addImage(logo, "png", 30, 20, 100, 30);

    doc.text(`${result.ProgramName}`, 40, 100);
    doc.text(`Drawing Type: ${result.DrawingTypeDesc}`, 40, 120);
    doc.text(`Ranking Type: ${getRankingType(result.FinalType)}`, 40, 140);
    doc.text(
      `Drawing Id: ${drawingId}, ranking: ${result.LotteryRankingSmryID}`,
      40,
      160
    );

    doc.text(`Pre Component Name  : ${result.PreComponentName}`, 40, 180);
    doc.text(`Entry Deadline  : ${result.DrawingDate}`, 40, 200);
    doc.text(
      `File Submission Deadline  : ${result.FileSubmissionDeadline}`,
      40,
      220
    );

    //opportunities
    doc.text(`Opportunities (${opps.length})`, 80, 240);
    let oppsContent = {
      startY: 260,
      head: [
        [
          "Address",
          "City",
          "State",
          "Zip",
          "Income Level",
          "Price",
          "Max hhld Size",
          "Min hhld Size",
          "PreComponent Name",
          "AMI",
        ],
      ],
      body: opps.map((o) => [
        o.Address1,
        o.City,
        o.State,
        o.ZipCode,
        o.CandidateIncomeLevelDesc,
        formatAsUSCurrency(o.PurchaseRentalFinanceAmt),
        o.MaxHHLDSize,
        o.MinHHLDSize,
        o.PreComponentName,
        `${o.AMIPct} %`,
      ]),
    };
    doc.autoTable(oppsContent);

    //preferences
    doc.addPage(size, orientation);
    doc.text(`Preferences (${preferences.length})`, 80, 40);
    let prefsContent = {
      startY: 60,
      head: [["Tier", "Preference", "Points"]],
      body: preferences.map((p) => [p.Priority, p.PreferenceTitle, p.Points]),
    };
    doc.autoTable(prefsContent);
    //applicants
    doc.addPage(size, orientation);
    doc.text(
      `Applicants (${results.length}) in Ranking Index ${result.LotteryRankingSmryID}, Drawing Index ${drawingId} `,
      80,
      40
    );
    doc.text(`Published:  ${result.RankingDrawingDate} `, 80, 60);

    let applicantsContent = {
      startY: 80,
      head: [tableColumns.flatMap((t) => t.title)],
      body: results.map((a, i) => {
        return [
          a.ApplicationFileID,
          a.TotalPoints,
          a.InitialRank,
          a.LastRank,
          a.PreferenceValues,
        ];
      }),
    };
    doc.autoTable(applicantsContent);
    doc.save(`Drawing(${drawingId})_results.pdf`, { returnPromise: true });
  }
}
