import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardContent, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    height: 400,
    overflowY: "scroll",
    position: "relative",

    "&& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    cursor: "pointer",
    "&:hover": {
      boxShadow: "10px 10px 10px 10px  #edf2f7",
    },
  },
  header: {
    position: "sticky",
    top: 0,
    left: 0,
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export default function HHldDrawings({ drawings }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.root}>
      <CardHeader
        onClick={() => history.push("/hhld/drawings")}
        disableTypography
        subheader={
          <Tooltip title="Click to go to Program Center">
            <div className="bg-hk-cyan-100 p-2 flex flex-col">
              <span className="text-lg tracking-wider text-gray-600">{`My Drawings (${drawings.length})`}</span>
              <span className="text-xs text-gray-400 oblique">
                (Drawings I've entered)
              </span>
            </div>
          </Tooltip>
        }
      ></CardHeader>

      <CardContent>
        <div>
          {drawings.map((d) => (
            <CardItem key={d.opportunityDrawingId} drawing={d} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

function CardItem({ drawing }) {
  const {
    entityName,
    optOutDate,
    submitDate,
    programTitle,
    applicantOptOutRequestId,
    lotteryRosterId,
    entryNo,
  } = drawing;
  return (
    <div className="py-4  px-6 rounded flex flex-col mt-2 text-sm  border border-gray-300">
      <span className="">{programTitle}</span>
      <span className="text-gray-600 mt-2">{entityName}</span>
      <div className="flex space-x-2 mt-6">
        <span className="font-semibold">Submitted On</span>
        <span>{submitDate}</span>
      </div>

      <span className="mt-4 text-hk-orange-400 p-2 rounded  font-semibold bg-hk-orange-100">
        {`${lotteryRosterId}-${entryNo}`}
      </span>
      {applicantOptOutRequestId != "" ||
        (applicantOptOutRequestId != 0 && (
          <span className="bg-red-200 text-red-800 font-semibold p-2 mt-6 rounded">
            Opted Out on {optOutDate}
          </span>
        ))}
    </div>
  );
}
