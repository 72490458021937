import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useDrawingDetails(drawingId) {
  const [drawing, setDrawing] = useState([]);

  useEffect(() => {
    fetchDrawingDetail();
  }, [drawingId]);

  async function fetchDrawingDetail() {
    const {
      data: { data: drawing },
    } = await axios.get(`${getBaseUrl()}/drawings/${drawingId}`);
    setDrawing(drawing);
  }

  return [drawing, fetchDrawingDetail];
}
