import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

export function useRanking(rankingId) {
  const [ranking, setRanking] = useState({});

  useEffect(() => {
    (async () => {
      const {
        data: { data: ranking },
      } = await axios.get(`${getBaseUrl()}/rankings/${rankingId}`);
      setRanking(ranking);
    })();
  }, [rankingId]);

  return ranking;
}
