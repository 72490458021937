import React from "react";

export default function CardItem({ Icon, label, value }) {
  return (
    <span className="py-1 flex  items-center w-full">
      <Icon className="text-background-blue mr-2 " />

      <span className="text-gray-900 text-sm w-1/2 text-left">{label}:</span>
      <span className="px-2 text-gray-600 text-sm w-1/2">{value}</span>
    </span>
  );
}
