import React from "react";
import hkSpinner from "../../../media/HK-assets/hk_spinner.png";

export default function HkLoading({ containerHeight = "" }) {
  return (
    <div
      className={`flex border-1 relative justify-center py-2 items-center ${containerHeight}`}
    >
      <div className="absolute z-50 w-16 h-16 border-b-2 rounded-full animate-spin border-hk-dark-blue"></div>
      <img src={hkSpinner} className="w-12 h-12" />
    </div>
  );
}
