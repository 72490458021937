import React, { useReducer, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { makeStyles } from "@material-ui/core/styles";
import { formatAsUSCurrency } from "../../../../utils/formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  checkboxLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.8rem",
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
}));

const formatHhldIncome = (value) => {
  console.log("formatHhldIncome value", value);
  return formatAsUSCurrency(value.substring(1));
};

export default function HHldApplication(props) {
  const classes = useStyles();
  const [state, dispatch] = useReducer(formReducer, {
    city: "",
    county: "",
    totalHhldIncome: "",
    numHhld: 0,
    householdMembers: [],
  });

  const [addHhld, setAddHhld] = useState(false);

  const {
    city,
    county,
    zip,
    numHhld,
    totalHhldIncome,
    householdMembers,
  } = state;

  /* const hhldIncome =
    totalHhldIncome > 0 ? formatHhldIncome(totalHhldIncome) : totalHhldIncome; */

  function handleChange(e) {
    let { value, name } = e.target;
    console.log("handleChange", value);
    //if (name === "totalHhldIncome") asynvalue = value.substring(1);
    dispatch({
      type: name,
      payload: value,
    });
  }

  function addHouseholdMember(hhldMember) {
    dispatch({
      type: "householdMembers",
      payload: {
        firstName: "",
        lastName: "",
        income: "",
        livesWithPrimary: false,
      },
    });
  }

  function removeHouseholdMember(hhldMember) {
    dispatch({
      type: "removeHouseholdMembers",
      payload: hhldMember,
    });
  }

  return (
    <section className="min-h-screen">
      <h3>Household Application here</h3>
      <form className="w-full mt-6" className={classes.root}>
        <section className="flex border-b border-gray-200 ">
          <section className="w-1/4 self-center ">
            <h5>Personal</h5>
          </section>

          <section className="w-3/4 px-4 flex flex-col justify-center border-l border-gray-300">
            <TextField
              name="city"
              required
              label="City"
              value={city}
              onChange={handleChange}
            ></TextField>
            <TextField
              name="county"
              label="County"
              value={county}
              onChange={handleChange}
            ></TextField>
            <TextField
              name="zip"
              label="Zip"
              value={zip}
              onChange={handleChange}
            ></TextField>
          </section>
        </section>

        <section className="flex ">
          <section className="w-1/4 self-center ">
            <h5>Household</h5>
          </section>

          <section className="w-3/4  flex flex-col justify-center border-l border-gray-300">
            <div className="flex">
              <TextField
                name="totalHhldIncome"
                label="Total Hhld Income"
                value={totalHhldIncome}
                onChange={handleChange}
              ></TextField>
              <Tooltip title="Add Household Member">
                <Button
                  size="small"
                  color="primary"
                  onClick={addHouseholdMember}
                  startIcon={<PersonAddIcon />}
                >
                  Add Household Member
                </Button>
              </Tooltip>
            </div>

            {householdMembers.map((h, i) => (
              <HouseholdMember key={i} />
            ))}
          </section>
        </section>
      </form>
    </section>
  );
}

function HouseholdMember({ handleChange, handleRemove }) {
  const classes = useStyles();
  return (
    <>
      <Button startIcon={<RemoveCircleIcon />} onClick={handleRemove}></Button>
      <TextField name="hhld-firstName" label="First Name"></TextField>
      <TextField name="hhld-lastName" label="Last Name"></TextField>
      <TextField name="hhld-email" label="Email"></TextField>
      <TextField name="hhld-dob" label="DOB"></TextField>
      <TextField name="hhld-primaryPhone" label="Primary Phone"></TextField>
      <TextField name="hhld-maritalStatus" label="Marital Status"></TextField>
      <TextField
        name="hhld-primaryRelation"
        label="Relationship with Primary"
      ></TextField>

      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            checked={true}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Lives at the household address provided?"
      />

      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            checked={true}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Contributes to the housing expense ?"
      />
      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            checked={false}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Income included in the total household income ?"
      />
    </>
  );
}

function formReducer(state, action) {
  console.log("formReducer", action);
  switch (action.type) {
    case "numHhld":
      return { ...state, numHhld: action.payload };
    case "totalHhldIncome":
      return { ...state, totalHhldIncome: action.payload };
    case "city":
      return { ...state, city: action.payload };
    case "county":
      return { ...state, county: action.payload };
    case "zip":
      return { ...state, zip: action.payload };

    case "householdMembers":
      return {
        ...state,
        householdMembers: state.householdMembers.concat(action.paylod),
      };
  }
}
