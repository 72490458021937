import React, { useReducer, useEffect } from "react";
import axios from "../../utils/axios";
import { getBaseUrl } from "../../utils/queryHelpers";

function reducer(state, { type, payload }) {
  if (type === "applicants") {
    return {
      ...state,
      applicants: payload.applicants,
      counts: payload.counts,
      demographics: payload.demographics,
    };
  }

  if (type === "reset") {
    return {
      ...state,
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      timePeriod: "",
      amount: "",
      county: "",
      provider: "",
      programIncomeLevel: "",
      numMembers: "",
      phone: "",
      showOnlyUsersWithFileUploads: false,
    };
  }
  return {
    ...state,
    [type]: payload,
  };
}

export function useProgramApplicants(programId) {
  const [state, dispatch] = useReducer(reducer, {
    applicants: [],
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    timePeriod: "",
    phone: "",
    amount: "",
    county: "",
    provider: "",
    programIncomeLevel: "",
    numMembers: "",
    searching: false,
    loading: false,
    showOnlyUsersWithFileUploads: false,
  });

  const {
    firstName,
    lastName,
    city,
    email,
    county,
    amount,
    provider,
    numMembers,
    programIncomeLevel,

    phone,
    searching,
    showOnlyUsersWithFileUploads,
  } = state;

  useEffect(() => {
    if (!programId || !searching) return;
    fetchProgramApplicants(programId);
  }, [searching]);

  return [state, dispatch];

  async function fetchProgramApplicants(programId) {
    dispatch({
      type: "loading",
      payload: true,
    });
    const {
      data: { data },
    } = await axios.get(
      `${getBaseUrl()}/programs/${programId}/applicants?firstName=${firstName}&lastName=${lastName}&city=${city}&county=${county}&email=${email}&numMembers=${numMembers}&incomeLevel=${programIncomeLevel}&showOnlyUsersWithFileUploads=${showOnlyUsersWithFileUploads}&page=1&rowCnt=5000`
    );

    dispatch({
      type: "applicants",
      payload: data,
    });
    dispatch({
      type: "loading",
      payload: false,
    });
    dispatch({
      type: "searching",
      payload: false,
    });
  }
}
