import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Button,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import MaterialUITable from "../../shared/Table/MaterialUITable";
import TextField from "../../shared/form/TextField";
import PopperMsg from "../../shared/misc/PopperMsg";
import AddUserModal from "./AddUser";
import AddFilePermsModal from "./AddFilePermsModal";
import axios from "../../../utils/axios";
import { allowUserEmulation } from "../../../utils/authUtils";
import { getBaseUrl } from "../../../utils/queryHelpers";
import { MTablePagination } from "material-table";
import UserContext from "../UserContext";
import useLocalStorage from "../../shared/hooks/useLocalStorage";
import { usePrograms } from "../../../queries/programs/usePrograms";
import useQueryParams from "../../shared/hooks/useQuery";

const initState = (qryParams) => ({
  firstName: qryParams?.get("firstName") ?? "",
  lastName: qryParams?.get("lastName") ?? "",
  page: 0,
  limit: 50,
  profileType: "",
  userName: qryParams?.get("userName") ?? "",
  userId: qryParams?.get("userId") ?? "",
  loadingUser: false,
  showUserModal: false,
  showFilePermsModal: false,
  showOnlyUsersWithFileUploads:
    qryParams?.get("showOnlyUsersWithFileUploads") === true ? true : false,
  selectAllWithoutFilePrivs:
    qryParams?.get("selectAllWithoutFilePrivs") ?? false,
  drawings: [],
  selectedDrawingId: qryParams?.get("drawingId") ?? "",
  selectedProgramId: qryParams?.get("programId") ?? "",
  excludeClosedDrawings: true,
});

const reducer = (state, action) => {
  switch (action.type) {
    case "firstName":
      return { ...state, page: 0, firstName: action.payload };
    case "lastName":
      return { ...state, page: 0, lastName: action.payload };
    case "userName":
      return { ...state, page: 0, userName: action.payload };
    case "userId":
      return { ...state, page: 0, userId: action.payload };
    case "profileType":
      return { ...state, page: 0, profileType: action.payload };
    case "page":
      return { ...state, page: action.payload };
    case "limit":
      return { ...state, limit: action.payload };
    case "loadingUsers":
      return { ...state, loadingUsers: action.payload };
    case "selectAllWithoutFilePrivs":
      return { ...state, selectAllWithoutFilePrivs: action.payload };

    case "showFilePermsModal":
      return { ...state, showFilePermsModal: action.payload };
    case "showUserModal":
      return { ...state, showUserModal: action.payload };

    case "showOnlyUsersWithFileUploads":
      return { ...state, showOnlyUsersWithFileUploads: action.payload };
    case "reset":
      return { ...initState(), drawings: state.drawings };
    case "selectedDrawingId":
      return {
        ...state,
        selectedDrawingId: action.payload,
      };

    case "selectedProgramId":
      return {
        ...state,
        selectedProgramId: action.payload,
      };
    case "excludeClosedDrawings":
      return { ...state, excludeClosedDrawings: action.payload };

    case "drawings":
      return { ...state, drawings: action.payload };
    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

const drawingStatuses = new Map([
  ["open", 43],
  ["pending", 45],
  ["closed", 44],
]);

export default function Users(props) {
  const [users, setUsers] = useState([]);
  const [errorMsg, setError] = useState("");
  const history = useHistory();
  const { user, setUser } = useContext(UserContext) || {};
  const programs = usePrograms();

  const classes = useStyles();

  const qryParams = useQueryParams();

  const [
    {
      drawings,
      firstName,
      lastName,
      page,
      limit,
      profileType,
      userId,
      userName,
      loadingUsers,
      showUserModal,
      showFilePermsModal,
      showOnlyUsersWithFileUploads,
      selectAllWithoutFilePrivs,
      selectedDrawingId,
      selectedProgramId,

      excludeClosedDrawings,
    },
    dispatch,
  ] = useReducer(reducer, initState(qryParams));

  const [_, setEmulatedUser] = useLocalStorage("emulatedUser");

  useEffect(() => {
    fetchUsers();
    fetchDrawings();
  }, []);

  const usersWhoAreAdminAndNoFilePerms = users.filter(
    (u) => u.role_admin == 1 && !u.fileGroupMemberId
  );

  const filteredDrawings = excludeClosedDrawings
    ? drawings.filter(
        (d) =>
          d.DrawingStatus === drawingStatuses.get("open") ||
          d.DrawingStatus === drawingStatuses.get("pending")
      )
    : drawings;

  const tableColumns = [
    { title: "User ID", field: "userId" },
    { title: "Member ID", field: "memberId" },
    { title: "User Name", field: "userName" },
    { title: "First", field: "firstName" },
    { title: "Last", field: "lastName" },
    { title: "Account Status", field: "accountStatus" },
    { title: "Profile Type", field: "profileType" },
    { title: "# Files", field: "fileCount" },
    {
      title: "Hhld Admin",
      field: "role_admin",
      render: (rowData) => (rowData.role_admin == 1 ? "Y" : "N"),
    },
    { title: "Org", field: "entityName" },
  ];

  return (
    <section className="min-h-screen  flex flex-col ">
      <h2 className="text-2xl text-gray-600 bg-hk-orange-100 text-hk-orange-400 uppercase h-20 flex justify-center items-center">
        Users
      </h2>
      <form
        className="flex flex-col flex-wrap  items-baseline md:flex-row gap-4 p-4"
        onSubmit={fetchUsers}
      >
        <TextField
          label="User Name"
          value={userName}
          onChange={handleChange}
          name="userName"
        />
        <TextField
          label="User ID"
          value={userId}
          onChange={handleChange}
          name="userId"
        />
        <TextField
          label="First"
          value={firstName}
          onChange={handleChange}
          name="firstName"
        />
        <TextField
          label="Last"
          value={lastName}
          onChange={handleChange}
          name="lastName"
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="drawings">
            Drawings ({filteredDrawings.length})
          </InputLabel>
          <Select
            name="selectedDrawingId"
            value={selectedDrawingId}
            onChange={handleChange}
            id="drawings"
          >
            {buildSelectItems(groupDrawingsByProgramId(filteredDrawings))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="programs">
            Programs ({programs.length})
          </InputLabel>
          <Select
            name="selectedProgramId"
            value={selectedProgramId}
            onChange={handleChange}
            id="programs"
          >
            {programs.map((p) => (
              <MenuItem
                key={p.ProgramID}
                value={p.ProgramID}
              >{`${p.Title} (${p.numApplicants})`}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={excludeClosedDrawings}
                onChange={(e) =>
                  dispatch({
                    type: "excludeClosedDrawings",
                    payload: !excludeClosedDrawings,
                  })
                }
              />
            }
            label="Exclude Closed drawings"
          />
        </FormGroup>

        <div className="flex gap-2">
          <Button
            size="small"
            type="submit"
            color="primary"
            variant="contained"
          >
            Search
          </Button>
          <Button size="small" onClick={clearSearchFields}>
            Clear
          </Button>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={showOnlyUsersWithFileUploads}
              onChange={handleChange}
              name="showOnlyUsersWithFileUploads"
            />
          }
          label="Show only users with file uploads"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={selectAllWithoutFilePrivs}
              onChange={handleChange}
              name="selectAllWithoutFilePrivs"
            />
          }
          label="Select all without file permissions"
        />
      </form>
      <section className="pt-4 text-right">
        <Button
          color="primary"
          disabled={!selectAllWithoutFilePrivs}
          startIcon={<FileCopyIcon />}
          onClick={showAddFilePermsModal}
          variant="contained"
          size="small"
        >
          Add File Permissions
        </Button>
        <Button
          color="primary"
          startIcon={<PersonAddIcon />}
          onClick={showAddUserModal}
        >
          Add User
        </Button>

        <AddUserModal
          onClose={onCloseUserModal}
          open={showUserModal}
          updateUserIncId={updateUserIncId}
        />
        <AddFilePermsModal
          handleAdd={() => addFilePermissions(usersWhoAreAdminAndNoFilePerms)}
          users={usersWhoAreAdminAndNoFilePerms}
          onClose={() => {
            dispatch({
              type: "showFilePermsModal",
              payload: false,
            });
          }}
          open={showFilePermsModal}
        />
      </section>
      <section className="p-2">
        <MaterialUITable
          columns={tableColumns}
          isLoading={loadingUsers}
          onChangeRowsPerPage={handleRowsPerPageChange}
          stickyHeader
          className="p-4 mt-4"
          data={users}
          title={`Users (${users[0]?.totalRecords ?? 0})`}
          components={{
            Pagination: (props) => (
              <MTablePagination
                {...props}
                count={users[0]?.totalRecords ?? 0}
                onChangePage={handlePageChange}
                page={page}
              />
            ),
          }}
          options={{
            exportButton: true,
            pageSize: limit,
            paging: true,
            grouping: true,
            actionsColumnIndex: -1,
            pageSizeOptions: [50],
            rowStyle: (rowData) => {
              return !rowData.fileGroupMemberId &&
                rowData.role_admin == 1 &&
                selectAllWithoutFilePrivs
                ? {
                    backgroundColor: "#4986a7",
                    color: "#fff",
                  }
                : {};
            },
          }}
          actions={[
            {
              icon: ChevronRightIcon,
              tooltip: "View Details",

              onClick: (event, rowData) => {
                viewUserDetails(rowData.userId);
              },
            },
            (rowData) => ({
              icon: "A",
              tooltip: "Make hhld Admin ",
              disabled: rowData.role_admin == 1,
              onClick: (event, rowData) => {
                promoteToAdmin(rowData.userId, rowData.memberId);
              },
            }),
            (rowData) => ({
              icon: SupervisorAccountIcon,
              tooltip: "Emulate User",
              disabled: !allowUserEmulation(user),
              onClick: (event, rowData) => {
                emulateUser(rowData.userName, rowData.profileType);
              },
            }),
            (rowData) => ({
              icon: FileCopyIcon,
              tooltip:
                rowData.role_admin != 1
                  ? "user is not hhld admin"
                  : rowData.fileGroupMemberId != null
                  ? "user has file perimissions assigned"
                  : "Add File permissions",
              disabled: !!rowData.fileGroupMemberId || rowData.role_admin != 1,
              onClick: (event, rowData) => {
                addFilePermissions(rowData.userId);
              },
            }),
          ]}
        />
      </section>
      <PopperMsg
        msgType="error"
        open={errorMsg !== ""}
        handleClose={clearError}
      >
        <p>{errorMsg}</p>
      </PopperMsg>
    </section>
  );

  async function handlePageChange(e, page) {
    dispatch({
      type: "page",
      payload: page,
    });
    fetchUsers();
  }

  async function viewUserDetails(userId) {
    history.push({
      pathname: `/user/${userId}`,
    });
  }

  async function emulateUser(userName, profileType) {
    setEmulatedUser({
      userName,
      profileType,
    });
    await setUser();
    window.location.reload();
  }

  async function handleRowsPerPageChange(pageSize) {
    console.log("handleRowsPerPageChange pageSize", pageSize);
  }

  async function fetchUsers(e) {
    e?.preventDefault();
    dispatch({
      type: "loadingUsers",
      payload: true,
    });

    history.push({
      pathname: "/user",
      search: `?profileType=${profileType}&userName=${userName}&userId=${userId}&firstName=${firstName}&lastName=${lastName}&limit=${limit}&page=${page}&hasFilePermissions=false&drawingId=${selectedDrawingId}&showOnlyUsersWithFileUploads=${showOnlyUsersWithFileUploads}&programId=${selectedProgramId}`,
    });
    try {
      const {
        data: { data: users },
      } = await axios.get(
        `${getBaseUrl()}/user?profileType=${profileType}&userName=${userName}&userId=${userId}&firstName=${firstName}&lastName=${lastName}&limit=${limit}&page=${page}&hasFilePermissions=false&drawingId=${selectedDrawingId}&showOnlyUsersWithFileUploads=${showOnlyUsersWithFileUploads}&programId=${selectedProgramId}`
      );
      if (!users) setError("error loading users");
      setUsers(users || []);
    } catch (e) {
      setError("error loading users");
      setUsers([]);
    }
    dispatch({
      type: "loadingUsers",
      payload: false,
    });
  }

  function handleChange(e) {
    const { name, value, checked, type } = e.target;

    dispatch({
      type: name,
      payload: value ? value : type !== "text" ? checked : "",
    });
  }

  function onCloseUserModal() {
    dispatch({
      type: "showUserModal",
      payload: false,
    });
  }

  function updateUserIncId() {}

  function showAddUserModal() {
    dispatch({
      type: "showUserModal",
      payload: true,
    });
  }

  function showAddFilePermsModal() {
    dispatch({
      type: "showFilePermsModal",
      payload: true,
    });
  }

  function clearSearchFields() {
    dispatch({
      type: "reset",
    });
  }

  function clearError() {
    setError("");
  }

  async function addFilePermissions(userIds) {
    const {
      data: { data: perms },
    } = await axios.post(`${getBaseUrl()}/files/migrate`, {
      userids: Array.isArray(userIds)
        ? userIds.map((user) => user.userId)
        : [userIds],
    });
    dispatch({
      type: "showFilePermsModal",
      payload: false,
    });
    fetchUsers();
  }

  async function promoteToAdmin(userId) {
    try {
      const {
        data: { data: perms },
      } = await axios.post(`${getBaseUrl()}/user/${userId}`, {
        role_admin: 1,
      });
      if (!perms) setError("request could not be processed.");
    } catch (e) {
      setError("request failed");
    }
    fetchUsers();
  }

  async function fetchDrawings() {
    dispatch({
      type: "loadingUsers",
      payload: true,
    });
    const {
      data: { data: drawings },
    } = await axios.get(`${getBaseUrl()}/drawings?rowCnt=500&page=1`);
    dispatch({
      type: "loadingUsers",
      payload: false,
    });
    dispatch({
      type: "drawings",
      payload: drawings,
    });
  }
}

function buildSelectItems(drawings) {
  return Object.entries(drawings).map(([programId, drawings]) => {
    return [
      <ListSubheader>
        {drawings?.[0].title} ({drawings.length})
      </ListSubheader>,
      ...drawings.map((drawing) => {
        return (
          <MenuItem
            key={drawing.value}
            value={drawing.drawingId}
          >{`${drawing.title} (${drawing.drawingId})`}</MenuItem>
        );
      }),
    ];
  });
}

function groupDrawingsByProgramId(drawings) {
  return drawings.reduce((acc, curr) => {
    const { ProgramID, LotteryScheduleID, ProgramTitle } = curr;
    if (acc[ProgramID]) {
      acc[ProgramID].push({
        drawingId: LotteryScheduleID,
        title: ProgramTitle,
      });
    } else {
      acc[ProgramID] = [
        {
          drawingId: LotteryScheduleID,
          title: ProgramTitle,
        },
      ];
    }
    return acc;
  }, {});
}
