import React from "react";
import Avatar from "@material-ui/core/Avatar";

import { makeStyles } from "@material-ui/core/styles";
import BathtubIcon from "@material-ui/icons/Bathtub";
import HotelIcon from "@material-ui/icons/Hotel";
import { formatAsUSCurrency } from "../../../utils/formatters";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: "1rem",
  },
}));
export default function OpportunityCard(props) {
  const classes = useStyles();
  const {
    numBeds,
    numBaths,
    City,
    State,
    Address1,
    CandidateIncomeLevelDesc,
    PurchaseRentalFinanceAmt,
  } = props;
  return (
    <div>
      <div className="flex  p-2 items-center min-h-10">
        <Avatar />
        <div className="ml-2 p-2 flex flex-col">
          <span className="pl-2 oblique  text-xs text-gray-700">
            {Address1} {City && `, ${City} `} {State && `, ${State}`}
          </span>

          <div className="flex pl-2">
            <span className="flex items-center text-gray-700 text-xs">
              <HotelIcon className={classes.icon} />
              <span className="pl-1 font-bold">{numBeds}</span>
            </span>

            <span className="flex pl-2 items-center text-gray-700 text-xs ">
              <BathtubIcon className={classes.icon} />
              <span className="pl-1 ">{numBaths}</span>
            </span>

            <span className="flex pl-2 items-center text-teal-500  text-xs ">
              <span className="pl-1 oblique ">
                Income Level: {CandidateIncomeLevelDesc}
              </span>
            </span>
          </div>
        </div>

        <div className="flex flex-col ml-auto">
          <span className="text-hk-orange font-semibold text-xs border rounded-full border-hk-dark-blue p-2 oblique">
            {formatAsUSCurrency(PurchaseRentalFinanceAmt)}
          </span>
        </div>
      </div>

      <hr className="text-gray-500" />
    </div>
  );
}
